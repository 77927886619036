import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const TaxGroupList = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    

    const [user, setUser] = useState({});
    
    const [state, setstate] = useState(2);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [tax_list, settax_list] = useState([]);

    const [TaxGroupName, setTaxGroupName] = useState("");
    const [tax_group_id, settax_group_id] = useState("");
    const [status, setstatus] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [userlist, setuserList] = useState([])
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "tax-group-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Merchant',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "tax_group_name",
            label: "Tax Group Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];


    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])


    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id && validateJWT();
    }, [organization_id])

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);
 
    useEffect(() => {
        async function gettaxgroupdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            for(let i=0;i<body.length;i++)
            {
                body[i].check=false;
            }
            settax_list(body);
        }
        if (read || write) {
            user.email && visibility && gettaxgroupdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function gettaxgroupdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write) {
            user.email && visibility && gettaxgroupdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.tax_group_name = obj.tax_group_name;
                    temp_obj.tax_id = obj.tax_id;
                    temp_obj.status = obj.tax_group_status;
                    temp_obj.tax_group_id = obj.tax_group_id;
                    var id = obj.tax_group_id;
                    // console.log(id)
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateTaxgroup(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                        {/* <li><a href="#" onClick={(e) => { updateTaxgroup(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    function contains(a, obj) {
        for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
        }
        return false;
    }

    const updateTaxgroup = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].tax_group_id) {
                settax_group_id(editmodal[i].tax_group_id)
                setTaxGroupName(editmodal[i].tax_group_name);
                let tax=editmodal[i].tax_id.split(",")
                const values = [...tax_list];
        
                for(let i=0;i<values.length;i++)
                {

                    contains(tax,values[i].tax_id)
                    if(contains(tax,values[i].tax_id))
                    {
                        values[i].check=true;
                    }
                    else
                    {
                        values[i].check=false;
                    }
                    
                }
                settax_list(values)
                //console.log(values)

            }
        }
    }
    const submitTaxGroup = () => {
        
        settax_group_id("")
        setTaxGroupName("");
        const values1 = [...tax_list];
        
        for(let i=0;i<values1.length;i++)
        {     
            values1[i].check=false;

        }
        settax_list(values1)
           
    }
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].tax_group_id) {
                settax_group_id(editmodal[i].tax_group_id)
                setstatus(editmodal[i].tax_group_status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": tax_group_id, "status": status, "page_name": page_name };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-tax-group-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false) 
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Tax group details deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitTaxGroupDetails = (e) => {
        e.preventDefault();
        
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (TaxGroupName == "") {
            swal("Warning!", "Tax Group Name cannot be empty", "warning");
            return false;
        }
        else if (!TaxGroupName.match(nexp)) {
            swal("Warning!", "Tax Group Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = editmodal.filter((item) => { return item.tax_group_id !== tax_group_id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].tax_group_name == TaxGroupName) {
                    swal("Oops!", "Tax Group Name already exists, try different one!", "error");
                    return false;
                }
            }
        }

        var newArray = tax_list.filter(function (el)
        {
          return el.check ==true 
        }
        );

        if (newArray.length ==0) {
            swal("Warning!", "Please select tax for tax group", "warning");
            return false;
        }
        // else if(newArray.length<2) {
        //     swal("Warning!", "Please select  atleast two tax for tax group", "warning");
        //     return false;
        // }

        console.log(newArray)
        let str = newArray.map(x=>x.tax_id).join(",") 
        let total_percentage = newArray.reduce((p,x)=>{return p+=parseFloat(x.tax_percentage)},0)
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
    
        if(tax_group_id!="")
        {
            var values = { "id": tax_group_id, "Tax_Group_Name": TaxGroupName, "tax_list": str, "tax_group_percentage":total_percentage, "page_name": page_name };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-tax-group`, values, headers).then((res) => {
                setMainSubmitButtonState(false) 
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Tax group details updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else
        {
            var values = { "Tax_Group_Name": TaxGroupName, "tax_list": str, "tax_group_percentage":total_percentage, "page_name": page_name };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-tax-group`, values, headers).then((res) => {
                setMainSubmitButtonState(false) 
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Tax group details updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }

    const handleInputChange = (index,event,tax_id) => {
        const values1 = [...tax_list];
        values1[index].check=event.target.checked;
        settax_list(values1)
    };

    

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tax Group List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                    {tax_list.length>0 &&
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body">
                            <h4 style={{textAlign: "center" }} className="text-gray-800 fw-bolder">Add More Tax Group</h4>
                            <div className="d-flex justify-content-center pb-0 px-0">
                                {
                                    write==1 &&
                                        <button type="button" onClick={(e) => { submitTaxGroup() }} data-bs-toggle="modal" data-bs-target="#EditModal" className="btn btn-sm btn-primary me-3 rounded">Add Tax Group</button>
                                }
                            </div>
                            <br />
                        </div>
                    </div>}
                    <div className="card">
                        <div className="card-body pt-6">
                            {
                                (data.length > 0) ?
                                <>
                                <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                    <MUIDataTable
                                        title={"Tax Group List"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                    </MuiThemeProvider>
                                </>
                                :
                                state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <>
                                    <div className="card-body pt-0">
                                        <div className="text-gray-500 fw-bold fs-5 mb-5">Tax Group List</div>

                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <h3>Are you sure you want to delete this tax details?</h3>
                                </div>
                            </div>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span></button>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>{tax_group_id==""?"Submit":"Update"} Tax Group</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Tax Group Name</label>
                                            <input id="tax_group_name" type="text" class="form-control form-control-solid" placeholder="" name="account-head" value={TaxGroupName} onChange={(e) => { setTaxGroupName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Tax List</label>
                                        <div class="table-responsive">
													
													<table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
														
														<thead>
															<tr class="fw-bolder text-muted">
                                                                <th class="min-w-140px"></th>
																<th class="min-w-140px">Tax</th>
																<th class="min-w-120px">Percentage</th>	
															</tr>
														</thead>
														
														<tbody >
                                                            {tax_list.length > 0 && tax_list.map((obj,index) =>
                                                             {
                                                                return(
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                                                <input class="form-check-input widget-9-check" type="checkbox" value={obj.check} checked={obj.check} onChange={(event) => { handleInputChange(index,event,obj.tax_id);}}/>
                                                                            </div>
                                                                        </td>
                                                                    
                                                                        <td>
                                                                            <p class="text-dark fw-bolder text-hover-primary d-block fs-6">{obj.tax_name}</p>
                                                                        </td>

                                                                        <td class="text-end">
                                                                            <div class="d-flex flex-column w-100 me-2">
                                                                                <div class="d-flex flex-stack mb-2">
                                                                                    <span class="text-muted me-2 fs-7 fw-bold">{obj.tax_percentage}%</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                )}
                                                           )}	
														</tbody>
													</table>
													
												</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                            {write==1 &&<button onClick={(e) => { submitTaxGroupDetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
                                    <span class="indicator-label">{tax_group_id==""?"Submit":"Update"}</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            }
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default TaxGroupList;

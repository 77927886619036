import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';

import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';

import axios from 'axios';
import uuid from 'react-uuid';
import { Modal } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import { Link, Redirect } from "react-router-dom";

import swal from 'sweetalert';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function AdminDashboard() {
	const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
	const [accessToken, setaccessToken] = useState('');
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	let [purchase_flag, setpurchase_flag] = useState("");
	let [Quotalimit_count, setQuotalimit_count] = useState([]);
	let [redirect_to_login, setRedirect_to_login] = useState(2);
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);
	let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	var page_name = "dashboard";

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};
	
	useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1,false)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)

						// const script = document.createElement('script');
						// script.type = "text/javascript";
						// script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
						// script.async = true;
						// script.text = '{\
						// 				  "width": "100%",\
						// 				  "height": "100%",\
						// 				  "defaultColumn": "overview",\
						// 				  "screener_type": "crypto_mkt",\
						// 				  "displayCurrency": "USDT",\
						// 				  "colorTheme": "light",\
						// 				  "locale": "en",\
						// 				  "isTransparent": false\
						// 				}';
						// document.getElementById("dashboard_script").appendChild(script);

					}
					else {
						/*
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
						*/
						setRedirect_to_login(1)
					}
				})
				.catch(function (error) {
					/*
					swal("Oops!", "Please login", "error").then((value) => {
						window.location.href = "/login"
					});
					*/
					setRedirect_to_login(1)
				});
				object.organization_id=organization_id;
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
						else {
							/*var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							//var original_url = path.split("/")[1]*/
							var original_url ="dashboard"
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++)
							{
								if (temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
								}
							}
							if(temp == 2)
                            {
								//alert("403");
                                setredirect(1);
                            }
						}
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1,true)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						/*
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
						*/
						setRedirect_to_login(1)
					}
				})
					.catch(function (error) {
						/*
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
						*/
						setRedirect_to_login(1)
					});
			}
			else {
				/*
				swal("Oops!", "Please login", "error").then((value) => {
					window.location.href = "/login"
				});
				*/
				setRedirect_to_login(1)
			}
		}

		organization_id!="" && validateJWT();

	}, [organization_id]);

	useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

	useEffect(() => {
		async function getpurchaseflagdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-flag-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			setpurchase_flag(body.purchase_flag);
		}
		if (read || write) {
			(user.email && visibility) && getpurchaseflagdetails()
		}

		/*async function getaccountactivity() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/account-activity`, { method: 'POST', headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			setQuotalimit_count(body);

		}
		if (read || write) {
			(user.email && visibility) && getaccountactivity()
		}*/
	}, [user, visibility, read, write]);



	// console.log(Quotalimit_count[0].quotalimit_count);
	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}
			{
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

			{user.email ?
				<>
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Admin Dashboard
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
								<div className="d-flex align-items-stretch">
								
								</div>   
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
								{
									purchase_flag == "not found" ?
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6">

											<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
													<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
												</svg>
											</span>

											<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
												<div className="mb-3 mb-md-0 fw-bold">
													<h4 className="text-gray-800 fw-bolder">Activate Trial Account</h4>
													<div className="fs-6 text-gray-600 pe-7">You can Activate your Trial account and enjoy 7 days of free subscription.</div>
												</div>
												{
													write ?
														<Link to="/purchase-trial" className="btn btn-primary px-6 align-self-center text-nowrap rounded">Activate</Link>
														:
														<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Activate</button>
												}
											</div>

										</div>
										 :
										 null
								}
								{/*<img src={`https://quickchart.io/chart?c={type:'bar',data:{labels:[2012,2013,2014,2015,2016],datasets:[{label:'Users',data:[120,60,50,180,120]}]}}`}/>
								<img src={`https://quickchart.io/chart?c={type:'bar',data:{labels:["12 to 15"],datasets:[{label:'Sell Amount',data:[481]}]}}`}/>
								 {
									Quotalimit_count.length && Quotalimit_count[0].quotalimit_count > 0 ?
										<>
											<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">


												<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
														<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
														<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
													</svg>
												</span>


												<div className="d-flex flex-stack flex-grow-1">

													<div className="fw-bold">
														<h4 className="text-gray-800 fw-bolder">We need your attention!</h4>
														<div className="fs-6 text-gray-600">Your daily limit has been reached to receive more alerts please
															{
																write ?
																	<Link className="fw-bolder" to={`/subscription-list/${Quotalimit_count[0].purchase_subcription_id}`}> Upgrade your subscription</Link>
																	:
																	<button className="fw-bolder" > Upgrade your subscription</button>
															}

														</div>
													</div>

												</div>

											</div><br />
										</>
										: null
								} */}

								{/* <div className="tradingview-widget-container" id="dashboard_script" >
									<div className="tradingview-widget-container__widget"></div>
								</div> */}

							</div>
						</div>
					</div>
				</>
				:
				<>
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Admin Dashboard
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
								<div className="d-flex align-items-stretch">
								
								</div>   
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Dashboard</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}
			<Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
		</>
	);
}
export default AdminDashboard;
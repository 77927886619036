import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"
import moment from 'moment'

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import {setCookie,getCookie} from '../common_component/cookie.js';

function ActivityLogAlert() {
	
	let [alert_log, setAlertLog] = useState([]);
	let [state,setstate] = useState(2);
	let [timezone_change,set_timezone_change] = useState("+00:00");
	
	let [timer,setTimer] = useState(0);
	let [clock_timer,setClockTimer] = useState(0);
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	var page_name = "activity-log-alert";
	const loaderstyle = {
	  display: "block",
	  marginLeft: "auto",
	  marginRight: "auto",
	  marginTop: "auto",
	  marginBottom: "auto",
	};
	
	const renderTime = ({ remainingTime }) => {
		
	  if (remainingTime === 0)
	  {
		//return <div className="timer">Too lale...</div>;
		window.location.href = "/login";
	  }

	  return (
		<div className="timer">
		  <div className="text">Remaining</div>
		  <div className="value">{remainingTime}</div>
		  <div className="text">seconds</div>
		</div>
	  );
	};
		
	useEffect(() => {
		const validateJWT = ()=>{
		
			if(getCookie("access_token") != "")
			{
				var object = {};
				object.token = getCookie("access_token");
			
				var headers = {
				  headers: {
					"Content-Type": "application/json",
					"Accept": "*/*",
					"token" : getCookie("access_token")
				  }
				}

				async function getorganizationdetails() {
					var object = {};

		
					const response = await fetch(
						`${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
					);
					const body = await response.json();
		
					//setEditModal(body);
		
					if (body.length > 0) {
						setAlertLog(body)
						setstate(1);
					}
					else {
						setstate(0);
					}
				}
				getorganizationdetails();	
			}	
		}
		
		validateJWT();	
	},[])
	
	useEffect(() => {
		if(timer > 0)
		{
			const timer_decrease = setInterval(() => {
			  setTimer(timer - 1);
			  if(timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE)
			  {
				if(!document.getElementById("kt_modal_timer").classList.contains("show"))
				{
					setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
					window.$('#kt_modal_timer').modal('show');
				}
			  }
			}, 1000);
			
			return () => clearInterval(timer_decrease);
		}
	}, [timer]);
	
	return (
		<div id="kt_activities" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
			<div className="card shadow-none border-0 rounded-0">
				
				<div className="card-header" id="kt_activities_header">
					<h3 className="card-title fw-bolder text-dark">
 
 My Organizations</h3>
					<div className="card-toolbar">
						<button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
							
							<span className="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
										<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
										<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
									</g>
								</svg>
							</span>
							
						</button>
					</div>
				</div>
				
				
				<div className="card-body position-relative" id="kt_activities_body">
					
					<div id="kt_activities_scroll" className="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_activities_body" data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px">
						
						<div className="timeline">
							<br/>
							
							{
								alert_log.length > 0 ? alert_log.map((obj, index) => {
									if(index < 5)
									{
										return(
											<>									
											<div class="col-lg-12">
												{(getCookie("organization_id")==obj.organization_id)?
												<input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1,true); window.location.reload(); }} name="account_type" value="personal" id={obj.organization_id} checked/>
												:
												<input type="radio" class="btn-check" name="account_type" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1,true); window.location.reload(); }} value="personal"  id={obj.organization_id}/>
												}
												<label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
													<span class="svg-icon svg-icon-3x me-5">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
															<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
														</svg>
													</span>
													<span class="d-block fw-bold text-start">
														<span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
														<span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
													</span>
													
												</label>
												
											<div class="fv-plugins-message-container invalid-feedback"></div></div>
											</>
										
										);
									}
								})
								:
								<>
								{ state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
									:
									null
								}
								</>
							}
																
																
							{/*									
															
							{
								alert_log.length > 0 ? alert_log.map((obj, index) => {
									if(index < 5)
									{
										return(
											<>
											<input type="radio" class="btn-check" name="account_type" value="personal" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} checked="checked" id="kt_create_account_form_account_type_personal"/>
											
											
											<div class="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">							
											<div class="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
												<div class="symbol symbol-50px me-4">
													<span class="symbol-label">
														<span class="svg-icon svg-icon-2qx svg-icon-primary">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
																<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
															</svg>
														</span>
													</span>
												</div>
												<div class="me-2">
													<a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">{obj.organization_name}</a>
													<span class="text-gray-400 fw-bolder d-block fs-7">Organization ID:{obj.organization_id}</span>
												</div>
											</div>
											<div class="d-flex align-items-center">
												{(getCookie("organization_id")==obj.organization_id)&&
												<div class="symbol symbol-50px me-4">
														<span class="symbol-label">
															<span class="svg-icon svg-icon-2qx svg-icon-primary">
																<svg id="edit-svg" style={{"width": "50%", "height": "60%"}} mi-view-box="0 0 1024 1024" viewBox="0 0 1024 1024">
																	<rect fill="white" x="0" y="0" mi-width="1024" mi-height="1024" width="1024" height="1024"></rect>
																	<g>
																		<path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z" mi-fill="rgb(37, 174, 136)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z" fill="rgb(37, 174, 136)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
																		</path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M778.24 307.2l-327.68 368.64-204.8-163.84" mi-fill="none" mi-stroke="rgb(255, 255, 255)" mi-stroke-width="40.96" mi-stroke-linejoin="round" mi-stroke-linecap="round" mi-stroke-miterlimit="10" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M778.24 307.2l-327.68 368.64-204.8-163.84" fill="none" stroke="rgb(255, 255, 255)" stroke-width="40.96" stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" opacity="1">
																		</path>
																	</g>
																	<g ng-show="pref.showGrid" class="edit-grid ng-hide">
																		<g class="hLines"></g>
																	</g>
																</svg>
															</span>
														</span>
													</div>
												}
											</div>
											
										</div>
										</>
										
										);
									}
								})
								:
								<>
								{ state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
									:
									null
								}
								</>
							}
						*/}
							
							
						</div>
						
					</div>
					
				</div>
				
				
				<div className="card-footer py-5 text-center" id="kt_activities_footer">
					<a href="/show-organizations" className="btn btn-bg-white text-primary">Manage All Organizations
					<span className="svg-icon svg-icon-3 svg-icon-primary">
						<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
							<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<polygon points="0 0 24 0 24 24 0 24" />
								<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
								<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
							</g>
						</svg>
					</span>
					</a>
				</div>
				
			</div>
		</div>
	);
}
export default ActivityLogAlert;
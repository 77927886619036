import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'
import {MuiThemeProvider, createTheme} from '@material-ui/core';

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const ExpenseList = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    const [loading_modal, setloading_modal] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;
    let [state, setstate] = useState(2);

    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=5;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    let [fileNames, setFileNames] = useState(["", ""])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    let [Bulk_flag, setBulk_flag] = useState(false);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [Expense_date, setExpense_date] = useState("");
    const [description, setdescription] = useState("");
    const [References, setReferences] = useState("");
    const [Amount, setAmount] = useState("");
    const [Expense_amount, setExpense_amount] = useState("");
    const [LiveCurrencyData, setLiveCurrencyData] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [inputFields, setInputFields] = useState([{uid:uuid(),Expense_date:"",Currency:"",merchant:"",Claim_reimbursement:"",expenseAccount:[{account: null, amount: 0, description: "",Violation:false}]}]);

    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [AllCurrencylist, setAllCurrencylist] = useState([])
    const [multipleMode, setMultipleMode] = useState(false)
    const [DateFormat, setDateFormat] = useState("");
    const [DuplicateExpense, setDuplicateExpense] = useState([])
    const [CategoryList, setCategoryList] = useState([])
    const [CategoryList1, setCategoryList1] = useState([])
    const [Category_Limits, setCategory_Limits] = useState([])
    const [General_Limits, setGeneral_Limits] = useState([])
    const [MerchantList, setMerchantList] = useState([])
    const [Currencylist, setCurrencylist] = useState([])
    const [Reportlist, setReportlist] = useState([])
    const [Reportlist1, setReportlist1] = useState([])
    const [PaidThrouhAccountlist, setPaidThrouhAccountlist] = useState([])
    const [SplitTypelist, setSplitTypelist] = useState([{ label: "Split by amount", value: "Split by amount" }, { label: "Split by days", value: "Split by days" }])
    const [ExpenseNumberlist, setExpenseNumberlist] = useState([{ label: "2", value: "2" }, { label: "3", value: "3" },{ label: "4", value: "4" },{ label: "5", value: "5" }])
    
    let [folder_name, setfolder_name] = useState("expense_documents");
    const [Expense_Start_date, setExpense_Start_date] = useState("");
    const [Expense_End_date, setExpense_End_date] = useState("");
    const [expense_number, setexpense_number] = useState("2");
    const [Split_type, setSplit_type] = useState("Split by amount");
    const [Category, setCategory] = useState("");
    const [Merchant, setMerchant] = useState("");
    const [Currency, setCurrency] = useState("");
    const [Currency1, setCurrency1] = useState("");
    const [BaseCurrency, setBaseCurrency] = useState("");
    const [BaseCurrencyCode, setBaseCurrencyCode] = useState("");
    const [TargetCurrencyCode, setTargetCurrencyCode] = useState("");
    const [Report, setReport] = useState("");
    const [Account, setAccount] = useState("");
    const [Claim_reimbursement, setClaim_reimbursement] = useState(true);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [Index, setIndex] = useState("");
    const [Violation, setViolation] = useState(false);
    const [Currency_conversion_value, setCurrency_conversion_value] = useState("");

    const [selected_document_list, setselected_document_list] = useState([]);
    const [expenseAccounts,setExpenseAccounts] = useState([{account: null, amount: 0, notes: "",Violation:false}])

    const [Expense_Amount_Limit,setExpense_Amount_Limit] = useState("");
    const [Receipt_Required_Limit,setReceipt_Required_Limit] = useState("");
    const [Make_Description_Mandatory,setMake_Description_Mandatory] = useState("");
    const [ExpenseList,setExpenseList] = useState([])
    let [expense_id, setexpense_id] = useState("");
    const [document_list, setdocument_list] = useState([]);

    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([])
    
	var page_name = "expense-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const onRowClick = (rowData,rowMeta) => {
        window.location.href=`/expense-details/${rowData[0]}`
    }

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Document',
        onRowClick: onRowClick,
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "expense_id",
            label: "expense_id",
            options: {
                display:false,
                filter: true,
                sort: true
            }
        },
        {
            name: "category",
            label: "Expense Details",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "expense_date",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Merchant",
            label: "Merchant",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Report",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "duplicacy",
            label: "Duplicacy",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        }
    ];

    setFileNames = (name) => {
        fileNames.push(name)
        let uniq = fileNames => [...new Set(fileNames)];
        fileNames = fileNames.slice(-2)
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])
    
    useEffect(() => {
        async function getexpensedetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-expense`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                let temp_dropdown=[]
                body.map((obj) => {
                    if (obj.add_status === "Active" && obj.user_id === user.email) {
                        temp_dropdown.push(obj);
                    }
                })
                for(let i=0;i<body.length;i++)
                {
                    for(let j=0;j<body.length;j++)
                    {
                        if(i!=j)
                        {
                            if((body[i].expense_date==body[j].expense_date) && (body[i].amount==body[j].amount)&& (body[i].mark_as_not_duplicate=="" && body[j].mark_as_not_duplicate==""))
                            {
                                body[i].flag=true;
                                break;
                            }
                        }  
                    }
                }
                setEditModal(temp_dropdown);
                setloading(true);
            }
            else
            {
                setstate(0)
            }
        }
        if (read || write) {
            (user.email && visibility) && getexpensedetails();
        }
    }, [user, visibility,read, write])

    async function getreportdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-my-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status == "Active" && obj.user_id==user.email) {
                var temp_obj = {};
                temp_obj.label = obj.report_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);
            }
        })
        setReportlist(temp_dropdown);
        var temp_dropdown1 = [];
        body.map((obj) => {
            if (obj.add_status == "Active" && obj.user_id==user.email) {
                var temp_obj1 = {};
                temp_obj1.label = obj.report_name;
                temp_obj1.value = obj.id;
                temp_obj1.add_status = obj.add_status;
                temp_dropdown1.push(temp_obj1);
            }
        })
        setReportlist1(temp_dropdown1);
    }

    async function getaccountsdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/paid-through-accounts-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.account_name;
                temp_obj.value = obj.unique_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setPaidThrouhAccountlist(temp_dropdown);
    }

    async function getcurrencydetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.currency_code;
                temp_obj.value = obj.currency_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setCurrencylist(temp_dropdown);
        setAllCurrencylist(body)
    }

    async function getcategorydetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.category_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);
            }
        })
        setCategoryList1(temp_dropdown);
    }


    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if(body.length>0)
        {
            setCurrency(body[0].currency_id);
            setCurrency1(body[0].currency_id);
            setBaseCurrency(body[0].currency_symbol);
            setBaseCurrencyCode(body[0].currency_code)
            setDateFormat(body[0].date_format);
            setInputFields([{uid:uuid(),Expense_date:"",Currency:body[0].currency_id,TargetCurrencyCode:body[0].currency_code,merchant:"",Claim_reimbursement:"",expenseAccount:[{account: null, amount: 0, description: "",Violation:false}]}])
        }
    }

    async function getmerchantdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/merchant-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.merchant_name;
                temp_obj.value = obj.merchant_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setMerchantList(temp_dropdown);
    }

    async function getlivecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if(body.length>0)
        {
            let arr=[];
            arr.push(body[0].data.quotes)
            setLiveCurrencyData(arr);
        }
    }

    useEffect(() => {
        if (loading) {
            let p1 = getreportdetails();
            let p2 = getaccountsdetails();
            let p3 = getcurrencydetails();
            let p4 = getcategorydetails();
            let p5 = getbasecurrency();
            let p6 = getmerchantdetails();
            let p7 = getlivecurrency();
            Promise.all([p1,p2,p3,p4,p5,p6,p7])
            .then(()=>{
                setloading_modal(true);
                console.log("all apis done")
            })
            .catch((err)=>{
                setloading_modal(false);
                console.log(err)
            })
        }
    }, [loading])

    useEffect(() => {
        async function getpolicycategorydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-policy-related-categories`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            if(body.length>0)
            {
                if(body[0].general_limits!="")
                {
                    setGeneral_Limits(JSON.parse(body[0].general_limits))
                }
                if(body[0].category_limits!="")
                {
                    setCategory_Limits(JSON.parse(body[0].category_limits))
                }
                JSON.parse(body[0].category_limits).map((obj) => {
                    if (obj.acceptability ===true) {
                        var temp_obj = {};
                        temp_obj.label = CategoryList1.filter(option => option.value == obj.category_id)[0].label;
                        temp_obj.value = obj.category_id;
                        temp_dropdown.push(temp_obj);
                    }
                })
            }
            setCategoryList(temp_dropdown);
        }
        if(read || write)
        {
            (user.email && visibility && CategoryList1.length>0) && getpolicycategorydetails();
        }
    }, [user, visibility,read, write,CategoryList1])

    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    if(JSON.parse(obj.accounts).length>1)
                    {
                        temp_obj.category ="Itemized";
                    }
                    else
                    {
                        //console.log(JSON.parse(obj.accounts[0]).account.label)
                        temp_obj.category =JSON.parse(obj.accounts)[0].account.label;
                    }
                    temp_obj.expense_date = moment(obj.expense_date).format(DateFormat);
                    if(obj.merchant_id!="")
                    {
                        temp_obj.Merchant =MerchantList.filter(option => option.value == obj.merchant_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Merchant ="---";
                    }
                    if(obj.report_id!=="")
                    {
                        temp_obj.Report = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Report ="---";
                    }
                    temp_obj.amount = obj.currency_symbol+obj.amount;
                    
                    if(obj.report_id=="")
                    {
                        temp_obj.status ="UNREPORTED";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else
                    {
                        temp_obj.status ="UNSUBMITTED";
                    }
                    //temp_obj.status = obj.add_status;
                    if(obj.flag==true)
                    {
                        temp_obj.duplicacy = <a href="#" onClick={(e) => { duplicateExpense(id); e.stopPropagation() }} data-bs-toggle="modal" data-bs-target="#duplicateExpense" className="dropdown-item">Duplicate</a>;
                    }
                    temp_obj.expense_id = obj.expense_id;
                    var id = obj.expense_id;
                    temp_obj.actions =
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a href="#" onClick={(e) => { splitExpense(id) }} data-bs-toggle="modal" data-bs-target="#SplitExpense" className="dropdown-item">Split Expense</a></li>
                                    <li><a href="#" onClick={(e) => { cloneExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Clone</a></li>
                                    <li><a href="#" onClick={(e) => { updateExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                    {obj.add_status="Active" &&<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="dropdown-item" href="#">Delete</a></li>}
                                    <li><a onClick={(e) => { updateattachments(id) }} data-bs-toggle="modal" data-bs-target="#EditAttachments" className="dropdown-item" href="#">Attach Receipts</a></li>
                                </ul>
                                :
                                null
                            }
                        </div>

                    temp_data.push(temp_obj);
                })
                var lookup = {};
                var items = temp_data;
                var result = [];
                result.push({label:"All",value:"All"});
                for (var item, i = 0; item = items[i++];) {
                var name = item.status;
                    if (!(name in lookup)) {
                        lookup[name] = 1;
                        result.push({label:name,value:name});
                    }
                }
                setStatusList(result)
                setData(temp_data);
            }
            else {
                setData([]);   
            }
        }
        (editmodal.length > 0 && CategoryList.length>0) && geteditdetails();

    }, [editmodal,CategoryList,MerchantList,Reportlist,Reportlist1])

    const getExpenseList= (id) => {
        if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            
            editmodal.map((obj) => {
                var temp_obj = {};
                    if(JSON.parse(obj.accounts).length>1)
                    {
                        temp_obj.category ="Itemized";
                    }
                    else
                    {
                        //console.log(JSON.parse(obj.accounts[0]).account.label)
                        temp_obj.category =JSON.parse(obj.accounts)[0].account.label;
                    }
                    temp_obj.expense_date = moment(obj.expense_date).format("ll");
                    if(obj.merchant_id!="")
                    {
                        temp_obj.Merchant =MerchantList.filter(option => option.value == obj.merchant_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Merchant ="---";
                    }
                    if(obj.report_id!=="")
                    {
                        temp_obj.Report = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Report ="---";
                    }
                    if(obj.flag==true)
                    {
                        temp_obj.duplicacy = <a href="#" onClick={(e) => { duplicateExpense(id); e.stopPropagation() }} data-bs-toggle="modal" data-bs-target="#duplicateExpense" className="dropdown-item">Duplicate</a>;
                    }
                    temp_obj.amount = obj.currency_symbol+obj.amount;
                    if(obj.report_id=="")
                    {
                        temp_obj.status ="UNREPORTED";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else
                    {
                        temp_obj.status ="UNSUBMITTED";
                    }
                    //temp_obj.status = obj.add_status;
                    temp_obj.expense_id = obj.expense_id;
                    var id = obj.expense_id;
                    temp_obj.actions =
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { splitExpense(id) }} data-bs-toggle="modal" data-bs-target="#SplitExpense" className="dropdown-item">Split Expense</a></li>
                                         <li><a href="#" onClick={(e) => { cloneExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Clone</a></li>
                                        <li><a href="#" onClick={(e) => { updateExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        {obj.add_status="Active" &&<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="dropdown-item" href="#">Delete</a></li>}
                                        <li><a onClick={(e) => { updateattachments(id) }} data-bs-toggle="modal" data-bs-target="#EditAttachments" className="dropdown-item" href="#">Attach Receipts</a></li>
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
            })
            var lookup = {};
            var items = temp_data;
            var result = [];
            result.push({label:"All",value:"All"});
            for (var item, i = 0; item = items[i++];) {
            var name = item.status;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push({label:name,value:name});
                }
            }
            if(id!="All")
            {
                arr=temp_data.filter(option => option.status == id);
            }
            else
            {
                arr=temp_data;
            }
            setData(arr);
        }
        else {
            setData([]);
        }
    }

    const updateExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setexpense_id(id)
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setClaim_reimbursement(editmodal[i].claim_reimbursement) 
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const cloneExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setexpense_id("")
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setClaim_reimbursement(editmodal[i].claim_reimbursement) 
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const duplicateExpense = (id) => {
        let duplicate=[];
        let amount2=editmodal.filter(option => option.expense_id == id)[0].amount;
        let currency1=editmodal.filter(option => option.expense_id == id)[0].currency;
        let expense_date1=editmodal.filter(option => option.expense_id == id)[0].expense_date;
        for(let i=0;i<editmodal.length;i++)
        { 
            if((editmodal[i].expense_date==expense_date1) && (editmodal[i].amount==amount2) && (editmodal[i].currency==currency1))
            {
                duplicate.push(editmodal[i])
            }
        }
        setDuplicateExpense(duplicate)        
    }

    const splitExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setexpense_id(id)
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setReferences(editmodal[i].reference);
                setClaim_reimbursement(editmodal[i].claim_reimbursement) 
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setAmount(parseFloat(editmodal[i].amount).toFixed(2));
                setCurrency(editmodal[i].currency);
                setAccount(editmodal[i].paid_through_account);
                let list=[];
                let date=moment(editmodal[i].expense_date).format("yyyy-MM-DD");
                let amount1=parseInt((editmodal[i].amount/expense_number)*100)/100;
                let percentage=parseFloat(100/expense_number).toFixed(2);
                //console.log(JSON.parse(editmodal[i].accounts)[0].account.label)
                let category=JSON.parse(editmodal[i].accounts)[0].account;
                var amnt=0;
                //if()
                for(let i=0;i<parseInt(expense_number);i++)
                {
                    amnt=parseFloat(amnt)+parseFloat(amount1);
                    list.push({Claim_reimbursement:editmodal[i].claim_reimbursement,expense_date:date,category: category, amount: amount1, percentage: percentage})
                }
                setExpense_amount(parseFloat(amnt).toFixed(2))
                //console.log(list)
                setExpenseList(list);
            }
        }
    }

    const updateattachments = (id) => {
        setexpense_id(id)
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setdocument_list(editmodal[i].expense_document_details) 
                let result = editmodal[i].expense_document_details.map(ele=>ele.image_original_name);;
                setselected_document_list(result)
            }
        }     
    }

    const updateStatus = (id) => {
         setexpense_id(id)      
    }

    const submitStatus = (e) => {
        e.preventDefault();
        var values = { "id": expense_id,"folder_name":folder_name,"page_name": page_name,"organization_id":organization_id };
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setMainSubmitButtonState(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-expense`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Mark_duplicate = (e) => {
        e.preventDefault();

        var values = { "id": expense_id, "page_name": page_name,"organization_id":organization_id};

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setMainSubmitButtonState(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/mark-as-not-duplicate`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense is marked as not duplicate!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const add_expense_section = (type) => {
        const values1 = [...inputFields];
        values1.push({uid:uuid(),Expense_date:"",Currency:Currency1,TargetCurrencyCode:BaseCurrencyCode,merchant:"",Claim_reimbursement:"",expenseAccount:[{account: null, amount: 0, description: "",Violation:false}]});
        setInputFields(values1);

    }

    const remove_expense_section = (index) => {
        const values1 = [...inputFields];
        values1.splice(index,1);
        setInputFields(values1);
    }

    const submitattachments = (e) => {
        e.preventDefault();

        var values = { "expense_id": expense_id,"id":id,'filenames': selected_document_list,"page_name": page_name,"organization_id":organization_id};

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-expense-document-details`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense attachments saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const SplitExpenseDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = expense_id;
        object.organization_id = organization_id;
        //object.Expense_date = Expense_date;
        object.Merchant = Merchant;
        object.Report = Report;
        object.Currency = Currency;
        //object.Amount = expenseAccounts.reduce((p,o)=>{return p+o.amount},0);
        //object.expenseAccounts = expenseAccounts;
        object.References = References;
        //object.Claim_reimbursement = Claim_reimbursement;
        object.Account = Account;
        object.ExpenseList=ExpenseList;
        object.page_name = page_name;
        
        if (Expense_date == "") {
            swal("Warning!", "Expense date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Currency == "") {
            swal("Warning!", "Currency cannot be a empty", "warning");
            return false;
        }
        let t = expenseAccounts.find(o=>{
            if((o.account==null)||(o.amount==0)){
                return o
            }
        })
        if(t!=undefined)
        {
            swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
            return
        }
        /*else if (!Amount.match(cexp)) {
            swal("Warning!", " Amount cannot contain certain symbols ", "warning");
            return false;
        }
        */
        
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/split-expense`, object, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense details splitted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    var files = [];
    var sendButton = document.getElementById("kt_contact_submit_button");
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status) => {
        // console.log('handleChangeStatus2');
        const { name } = meta;
        // console.log(name)
        let updatedFiles = [];
        if (status === 'done' || status === 'removed') {
            // Add meta to files
            if (status === 'done') {
                updatedFiles = [...files, meta];
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
            }
            // Remove meta from files
            else if (status === 'removed') {
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
                fileNames = fileNames.filter(item => item !== String(name))
                if (fileNames.length === 0) {
                    fileNames.push("")
                    fileNames.push("")
                }
            }
            else {
                updatedFiles = files.filter(files, (file) => {
                    return file.name !== meta.name
                        || file.size !== meta.size
                        || file.type !== meta.type;
                });

            }
            files = updatedFiles;
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        const values = [...selected_document_list];
        values.push(file.name)
        setselected_document_list(values)
        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('organization_id', organization_id);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        sendButton.setAttribute("data-kt-indicator", "on");
        sendButton.disabled = true;
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-expense-documents`, headers, body }
    }

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        //console.log('handleChangeStatus');
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    // console.log(result);
                }
            }
        }
    }

    function removeTravelDocument(index) {
		const data1 = [...document_list];
        data1.splice(index,1);
        setdocument_list(data1);
        const data2 = [...selected_document_list];
        data2.splice(index,1);
        setselected_document_list(data2);
    }

    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }

    const check_expense_count = (count) => {
        setExpenseList([]);
        let list=[];
        let date=Expense_date;
        let amount1=parseInt((Amount/count)*100)/100;
        let percentage=parseFloat(100/count).toFixed(2);
        let category=expenseAccounts[0].account.label;
        let amnt=0;
        for(let i=0;i<parseInt(count);i++)
        {
            amnt=parseInt((amnt)*100)/100+parseInt((amount1)*100)/100;
            list.push({Claim_reimbursement:Claim_reimbursement,expense_date:date,category: category, amount: amount1, percentage: percentage})
        }
        setExpense_amount(amnt)
        setExpenseList(list);
    }

    const check_expense = (type) => {
        console.log(type)
        if(type=="Split by days")
        {
            setExpense_Start_date(Expense_date)
            setExpense_End_date(moment.utc(Expense_date).add(1, 'days').format('YYYY-MM-DD'))
        }
        setExpenseList([]);
        let list=[];
        let date=Expense_date;
        let amount1=parseInt((Amount/expense_number)*100)/100;
        let percentage=parseFloat(100/expense_number).toFixed(2);
        let category=expenseAccounts[0].account.label;
        let amnt=0;
        amnt=parseFloat(amnt)+parseFloat(amount1);
        amnt=parseFloat(amnt)+parseFloat(amount1);
        setExpense_amount(parseFloat(amnt).toFixed(2))
        list.push({Claim_reimbursement:Claim_reimbursement,expense_date:Expense_date,category: category, amount: amount1, percentage: percentage})
        list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(Expense_date).add(1, 'days').format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
        setExpenseList(list);
    }

    const check_expense_date = (start_date,end_date) => {
        console.log(Expense_Start_date,Expense_End_date)
        var start_date1 = moment(start_date, 'YYYY-MM-DD'); 
        var end_date1 = moment(end_date, 'YYYY-MM-DD');
        let diff = end_date1.diff(start_date1, 'days');
        setExpenseList([]);
        let list=[];
        let date=Expense_date;
        let amount1=parseFloat(Amount/(diff+1)).toFixed(2);
        let percentage=parseFloat(100/(diff+1)).toFixed(2);
        let category=expenseAccounts[0].account.label;
        let amnt=0;      
        amnt=parseFloat(amnt)+parseFloat(amount1);
        list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(start_date).format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
        for(let i=0;i<parseInt(diff);i++)
        {
            amnt=parseFloat(amnt)+parseFloat(amount1);
            list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(start_date).add(1, 'days').format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
            start_date=moment.utc(start_date).add(1, 'days').format('YYYY-MM-DD')
        }
        setExpense_amount(parseFloat(amnt).toFixed(2))
        setExpenseList(list);    
    }

    const add_expense = (type) => {
        const values1 = [...ExpenseList];
        let count=ExpenseList.length+1;
        let date=Expense_date;
        if(type=="Split by days")
        {
            //console.log(values1[ExpenseList.length-1])
            date=moment.utc(values1[ExpenseList.length-1].expense_date).add(1, 'days').format('YYYY-MM-DD');
        }
        let category=expenseAccounts[0].account.label;
         let amount=0;
         let percentage=0;
        if(Amount-Expense_amount>0)
        {
            amount=parseFloat(Amount-Expense_amount).toFixed(2);
            percentage=parseFloat((amount)/Amount).toFixed(2);
            setExpense_amount(parseFloat(Amount).toFixed(2));
        }
        values1.push({Claim_reimbursement:Claim_reimbursement,expense_date:date,category: category, amount:amount, percentage: percentage})
        setExpenseList(values1);
    };

    const check_expense_amount = (temp) => {
        let amnt=0;      
        for(let i=0;i<parseInt(temp.length);i++)
        {
            amnt=parseFloat(amnt)+parseFloat(temp[i].amount);
        }
        setExpense_amount(parseFloat(amnt).toFixed(2))   
    }

    const register = (e) => {
        expenseAccounts.forEach(object => {
            delete object['Violation'];
          });
        e.preventDefault();
        var object = {};
        object.organization_id = organization_id;
        object.Expense_date = Expense_date;
        object.Merchant = Merchant;
        object.Report = Report;
        object.Currency = Currency;
        object.Amount = expenseAccounts.reduce((p,o)=>{return p+o.amount},0);
        object.References = References;
        object.expenseAccounts = expenseAccounts;
        object.Claim_reimbursement = Claim_reimbursement;
        object.Account = Account;
        object.page_name = page_name;
        //console.log(LiveCurrencyData[0])
        object.Currency_conversion_value = Currency_conversion_value;
        
        var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (object.Expense_date == "") {
            swal("Warning!", "Expense date cannot be a empty", "warning");
            return false;
        }
         
		var cexp=/^[0-9]+$/
        if (Currency == "") {
            swal("Warning!", "Currency cannot be a empty", "warning");
            return false;
        }
        let t = expenseAccounts.find(o=>{
            if((o.account==null)||(o.amount==0)){
                return o
            }
        })
        if(t!=undefined)
        {
            swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
            return
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-expense`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense Created successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else
			{
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
        });        
    }

    const BulkAddExpense = (e) => {
        
        e.preventDefault();
        var object = {};
        object.organization_id = organization_id;
        object.bulk_expenses=inputFields;
        object.page_name = page_name;
        let t=true;
        t = inputFields.find(o=>{
            if (o.Expense_date == "") {
                swal("Warning!", "Expense date cannot be a empty", "warning");
                return false;
            }
             
            var cexp=/^[0-9]+$/
            if (o.Currency == "") {
                swal("Warning!", "Currency cannot be a empty", "warning");
                return false;
            }
            let t1 = o.expenseAccount.find(x=>{
                delete x['Violation'];
                if((x.account==null)||(x.amount==0)){
                    swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
                return false;
                }

            })
            
            /*console.log(t1)
            if(t1==false)
            {
                swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
                return false;
            }
            */
        })
        console.log(inputFields)
       
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        console.log(t)
        if(t==undefined)
        {
            setMainSubmitButtonState(true);
            axios.post(`${process.env.REACT_APP_SERVER_URL}/bulk-add-expense`, object, headers).then((res) => {
                setMainSubmitButtonState(false);
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Expense Created successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else
                {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })
            .catch(function (error) {
            });
        }      
    }
    
    const expense_rule_check = (account,indx) => {
        //console.log(account);
        //console.log(indx);
        if(account!=null)
        {
            setIndex(indx);
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
            {
                setExpense_Amount_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
            }
            else
            {
                if(General_Limits!="")
                {
                    setExpense_Amount_Limit(General_Limits[0].Expense_Amount_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
            {
                setReceipt_Required_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
            }
            else
            {
                if(General_Limits!="")
                {
                    setReceipt_Required_Limit(General_Limits[0].Receipt_Required_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
            {
                setMake_Description_Mandatory(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
            }
            else
            {
                if(General_Limits!="")
                {
                    setMake_Description_Mandatory(General_Limits[0].Make_Description_Mandatory);
                }
            }     
        }
    }

    const violation_check = (index,account,notes,amount) => {
        if(account!=null)
        {       
            let Expense_Amount= "";
            let Receipt_Required= "";
            let Make_Description= false;
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
            {
                Expense_Amount= (Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
            }
            else
                {if(General_Limits!="")
                {
                    Expense_Amount= (General_Limits[0].Expense_Amount_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
            {
                Receipt_Required= (Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
            }
            else
            {
                if(General_Limits!="")
                {
                    Receipt_Required= (General_Limits[0].Receipt_Required_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
            {
                Make_Description=(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
            }
            else
            {
                if(General_Limits!="")
                {
                    Make_Description=(General_Limits[0].Make_Description_Mandatory);
                }
            }
            console.log(Expense_Amount,Receipt_Required,amount)
            if(parseInt(Expense_Amount)< parseInt(amount) || parseInt(Receipt_Required)< parseInt(amount) || (notes=="" && Make_Description=="Yes"))
            {
                let temp = [...expenseAccounts];
                temp[index].Violation=true;
                temp[index].account=account;
                temp[index].amount=parseInt(amount);
                temp[index].notes=notes;
                setExpenseAccounts(temp);
            }
            else
            {
                let temp = [...expenseAccounts];
                temp[index].account=account;
                temp[index].Violation=false;
                temp[index].amount=parseInt(amount);
                temp[index].notes=notes;
                setExpenseAccounts(temp);
            }  
        }
    }

    const violation_check1 = (index,account,notes,amount) => {
        //console.log(account)
        if(account!=null)
        {       
            let Expense_Amount= "";
            let Receipt_Required= "";
            let Make_Description= false;
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
            {
                Expense_Amount= (Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
            }
            else
                {if(General_Limits!="")
                {
                    Expense_Amount= (General_Limits[0].Expense_Amount_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
            {
                Receipt_Required= (Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
            }
            else
            {
                if(General_Limits!="")
                {
                    Receipt_Required= (General_Limits[0].Receipt_Required_Limit);
                }
            }
            if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
            {
                Make_Description=(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
            }
            else
            {
                if(General_Limits!="")
                {
                    Make_Description=(General_Limits[0].Make_Description_Mandatory);
                }
            }
            //console.log(Make_Description);
            //console.log(notes);
            if(parseInt(Expense_Amount)< parseInt(amount) || parseInt(Receipt_Required)< parseInt(amount) || (notes=="" && Make_Description=="Yes"))
            {
                let temp = [...inputFields];
                temp[index].Violation=true;
                temp[index].expenseAccount[0].account=account;
                temp[index].expenseAccount[0].amount=parseInt(amount);
                temp[index].expenseAccount[0].description=notes;
                setInputFields(temp);
            }
            else
            {
                let temp = [...inputFields];
                temp[index].expenseAccount[0].account=account;
                temp[index].expenseAccount[0].Violation=false;
                temp[index].expenseAccount[0].amount=parseInt(amount);
                temp[index].description=notes;
                setInputFields(temp);
            }  
        }
    }

    const load_bulk_currency_conversion_value = (indx,val) => {
        let temp = [...inputFields];
        let target_currency_code=AllCurrencylist.filter(option =>
            option.currency_id == val)[0].currency_code;
        temp[indx].TargetCurrencyCode=target_currency_code;
        temp[indx].Currency_conversion_value=LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code];
        setInputFields(temp);
    }

    const load_currency_conversion_value = (val) => {
        let target_currency_code=AllCurrencylist.filter(option =>
            option.currency_id == val)[0].currency_code;
            let conversion_value=0;
            if(target_currency_code!="USD")
            {
                conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code])*100)/100;
            }
            else
            {
                conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode])*100)/100;
            }
        setTargetCurrencyCode(target_currency_code);
        setCurrency_conversion_value(conversion_value);
    }
    
    return (
        <>

            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Expense List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <span className="badge badge-light fw-bolder my-2">
                        {write==1&&<a id="button_create_alert" type="button" data-bs-toggle="modal" onClick={(e) => {setloading(true)}} data-bs-target="#AddExpense" className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Add Expense</a>}
                        {write==1&&<a id="button_create_alert" type="button" data-bs-toggle="modal" onClick={(e) => {setloading(true)}} data-bs-target="#BulkAddExpense" className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Bulk Add Expense</a>}
                        </span>    
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                data.length > 0 ?
                                    <>
                                        <div className="row mb-5">
                                            <div className="col-md-4 fv-row">
                                                <label className="fs-5 fw-bold mb-2">Select status:</label>
                                                <Select
                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                    name="category"
                                                    id="category"
                                                    className="form-control"
                                                    options={StatusList}
                                                    onChange={(event) => { 
                                                        setStatus(event.value)
                                                        getExpenseList(event.value)
                                                    }}
                                                    value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                    placeholder="--Choose a Type--"
                                                />
                                            </div>
                                        </div>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                        <MUIDataTable
                                            title={"Expense List"}
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                        </MuiThemeProvider> 
                                    </>
                                    :
                                    <>
                                        {
                                            state == 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                :
                                                <>
                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5">Expense List</div>

                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                    <div className="fs-6 text-gray-600"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}
            <div className="modal fade" id="duplicateExpense" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Possible Duplicates</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <label className="d-flex align-items-center form-label mb-5">Possible Duplicates
                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="" aria-label=""></i></label>
                                    <div className="mb-0">	
                                    {DuplicateExpense.length > 0 && DuplicateExpense.map((obj1, index) => {
                                    return (
                                    <>
                                    <div class="d-flex align-items-center mb-8">
                            
                                        <span class="bullet bullet-vertical h-40px bg-success"></span>
                                        <div class="form-check form-check-custom form-check-solid mx-5"></div>
                                        
                                        <div class="flex-grow-1">
                                            <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{BaseCurrency}{obj1.amount}</a>
                                            
                                            {obj1.mark_as_not_duplicate=="true" &&<span class="badge badge-light-success me-auto rounded">Mark as not duplicate</span>}
                                            <span class="text-muted fw-bold d-block">{moment(obj1.date).format("ll")} , Category:{JSON.parse(obj1.accounts).length>1 ?<span>Itemize</span> : JSON.parse(obj1.accounts)[0].account.label}</span>
                                        </div>
                                        {obj1.mark_as_not_duplicate!="true" && <button class="btn btn-sm btn-primary text-white d-block mx-auto me-2" type="button" onClick={(e) => { updateStatus(obj1.expense_id) }} data-bs-toggle="modal" data-bs-target="#ConfirmDuplicates">
                                            Not a duplicate
                                        </button>}
                                        <button class="btn btn-sm btn-primary text-white d-block mx-auto" type="button" onClick={(e) => { updateStatus(obj1.expense_id) }} data-bs-toggle="modal" data-bs-target="#EditStatus">
                                        Delete
                                        </button>
                                    </div><br/>  
                                    </>
                                    );
                                })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {/*<form >
                                <button type="button" className="btn btn-primary" onClick={(e) => { Select_Expense(e); }} >Save</button>
                            </form>
                            */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="ConfirmDuplicates" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Marked as not a duplicate</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12"> 
                                    <h3>Are you sure you want to Marked as not a duplicate?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <button type="button" className="btn btn-success" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { Mark_duplicate(e); }} >Confirm</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Expense Record</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">  
                                    <h3>Are you sure you want to delete this Expense Record?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitStatus(e); }} >Delete</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="AddExpense" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-850px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>{expense_id=="" ?"Add":"Update"} Expense</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                        {loading_modal ?
                                        <>
                                        <div className="row mb-5">
                                            <div className="col-md-6 fv-row">
                                                <label className="required fs-5 fw-bold mb-2">Expense Date</label>
                                                <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                    onChange={(e) => {
                                                        setExpense_date(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-12 fv-row">
                                                <label className="fs-5 fw-bold mb-2">Merchant</label>
                                                <Select
                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                    name="category"
                                                    id="category"
                                                    className="form-control form-control-solid"
                                                    options={MerchantList}
                                                    onChange={(event) => { setMerchant(event.value) }}
                                                    value={MerchantList.filter(option => option.value == Merchant).length != 0 && MerchantList.filter(option => option.value == Merchant)[0]}
                                                    
                                                    placeholder="--Choose a Merchant--"
                                                />
                                            </div>
                                        </div>
                                            <hr/>
                                            {
                                                multipleMode?
                                                <>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6">
                                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="Currency"
                                                                id="Currency"
                                                                className="form-control form-control-solid"
                                                                options={Currencylist}
                                                                onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                placeholder="--Choose a Currency--"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="fs-5 fw-bold mb-2"></label><br/>
                                                            {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                <>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>     
                                                    <div className="row mb-5">
                                                        <div className=" col-md-3"><p className=" required fs-5">Expense Account</p></div>
                                                        <div className="col-md-3"><p className="fs-5">Notes</p></div>
                                                        <div className="col-md-2"><p className="required fs-5">Amount</p></div>
                                                        <div className="col-md-3"><p className="fs-5">Violation</p></div> 
                                                    </div>
                                                    {
                                                        expenseAccounts.map((obj,indx,arr)=>{
                                                            return(
                                                                <div className="row mb-5">
                                                                    <div className="col-md-3 mb-1">
                                                                        <Select
                                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                            name="category"
                                                                            id="category"
                                                                            className="form-control-solid"
                                                                            options={CategoryList}
                                                                            onChange={(val) => {
                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                temp[indx].account = val
                                                                                setExpenseAccounts(temp)
                                                                                violation_check(indx,val,expenseAccounts[indx].notes,expenseAccounts[indx].amount) 
                                                                            }}        
                                                                            value={expenseAccounts[indx].account}
                                                                            placeholder="--Select--"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 mb-1">
                                                                        <textarea className="form-control form-control-solid" rows="1" value={expenseAccounts[indx].notes} 
                                                                            onChange={(e) => {
                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                temp[indx].notes = e.target.value
                                                                                setExpenseAccounts(temp)
                                                                                violation_check(indx,expenseAccounts[indx].account,e.target.value,expenseAccounts[indx].amount) 
                                                                            }}
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="col-md-2 mb-1">
                                                                        <input type="number" className="form-control form-control-solid" value={expenseAccounts[indx].amount} 
                                                                            onChange={(e) => {
                                                                                if(parseFloat(e.target.value)>=0){
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[indx].amount = parseFloat(e.target.value)
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(indx,expenseAccounts[indx].account,expenseAccounts[indx].notes,parseFloat(e.target.value))  
                                                                                }
                                                                            }}
                                                                        />                                                                        
                                                                    </div>
                                                                    {expenseAccounts[indx].Violation==true ?
                                                                    <div className="col-md-3 mb-1">
                                                                        <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { setBulk_flag(false); expense_rule_check(obj.account,indx) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-3 mb-1"></div>
                                                                    }
                                                                    {
                                                                        arr.length>1 &&
                                                                        <div className="col-md-1 mb-1">
                                                                            <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                onClick={()=>{
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp.splice(indx,1)
                                                                                    setExpenseAccounts(temp)
                                                                                }}
                                                                            >&#10006;</button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="row mb-5">
                                                        <div className="col-3">
                                                            <a className='btn btn-primary rounded fs-7' onClick={()=>{setExpenseAccounts([...expenseAccounts, {account: null, amount: 0, notes: "",Violation:false}])}}> Add a row</a>
                                                        </div>
                                                        <div className="col-4">
                                                            <button className='btn btn-primary rounded fs-7' 
                                                                onClick={()=>{
                                                                    setMultipleMode(false)
                                                                    setExpenseAccounts([expenseAccounts[0]])
                                                                }}
                                                            >
                                                            Back to single expense view
                                                            </button>
                                                        </div>
                                                        <div className="col-5">
                                                            <p className='fw-bolder fs-4 mt-2'>Total Amount{Currency!=""&&<>({AllCurrencylist.filter(option =>
                                                            option.currency_id == Currency)[0].currency_symbol})</>}
                                                                <span className='ps-15'>{expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="row mb-5">
                                                        <div className="col-md-8">
                                                            <label className="required fs-5 fw-bold mb-2">Category Name</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                className="form-control-solid"
                                                                options={CategoryList}
                                                                onChange={(val) => {
                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                    temp[0].account = val
                                                                    setExpenseAccounts(temp)
                                                                    violation_check(0,val,temp[0].notes,temp[0].amount);
                                                                }}        
                                                                value={expenseAccounts[0].account}
                                                                placeholder="Select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6">
                                                            <label className="required fs-5 fw-bold mb-2">Amount</label>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Currency"
                                                                        id="Currency"
                                                                        className=" form-control-solid"
                                                                        options={Currencylist}
                                                                        onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                                        value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                        placeholder="--Currency--"
                                                                    />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <input type="number" className="form-control form-control-solid" value={expenseAccounts[0].amount} 
                                                                        onChange={(e) => {
                                                                            if(parseFloat(e.target.value)>=0){
                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                temp[0].amount = parseFloat(e.target.value)
                                                                                setExpenseAccounts(temp)
                                                                                violation_check(0,temp[0].account,temp[0].notes,parseFloat(e.target.value));
                                                                            }
                                                                        }} 
                                                                    />
                                                                </div>
                                                            </div>
                                                            {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                <>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="col-md-3 mt-9">
                                                            <button className='btn btn-primary rounded' onClick={()=>{setMultipleMode(true)}}>Itemize</button>
                                                        </div>
                                                        {expenseAccounts[0].Violation==true ?
                                                        <div className="col-md-3  mt-9">
                                                            <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { setBulk_flag(false); expense_rule_check(expenseAccounts[0].account,0) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                        </div>
                                                        :
                                                        <div className="col-md-3  mt-9"></div>
                                                        }
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-8">
                                                            <label className="fs-6 fw-bold mb-2">Note</label>
                                                            <textarea className="form-control form-control-solid" rows="3" value={expenseAccounts[0].notes} 
                                                                onChange={(e) => {
                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                    temp[0].notes = e.target.value
                                                                    setExpenseAccounts(temp)
                                                                    violation_check(0,temp[0].account,e.target.value,parseFloat(temp[0].amount));
                                                                }} 
                                                            ></textarea>
                                                        </div> 
                                                    </div>
                                                </>
                                            }
                                            <hr/>
                                            <div className="form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <input name="deactivate" className="form-check-input" type="checkbox" value="" id="deactivate" onChange={(event) => { setClaim_reimbursement(event.target.checked) }} checked={Claim_reimbursement}/>
                                                <label className="form-check-label fw-bold ps-2 fs-6" for="deactivate">Claim reimbursement </label>
                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                            {Claim_reimbursement==false &&
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="Account"
                                                        id="Account"
                                                        className="form-control form-control-solid"
                                                        options={PaidThrouhAccountlist}
                                                        onChange={(event) => { setAccount(event.value) }}
                                                        value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                        placeholder="--Choose a Account--"
                                                    />
                                                </div>
                                            </div>
                                            }<br/>
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">References </label>
                                                    <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" onChange={(e) => { setReferences(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Add To Report</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="Report"
                                                        id="Report"
                                                        className="form-control form-control-solid"
                                                        options={Reportlist}
                                                        onChange={(event) => { setReport(event.value) }}
                                                        value={Reportlist.filter(option => option.value == Report).length != 0 && Reportlist.filter(option => option.value == Report)[0]}
                                                        placeholder="--Choose a Report--"
                                                    />
                                                </div>
                                            </div>
                                            </>
                                            :
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        }   
                                        </div>
                                    </div>
                            <div class="modal-footer flex-center">
                            {write == 1 &&
                            <button type="submit"
                                className="btn btn-primary rounded"
                                data-kt-indicator={mainSubmitButtonState?"on":"off"} 
                                disabled={mainSubmitButtonState}
                                onClick={(e) => { register(e) }}
                            >
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                            }   
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="BulkAddExpense" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-950px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Bulk Add Expense</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-170 px-lg-207">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                {loading_modal ?
                                    <>
                                    <div className="d-flex align-items-center bg-light-primary rounded p-5 mb-7">
                                        <div className="col-md-12 fv-row">
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Expenses</a>
                                                        <span className="text-muted fw-bold d-block fs-7"></span>
                                                    </div>
                                                    <span className="badge badge-light fw-bolder my-2">
                                                        <button type="button" className="btn font-weight-bold btn-primary" onClick={(event) => { add_expense_section();}}><i className="la la-plus"></i>Add</button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="table-responsive mb-10">
                                                <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700" data-kt-element="items">
                                                    <thead>
                                                        <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                                            <th className="required min-w-100px w-350px">CATEGORY</th>
                                                            <th className=" required min-w-100px w-250px">EXPENSE DATE</th>
                                                            <th className="required min-w-100px w-250px">CURRENCY</th>
                                                            <th className="required min-w-100px w-250px">AMOUNT</th>
                                                            <th className="min-w-35px w-35px"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {inputFields.map((obj1,index1) =>
                                                        {
                                                            return(
                                                            <>
                                                            <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                                <td className="pt-8 text-nowrap">
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="category"
                                                                        id="category"
                                                                        className="form-control-solid"
                                                                        options={CategoryList}
                                                                        onChange={(val) => {
                                                                            const values1 = [...inputFields];
                                                                            values1[index1].expenseAccount[0].account=val;
                                                                            setInputFields(values1);
                                                                            violation_check1(index1,val,values1[index1].expenseAccount[0].description,values1[index1].expenseAccount[0].amount);
                                                                        }}        
                                                                        value={obj1.expenseAccount[0].account}
                                                                        placeholder="Select"
                                                                    />
                                                                    <br/>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="category"
                                                                        id="category"
                                                                        className="form-control-solid"
                                                                        options={MerchantList}
                                                                        onChange={(event) => {
                                                                            const values1 = [...inputFields];
                                                                            values1[index1].merchant=event.value;
                                                                            setInputFields(values1); 
                                                                        }}
                                                                        value={MerchantList.filter(option => option.value == obj1.merchant).length != 0 && MerchantList.filter(option => option.value == obj1.merchant)[0]}
                                                                        placeholder="Select  Merchant"
                                                                    />  
                                                                </td>
                                                                <td className="pt-8 text-nowrap">
                                                                    <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={obj1.Expense_date}
                                                                        onChange={(e) => {
                                                                            const values1 = [...inputFields];
                                                                            values1[index1].Expense_date=e.target.value;
                                                                            setInputFields(values1);
                                                                        }}
                                                                    />
                                                                    <br/>
                                                                    <textarea rows="1" placeholder="Description" id="ember178" className="ember-text-area ember-view form-control" aria-label="Description" 
                                                                        value={obj1.expenseAccount[0].description}
                                                                        onChange={(e) => { 
                                                                            const values1 = [...inputFields];
                                                                            values1[index1].expenseAccount[0].description=e.value;
                                                                            setInputFields(values1);
                                                                            violation_check1(index1,values1[index1].expenseAccount[0].description,e.target.value,values1[index1].expenseAccount[0].amount);
                                                                            }}
                                                                        > 
                                                                    </textarea>
                                                                </td>
                                                                <td className="pt-8 text-nowrap">
                                                                <div className="row mb-5">
                                                                <div className="col-md-8 fv-row">
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Currency"
                                                                        id="Currency"
                                                                        className=" form-control-solid"
                                                                        options={Currencylist}
                                                                        onChange={(event) => {
                                                                            const values1 = [...inputFields];
                                                                            values1[index1].Currency=event.value;
                                                                            load_bulk_currency_conversion_value(index1,event.value)
                                                                            setInputFields(values1);
                                                                        }}
                                                                        value={Currencylist.filter(option => option.value == obj1.Currency).length != 0 && Currencylist.filter(option => option.value == obj1.Currency)[0]}
                                                                        placeholder="--Currency--"
                                                                    />
                                                                    {inputFields[index1].TargetCurrencyCode!=BaseCurrencyCode &&<span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {inputFields[index1].TargetCurrencyCode} ={inputFields[index1].Currency_conversion_value}{BaseCurrencyCode}</span>}<br/>
                                                                    </div>
                                                                </div>
                                                                
                                                                <input name="deactivate" className="form-check-input" type="checkbox" value="" id="deactivate" 
                                                                    onChange={(event) => {
                                                                        const values1 = [...inputFields];
                                                                        values1[index1].Claim_reimbursement=event.target.checked;
                                                                        setInputFields(values1);
                                                                    }}  
                                                                 checked={obj1.Claim_reimbursement}/>
                                                                <label className="form-check-label fw-bold ps-2 fs-6" for="deactivate">Claim reimbursement </label>
                                                                </td>
                                                                <td className="pt-8 text-nowrap">
                                                                    <input type="number" className="form-control form-control-solid" value={obj1.expenseAccount[0].amount} 
                                                                        onChange={(e) => {
                                                                            if(parseFloat(e.target.value)>=0){
                                                                                const values1 = [...inputFields];
                                                                                values1[index1].expenseAccount[0].amount = parseFloat(e.target.value);
                                                                                setInputFields(values1);
                                                                                violation_check1(index1,values1[index1].expenseAccount[0].account,values1[index1].expenseAccount[0].description,parseFloat(e.target.value));
                                                                            }
                                                                        }} 
                                                                    />
                                                                    {inputFields[index1].TargetCurrencyCode!=BaseCurrencyCode &&<span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{inputFields[index1].Currency_conversion_value*inputFields[index1].expenseAccount[0].amount}</span>}
                                                                    <br/>
                                                                    {inputFields[index1].Violation==true ?
                                                                    <div className="col-md-3 mb-1">
                                                                        <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { setBulk_flag(true); expense_rule_check(obj1.expenseAccount[0].account,index1); }} data-bs-toggle="modal" data-bs-target="#ViolationModal1">Violation</a> 
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-3 mb-1"></div>
                                                                    }
                                                                </td>
                                                                <td className="pt-5 text-end">
                                                                    <div className="col-lg-1" ><button className="btn font-weight-bold btn-danger btn-icon" type="button" onClick={(event) => { remove_expense_section();}}><i className="la la-remove"></i></button></div>
                                                                </td>
                                                            </tr>
                                                            </>
                                                            );
                                                        })} 
                                                    </tbody> 
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                }  
                                </div>
                            </div>
                                
                            <div class="modal-footer flex-center">
                            {write == 1 &&
                            <button type="submit"
                                className="btn btn-primary rounded"
                                data-kt-indicator={mainSubmitButtonState?"on":"off"} 
                                disabled={mainSubmitButtonState}
                                onClick={(e) => { BulkAddExpense(e) }}
                            >
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                            }   
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="ViolationModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Warning Violations</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                {((Expense_Amount_Limit!="" || Receipt_Required_Limit!="" || Make_Description_Mandatory!="")&& Bulk_flag==false)&&
                                    <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                        {parseInt(Expense_Amount_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Exceeds Expense Amount Limit {BaseCurrency}{parseInt(Expense_Amount_Limit)}</div>
                                            </div>
                                        </div>
                                        </>
                                        }<br/>
                                        {parseInt(Receipt_Required_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Exceeds Receipt Required Limit {BaseCurrency}{parseInt(Receipt_Required_Limit)}</div>
                                            </div>
                                        </div>
                                        </>
                                        }<br/>
                                        {Make_Description_Mandatory=="Yes" && expenseAccounts[Index].notes=="" &&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Description is mandatory.</div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="ViolationModal1" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Warning Violations</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                {((Expense_Amount_Limit!="" || Receipt_Required_Limit!="" || Make_Description_Mandatory!="")&& Bulk_flag==true)&&
                                    <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                        {parseInt(Expense_Amount_Limit)<parseInt(inputFields[Index].expenseAccount[0].amount)&&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Exceeds Expense Amount Limit {BaseCurrency}{parseInt(Expense_Amount_Limit)}</div>
                                            </div>
                                        </div>
                                        </>
                                        }<br/>
                                        {parseInt(Receipt_Required_Limit)<parseInt(inputFields[Index].expenseAccount[0].amount)&&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Exceeds Receipt Required Limit {BaseCurrency}{parseInt(Receipt_Required_Limit)}</div>
                                            </div>
                                        </div>
                                        </>
                                        }<br/>
                                        {Make_Description_Mandatory=="Yes" && inputFields[Index].expenseAccount[0].description=="" &&
                                        <>			
                                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        <div class="d-flex flex-stack flex-grow-1">
                                            <div class="fw-bold">
                                                {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                <div class="fs-6 text-gray-700">Description is mandatory.</div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>


            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ExpenseList;

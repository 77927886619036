import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"

import { Link } from 'react-router-dom';

import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
function Signup() {
	let modal=2;
	let [RedirectState, setRedirectState] = useState(2);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	let [captchaToken, setCaptchaToken] = useState("");
	let [phone_input, setphone_input] = useState({});
	
	const [value, setValue] = useState();
	
	function onChange(value)
	{
		setCaptchaToken(value)
	}
	
	function setCookie(cname, cvalue, exdays) {
	  var d = new Date();
	  d.setTime(d.getTime() + (exdays*24*60*60*1000));
	  var expires = "expires="+ d.toUTCString();
	  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	function getCookie(cname) {
	  var name = cname + "=";
	  var ca = document.cookie.split(';');
	  for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
		  c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		  return c.substring(name.length, c.length);
		}
	  }
	  return "";
	}
	
	useEffect(() => {
		/*
			var se = document.createElement('script');
			se.src = "/assets/js/custom/authentication/sign-up/general.js";
			document.body.appendChild(se);
			*/
	},[])

 
	const register =(e)=>{
		
			e.preventDefault();
			var object = {};
			object.email = document.getElementById("email").value;
			object.password = document.getElementById("password").value;
			object.fname = document.getElementById("fname").value;
			object.lname = document.getElementById("lname").value;
			object.cpassword = document.getElementById("cpassword").value;
			//object.orgname = document.getElementById("orgname").value;
			//object.orgdomain = document.getElementById("orgdomain").value.trim().toLowerCase();
			// object.domain = document.getElementById("domain").value;
			object.service="expense";
			object.phone = document.getElementById("phone").value;
			object.token = captchaToken;
			if(phone_input.getNumber().startsWith("+") )
			{
				object.phone = phone_input.getNumber();
			}
			else{
				var countryData = phone_input.getSelectedCountryData();
				object.phone = "+"+countryData.dialCode+phone_input.getNumber();
			}
			// console.log(phoneNumber);
			// console.log(object);
			var nexp=/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
			if(object.fname=="")
			{
				//console.log("1");
				swal("Warning!", "First Name cannot be a empty", "warning");
				return false;
			}
			else if(!isNaN(object.fname))
			{
				//console.log("2");
				swal("Warning!", "First Name cannot be a number", "warning");
				return false;
			}
			else if (object.fname.match(nexp))
			{
				//console.log("3");
				swal("Warning!", "First Name cannot contain symbols ", "warning");
				return false;
			}

			if(object.lname=="")
			{
				//console.log("4");
				swal("Warning!", "Last Name cannot be a empty", "warning");
				return false;
			}
			else if(!isNaN(object.lname))
			{
				//console.log("5");
				swal("Warning!", "Last Name cannot be a number", "warning");
				return false;
			}
			else if (object.lname.match(nexp))
			{
				//console.log("6");
				swal("Warning!", "Last Name cannot contain symbols ", "warning");
				return false;
			}
			
			
			
			// var nexp2=/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/; //regex without NOT operator needed in match
			/*var nexp2=/^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 &$|~!@#()_.;,:*-+%]*)?$/; //NOT operator needed in match
			if(object.orgname=="")
			{
				//console.log("1");
				swal("Warning!", "Organization Name cannot be empty", "warning");
				return false;
			}
			else if(!isNaN(object.orgname))
			{
				//console.log("2");
				swal("Warning!", "Organization Name cannot be a number", "warning");
				return false;
			}
			else if (!object.orgname.match(nexp2))
			{
				//console.log("3");
				swal("Warning!", "Organization Name cannot contain symbols ", "warning");
				return false;
			}

			var exp3 = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9\/\-.:]*)?$/;
			//only single word without space, numbers or special characters
			if(object.orgdomain=="")
			{
				//console.log("7");
				swal("Warning!", "Please provide a Organization domain name", "warning");
				return false;
			}
			else if (!object.orgdomain.match(exp3))
			{
				swal("Warning!", "Certain symbols are not allowed in domain name", "warning");
				return false;
			}
			*/
			
			// var exp4= /^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/;
			// var exp4= /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
			// //only domain.com
			// if(object.orgdomain=="")
			// {
			// 	//console.log("7");
			// 	swal("Warning!", "Please provide a Organization domain", "warning");
			// 	return false;
			// }
			// else if (!object.orgdomain.match(exp4))
			// {
			// 	swal("Warning!", "Please provide a valid Organization domain", "warning");
			// 	return false;
			// }

			// var exp=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			var exp=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
			if(object.email=="")
			{
				//console.log("7");
				swal("Warning!", "Please provide an email", "warning");
				return false;
			}
			else if (!object.email.match(exp))
			{
				//console.log("8");
				swal("Warning!", "Please provide a valid email", "warning");
				return false;
			}
			/*else if(!object.email.toLowerCase().includes(object.orgdomain)){
				swal("Warning!", "Your email doesn't match your Organization domain", "warning");
				return false;
			}*/

			//var exp2=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%^&*])[a-zA-Z\d@.#$%^&*]{8,}$/;
			var exp2=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-]).{8,}$/;
			if(object.password=="")
			{
				//console.log("9");
				swal("Warning!", "Password cannot be empty", "warning");
				return false;
			}
			else if(object.password.length < 8)
			{
				swal("Warning!", "Password must have 8 characters.", "warning");
				return false;
			}
			// else if (object.password.includes("@") || object.password.includes("."))
			// {
			// 	//console.log("11");
			// 	swal("Warning!", "Password cannot contain '@' and '.' symbols within it.", "warning");
			// 	return false;
			// }
			// else if (!object.password.match(exp2))
			// {
			// 	swal("Warning!", "Password is not valid, try with a different password.", "warning");
			// 	return false;
			// }
			
			
			if(phone_input.getNumber() == "")
			{
				swal("Warning!", "Phone number cannot be empty.", "warning");
				return false;
			}
			else if(isNaN(object.phone) )
			{
				//console.log("13");
				swal("Oops!", "Please enter a valid phone number", "error");
				return false;
			}
			// else if(object.phone.length != 10 )
			// {
			// 	//console.log("13");
			// 	swal("Oops!", "Please enter 10 digit phone number", "error");
			// 	return false;
			// }
			
			if(object.cpassword=="")
			{
				//console.log("14");
				swal("Warning!", "Please confirm your password by re-typing.", "warning");
				return false;
			}
			else if (object.cpassword != object.password)
			{
				//console.log("15");
				swal("Warning!", "New Password and Confirm Password is not matching.", "warning");
				return false;
			}
			/*
			if(document.getElementById("agreement").checked == false)
			{
				//console.log("16");
				swal("Warning!", "Please check our terms and condition.", "warning");
				return false;
			}
			*/
			
			if(object.token=="")
			{
				//console.log("17");
				swal("Warning!", "Please Verify the captcha", "warning");
				return false;
			}
			else if(!isNaN(object.token))
			{
				//console.log("18");
				swal("Warning!", "Please Verify the captcha", "warning");
				return false;
			}
			
			// if(e.target.id == "kt_sign_up_submit")
			// {
			// 	e.target.setAttribute("data-kt-indicator", "on");
			// 	e.target.disabled = true;
			// }
			// else
			// {
			// 	document.getElementById("kt_sign_up_submit").setAttribute("data-kt-indicator", "on");
			// 	document.getElementById("kt_sign_up_submit").disabled = true;
			// }
			// console.log(object)
			setMainSubmitButtonState(true)
			var headers = {
			  headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			  }
			}
			// console.log(object)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/register`,object, headers).then((res) => {
			setMainSubmitButtonState(false)
				if(res.data.operation == "domain_exist"){
					swal("Oops!", "Domain already taken", "warning").then((res) => {
						window.location.reload();
					});
					// return false;
				}
				else if(res.data.operation == "success")
				{
					// console.log("success area")
					swal("Great!", "Registration Successful ", "success",{
							buttons: {
										continue: {
											text: "OK",
											value: "continue",
										},
										}},).then((result) => {
							//console.log(result);
							switch (result) {
								case "continue":
									window.location=`/login`;
									break;
								default:
									window.location=`/login`;
									break;
							}
					});
				}
				else
				{
					if(res.data.message != "danger")
					{
						// console.log("else part 1")

						swal("Oops!", res.data.message, "error").then((res) => {
							//window.location.reload();
						});
					}
					else
					{
						// console.log("else part 2")

						swal("Oops!", "something went wrong", "error").then((res) => {
							//window.location.reload();
						});
					}
				}
			})
			.catch(function (error) {
				// console.log("register api call exeption")
				swal("Oops!", "something went wrong", "error")
				.then((res) => {
							window.location.reload();
				});
			});
	}
	useEffect(() => {
		function loadScripts(){
			
        var se = document.createElement('script');
        se.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js";
        se.type="text/javascript";
        document.body.appendChild(se);
		};
		loadScripts();
	},[])

function international_phone_number(){
	const phoneInputField = document.querySelector("#phone");

			const phoneInput = window.intlTelInput(phoneInputField, {
				initialCountry: "auto",
				geoIpLookup: function(success, failure) {
					$.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
					  var countryCode = (resp && resp.country) ? resp.country : "us";
					  success(countryCode);
					});
				  },
				utilsScript:
				"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
			});
			setphone_input(phoneInput)
			//console.log(getIp);
	}
	 
	window.addEventListener("load", function(){
		if(modal==2){
	        international_phone_number()
		}
	}, false)


	return (
		<>
		{
			(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
			)
			: 
			null
		}
		<div class="d-flex flex-column flex-root" id="kt_app_root">
			
			<div class="d-flex flex-column flex-lg-row flex-column-fluid">
				
				<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
					
					<div class="d-flex flex-center flex-column flex-lg-row-fluid">
						
						<div class="w-lg-500px p-10">
								
								<div class="text-center mb-11">
									
									<h1 class="text-dark fw-bolder mb-3">Sign Up</h1>
									
									<div class="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
									
								</div>
								
								<div class="row g-3 mb-9">
									
									<div class="col-md-6">
										
										<a href="#" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" class="h-15px me-3" />Sign in with Google</a>
										
									</div>
									
									<div class="col-md-6">
										
										<a href="#" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" class="theme-light-show h-15px me-3" />
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" class="theme-dark-show h-15px me-3" />Sign in with Apple</a>
										
									</div>
									
								</div>
								
								<div class="separator separator-content my-14">
									<span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
								</div>
								<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<input class="form-control bg-transparent" type="text" placeholder="First Name" name="first-name" id="fname" autocomplete="off" />
								</div>
								<div className="col-xl-6">
									<input class="form-control bg-transparent" type="text" placeholder="Last Name" id="lname" name="last-name" autocomplete="off" />
								</div>
							</div>
							
							<div class="fv-row mb-8">
								<input className="form-control bg-transparent" type="text" placeholder="Enter Phone Number" id="phone"  name="phone" autocomplete="off" />
							</div>
								<div class="fv-row mb-8">
									<input type="text" placeholder="Email" name="email" id="email" autocomplete="off" class="form-control bg-transparent" />
								</div>
								
								<div class="fv-row mb-8" data-kt-password-meter="true">
									
									<div class="mb-1">
										
										<div class="position-relative mb-3">
											<input class="form-control bg-transparent" type="password" placeholder="Password" id="password" name="password" autocomplete="off" />
											<span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i class="bi bi-eye-slash fs-2"></i>
												<i class="bi bi-eye fs-2 d-none"></i>
											</span>
										</div>
										
										<div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
										</div>
										
									</div>
									
									<div class="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
									
								</div>
								
								<div class="fv-row mb-8">
									
									<input placeholder="Repeat Password" id="cpassword" name="confirm-password" type="password" autocomplete="off" class="form-control bg-transparent" />
									
								</div>
								
								<div class="fv-row mb-8">
									<label class="form-check form-check-inline">
										<input class="form-check-input" type="checkbox" name="toc" value="1" />
										<span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">I Accept the
										<a href="#" class="ms-1 link-primary">Terms</a></span>
									</label>
								</div>
								
								<div class="d-grid mb-10">
									<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
									<button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => {register(e)}} id="kt_sign_up_submit" class="btn btn-primary">
										<span class="indicator-label">Sign up</span>
										<span class="indicator-progress">Please wait...
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								</div>
								
								<div class="text-gray-500 text-center fw-semibold fs-6">Already have an Account?
								<a href="/login" class="link-primary fw-semibold">Sign in</a></div>
								

							
						</div>
						
					</div>
					
					<div class="d-flex flex-center flex-wrap px-5">
						
						<div class="d-flex fw-semibold text-primary fs-base">
							<a href="../../demo1/dist/pages/team.html" class="px-5" target="_blank">Terms</a>
							<a href="../../demo1/dist/pages/pricing/column.html" class="px-5" target="_blank">Plans</a>
							<a href="../../demo1/dist/pages/contact.html" class="px-5" target="_blank">Contact Us</a>
						</div>
						
					</div>
					
				</div>
				
				<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{"background-image":"url(assets/media/misc/auth-bg.png)"}}>
					
					<div class="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						
						<a href="../../demo1/dist/index.html" class="mb-12">
							<img alt="Logo" src=" https://cdnstatic.adocrm.com/static/ado-crm-logo-light.png" class="h-75px" />
						</a>
						
						<img class="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="assets/media/misc/auth-screens.png" alt="" />
						
						<h1 class="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
						
						<div class="text-white fs-base text-center">In this kind of post,
						<a href="#" class="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
						<br />and provides some background information about
						<a href="#" class="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
						<br />work following this is a transcript of the interview.</div>
						
					</div>
					
				</div>
				
			</div>
			
		</div>
		</>
	);
}
export default Signup;
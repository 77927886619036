import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'

import uuid from 'react-uuid';

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function Policies(props) {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    let [user, setUser] = useState({});

    let [currency_price, setCurrencyPrice] = useState("");
    let [currency_date, setCurrencyDate] = useState("");

    let [loader, setLoader] = useState(0);

    let [currency_value, setCurrencyValue] = useState("");
    let [currency_id, setCurrencyId] = useState("");

    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    let [subscription, set_subscription] = useState("");
    let [duration, setduration] = useState("");
    const [PlatformDetails, setPlatformDetails] = useState([]);

    let [photoName, setPhotoName] = useState([""]);
    let [idNames, setIdNames] = useState(["", "", ""]);
    const [profilePhoto, setProfilePhoto] = useState("");
    const [fileNames, setFileNames] = useState([]);
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());
    let [lead_source, setLead_source] = useState("");
    //let [address_type, setAddress_type] = useState("");
    let [identity_type, setIdentity_type] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    let [State, setState] = useState("");
	let [Country, setCountry] = useState("");
    const [unique_id, setUnique_id] = useState(uuid());

    const [VehicleName, setVehicleName] = useState("");
    const [RecordMileage, setRecordMileage] = useState("");
    const [RecordMileageList, setRecordMileageList] = useState([{label:"Manually",value:"Manually"}])
    const [Hints, setHints] = useState("");
    const [VehicleList, setVehicleList] = useState([]);
	
	let [country_array, set_country_array] = useState([]);
    let [state_array, set_state_array] = useState([]);
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"));

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [Policy_name, setPolicy_name] = useState("");
    let [Description, setDescription] = useState("");
    let [Policy_admin, setPolicy_admin] = useState([]);
    const [Category, setCategory] = useState("")
    let [Default_unit, setDefault_unit] = useState("Km");

    const [BaseCurrency, setBaseCurrency] = useState("");
    const [GeneralLimit, setGeneralLimit] = useState([{Expense_Amount_Limit:"",Receipt_Required_Limit:"",Make_Description_Mandatory:"No"}])
    const [Description_Permission, setDescription_Permission] = useState([{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }])
    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])
    const [CategoryList, setCategoryList] = useState([])
    const [CategoryList1, setCategoryList1] = useState([])

	var page_name = "policies";
    
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    //console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if(res.data.organization_flag==0){
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id!="" && validateJWT();

    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    function loadScripts() {

        var elem = document.getElementById("create_account");
        if (!elem) {
            var se = document.createElement('script');
            se.src = "/assets/js/custom/modals/create-account.js";
            se.id = "create_account";
            document.body.appendChild(se);
        }

        /*
        se = document.createElement('script');
        se.src = "/assets/js/custom/modals/new-address.js";
        document.body.appendChild(se);
    	
        se = document.createElement('script');
        se.src = "/assets/js/custom/widgets.js";
        document.body.appendChild(se);
    	
        se = document.createElement('script');
        se.src = "/assets/js/custom/apps/chat/chat.js";
        document.body.appendChild(se);
    	
        se = document.createElement('script');
        se.src = "/assets/js/custom/modals/create-app.js";
        document.body.appendChild(se);
        */
        //setLoader(2)
    }
	

	useEffect(() => {
        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
            let temp=[];
            temp.push({value:"user_all" ,label:"All User"});
            for(let i=0;i<body.length;i++)
            {
                temp.push({value: body[i].user_id ,label:body[i].user_id});
            }
            setuserList1(temp);
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setuserList(temp_dropdown);
        }
        if(read || write)
        {
        (user.email && visibility) && getuserdetails();
        }
}, [user, visibility, read, write])

useEffect(() => {
    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if(body.length>0)
        {
            setBaseCurrency(body[0].currency_symbol);
        }
    }
    if(read || write)
    {
        user.email && visibility && getbasecurrency();
    }
    async function getcategorydetails() {

        var object = {};
        object.email = user.email;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.category_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);
            }
        })
        setCategoryList(temp_dropdown);
        var temp_dropdown1 = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj1 = {};
                temp_obj1.category_id = obj.id;
                temp_obj1.acceptability =false;
                temp_obj1.Expense_Amount_Limit ="";
                temp_obj1.Receipt_Required_Limit ="";
                temp_obj1.Make_Description_Mandatory ="";
                temp_dropdown1.push(temp_obj1);
            }
        })
        setCategoryList1(temp_dropdown1);
    }
    if(read || write)
    {
        (user.email && visibility) && getcategorydetails();
    }

}, [user, visibility, read, write])

    const register = () => {
        var object = {};
        object.unique_id = unique_id;
        object.organization_id = organization_id;
        object.page_name=page_name;
        object.Policy_name = Policy_name;
        object.Policy_admin = Policy_admin;
        object.Description = Description;
        object.Default_unit = Default_unit;
        object.Default_category = Category;
        object.CategoryLimit = CategoryList1;
        object.VehicleList = VehicleList;
        object.GeneralLimit = GeneralLimit;
        object.file_names=fileNames;

        console.log(object);
        if (object.Policy_name == "") {
            swal("Warning!", "Policy Name cannot be a empty", "warning");
            return false;
        }
        let t=true;
        t = GeneralLimit.find(o=>{ 
            console.log("as",o.Expense_Amount_Limit)
            if(isNaN(o.Expense_Amount_Limit))
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else if(isNaN(o.Receipt_Required_Limit))
            {
                swal("Warning!", "Please enter a valid receipt required  limit", "warning");
                return false;
            }   
        })
        t = CategoryList1.find(o1=>{ 
            if(isNaN(o1.Expense_Amount_Limit))
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else if(isNaN(o1.Receipt_Required_Limit))
            {
                swal("Warning!", "Please enter a valid receipt required limit", "warning");
                return false;
            }   
        })
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(t!=false)
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/save-travel-policy`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data.operation === "success") {
                    swal("Great!", "You travel policy details saved successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else if (res.data.operation === "filenotuploaded") {
                    swal("Oops!", "You have not uploaded any images or documents!", "error");
                }

                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })
            .catch(function (error) {
            });
        }
            
    }
    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const handleChangeStatus1 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setProfilePhoto(name)
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            setProfilePhoto("")
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };
    const getUploadParams1 = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-travel-policy`, headers, body }
    }

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
            </div>
        )
    }

    const add_policy = () => {
        const values1 = [...VehicleList];
        let temp={};
        temp.VehicleName="";
        temp.VehicleName="";
        temp.Hints="";
        values1.push(temp);
        setVehicleList(values1);
    }

    const remove_policy = (index) => {
        const values1 = [...VehicleList];
        values1.splice(index,1);
        setVehicleList(values1);
    }

    const handle_validation = (type,value) => {
        if(isNaN(value))
        {
            if(type=="expense")
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else
            {
                swal("Warning!", "Please enter a valid receipt required limit", "warning");
                return false;
            }
        }   
    }
    
    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }

            
                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Add Policies
                                    
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                            {user.email ?
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                            <div className="stepper-nav py-5 mt-5">
                                                <div className="stepper-item current" data-kt-stepper-element="nav">
                                                    <h3 className="stepper-title">Policy Details</h3>
                                                </div>
                                                <div className="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 className="stepper-title">Mileage</h3>
                                                </div>
                                                <div className="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 className="stepper-title">Category</h3>
                                                </div>

                                            </div>
                                            <form className="mx-auto mw-600px w-100 pt-15 pb-10" novalidate="novalidate" id="kt_create_account_form">
                                                <div className="current" data-kt-stepper-element="content">
                                                    <div className="w-100">
                                                    <div class="row mb-5">
                                                        <div class="col-md-12 fv-row">
                                                            <label class="required fs-5 fw-bold mb-2">Policy Name</label>
                                                            <input id="first_name" type="text" class="form-control form-control-solid" placeholder="" name="first-name" value={Policy_name}
                                                                onChange={(event) => {setPolicy_name(event.target.value);}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-5">
                                                        <div class="col-md-12 fv-row">
                                                            <label class=" fs-5 fw-bold mb-2">Description</label>
                                                            <textarea id="email" type="email" class="form-control form-control-solid" placeholder="" name="email" value={Description}
                                                                onChange={(event) => { setDescription(event.target.value);}} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Policy Admin</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                isMulti
                                                                className="form-control form-control-solid"
                                                                options={userlist}
                                                                onChange={(val) => { setPolicy_admin(val.map(a => a.value)) }}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="fs-5 fw-bold mb-2">Upload Travel Policy (Single file upload)</label>
                                                        <Dropzone
                                                            getUploadParams={getUploadParams1}
                                                            onChangeStatus={handleChangeStatus1}
                                                            LayoutComponent={CustomLayout}
                                                            PreviewComponent={Preview}
                                                                styles={{
                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                }}
                                                                maxFiles={1}
                                                                maxSizeBytes={1024*1024*5}
                                                            accept="image/*,.pdf"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div data-kt-stepper-element="content">
                                                    <div className="w-100">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view1"> 
                                                        <div className="row mb-5">
                                                            <div className="col-md-12 fv-row">
                                                                <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                                    <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                        <span class="fs-5 fw-bold mb-2">Default Unit</span>
                                                                        <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify the bid amount to place in." aria-label="Specify the bid amount to place in."></i>
                                                                    </label>
                                                                    <div class="col-lg-8 fv-row">
                                                                        <div class="d-flex align-items-center mt-3">
                                                                            <label class="form-check form-check-inline form-check-solid me-5">
                                                                                <input class="form-check-input" name="communication[]" id="email" type="checkbox" onClick={() => {setDefault_unit("Km")}} value="email" checked={Default_unit =="Km"}/><span class="fw-bold ps-2 fs-6">Km</span>
                                                                            </label>
                                                                            <label class="form-check form-check-inline form-check-solid">
                                                                                <input class="form-check-input" name="communication[]" id="phone" type="checkbox" onClick={() => {setDefault_unit("Mile")}} value="phone" checked={Default_unit =="Mile"}/><span class="fw-bold ps-2 fs-6">Mile</span>
                                                                            </label>
                                                                        </div>
                                                                        <span id="communication1" class="text-danger font-weight-bold"></span>
                                                                    </div>
                                                                    {/*<div class="d-flex flex-stack gap-5 mb-3">
                                                                        <button type="button" class="btn btn-light-primary w-100" data-kt-modal-bidding="option" onClick={() => {setDefault_unit("Km")}}>Km</button>
                                                                        <button type="button" class="btn btn-light-primary w-100" data-kt-modal-bidding="option" onClick={() => {setDefault_unit("Mile") }}>Mile</button>
                                                                    </div>*/}
                                                                    <div class="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <label class="fs-5 fw-bold mb-2">Default Category</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={CategoryList}
                                                                onChange={(event) => { setCategory(event.value) }}
                                                                value={CategoryList.filter(option => option.value == Category).length != 0 && CategoryList.filter(option => option.value == Category)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                        <div class="card-header border-0 pt-5">
                                                            <h3 class="card-title align-items-start flex-column">
                                                                <span class="card-label fw-bolder fs-3 mb-1">Vehicle Details</span>
                                                                <span class="text-muted mt-1 fw-bold fs-7"></span>
                                                            </h3>
                                                            <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="Click to add a user">
                                                                <a href="#" class="btn btn-sm btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends" onClick={(e) => { add_policy(e) }}>
                                                                <span class="svg-icon svg-icon-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                                    </svg>
                                                                </span>
                                                                Add Vehicle</a>
												            </div>
                                                        </div>
                                                        <div class="card-body py-3">
                                                            {VehicleList.length>0 ?
                                                            <div class="">
                                                                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">  
                                                                    <thead>
                                                                        <tr class="fw-bolder text-muted">
                                                                        
                                                                            <th class="min-w-200px">Vehicle name</th>
                                                                            <th class="min-w-150px">Record Mileage Using </th>
                                                                            <th class="min-w-150px">Purpose</th>
                                                                            <th class="min-w-100px text-end"></th>  
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {VehicleList.map((obj, index) => {
                                                                            return (
                                                                            <>
                                                                            <tr>
                                                                                <td>
                                                                                    
                                                                                    <input type="text" class="form-control form-control-solid" placeholder="Enter Vehicle name" name="details_title" value={obj.VehicleName}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...VehicleList];
                                                                                            values1[index].VehicleName=event.target.value;
                                                                                            setVehicleList(values1);
                                                                                        }}
                                                                                    />
                                                                                        
                                                                                </td>
                                                                                <td>
                                                                                    <Select
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control"
                                                                                        options={RecordMileageList}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...VehicleList];
                                                                                            values1[index].RecordMileage=event.value;
                                                                                            setVehicleList(values1);
                                                                                        }}
                                                                                        value={RecordMileageList.filter(option => option.value == obj.RecordMileage).length != 0 && RecordMileageList.filter(option => option.value == obj.RecordMileage)[0]}
                                                                                        placeholder=""
                                                                                    />   
                                                                                </td>
                                                                                <td>
                                                                                    <textarea class="form-control form-control-solid" placeholder="Enter Hints" name="details_title" value={obj.Hints}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...VehicleList];
                                                                                            values1[index].Hints=event.target.value;
                                                                                            setVehicleList(values1);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" data-bs-toggle="modal" onClick={(e) => {remove_policy(index) }}>
                                                                                        <span class="svg-icon svg-icon-3">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                            </>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } 
                                                        </div>
                                                        </div> 
                                                        {/*<div class="overflow-auto pb-5">
                                                            <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                                        <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                                    <div class="mb-3 mb-md-0 fw-bold">
                                                                        <a href="/metronic8/demo1/../demo1/apps/projects/project.html" class="fs-6 text-hover-primary fw-bolder" data-bs-toggle="modal" data-bs-target="#VehicleList">Your Organization Have {VehicleList.length} vehicle</a>
                                                                        <div class="fs-6 text-gray-700 pe-7">Added vehicles used in your organization.</div>
                                                                    </div>
                                                                    <a href="#" class="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#NewVehicle" onClick={(e) => { New_Vehicle() }}>New vehicle</a>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                <div data-kt-stepper-element="content">
                                                    <div className="w-100">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view1">
                                                        <div class="card-header border-0 pt-5">
                                                            <h3 class="card-title align-items-start flex-column">
                                                                <span class="card-label fw-bolder fs-3 mb-1">General Limits</span>
                                                                <span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>
                                                            </h3>
                                                        </div>
                                                        <div class="card-body py-3">
                                                            <div class="">
                                                                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">  
                                                                    <thead>
                                                                        <tr class="fw-bolder text-muted">
                                                                            <th class="min-w-150px">Expense Amount Limit</th>
                                                                            <th class="min-w-150px">Receipt Required Limit</th>
                                                                            <th class="min-w-150px text-end">Description Required</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {GeneralLimit.map((obj, index) => {
                                                                        return (
                                                                        <>
                                                                        <tr>
                                                                        <td>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">{BaseCurrency}</span>
                                                                            </div>
                                                                            <input id="ember537" class="form-control ember-text-field text-left ember-view" type="text" value={obj.Expense_Amount_Limit}
                                                                            onChange={(event) => { 
                                                                                const values1 = [...GeneralLimit];
                                                                                values1[index].Expense_Amount_Limit=event.target.value;
                                                                                setGeneralLimit(values1);
                                                                                handle_validation("expense",event.target.value);
                                                                            }}
                                                                            />
                                                                        </div>
                                                                        </td>
                                                                        <td>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> {BaseCurrency} </span>
                                                                            </div>
                                                                            <input id="ember537" class="form-control ember-text-field text-left ember-view" type="text" value={obj.Receipt_Required_Limit}
                                                                            onChange={(event) => { 
                                                                                const values1 = [...GeneralLimit];
                                                                                values1[index].Receipt_Required_Limit=event.target.value;
                                                                                setGeneralLimit(values1);
                                                                                handle_validation("receipt",event.target.value);
                                                                            }}
                                                                            />
                                                                        </div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="d-flex align-items-center">
                                                                                <div class="d-flex justify-content-start flex-column">
                                                                                    <Select
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control"
                                                                                        options={Description_Permission}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...GeneralLimit];
                                                                                            values1[index].Make_Description_Mandatory=event.value;
                                                                                            setGeneralLimit(values1);
                                                                                        }}
                                                                                        value={Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory).length != 0 && Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory)[0]}
                                                                                        placeholder=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        </tr>
                                                                        </>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div class="card-header border-0 pt-5">
                                                            <h3 class="card-title align-items-start flex-column">
                                                                <span class="card-label fw-bolder fs-3 mb-1">Category-specific Limits</span>
                                                                <span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>
                                                            </h3>
                                                        </div>         
                                                        <div class="card-body py-3">
                                                            <div class="">
                                                                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">  
                                                                    <thead>
                                                                        <tr class="fw-bolder text-muted">
                                                                            <th class="w-25px">
                                                                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="1" data-kt-check="true" data-kt-check-target=".widget-9-check"/>
                                                                                </div>
                                                                            </th>
                                                                            <th class="min-w-200px">Category Name</th>
                                                                            <th class="min-w-150px">Expense Amount Limit</th>
                                                                            <th class="min-w-150px">Receipt Required Limit</th>
                                                                            <th class="min-w-100px text-end">Description Required</th> 
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CategoryList1.map((obj, index) => {
                                                                            return (
                                                                            <>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                                                        <input class="form-check-input widget-9-check" type="checkbox" value={obj.acceptability}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...CategoryList1];
                                                                                            values1[index].acceptability=event.target.checked;
                                                                                            setCategoryList1(values1);
                                                                                        }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div class="d-flex justify-content-start flex-column">
                                                                                            <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{CategoryList.filter(option => option.value == obj.category_id)[0].label}</a>
                                                                                            <span class="text-muted fw-bold text-muted d-block fs-7"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"> {BaseCurrency} </span>
                                                                                    </div>
                                                                                    <input id="ember537" class="form-control ember-text-field text-left ember-view" type="text" value={obj.Expense_Amount_Limit}
                                                                                    onChange={(event) => { 
                                                                                        const values1 = [...CategoryList1];
                                                                                        values1[index].Expense_Amount_Limit=event.target.value;
                                                                                        setCategoryList1(values1);
                                                                                        handle_validation("expense",event.target.value);
                                                                                    }}
                                                                                    />
                                                                                </div>
                                                                                </td>
                                                                                <td>
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"> {BaseCurrency} </span>
                                                                                    </div>
                                                                                    <input id="ember537" class="form-control ember-text-field text-left ember-view" type="text" value={obj.Receipt_Required_Limit}
                                                                                    onChange={(event) => { 
                                                                                        const values1 = [...CategoryList1];
                                                                                        values1[index].Receipt_Required_Limit=event.target.value;
                                                                                        setCategoryList1(values1);
                                                                                        handle_validation("receipt",event.target.value);
                                                                                    }}
                                                                                    />
                                                                                </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div class="d-flex justify-content-start flex-column">
                                                                                            <Select
                                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                                name="category"
                                                                                                id="category"
                                                                                                className="form-control"
                                                                                                options={Description_Permission}
                                                                                                onChange={(event) => { 
                                                                                                    const values1 = [...CategoryList1];
                                                                                                    values1[index].Make_Description_Mandatory=event.value;
                                                                                                    setCategoryList1(values1);
                                                                                                }}
                                                                                                value={Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory).length != 0 && Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory)[0]}
                                                                                                placeholder=""
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            </>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="d-flex flex-stack pt-15">
                                                    <div className="mr-2">
                                                        <button type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                                                            <span className="svg-icon svg-icon-4 me-1">
                                                                Previous
                                                            </span>
                                                        </button>
                                                    </div>
                                         
                                                    <div>
                                                    {
                                                        write==1 &&
                                                        <>
                                                        <button
                                                            type="button"
                                                            id="kt_contact_submit_button"
                                                            className="btn btn-lg btn-primary me-3"
                                                            data-kt-stepper-action="submit"
                                                            data-kt-indicator={mainSubmitButtonState?"on":"off"}
                                                            disabled={mainSubmitButtonState}
                                                            onClick={() => { register() }}
                                                        >
                                                            <span className="indicator-label">Submit
                                                                <span className="svg-icon svg-icon-3 ms-2 me-0">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
                                                        <button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="next">Next
                                                            <span className="svg-icon svg-icon-4 ms-1 me-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        </>                                                           
                                                    }
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>
                                </>
                                :
                                <>
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>

                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className="fw-bolder">Create Live Alerts</h2>
                                                    </div>
                                                </div>


                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">


                                                    </div>


                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </>
                                }
                            </>
                            }
                            </div>
                        </div>
                    </div>
                    <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>
    );
}
export default Policies;
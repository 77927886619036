import React, {useState, useEffect,useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select, {components} from 'react-select';
import removeImg from './icons/remove.svg'

import uuid from 'react-uuid';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';
import { Modal, Tabs, Tab } from 'react-bootstrap';

import ReactDOM from 'react-dom';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import $ from 'jquery';
import moment from 'moment';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function Customer(props) {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    
    const [user, setUser] = useState({});
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [state, setstate] = useState(2);

    const [currencyList, setCurrencyList] = useState([])
    //const [termsList, setTermsList] = useState([])

    const [customerType, setCustomerType] = useState("business")
    const [primaryContact, setPrimaryContact] = useState({salutation: null, firstName: "", lastName: ""})
    const [companyName, setCompanyName] = useState("")
    const [customerDisplayNameOptions, setCustomerDisplayNameOptions] = useState([])
    const [selectedCustomerDisplayName, setSelectedCustomerDisplayName] = useState(null)
    const [workPhone, setWorkPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [designation, setDesignation] = useState("")
    const [department, setDepartment] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [selectedLeadSource, setSelectedLeadSource] = useState(null)

    const [currentPanel, setCurrentPanel] = useState("otherDetails")

    const [selectedIdentityType, setSelectedIdentityType] = useState(null)
    const [identityNumber, setIdentityNumber] = useState("")
    const [pan, setPan] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [portalCheck, setPortalCheck] = useState(false)
    const [selectedPortalLanguage, setSelectedPortalLanguage] = useState(null)
    const [facebookLink, setFacebookLink] = useState("")
    const [twitterLink, setTwitterLink] = useState("")

    const [profilePhoto, setProfilePhoto] = useState("");
    const [fileNames, setFileNames] = useState([]);
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());


    const [contactPersonsArray, setContactPersonsArray] = useState([{salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""}])

    const [remarks, setRemarks] = useState("")

    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false)

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [currencyCode, setCurrencyCode] = useState("")
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [currencyDecimalPlace, setCurrencyDecimalPlace] = useState("")
    const [currencyFormat, setCurrencyFormat] = useState("")
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

	var page_name = "add-customer";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                .catch(function (error) {
                    swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                        window.location.href = "/login"
                    });
                });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    //console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
    }

    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getCurrencies();
            //let p2 = getTerms();
            Promise.all([p1])
            .then(()=>{
                setstate(1)
                console.log("all apis done")
            })
            .catch((err)=>{
                setstate(3)
                console.log(err)
            })
		}

	}, [user, visibility, read, write])

    useEffect(() => {
        setSelectedCustomerDisplayName(null)
        let tempArr = []
        if(primaryContact.salutation!=null){
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName+" "+primaryContact.lastName, primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName, primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.lastName, primaryContact.lastName)
                }
            }
        }
        else{
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.lastName)
                }
            }
        }
        setCustomerDisplayNameOptions(tempArr)
    }, [primaryContact])
    
    const register = () => {
        var nexp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;
        if ((primaryContact.firstName == "") && (primaryContact.lastName == "")) {
            swal("Warning!", "Primary Contact must contain first name or last name", "warning");
            return
        }
        
        if(nexp.test(primaryContact.firstName)){
            swal("Warning!", "First name cannot contain a number or symbols", "warning");
            return
        }
        if(nexp.test(primaryContact.lastName)){
            swal("Warning!", "Last name cannot contain a number or symbols", "warning");
            return
        }

        if(selectedCustomerDisplayName == null){
            swal("Warning!", "Customer Display Name cannot be empty", "warning");
            return
        }

        if(workPhone == ""){
            swal("Warning!", "Work phone cannot be empty", "warning");
            return
        }
        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
        if(!regex1.test(workPhone)){
            swal("Warning!", "Please provide valid Work phone number", "warning");
            return
        }

        if((portalCheck) && (email == "")) {
            swal("Warning!", "Email id cannot be empty", "warning");
            return false;
        }
        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if((email!="") && (!regex2.test(email))) {
            swal("Warning!", "Please enter a valid Email id", "warning");
            return false;
        }

        if(selectedLeadSource == null){
            swal("Warning!", "Lead Source cannot be empty", "warning");
            return
        }

        /*if(selectedIdentityType == null){
            swal("Warning!", "Identity type cannot be empty", "warning");
            return
        }
        if(identityNumber == '')
		{
            swal("Warning!", "Identity Number cannot be empty", "warning");
            return
        }
        */
        let regex3 = /^[0-9]{4}?[0-9]{4}?[0-9]{4}$/;
        if(identityNumber!="")
        {
            if ((selectedIdentityType.value == 'aadhar_card') && (!regex3.test(identityNumber))) {
                swal("Warning!", "Please enter a valid Aadhar number without any spaces", "warning");
                return
            }
        }

        let regex4 = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (pan!="" && !regex4.test(pan)) {
            swal("Warning!", "Please enter a valid Pan number", "warning");
            return
        }

        if(selectedCurrency == null){
            swal("Warning!", "Currency cannot be empty", "warning");
            return
        }

        let object = {};
        
        object.customer_type = customerType
        object.primary_contact = {...primaryContact,salutation: primaryContact.salutation.value}
        object.company_name = companyName
        object.customer_display_name = selectedCustomerDisplayName.value
        object.work_phone = workPhone
        object.mobile = mobile
        object.designation ="";
        object.department = "";
        object.email = email
        object.website = website
        object.organization_id=organization_id;
        object.lead_source = (selectedLeadSource==null)?null:selectedLeadSource.value

        object.identity_type = selectedIdentityType.value
        object.identity_number = identityNumber
        object.pan = pan
        object.currency = {currency_code: selectedCurrency.label, currency_id: selectedCurrency.value}
        object.term = (selectedTerm==null)?null:{term_name:selectedTerm.label, term_days:selectedTerm.value}
        object.portal_check ="";
        object.portal_language = "";
        object.facebook_link = facebookLink
        object.twitter_link = twitterLink

        object.profile_photo = profilePhoto
        object.file_names = fileNames
        object.unique_id = fileSessionUniqueId

        let temp = contactPersonsArray.filter((obj)=>{
            if (!((obj.salutation == null) && (obj.firstName == "") && (obj.lastName == "") && (obj.email == "") && (obj.workPhone == "") && (obj.mobile == "") && (obj.designation == "") && (obj.department == "")))
                return true
        })
        temp = temp.map((obj)=>{
            if(obj.salutation != null){
                obj.salutation = obj.salutation.value
            }
            return(obj)
        })
        object.contact_persons_array = temp
        object.remarks = remarks

        object.page_name=page_name;

        console.log(object);
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/customers`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "same_email") {
                swal("Oops!", "Email already exists!!", "error");
            }
            else if (res.data.operation === "success") {
                swal("Great!", "You have registered the Customer successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj=>{
            if((obj.term_name.trim() == "") || (obj.no_of_days=="")){
                f1=false
            }
        })
        if(f1 == false){
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj=>{ return obj.term_name.trim() })
        if(temp.length!= [... new Set(temp)].length){
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList
        object.page_name = page_name;

        // console.log(object);
        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Sales Person added successfully!", "success")
                handleNewTermsModalClose()
                //getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([]) 
    }

    const newCurrencySubmit = () => {
        console.log(currencyCode,currencySymbol,currencyDecimalPlace,currencyFormat)

        if(currencyCode == ""){
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if(currencySymbol == ""){
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = currencyCode
        object.currency_symbol = currencySymbol
		object.decimal_place = currencyDecimalPlace
		object.format = currencyFormat
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if(res.data.operation === "same_currency_code"){
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setCurrencyCode("") 
        setCurrencySymbol("")
        setCurrencyDecimalPlace("")
        setCurrencyFormat("")
    }

    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams1 = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-profile`, headers, body }
    }

    const handleChangeStatus1 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setProfilePhoto(name)
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            setProfilePhoto("")
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const getUploadParams2 = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-documents`, headers, body }
    }

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames] 
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">New Customer
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className='container'>
                                                <div className="row mb-5">
                                                    <div className='col-4 my-auto'>
                                                        <label className="fs-5 fw-bold mb-2">Customer Type</label>
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("business") }}> Business
                                                            <input type="radio" className="form-check-input ms-5" checked={customerType=="business"} />
                                                        </label>
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("individual") }}>Individual
                                                            <input type="radio" className="form-check-input ms-5" checked={customerType=="individual"}/>
                                                        </label>                                                    
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-3 my-auto">
                                                        <label className="required fs-5 fw-bold mb-2">Primary Contact</label>
                                                    </div>
                                                    <div className='col-3 my-auto'>
                                                        <Select
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                            options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                            value={primaryContact.salutation}
                                                            onChange={(val)=>{ 
                                                                setPrimaryContact({...primaryContact, salutation: val})
                                                            }}
                                                            placeholder="Salutation"
                                                        />
                                                    </div>
                                                    <div className='col-6 input-group-text p-0'>
                                                        <input type="text" className="form-control form-control-solid" value={primaryContact.firstName} onChange={(e)=>{setPrimaryContact({...primaryContact,firstName: e.target.value}) }} placeholder='First Name'/>
                                                        <input type="text" className="form-control form-control-solid" value={primaryContact.lastName} onChange={(e)=>{setPrimaryContact({...primaryContact,lastName: e.target.value}) }} placeholder='Last Name'/>
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Company Name</label>
                                                        <input type="text" className="form-control form-control-solid" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6 my-auto">
                                                        <label className="required fs-5 fw-bold mb-2">Customer Display Name</label>
                                                        <Select
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                            options={ customerDisplayNameOptions.map(x=>{return {label:x, value:x} })}
                                                            value={selectedCustomerDisplayName}
                                                            onChange={(val)=>{ setSelectedCustomerDisplayName(val) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="required fs-5 fw-bold mb-2">Work Phone</label>
                                                        <input type="number" className="form-control form-control-solid" value={workPhone} onChange={(e)=>{setWorkPhone(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                        <input type="number" className="form-control form-control-solid" value={mobile} onChange={(e)=>{setMobile(e.target.value)}} />
                                                    </div>
                                                </div>

                                                {/*<div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Designation</label>
                                                        <input type="text" className="form-control form-control-solid" value={designation} onChange={(e)=>{setDesignation(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Department</label>
                                                        <input type="text" className="form-control form-control-solid" value={department} onChange={(e)=>{setDepartment(e.target.value)}} />
                                                    </div>
                                                        </div>*/} 

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className= {"fs-5 fw-bold mb-2 "+(portalCheck?"required":"")}>Email</label>
                                                        <input type="email" className="form-control form-control-solid" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Website</label>
                                                        <input type="text" className="form-control form-control-solid" value={website} onChange={(e)=>{setWebsite(e.target.value)}} />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="required fs-5 fw-bold mb-2">Lead Source:</label>
                                                        <Select
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                            options={["CRM","Websites","Social","Referral","Cold Call","SEO"].map(x=>{return {label:x, value:x} })}
                                                            value={selectedLeadSource}
                                                            onChange={(val) => { setSelectedLeadSource(val) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                        <Select
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                            options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                                            onChange={(val) => {setSelectedCurrency(val)}}        
                                                            value={selectedCurrency}
                                                            components={{ 
                                                                MenuList: (props) => {
                                                                    return (
                                                                        <components.MenuList  {...props}>
                                                                            {props.children} 
                                                                            <div className='d-flex justify-content-center'>
                                                                                <button className='btn btn-success rounded btn-sm my-1' onClick={()=>{ setCurrencyModalShow(true) }}> &#10011; Add New Currency</button>
                                                                            </div>
                                                                        </components.MenuList >
                                                                    ) 
                                                                } 
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className='' style={{marginTop:"35px"}}>
                                                <Tabs className="mb-3 fs-4" activeKey={currentPanel} onSelect={(k) => setCurrentPanel(k)}>
                                                    <Tab eventKey="otherDetails" title="Other Details" className='mt-10'>
                                                        <div className='container'>
                                                            <div className='row mb-5'>
                                                                <div className="col-md-4 my-auto">
                                                                    <label className=" fs-5 fw-bold mb-2">Identity Type</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        options = {[{label:"Aadhar Card", value:"aadhar_card"}, {label:"Voter Card", value:"voter_card"}]}
                                                                        value= {selectedIdentityType}
                                                                        onChange= {(val)=>{setSelectedIdentityType(val)} }
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className=" fs-5 fw-bold mb-2">Identity Number</label>
                                                                    <input type="text" className="form-control form-control-solid" value={identityNumber} onChange={(e)=>{setIdentityNumber(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className=" fs-5 fw-bold mb-2">Pan</label>
                                                                    <input type="text" className="form-control form-control-solid" value={pan} onChange={(e)=>{setPan(e.target.value)}} />
                                                                </div>
                                                            </div>

                                        
                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Facebook</label>
                                                                    <div className='input-group'>
                                                                        <div className="input-group-prepend">
                                                                            <span class="input-group-text h-100" >
                                                                                <i className="bi bi-facebook" style={{color:"blue"}}></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={facebookLink} onChange={(e)=>{setFacebookLink(e.target.value)}} placeholder={"http://www.facebook.com"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Twitter</label>
                                                                    <div className='input-group'>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text h-100" >
                                                                                <i className="bi bi-twitter" style={{color:"skyblue"}}></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={twitterLink} onChange={(e)=>{setTwitterLink(e.target.value)}} placeholder={"http://www.twitter.com"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="profile" title="Profile" className='mt-10'>
                                                        <div className='container'>
                                                            <div className='mb-3'>
                                                                <label className="fs-5 fw-bold mb-2">Upload Profile Photo (Single file upload)</label>
                                                                <Dropzone
                                                                    getUploadParams={getUploadParams1}
                                                                    onChangeStatus={handleChangeStatus1}
                                                                    LayoutComponent={CustomLayout}
                                                                    PreviewComponent={Preview}
                                                                    styles={{
                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                    }}
                                                                    maxFiles={1}
                                                                    maxSizeBytes={1024*1024*5}
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label className="fs-5 fw-bold mb-2">Upload Identity Proof (Maximum 3 files)</label>
                                                                <Dropzone
                                                                    getUploadParams={getUploadParams2}
                                                                    onChangeStatus={handleChangeStatus2}
                                                                    LayoutComponent={CustomLayout}
                                                                    PreviewComponent={Preview}
                                                                    styles={{
                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                    }}
                                                                    maxFiles={3}
                                                                    maxSizeBytes={1024*1024*5}
                                                                    accept="image/*,.pdf"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="contactPersons" title="Contact Persons" className='mt-10'>
                                                        <div className='w-100' style={{overflowX:"scroll"}}>
                                                            <div className='container' style={{minWidth:"max-content"}}>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Salutation</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">First Name</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Last Name</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Email Address</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Work Phone</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                                    </div>
                
                                                                    {
                                                                        contactPersonsArray.length>1 && <div className='col'></div> 
                                                                    }
                                                                </div>
                                                                {
                                                                    contactPersonsArray.map((obj,indx)=>{
                                                                        return <div className="row mb-1">
                                                                            <div className="col border border-dark rounded my-auto px-0 py-1">
                                                                                <Select
                                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                    options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                                                    value={obj.salutation}
                                                                                    onChange={(val)=>{ 
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].salutation = val
                                                                                        setContactPersonsArray(temp)
                                                                                    }}
                                                                                    placeholder=""
                                                                                />
                                                                            </div>
                                                                            <div className="col border border-dark rounded px-1">
                                                                                <input type="text" className="form-control form-control-solid" value={obj.firstName} 
                                                                                    onChange={(e)=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].firstName = e.target.value
                                                                                        setContactPersonsArray(temp)
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            <div className="col border border-dark rounded px-1">
                                                                                <input type="text" className="form-control form-control-solid" value={obj.lastName} 
                                                                                    onChange={(e)=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].lastName = e.target.value
                                                                                        setContactPersonsArray(temp)
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            <div className="col border border-dark rounded px-1">
                                                                                <input type="email" className="form-control form-control-solid" value={obj.email} 
                                                                                    onChange={(e)=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].email = e.target.value
                                                                                        setContactPersonsArray(temp)
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            <div className="col border border-dark rounded px-1">
                                                                                <input type="text" className="form-control form-control-solid" value={obj.workPhone} 
                                                                                    onChange={(e)=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].workPhone = e.target.value
                                                                                        setContactPersonsArray(temp)
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            <div className="col border border-dark rounded px-1">
                                                                                <input type="text" className="form-control form-control-solid" value={obj.mobile} 
                                                                                    onChange={(e)=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp[indx].mobile = e.target.value
                                                                                        setContactPersonsArray(temp)
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            {
                                                                                contactPersonsArray.length>0 &&
                                                                                <div className="col my-auto">
                                                                                    <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                        onClick={()=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp.splice(indx,1)
                                                                                            setContactPersonsArray(temp)
                                                                                        }}
                                                                                    >&#10006;</button>
                                                                                </div>
                                                                            }
                                                                        </div>  
                                                                    })
                                                                }
                                                                <div className='mt-10'>
                                                                    <button className='btn btn-primary rounded' onClick={()=>{ setContactPersonsArray([...contactPersonsArray, {salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""} ]) }}>+ Add Contact Person</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    {/*<Tab eventKey="remarks" title="Remarks" className='mt-10'>
                                                        <div className='container'>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Remarks (For Internal Use)</label>
                                                                    <textarea className="form-control form-control-solid" rows={5} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>*/}

                                                </Tabs>
                                            </div>


                                            <div style={{marginTop:"35px"}}>                           
                                                {
                                                    write==1 ?
                                                        <div className='d-flex justify-content-center'>
                                                            <button className="btn btn-primary btn-lg rounded" ata-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => {register(e)}}>
                                                                <span className="indicator-label">Submit &#x2794;</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    :
                                                        <div className='d-flex justify-content-center'>
                                                            <button className="btn btn-primary btn-lg rounded" disabled>
                                                                <span className="indicator-label">Submit &#x2794;</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                                <>
                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2"></div>

                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Please Refresh this page</h4>
                                                            <div className="fs-6 text-gray-600"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>

            </div>

            <Modal show={currencyModalShow} onHide={()=>{handleNewCurrencyModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewCurrencyModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                            <input type="text" className="form-control form-control-solid" value={currencyCode} onChange={(e)=>{setCurrencyCode(e.target.value)}}/>
                        </div>
                        <div className="row">
                            <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                            <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e)=>{setCurrencySymbol(e.target.value)}}/>
                        </div>
                        <div className="row">
                            <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                            <input type="text" className="form-control form-control-solid" value={currencyDecimalPlace} onChange={(e)=>{setCurrencyDecimalPlace(e.target.value)}}/>
                        </div>
                        <div className="row">
                            <label className="fs-6 fw-bold mb-2">Format</label>
                            <input type="text" className="form-control form-control-solid" value={currencyFormat} onChange={(e)=>{setCurrencyFormat(e.target.value)}}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState?"on":"off"} disabled={currencySubmitButtonState} onClick={()=>{newCurrencySubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewCurrencyModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={()=>{handleNewTermsModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewTermsModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                            termsPanelList.map((x,indx)=>{
                                return (
                                    <div className="row mb-2">
                                        <div className='col-5'>
                                            <input type="text" className="form-control form-control-solid" value={x.term_name} 
                                                onChange={(e)=>{
                                                    let temp = termsPanelList.map(x=>{return {...x}})
                                                    temp[indx].term_name = e.target.value 
                                                    setTermsPanelList(temp)
                                                }}
                                            />
                                        </div>
                                        <div className='col-5'>
                                            <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days} 
                                                onChange={(e)=>{
                                                    if(e.target.value>=0){
                                                        let temp = termsPanelList.map(x=>{return {...x}})
                                                        temp[indx].no_of_days = e.target.value 
                                                        setTermsPanelList(temp)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='col-2 my-auto'>
                                            <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger' 
                                                onClick={()=>{
                                                    let temp = termsPanelList.map(a=>{return{...a}})
                                                    temp.splice(indx,1)
                                                    setTermsPanelList(temp)
                                                }}
                                            >&#10006;</button>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={()=>{setTermsPanelList([...termsPanelList, {term_name: "", no_of_days: ""}]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState?"on":"off"} disabled={termsSubmitButtonState} onClick={()=>{newTermsSubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewTermsModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>

        </>
    );
}
export default Customer;
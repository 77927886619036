
var airport_arr = new Array(
    {
        "City": "Aalborg",
        "Country": "Denmark",
        "Code": "AAL"
    },
    {
        "City": "Aalesund",
        "Country": "Norway",
        "Code": "AES"
    },
    {
        "City": "Aarhus",
        "Country": "Denmark",
        "Code": "AAR"
    },
    {
        "City": "Abbotsford, BC",
        "Country": "Canada",
        "Code": "YXX"
    },
    {
        "City": "Abbotsford, BC ",
        "Country": "Canada ",
        "Code": "YXX"
    },
    {
        "City": "Aberdeen ",
        "Country": "Scotland",
        "Code": "ABZ"
    },
    {
        "City": "Aberdeen, SD ",
        "Country": "USA ",
        "Code": "ABR"
    },
    {
        "City": "Abidjan",
        "Country": "Ivory Coast",
        "Code": "ABJ"
    },
    {
        "City": "Abilene, TX ",
        "Country": "USA ",
        "Code": "ABI"
    },
    {
        "City": "Abu Dhabi",
        "Country": "United Arab Emirates",
        "Code": "AUH"
    },
    {
        "City": "Abuja",
        "Country": "Nigeria",
        "Code": "ABV"
    },
    {
        "City": "Acapulco",
        "Country": "Mexico",
        "Code": "ACA"
    },
    {
        "City": "Accra",
        "Country": "Ghana",
        "Code": "ACC"
    },
    {
        "City": "Adana",
        "Country": "Turkey",
        "Code": "ADA"
    },
    {
        "City": "Addis Ababa",
        "Country": "Ethiopia",
        "Code": "ADD"
    },
    {
        "City": "Adelaide, S.A.",
        "Country": "Australia",
        "Code": "ADL"
    },
    {
        "City": "Aden",
        "Country": "Yemen",
        "Code": "ADE"
    },
    {
        "City": "Adiyaman",
        "Country": "Turkey",
        "Code": "ADF"
    },
    {
        "City": "Agadir",
        "Country": "Morocco",
        "Code": "AGA"
    },
    {
        "City": "Agana",
        "Country": "Guam",
        "Code": "GUM"
    },
    {
        "City": "Aguadilla",
        "Country": "Puerto Rico",
        "Code": "BQN"
    },
    {
        "City": "Aguascalientes",
        "Country": "Mexico",
        "Code": "AGU"
    },
    {
        "City": "Ahe",
        "Country": "French Polynesia",
        "Code": "AHE"
    },
    {
        "City": "Ahmedabad",
        "Country": "India",
        "Code": "AMD"
    },
    {
        "City": "Ajaccio, Corsica",
        "Country": "France",
        "Code": "AJA"
    },
    {
        "City": "Akita",
        "Country": "Japan",
        "Code": "AXT"
    },
    {
        "City": "Akron, OH ",
        "Country": "USA ",
        "Code": "CAK"
    },
    {
        "City": "Alamosa, CO ",
        "Country": "USA ",
        "Code": "ALS"
    },
    {
        "City": "Albany, GA ",
        "Country": "USA ",
        "Code": "ABY"
    },
    {
        "City": "Albany, NY ",
        "Country": "USA ",
        "Code": "ALB"
    },
    {
        "City": "Albenga",
        "Country": "Italy",
        "Code": "ALL"
    },
    {
        "City": "Albuquerque, NM ",
        "Country": "USA ",
        "Code": "ABQ"
    },
    {
        "City": "Albury, N.S.W.",
        "Country": "Australia",
        "Code": "ABX"
    },
    {
        "City": "Alexandria",
        "Country": "Egypt",
        "Code": "ALY"
    },
    {
        "City": "Alexandria",
        "Country": "Egypt",
        "Code": "HBE"
    },
    {
        "City": "Alexandria, LA ",
        "Country": "USA ",
        "Code": "AEX"
    },
    {
        "City": "Alexandroupolis",
        "Country": "Greece",
        "Code": "AXD"
    },
    {
        "City": "Alghero, Sardinia",
        "Country": "Italy",
        "Code": "AHO"
    },
    {
        "City": "Algiers",
        "Country": "Algeria",
        "Code": "ALG"
    },
    {
        "City": "Alicante",
        "Country": "Spain",
        "Code": "ALC"
    },
    {
        "City": "Alice Springs, N.T.",
        "Country": "Australia",
        "Code": "ASP"
    },
    {
        "City": "Alice Town, North Bimini Island",
        "Country": "Bahamas",
        "Code": "NSB"
    },
    {
        "City": "Allentown, PA ",
        "Country": "USA ",
        "Code": "ABE"
    },
    {
        "City": "Alliance, NE ",
        "Country": "USA ",
        "Code": "AIA"
    },
    {
        "City": "Almaty",
        "Country": "Kazakhstan",
        "Code": "ALA"
    },
    {
        "City": "Almeria",
        "Country": "Spain",
        "Code": "LEI"
    },
    {
        "City": "Alor Setar",
        "Country": "Malaysia",
        "Code": "AOR"
    },
    {
        "City": "Alpena, MI ",
        "Country": "USA ",
        "Code": "APN"
    },
    {
        "City": "Alta",
        "Country": "Norway",
        "Code": "ALF"
    },
    {
        "City": "Altenrhein",
        "Country": "Switzerland",
        "Code": "ACH"
    },
    {
        "City": "Altoona, PA ",
        "Country": "USA ",
        "Code": "AOO"
    },
    {
        "City": "Amarillo, TX ",
        "Country": "USA ",
        "Code": "AMA"
    },
    {
        "City": "Amman",
        "Country": "Jordan",
        "Code": "AMM"
    },
    {
        "City": "Amsterdam",
        "Country": "Netherlands",
        "Code": "AMS"
    },
    {
        "City": "Anahim Lake, BC",
        "Country": "Canada",
        "Code": "YAA"
    },
    {
        "City": "Anahim Lake, BC ",
        "Country": "Canada ",
        "Code": "YAA"
    },
    {
        "City": "Anchorage, AK ",
        "Country": "USA ",
        "Code": "ANC"
    },
    {
        "City": "Ancona",
        "Country": "Italy",
        "Code": "AOI"
    },
    {
        "City": "Andros Town",
        "Country": "Bahamas",
        "Code": "ASD"
    },
    {
        "City": "Angeles",
        "Country": "Philippines",
        "Code": "CRK"
    },
    {
        "City": "Angra do Heroismo, Terceira Island",
        "Country": "Azores",
        "Code": "TER"
    },
    {
        "City": "Ankara",
        "Country": "Turkey",
        "Code": "ESB"
    },
    {
        "City": "Annaba",
        "Country": "Algeria",
        "Code": "AAE"
    },
    {
        "City": "Annecy",
        "Country": "France",
        "Code": "NCY"
    },
    {
        "City": "Antalya",
        "Country": "Turkey",
        "Code": "AYT"
    },
    {
        "City": "Antananarivo",
        "Country": "Madagascar",
        "Code": "TNR"
    },
    {
        "City": "Antofagasta",
        "Country": "Chile",
        "Code": "ANF"
    },
    {
        "City": "Antwerp",
        "Country": "Belgium",
        "Code": "ANR"
    },
    {
        "City": "Aomori",
        "Country": "Japan",
        "Code": "AOJ"
    },
    {
        "City": "Aosta",
        "Country": "Italy",
        "Code": "AOT"
    },
    {
        "City": "Appleton, WI ",
        "Country": "USA ",
        "Code": "ATW"
    },
    {
        "City": "Ardabil",
        "Country": "Iran",
        "Code": "ADU"
    },
    {
        "City": "Arequipa",
        "Country": "Peru",
        "Code": "AQP"
    },
    {
        "City": "Argostoli, Kefalonia Island",
        "Country": "Greece",
        "Code": "EFL"
    },
    {
        "City": "Arica",
        "Country": "Chile",
        "Code": "ARI"
    },
    {
        "City": "Arkhangelsk",
        "Country": "Russia",
        "Code": "ARH"
    },
    {
        "City": "Armidale, N.S.W.",
        "Country": "Australia",
        "Code": "ARM"
    },
    {
        "City": "Arrecife, Lanzarote Island",
        "Country": "Canary Islands",
        "Code": "ACE"
    },
    {
        "City": "Arusha",
        "Country": "Tanzania",
        "Code": "ARK"
    },
    {
        "City": "Arviat, NWT",
        "Country": "Canada",
        "Code": "YEK"
    },
    {
        "City": "Arviat, NWT",
        "Country": "Canada ",
        "Code": "YEK"
    },
    {
        "City": "Arvidsjaur",
        "Country": "Sweden",
        "Code": "AJR"
    },
    {
        "City": "Asheville, NC ",
        "Country": "USA ",
        "Code": "AVL"
    },
    {
        "City": "Asmara",
        "Country": "Eritrea",
        "Code": "ASM"
    },
    {
        "City": "Aspen, CO ",
        "Country": "USA ",
        "Code": "ASE"
    },
    {
        "City": "Astana",
        "Country": "Kazakhstan",
        "Code": "TSE"
    },
    {
        "City": "Asuncion",
        "Country": "Paraguay",
        "Code": "ASU"
    },
    {
        "City": "Aswan",
        "Country": "Egypt",
        "Code": "ASW"
    },
    {
        "City": "Athens",
        "Country": "Greece",
        "Code": "ATH"
    },
    {
        "City": "Athens, GA ",
        "Country": "USA ",
        "Code": "AHN"
    },
    {
        "City": "Atlanta, GA ",
        "Country": "USA ",
        "Code": "ATL"
    },
    {
        "City": "Atlantic, NJ ",
        "Country": "USA ",
        "Code": "ACY"
    },
    {
        "City": "Auckland",
        "Country": "New Zealand",
        "Code": "AKL"
    },
    {
        "City": "Augusta, GA ",
        "Country": "USA ",
        "Code": "AGS"
    },
    {
        "City": "Augusta, ME ",
        "Country": "USA ",
        "Code": "AUG"
    },
    {
        "City": "Austin, TX ",
        "Country": "USA ",
        "Code": "AUS"
    },
    {
        "City": "Avarua, Rarotonga Island",
        "Country": "Cook Islands",
        "Code": "RAR"
    },
    {
        "City": "Ayers Rock, N.T.",
        "Country": "Australia",
        "Code": "AYQ"
    },
    {
        "City": "Bacolod",
        "Country": "Philippines",
        "Code": "BCD"
    },
    {
        "City": "Badajoz",
        "Country": "Spain",
        "Code": "BJZ"
    },
    {
        "City": "Baghdad",
        "Country": "Iraq",
        "Code": "SDA"
    },
    {
        "City": "Bagotville, PQ",
        "Country": "Canada",
        "Code": "YBG"
    },
    {
        "City": "Bagotville, PQ ",
        "Country": "Canada ",
        "Code": "YBG"
    },
    {
        "City": "Baie-Comeau, PQ",
        "Country": "Canada",
        "Code": "YBC"
    },
    {
        "City": "Baie-Comeau, PQ ",
        "Country": "Canada ",
        "Code": "YBC"
    },
    {
        "City": "Bakersfield, CA ",
        "Country": "USA ",
        "Code": "BFL"
    },
    {
        "City": "Baku",
        "Country": "Azerbaijan",
        "Code": "BAK"
    },
    {
        "City": "Baku",
        "Country": "Azerbaijan",
        "Code": "GYD"
    },
    {
        "City": "Balaton",
        "Country": "Hungary",
        "Code": "SOB"
    },
    {
        "City": "Balephuil",
        "Country": "Scotland",
        "Code": "TRE"
    },
    {
        "City": "Balikpapan, Kalimantan",
        "Country": "Indonesia",
        "Code": "BPN"
    },
    {
        "City": "Ballina, N.S.W.",
        "Country": "Australia",
        "Code": "BNK"
    },
    {
        "City": "Balmaceda",
        "Country": "Chile",
        "Code": "BBA"
    },
    {
        "City": "Baltimore, MD ",
        "Country": "USA ",
        "Code": "BWI"
    },
    {
        "City": "Ban Me Thuot",
        "Country": "Vietnam",
        "Code": "BMV"
    },
    {
        "City": "Bandar Seri Begawan",
        "Country": "Brunei",
        "Code": "BWN"
    },
    {
        "City": "Bangalore",
        "Country": "India",
        "Code": "BLR"
    },
    {
        "City": "Bangda",
        "Country": "China",
        "Code": "BPX"
    },
    {
        "City": "Bangkok",
        "Country": "Thailand",
        "Code": "BKK"
    },
    {
        "City": "Bangor, ME ",
        "Country": "USA ",
        "Code": "BGR"
    },
    {
        "City": "Bangui",
        "Country": "Central African Republic",
        "Code": "BGF"
    },
    {
        "City": "Banjul",
        "Country": "Gambia",
        "Code": "BJL"
    },
    {
        "City": "Bar Harbor, ME ",
        "Country": "USA ",
        "Code": "BHB"
    },
    {
        "City": "Barcelona",
        "Country": "Spain",
        "Code": "BCN"
    },
    {
        "City": "Bardufoss",
        "Country": "Norway",
        "Code": "BDU"
    },
    {
        "City": "Bari",
        "Country": "Italy",
        "Code": "BRI"
    },
    {
        "City": "Barisal",
        "Country": "Bangladesh",
        "Code": "BZL"
    },
    {
        "City": "Barranquilla",
        "Country": "Colombia",
        "Code": "BAQ"
    },
    {
        "City": "Barrow, AK",
        "Country": "USA ",
        "Code": "BRW"
    },
    {
        "City": "Basel",
        "Country": "Switzerland",
        "Code": "BSL"
    },
    {
        "City": "Basseterre",
        "Country": "St. Kitts",
        "Code": "SKB"
    },
    {
        "City": "Bastia, Corsica",
        "Country": "France",
        "Code": "BIA"
    },
    {
        "City": "Bathurst, N.S.W.",
        "Country": "Australia",
        "Code": "BHS"
    },
    {
        "City": "Baton Rouge, LA ",
        "Country": "USA ",
        "Code": "BTR"
    },
    {
        "City": "Bauru",
        "Country": "Brazil",
        "Code": "BAU"
    },
    {
        "City": "Beaumont, TX ",
        "Country": "USA ",
        "Code": "BPT"
    },
    {
        "City": "Beauvais",
        "Country": "France",
        "Code": "BVA"
    },
    {
        "City": "Beckley, WV ",
        "Country": "USA ",
        "Code": "BKW"
    },
    {
        "City": "Bedford, MA ",
        "Country": "USA ",
        "Code": "BED"
    },
    {
        "City": "Beef Island, Tortola",
        "Country": "British Virgin Islands",
        "Code": "EIS"
    },
    {
        "City": "Beijing",
        "Country": "China",
        "Code": "BJS"
    },
    {
        "City": "Beirut",
        "Country": "Lebanon",
        "Code": "BEY"
    },
    {
        "City": "Belem",
        "Country": "Brazil",
        "Code": "BEL"
    },
    {
        "City": "Belfast",
        "Country": "Northern Ireland",
        "Code": "BFS"
    },
    {
        "City": "Belfast",
        "Country": "Northern Ireland",
        "Code": "BHD"
    },
    {
        "City": "Belgrade",
        "Country": "Serbia and Montenegro",
        "Code": "BEG"
    },
    {
        "City": "Belize",
        "Country": "Belize",
        "Code": "BZE"
    },
    {
        "City": "Bellingham, WA ",
        "Country": "USA ",
        "Code": "BLI"
    },
    {
        "City": "Belo Horizonte",
        "Country": "Brazil",
        "Code": "CNF"
    },
    {
        "City": "Bemidji, MN ",
        "Country": "USA ",
        "Code": "BJI"
    },
    {
        "City": "Benbecula",
        "Country": "Scotland",
        "Code": "BEB"
    },
    {
        "City": "Benguela",
        "Country": "Angola",
        "Code": "BUG"
    },
    {
        "City": "Bergen",
        "Country": "Norway",
        "Code": "BGO"
    },
    {
        "City": "Berlin",
        "Country": "Germany",
        "Code": "BER - All Airports"
    },
    {
        "City": "Berlin",
        "Country": "Germany",
        "Code": "SXF"
    },
    {
        "City": "Berlin",
        "Country": "Germany",
        "Code": "THF"
    },
    {
        "City": "Berlin",
        "Country": "Germany",
        "Code": "TXL"
    },
    {
        "City": "Berne",
        "Country": "Switzerland",
        "Code": "BRN"
    },
    {
        "City": "Bethel, AK ",
        "Country": "USA ",
        "Code": "BET"
    },
    {
        "City": "Bettles, AK ",
        "Country": "USA ",
        "Code": "BTT"
    },
    {
        "City": "Biak, Papua",
        "Country": "Indonesia",
        "Code": "BIK"
    },
    {
        "City": "Biarritz",
        "Country": "France",
        "Code": "BIQ"
    },
    {
        "City": "Bilbao",
        "Country": "Spain",
        "Code": "BIO"
    },
    {
        "City": "Billings, MT ",
        "Country": "USA ",
        "Code": "BIL"
    },
    {
        "City": "Billund",
        "Country": "Denmark",
        "Code": "BLL"
    },
    {
        "City": "Binghamton, NY ",
        "Country": "USA ",
        "Code": "BGM"
    },
    {
        "City": "Bintulu, Sarawak",
        "Country": "Malaysia",
        "Code": "BTU"
    },
    {
        "City": "Birmingham",
        "Country": "England",
        "Code": "BHX"
    },
    {
        "City": "Birmingham, AL ",
        "Country": "USA ",
        "Code": "BHM"
    },
    {
        "City": "Bishkek",
        "Country": "Kyrgyzstan",
        "Code": "FRU"
    },
    {
        "City": "Bismarck, ND ",
        "Country": "USA ",
        "Code": "BIS"
    },
    {
        "City": "Bissau",
        "Country": "Guinea-Bissau",
        "Code": "OXB"
    },
    {
        "City": "Blantyre",
        "Country": "Malawi",
        "Code": "BLZ"
    },
    {
        "City": "Blenheim",
        "Country": "New Zealand",
        "Code": "BHE"
    },
    {
        "City": "Bloemfontein",
        "Country": "South Africa",
        "Code": "BFN"
    },
    {
        "City": "Bloomington, IL ",
        "Country": "USA ",
        "Code": "BMI"
    },
    {
        "City": "Bloomington, IN ",
        "Country": "USA ",
        "Code": "BMG"
    },
    {
        "City": "Bluefield, WV ",
        "Country": "USA ",
        "Code": "BLF"
    },
    {
        "City": "Bodo",
        "Country": "Norway",
        "Code": "BOO"
    },
    {
        "City": "Bogota",
        "Country": "Colombia",
        "Code": "BOG"
    },
    {
        "City": "Boise, ID ",
        "Country": "USA ",
        "Code": "BOI"
    },
    {
        "City": "Bologna",
        "Country": "Italy",
        "Code": "BLQ"
    },
    {
        "City": "Bolzano",
        "Country": "Italy",
        "Code": "BZO"
    },
    {
        "City": "Bordeaux",
        "Country": "France",
        "Code": "BOD"
    },
    {
        "City": "Borlange",
        "Country": "Sweden",
        "Code": "BLE"
    },
    {
        "City": "Boston, MA ",
        "Country": "USA ",
        "Code": "BOS"
    },
    {
        "City": "Bozeman, MT ",
        "Country": "USA ",
        "Code": "BZN"
    },
    {
        "City": "Brasilia",
        "Country": "Brazil",
        "Code": "BSB"
    },
    {
        "City": "Bratislava",
        "Country": "Slovakia",
        "Code": "BTS"
    },
    {
        "City": "Brazzaville",
        "Country": "Congo",
        "Code": "BZV"
    },
    {
        "City": "Bremen",
        "Country": "Germany",
        "Code": "BRE"
    },
    {
        "City": "Brest",
        "Country": "France",
        "Code": "BES"
    },
    {
        "City": "Bridgetown",
        "Country": "Barbados",
        "Code": "BGI"
    },
    {
        "City": "Brindisi",
        "Country": "Italy",
        "Code": "BDS"
    },
    {
        "City": "Brisbane, Qld.",
        "Country": "Australia",
        "Code": "BNE"
    },
    {
        "City": "Bristol",
        "Country": "England",
        "Code": "BRS"
    },
    {
        "City": "Broken Hill, N.S.W.",
        "Country": "Australia",
        "Code": "BHQ"
    },
    {
        "City": "Brookings, SD ",
        "Country": "USA ",
        "Code": "BKX"
    },
    {
        "City": "Broome, W.A.",
        "Country": "Australia",
        "Code": "BME"
    },
    {
        "City": "Brownsville, TX ",
        "Country": "USA ",
        "Code": "BRO"
    },
    {
        "City": "Brunswick, GA ",
        "Country": "USA ",
        "Code": "BQK"
    },
    {
        "City": "Brussels",
        "Country": "Belgium",
        "Code": "BRU"
    },
    {
        "City": "Bucharest",
        "Country": "Romania",
        "Code": "OTP"
    },
    {
        "City": "Budapest",
        "Country": "Hungary",
        "Code": "BUD"
    },
    {
        "City": "Buenos Aires",
        "Country": "Argentina",
        "Code": "EZE"
    },
    {
        "City": "Buffalo, NY ",
        "Country": "USA ",
        "Code": "BUF"
    },
    {
        "City": "Bujumbura",
        "Country": "Burundi",
        "Code": "BJM"
    },
    {
        "City": "Bulawayo",
        "Country": "Zimbabwe",
        "Code": "BUQ"
    },
    {
        "City": "Bundaberg, Qld.",
        "Country": "Australia",
        "Code": "BDB"
    },
    {
        "City": "Burbank, CA ",
        "Country": "USA ",
        "Code": "BUR"
    },
    {
        "City": "Buri Ram",
        "Country": "Thailand",
        "Code": "BFV"
    },
    {
        "City": "Burlington, IA ",
        "Country": "USA ",
        "Code": "BRL"
    },
    {
        "City": "Burlington, MA ",
        "Country": "USA ",
        "Code": "BBF"
    },
    {
        "City": "Burlington, VT ",
        "Country": "USA ",
        "Code": "BTV"
    },
    {
        "City": "Busan, Korea",
        "Country": "Republic of",
        "Code": "PUS"
    },
    {
        "City": "Butte, MT ",
        "Country": "USA ",
        "Code": "BTM"
    },
    {
        "City": "Butuan",
        "Country": "Philippines",
        "Code": "BXU"
    },
    {
        "City": "Bydgoszcz",
        "Country": "Poland",
        "Code": "BZG"
    },
    {
        "City": "Caen",
        "Country": "France",
        "Code": "CFR"
    },
    {
        "City": "Cagayan de Oro",
        "Country": "Philippines",
        "Code": "CGY"
    },
    {
        "City": "Cagliari, Sardinia",
        "Country": "Italy",
        "Code": "CAG"
    },
    {
        "City": "Cairns, Qld.",
        "Country": "Australia",
        "Code": "CNS"
    },
    {
        "City": "Cairo",
        "Country": "Egypt",
        "Code": "CAI"
    },
    {
        "City": "Calcutta",
        "Country": "India",
        "Code": "CCU"
    },
    {
        "City": "Calgary, AB",
        "Country": "Canada",
        "Code": "YYC"
    },
    {
        "City": "Calgary, AB ",
        "Country": "Canada ",
        "Code": "YYC"
    },
    {
        "City": "Cali",
        "Country": "Colombia",
        "Code": "CLO"
    },
    {
        "City": "Calvi, Corsica",
        "Country": "France",
        "Code": "CLY"
    },
    {
        "City": "Campbell River, BC",
        "Country": "Canada",
        "Code": "YBL"
    },
    {
        "City": "Campbell River, BC ",
        "Country": "Canada ",
        "Code": "YBL"
    },
    {
        "City": "Campbeltown",
        "Country": "Scotland",
        "Code": "CAL"
    },
    {
        "City": "Campeche",
        "Country": "Mexico",
        "Code": "CPE"
    },
    {
        "City": "Canberra, A.C.T.",
        "Country": "Australia",
        "Code": "CBR"
    },
    {
        "City": "Cancun",
        "Country": "Mexico",
        "Code": "CUN"
    },
    {
        "City": "Cap-aux-Meules, ",
        "Country": "Canada ",
        "Code": "YGR"
    },
    {
        "City": "Cap-aux-Meules, Magdalens Islands, PQ",
        "Country": "Canada",
        "Code": "YGR"
    },
    {
        "City": "Cape Girardeau, MO ",
        "Country": "USA ",
        "Code": "CGI"
    },
    {
        "City": "Cape Town",
        "Country": "South Africa",
        "Code": "CPT"
    },
    {
        "City": "Caracas",
        "Country": "Venezuela",
        "Code": "CCS"
    },
    {
        "City": "Cardiff",
        "Country": "Wales",
        "Code": "CWL"
    },
    {
        "City": "Carlsbad, CA ",
        "Country": "USA ",
        "Code": "CLD"
    },
    {
        "City": "Carlsbad, NM ",
        "Country": "USA ",
        "Code": "CNM"
    },
    {
        "City": "Cartagena",
        "Country": "Colombia",
        "Code": "CTG"
    },
    {
        "City": "Casablanca",
        "Country": "Morocco",
        "Code": "CMN"
    },
    {
        "City": "Casper, WY ",
        "Country": "USA ",
        "Code": "CPR"
    },
    {
        "City": "Castlegar, BC",
        "Country": "Canada",
        "Code": "YCG"
    },
    {
        "City": "Castlegar, BC ",
        "Country": "Canada ",
        "Code": "YCG"
    },
    {
        "City": "Castries",
        "Country": "St. Lucia",
        "Code": "SLU"
    },
    {
        "City": "Catania, Sicily",
        "Country": "Italy",
        "Code": "CTA"
    },
    {
        "City": "Cayenne",
        "Country": "French Guiana",
        "Code": "CAY"
    },
    {
        "City": "Cayman Brac",
        "Country": "Cayman Islands",
        "Code": "CYB"
    },
    {
        "City": "Cebu",
        "Country": "Philippines",
        "Code": "CEB"
    },
    {
        "City": "Cedar Rapids, IA ",
        "Country": "USA ",
        "Code": "CID"
    },
    {
        "City": "Ceduna, S.A.",
        "Country": "Australia",
        "Code": "CED"
    },
    {
        "City": "Champaign, IL ",
        "Country": "USA ",
        "Code": "CMI"
    },
    {
        "City": "Changchun",
        "Country": "China",
        "Code": "CGQ"
    },
    {
        "City": "Changsha",
        "Country": "China",
        "Code": "CSX"
    },
    {
        "City": "Chania, Crete",
        "Country": "Greece",
        "Code": "CHQ"
    },
    {
        "City": "Charleston, SC ",
        "Country": "USA ",
        "Code": "CHS"
    },
    {
        "City": "Charleston, WV ",
        "Country": "USA ",
        "Code": "CRW"
    },
    {
        "City": "Charleville, Qld.",
        "Country": "Australia",
        "Code": "CTL"
    },
    {
        "City": "Charlotte Amalie, St. Thomas",
        "Country": "U.S. Virgin Islands",
        "Code": "STT"
    },
    {
        "City": "Charlotte, NC ",
        "Country": "USA ",
        "Code": "CLT"
    },
    {
        "City": "Charlottesville, VA ",
        "Country": "USA ",
        "Code": "CHO"
    },
    {
        "City": "Charlottetown, PEI",
        "Country": "Canada",
        "Code": "YYG"
    },
    {
        "City": "Charlottetown, PEI ",
        "Country": "Canada ",
        "Code": "YYG"
    },
    {
        "City": "Chattanooga, TN ",
        "Country": "USA ",
        "Code": "CHA"
    },
    {
        "City": "Chengdu",
        "Country": "China",
        "Code": "CTU"
    },
    {
        "City": "Chennai (Madras)",
        "Country": "India",
        "Code": "MAA"
    },
    {
        "City": "Chetumal",
        "Country": "Mexico",
        "Code": "CTM"
    },
    {
        "City": "Cheyenne, WY ",
        "Country": "USA ",
        "Code": "CYS"
    },
    {
        "City": "Chiang Mai",
        "Country": "Thailand",
        "Code": "CNX"
    },
    {
        "City": "Chiang Rai",
        "Country": "Thailand",
        "Code": "CEI"
    },
    {
        "City": "Chibougamau, PQ",
        "Country": "Canada",
        "Code": "YMT"
    },
    {
        "City": "Chibougamau, PQ ",
        "Country": "Canada ",
        "Code": "YMT"
    },
    {
        "City": "Chicago, IL ",
        "Country": "USA ",
        "Code": "CHI - All "
    },
    {
        "City": "Chicago, IL ",
        "Country": "USA ",
        "Code": "MDW"
    },
    {
        "City": "Chicago, IL ",
        "Country": "USA ",
        "Code": "ORD"
    },
    {
        "City": "Chico, CA ",
        "Country": "USA ",
        "Code": "CIC"
    },
    {
        "City": "Chihuahua",
        "Country": "Mexico",
        "Code": "CUU"
    },
    {
        "City": "Chios",
        "Country": "Greece",
        "Code": "JKH"
    },
    {
        "City": "Chisinau",
        "Country": "Moldova",
        "Code": "KIV"
    },
    {
        "City": "Chongqing",
        "Country": "China",
        "Code": "CKG"
    },
    {
        "City": "Christchurch",
        "Country": "New Zealand",
        "Code": "CHC"
    },
    {
        "City": "Christiansted, St. Croix",
        "Country": "U.S. Virgin Islands",
        "Code": "STX"
    },
    {
        "City": "Churchill, MB",
        "Country": "Canada",
        "Code": "YYQ"
    },
    {
        "City": "Churchill, MB ",
        "Country": "Canada ",
        "Code": "YYQ"
    },
    {
        "City": "Cincinnati, OH ",
        "Country": "USA ",
        "Code": "CVG"
    },
    {
        "City": "Ciudad Bolivar",
        "Country": "Venezuela",
        "Code": "CBL"
    },
    {
        "City": "Ciudad Del Carmen",
        "Country": "Mexico",
        "Code": "CME"
    },
    {
        "City": "Ciudad del Este",
        "Country": "Paraguay",
        "Code": "AGT"
    },
    {
        "City": "Ciudad Juarez",
        "Country": "Mexico",
        "Code": "CJS"
    },
    {
        "City": "Ciudad Obregon",
        "Country": "Mexico",
        "Code": "CEN"
    },
    {
        "City": "Ciudad Victoria",
        "Country": "Mexico",
        "Code": "CVM"
    },
    {
        "City": "Clarksburg, WV ",
        "Country": "USA ",
        "Code": "CKB"
    },
    {
        "City": "Clermont-Ferrand",
        "Country": "France",
        "Code": "CFE"
    },
    {
        "City": "Cleveland, OH ",
        "Country": "USA ",
        "Code": "CLE"
    },
    {
        "City": "Clovis, NM ",
        "Country": "USA ",
        "Code": "CVN"
    },
    {
        "City": "Cockburn Town, San Salvador Island",
        "Country": "Bahamas",
        "Code": "ZSA"
    },
    {
        "City": "Cody, WY ",
        "Country": "USA ",
        "Code": "COD"
    },
    {
        "City": "Coffs Harbour, N.S.W.",
        "Country": "Australia",
        "Code": "CFS"
    },
    {
        "City": "Colima",
        "Country": "Mexico",
        "Code": "CLQ"
    },
    {
        "City": "College Station, TX ",
        "Country": "USA ",
        "Code": "CLL"
    },
    {
        "City": "Cologne",
        "Country": "Germany",
        "Code": "CGN"
    },
    {
        "City": "Colombo",
        "Country": "Sri Lanka",
        "Code": "CMB"
    },
    {
        "City": "Colorado Springs, CO ",
        "Country": "USA ",
        "Code": "COS"
    },
    {
        "City": "Columbia, MO ",
        "Country": "USA ",
        "Code": "COU"
    },
    {
        "City": "Columbia, SC ",
        "Country": "USA ",
        "Code": "CAE"
    },
    {
        "City": "Columbus, GA ",
        "Country": "USA ",
        "Code": "CSG"
    },
    {
        "City": "Columbus, IN ",
        "Country": "USA ",
        "Code": "CLU"
    },
    {
        "City": "Columbus, MS ",
        "Country": "USA ",
        "Code": "GTR"
    },
    {
        "City": "Columbus, NE ",
        "Country": "USA ",
        "Code": "OLU"
    },
    {
        "City": "Columbus, OH ",
        "Country": "USA ",
        "Code": "CMH"
    },
    {
        "City": "Conakry",
        "Country": "Guinea",
        "Code": "CKY"
    },
    {
        "City": "Concepcion",
        "Country": "Chile",
        "Code": "CCP"
    },
    {
        "City": "Connaught",
        "Country": "Ireland",
        "Code": "NOC"
    },
    {
        "City": "Coober Pedy, S.A.",
        "Country": "Australia",
        "Code": "CPD"
    },
    {
        "City": "Copenhagen",
        "Country": "Denmark",
        "Code": "CPH"
    },
    {
        "City": "Copiapo",
        "Country": "Chile",
        "Code": "CPO"
    },
    {
        "City": "Cordoba",
        "Country": "Argentina",
        "Code": "COR"
    },
    {
        "City": "Cordova, AK ",
        "Country": "USA ",
        "Code": "CDV"
    },
    {
        "City": "Cork",
        "Country": "Ireland",
        "Code": "ORK"
    },
    {
        "City": "Corpus Christi, TX ",
        "Country": "USA ",
        "Code": "CRP"
    },
    {
        "City": "Cotabato",
        "Country": "Philippines",
        "Code": "CBO"
    },
    {
        "City": "Cozumel",
        "Country": "Mexico",
        "Code": "CZM"
    },
    {
        "City": "Cranbrook, BC",
        "Country": "Canada",
        "Code": "YXC"
    },
    {
        "City": "Cranbrook, BC ",
        "Country": "Canada ",
        "Code": "YXC"
    },
    {
        "City": "Crotone",
        "Country": "Italy",
        "Code": "CRV"
    },
    {
        "City": "Cubi Point Nas",
        "Country": "Philippines",
        "Code": "NCP"
    },
    {
        "City": "Culiacan",
        "Country": "Mexico",
        "Code": "CUL"
    },
    {
        "City": "Curitiba",
        "Country": "Brazil",
        "Code": "CWB"
    },
    {
        "City": "Cusco",
        "Country": "Peru",
        "Code": "CUZ"
    },
    {
        "City": "Da Nang",
        "Country": "Vietnam",
        "Code": "DAD"
    },
    {
        "City": "Dakar",
        "Country": "Senegal",
        "Code": "DKR"
    },
    {
        "City": "Dalaman",
        "Country": "Turkey",
        "Code": "DLM"
    },
    {
        "City": "Dali",
        "Country": "China",
        "Code": "DLU"
    },
    {
        "City": "Dalian",
        "Country": "China",
        "Code": "DLC"
    },
    {
        "City": "Dallas, TX ",
        "Country": "USA ",
        "Code": "DAL"
    },
    {
        "City": "Dallas, TX ",
        "Country": "USA ",
        "Code": "DFW"
    },
    {
        "City": "Damascus",
        "Country": "Syria",
        "Code": "DAM"
    },
    {
        "City": "Dangriga",
        "Country": "Belize",
        "Code": "DGA"
    },
    {
        "City": "Dar es Salaam",
        "Country": "Tanzania",
        "Code": "DAR"
    },
    {
        "City": "Darwin, N.T.",
        "Country": "Australia",
        "Code": "DRW"
    },
    {
        "City": "Davao",
        "Country": "Philippines",
        "Code": "DVO"
    },
    {
        "City": "Dawadmi",
        "Country": "Saudi Arabia",
        "Code": "DWD"
    },
    {
        "City": "Dayong",
        "Country": "China",
        "Code": "DYG"
    },
    {
        "City": "Dayton, OH ",
        "Country": "USA ",
        "Code": "DAY"
    },
    {
        "City": "Daytona Beach, FL ",
        "Country": "USA ",
        "Code": "DAB"
    },
    {
        "City": "Deadmans Cay, Long Island",
        "Country": "Bahamas",
        "Code": "LGI"
    },
    {
        "City": "Deboyne",
        "Country": "Papua-New Guinea",
        "Code": "DOY"
    },
    {
        "City": "Decatur, IL ",
        "Country": "USA ",
        "Code": "DEC"
    },
    {
        "City": "Deer Lake, NF",
        "Country": "Canada",
        "Code": "YDF"
    },
    {
        "City": "Deer Lake, NF ",
        "Country": "Canada ",
        "Code": "YDF"
    },
    {
        "City": "Delhi",
        "Country": "India",
        "Code": "DEL"
    },
    {
        "City": "Denpasar, Bali",
        "Country": "Indonesia",
        "Code": "DPS"
    },
    {
        "City": "Denver, CO ",
        "Country": "USA ",
        "Code": "DEN"
    },
    {
        "City": "Des Moines, IA ",
        "Country": "USA ",
        "Code": "DSM"
    },
    {
        "City": "Detroit, MI ",
        "Country": "USA ",
        "Code": "DTT - All "
    },
    {
        "City": "Detroit, MI ",
        "Country": "USA ",
        "Code": "DTW"
    },
    {
        "City": "Devils Lake, ND ",
        "Country": "USA ",
        "Code": "DVL"
    },
    {
        "City": "Devonport, Tas.",
        "Country": "Australia",
        "Code": "DPO"
    },
    {
        "City": "Dhahran",
        "Country": "Saudi Arabia",
        "Code": "DHA"
    },
    {
        "City": "Dhaka",
        "Country": "Bangladesh",
        "Code": "DAC"
    },
    {
        "City": "Dickinson, ND ",
        "Country": "USA ",
        "Code": "DIK"
    },
    {
        "City": "Dien-Bien-Phu",
        "Country": "Vietnam",
        "Code": "DIN"
    },
    {
        "City": "Dillingham, AK ",
        "Country": "USA ",
        "Code": "DLG"
    },
    {
        "City": "Dipolog",
        "Country": "Philippines",
        "Code": "DPL"
    },
    {
        "City": "Djibouti",
        "Country": "Djibouti",
        "Code": "JIB"
    },
    {
        "City": "Dnepropetrovsk",
        "Country": "Ukraine",
        "Code": "DNK"
    },
    {
        "City": "Dodge KS ",
        "Country": "USA ",
        "Code": "DDC"
    },
    {
        "City": "Doha",
        "Country": "Qatar",
        "Code": "DOH"
    },
    {
        "City": "Doncaster",
        "Country": "England",
        "Code": "DSA"
    },
    {
        "City": "Donetsk",
        "Country": "Ukraine",
        "Code": "DOK"
    },
    {
        "City": "Dortmund",
        "Country": "Germany",
        "Code": "DTM"
    },
    {
        "City": "Dothan, AL ",
        "Country": "USA ",
        "Code": "DHN"
    },
    {
        "City": "Douala",
        "Country": "Cameroon",
        "Code": "DLA"
    },
    {
        "City": "Dresden",
        "Country": "Germany",
        "Code": "DRS"
    },
    {
        "City": "Dryden, ON",
        "Country": "Canada",
        "Code": "YHD"
    },
    {
        "City": "Dryden, ON ",
        "Country": "Canada ",
        "Code": "YHD"
    },
    {
        "City": "Du Bois, PA ",
        "Country": "USA ",
        "Code": "DUJ"
    },
    {
        "City": "Dubai",
        "Country": "United Arab Emirates",
        "Code": "DXB"
    },
    {
        "City": "Dubbo, N.S.W.",
        "Country": "Australia",
        "Code": "DBO"
    },
    {
        "City": "Dublin",
        "Country": "Ireland",
        "Code": "DUB"
    },
    {
        "City": "Dubrovnik",
        "Country": "Croatia",
        "Code": "DBV"
    },
    {
        "City": "Dubuque, IA ",
        "Country": "USA ",
        "Code": "DBQ"
    },
    {
        "City": "Duluth, MN ",
        "Country": "USA ",
        "Code": "DLH"
    },
    {
        "City": "Dumaguete",
        "Country": "Philippines",
        "Code": "DGT"
    },
    {
        "City": "Dunedin",
        "Country": "New Zealand",
        "Code": "DUD"
    },
    {
        "City": "Durango",
        "Country": "Mexico",
        "Code": "DGO"
    },
    {
        "City": "Durango, CO ",
        "Country": "USA ",
        "Code": "DRO"
    },
    {
        "City": "Durban",
        "Country": "South Africa",
        "Code": "DUR"
    },
    {
        "City": "Dushanbe",
        "Country": "Tajikistan",
        "Code": "DYU"
    },
    {
        "City": "Dusseldorf",
        "Country": "Germany",
        "Code": "DUS"
    },
    {
        "City": "Dutch Harbor, Un Island, AK",
        "Country": "USA ",
        "Code": "DUT"
    },
    {
        "City": "East London",
        "Country": "South Africa",
        "Code": "ELS"
    },
    {
        "City": "East Midlands",
        "Country": "England",
        "Code": "EMA"
    },
    {
        "City": "Eau Claire, WI ",
        "Country": "USA ",
        "Code": "EAU"
    },
    {
        "City": "Edinburgh",
        "Country": "Scotland",
        "Code": "EDI"
    },
    {
        "City": "Edmonton, AB",
        "Country": "Canada",
        "Code": "YEG"
    },
    {
        "City": "Edmonton, AB ",
        "Country": "Canada ",
        "Code": "YEG"
    },
    {
        "City": "Eek, AK ",
        "Country": "USA ",
        "Code": "EEK"
    },
    {
        "City": "Ekaterinburg, Siberia",
        "Country": "Russia",
        "Code": "SVX"
    },
    {
        "City": "El Calafate",
        "Country": "Argentina",
        "Code": "FTE"
    },
    {
        "City": "El Centro, CA ",
        "Country": "USA ",
        "Code": "IPL"
    },
    {
        "City": "El Dorado, AR ",
        "Country": "USA ",
        "Code": "ELD"
    },
    {
        "City": "El Paso, TX ",
        "Country": "USA ",
        "Code": "ELP"
    },
    {
        "City": "El Salvador",
        "Country": "Chile",
        "Code": "ESR"
    },
    {
        "City": "Elko, NV ",
        "Country": "USA ",
        "Code": "EKO"
    },
    {
        "City": "Elmira, NY ",
        "Country": "USA ",
        "Code": "ELM"
    },
    {
        "City": "Emerald, Qld.",
        "Country": "Australia",
        "Code": "EMD"
    },
    {
        "City": "Enid, OK ",
        "Country": "USA ",
        "Code": "WDG"
    },
    {
        "City": "Entebbe",
        "Country": "Uganda",
        "Code": "EBB"
    },
    {
        "City": "Erfurt",
        "Country": "Germany",
        "Code": "ERF"
    },
    {
        "City": "Erie, PA ",
        "Country": "USA ",
        "Code": "ERI"
    },
    {
        "City": "Esbjerg",
        "Country": "Denmark",
        "Code": "EBJ"
    },
    {
        "City": "Escanaba, MI ",
        "Country": "USA ",
        "Code": "ESC"
    },
    {
        "City": "Eugene, OR ",
        "Country": "USA ",
        "Code": "EUG"
    },
    {
        "City": "Eureka, CA",
        "Country": "USA ",
        "Code": "ACV"
    },
    {
        "City": "Evansville, IN ",
        "Country": "USA ",
        "Code": "EVV"
    },
    {
        "City": "Evenes",
        "Country": "Norway",
        "Code": "EVE"
    },
    {
        "City": "Fairbanks, AK ",
        "Country": "USA ",
        "Code": "FAI"
    },
    {
        "City": "Fargo, ND ",
        "Country": "USA ",
        "Code": "FAR"
    },
    {
        "City": "Farmington, NM ",
        "Country": "USA ",
        "Code": "FMN"
    },
    {
        "City": "Faro",
        "Country": "Portugal",
        "Code": "FAO"
    },
    {
        "City": "Fayetteville, AR ",
        "Country": "USA ",
        "Code": "XNA"
    },
    {
        "City": "Fayetteville, NC ",
        "Country": "USA ",
        "Code": "FAY"
    },
    {
        "City": "Fernando de Noronha",
        "Country": "Brazil",
        "Code": "FEN"
    },
    {
        "City": "Figari, Corsica",
        "Country": "France",
        "Code": "FSC"
    },
    {
        "City": "Flagstaff, AZ ",
        "Country": "USA ",
        "Code": "FLG"
    },
    {
        "City": "Flin Flon, MB",
        "Country": "Canada",
        "Code": "YFO"
    },
    {
        "City": "Flin Flon, MB ",
        "Country": "Canada ",
        "Code": "YFO"
    },
    {
        "City": "Flint, MI ",
        "Country": "USA ",
        "Code": "FNT"
    },
    {
        "City": "Florence",
        "Country": "Italy",
        "Code": "FLR"
    },
    {
        "City": "Florence, SC ",
        "Country": "USA ",
        "Code": "FLO"
    },
    {
        "City": "Flores",
        "Country": "Guatemala",
        "Code": "FRS"
    },
    {
        "City": "Fort Dodge, IA ",
        "Country": "USA ",
        "Code": "FOD"
    },
    {
        "City": "Fort Lauderdale, FL ",
        "Country": "USA ",
        "Code": "FLL"
    },
    {
        "City": "Fort Leonard Wood, MO",
        "Country": "USA ",
        "Code": "TBN"
    },
    {
        "City": "Fort McMurray, AB",
        "Country": "Canada",
        "Code": "YMM"
    },
    {
        "City": "Fort McMurray, AB ",
        "Country": "Canada ",
        "Code": "YMM"
    },
    {
        "City": "Fort Myers, FL ",
        "Country": "USA ",
        "Code": "RSW"
    },
    {
        "City": "Fort Nelson, BC",
        "Country": "Canada",
        "Code": "YYE"
    },
    {
        "City": "Fort Nelson, BC ",
        "Country": "Canada ",
        "Code": "YYE"
    },
    {
        "City": "Fort Saint John, BC",
        "Country": "Canada",
        "Code": "YXJ"
    },
    {
        "City": "Fort Saint John, BC ",
        "Country": "Canada ",
        "Code": "YXJ"
    },
    {
        "City": "Fort Smith, AR ",
        "Country": "USA ",
        "Code": "FSM"
    },
    {
        "City": "Fort Smith, NWT",
        "Country": "Canada",
        "Code": "YSM"
    },
    {
        "City": "Fort Smith, NWT",
        "Country": "Canada ",
        "Code": "YSM"
    },
    {
        "City": "Fort Walton Beach, FL ",
        "Country": "USA ",
        "Code": "VPS"
    },
    {
        "City": "Fort Wayne, IN ",
        "Country": "USA ",
        "Code": "FWA"
    },
    {
        "City": "Fort Yukon, AK ",
        "Country": "USA ",
        "Code": "FYU"
    },
    {
        "City": "Fort-de-France",
        "Country": "Martinique",
        "Code": "FDF"
    },
    {
        "City": "Frankfurt",
        "Country": "Germany",
        "Code": "FRA"
    },
    {
        "City": "Fredericton, NB",
        "Country": "Canada",
        "Code": "YFC"
    },
    {
        "City": "Fredericton, NB ",
        "Country": "Canada ",
        "Code": "YFC"
    },
    {
        "City": "Freeport, Grand Bahama Island",
        "Country": "Bahamas",
        "Code": "FPO"
    },
    {
        "City": "Freetown",
        "Country": "Sierra Leone",
        "Code": "FNA"
    },
    {
        "City": "Fresno, CA ",
        "Country": "USA ",
        "Code": "FAT"
    },
    {
        "City": "Friedrichshafen",
        "Country": "Germany",
        "Code": "FDH"
    },
    {
        "City": "Fukuoka",
        "Country": "Japan",
        "Code": "FUK"
    },
    {
        "City": "Fukushima",
        "Country": "Japan",
        "Code": "FKS"
    },
    {
        "City": "Funchal",
        "Country": "Madeira",
        "Code": "FNC"
    },
    {
        "City": "Fuzhou",
        "Country": "China",
        "Code": "FOC"
    },
    {
        "City": "Gainesville, FL ",
        "Country": "USA ",
        "Code": "GNV"
    },
    {
        "City": "Gander, NF",
        "Country": "Canada",
        "Code": "YQX"
    },
    {
        "City": "Gander, NF ",
        "Country": "Canada ",
        "Code": "YQX"
    },
    {
        "City": "Garden KS",
        "Country": "USA ",
        "Code": "GCK"
    },
    {
        "City": "Gaspe, PQ",
        "Country": "Canada",
        "Code": "YGP"
    },
    {
        "City": "Gaspe, PQ ",
        "Country": "Canada ",
        "Code": "YGP"
    },
    {
        "City": "Gdansk",
        "Country": "Poland",
        "Code": "GDN"
    },
    {
        "City": "General Santos",
        "Country": "Philippines",
        "Code": "GES"
    },
    {
        "City": "Geneva",
        "Country": "Switzerland",
        "Code": "GVA"
    },
    {
        "City": "Genoa",
        "Country": "Italy",
        "Code": "GOA"
    },
    {
        "City": "George Town, Great Exuma Island",
        "Country": "Bahamas",
        "Code": "GGT"
    },
    {
        "City": "Georgetown",
        "Country": "Guyana",
        "Code": "GEO"
    },
    {
        "City": "Georgetown, Grand Cayman Island",
        "Country": "Cayman Islands",
        "Code": "GCM"
    },
    {
        "City": "Gillette, WY ",
        "Country": "USA ",
        "Code": "GCC"
    },
    {
        "City": "Gisborne",
        "Country": "New Zealand",
        "Code": "GIS"
    },
    {
        "City": "Gladstone, Qld.",
        "Country": "Australia",
        "Code": "GLT"
    },
    {
        "City": "Glasgow",
        "Country": "Scotland",
        "Code": "GLA"
    },
    {
        "City": "Glasgow",
        "Country": "Scotland",
        "Code": "PIK"
    },
    {
        "City": "Glendive, MT ",
        "Country": "USA ",
        "Code": "GDV"
    },
    {
        "City": "Gold Coast, Qld.",
        "Country": "Australia",
        "Code": "OOL"
    },
    {
        "City": "Gothenburg",
        "Country": "Sweden",
        "Code": "GOT"
    },
    {
        "City": "Gove, N.T.",
        "Country": "Australia",
        "Code": "GOV"
    },
    {
        "City": "Governor's Harbour, Eleuthera Island",
        "Country": "Bahamas",
        "Code": "GHB"
    },
    {
        "City": "Granada",
        "Country": "Spain",
        "Code": "GRX"
    },
    {
        "City": "Grand Forks, ND ",
        "Country": "USA ",
        "Code": "GFK"
    },
    {
        "City": "Grand Island, NE ",
        "Country": "USA ",
        "Code": "GRI"
    },
    {
        "City": "Grand Junction, CO ",
        "Country": "USA ",
        "Code": "GJT"
    },
    {
        "City": "Grand Rapids, MI ",
        "Country": "USA ",
        "Code": "GRR"
    },
    {
        "City": "Grande Prairie, AB",
        "Country": "Canada",
        "Code": "YQU"
    },
    {
        "City": "Grande Prairie, AB ",
        "Country": "Canada ",
        "Code": "YQU"
    },
    {
        "City": "Graz",
        "Country": "Austria",
        "Code": "GRZ"
    },
    {
        "City": "Great Bend, KS ",
        "Country": "USA ",
        "Code": "GBD"
    },
    {
        "City": "Great Falls, MT ",
        "Country": "USA ",
        "Code": "GTF"
    },
    {
        "City": "Green Bay, WI ",
        "Country": "USA ",
        "Code": "GRB"
    },
    {
        "City": "Greenbrier, WV",
        "Country": "USA ",
        "Code": "LWB"
    },
    {
        "City": "Greensboro, NC ",
        "Country": "USA ",
        "Code": "GSO"
    },
    {
        "City": "Greenville, MS ",
        "Country": "USA ",
        "Code": "GLH"
    },
    {
        "City": "Greenville, NC ",
        "Country": "USA ",
        "Code": "PGV"
    },
    {
        "City": "Greenville, SC ",
        "Country": "USA ",
        "Code": "GSP"
    },
    {
        "City": "Griffith, N.S.W.",
        "Country": "Australia",
        "Code": "GFF"
    },
    {
        "City": "Guadalajara",
        "Country": "Mexico",
        "Code": "GDL"
    },
    {
        "City": "Guangzhou",
        "Country": "China",
        "Code": "CAN"
    },
    {
        "City": "Guatemala",
        "Country": "Guatemala",
        "Code": "GUA"
    },
    {
        "City": "Guayaquil",
        "Country": "Ecuador",
        "Code": "GYE"
    },
    {
        "City": "Guaymas",
        "Country": "Mexico",
        "Code": "GYM"
    },
    {
        "City": "Guernsey",
        "Country": "Channel Islands",
        "Code": "GCI"
    },
    {
        "City": "Guilin",
        "Country": "China",
        "Code": "KWL"
    },
    {
        "City": "Gulfport, MS ",
        "Country": "USA ",
        "Code": "GPT"
    },
    {
        "City": "Gunnison, CO ",
        "Country": "USA ",
        "Code": "GUC"
    },
    {
        "City": "Gustavia",
        "Country": "St. Barts",
        "Code": "SBH"
    },
    {
        "City": "Hagerstown, MD ",
        "Country": "USA ",
        "Code": "HGR"
    },
    {
        "City": "Haikou, Hainan Island",
        "Country": "China",
        "Code": "HAK"
    },
    {
        "City": "Hailar",
        "Country": "China",
        "Code": "HLD"
    },
    {
        "City": "Hakodate",
        "Country": "Japan",
        "Code": "HKD"
    },
    {
        "City": "Halifax, NS",
        "Country": "Canada",
        "Code": "YHZ"
    },
    {
        "City": "Halifax, NS ",
        "Country": "Canada ",
        "Code": "YHZ"
    },
    {
        "City": "Halmstad",
        "Country": "Sweden",
        "Code": "HAD"
    },
    {
        "City": "Hamburg",
        "Country": "Germany",
        "Code": "HAM"
    },
    {
        "City": "Hamilton",
        "Country": "Bermuda",
        "Code": "BDA"
    },
    {
        "City": "Hamilton",
        "Country": "New Zealand",
        "Code": "HLZ"
    },
    {
        "City": "Hamilton Island, Whitsunday Island, Qld.",
        "Country": "Australia",
        "Code": "HTI"
    },
    {
        "City": "Hamilton, ON",
        "Country": "Canada",
        "Code": "YHM"
    },
    {
        "City": "Hamilton, ON ",
        "Country": "Canada ",
        "Code": "YHM"
    },
    {
        "City": "Hammerfest",
        "Country": "Norway",
        "Code": "HFT"
    },
    {
        "City": "Hana, Maui, HI",
        "Country": "USA ",
        "Code": "HNM"
    },
    {
        "City": "Hancock, MI ",
        "Country": "USA ",
        "Code": "CMX"
    },
    {
        "City": "Hanga Roa, Easter Island",
        "Country": "Chile",
        "Code": "IPC"
    },
    {
        "City": "Hangzhou",
        "Country": "China",
        "Code": "HGH"
    },
    {
        "City": "Hannover",
        "Country": "Germany",
        "Code": "HAJ"
    },
    {
        "City": "Hanoi",
        "Country": "Vietnam",
        "Code": "HAN"
    },
    {
        "City": "Harare",
        "Country": "Zimbabwe",
        "Code": "HRE"
    },
    {
        "City": "Harbin",
        "Country": "China",
        "Code": "HRB"
    },
    {
        "City": "Harlingen, TX ",
        "Country": "USA ",
        "Code": "HRL"
    },
    {
        "City": "Harrisburg, PA ",
        "Country": "USA ",
        "Code": "MDT"
    },
    {
        "City": "Harrison, AR ",
        "Country": "USA ",
        "Code": "HRO"
    },
    {
        "City": "Hartford, CT ",
        "Country": "USA ",
        "Code": "BDL"
    },
    {
        "City": "Hat Yai",
        "Country": "Thailand",
        "Code": "HDY"
    },
    {
        "City": "Haugesund",
        "Country": "Norway",
        "Code": "HAU"
    },
    {
        "City": "Havana",
        "Country": "Cuba",
        "Code": "HAV"
    },
    {
        "City": "Havre, MT ",
        "Country": "USA ",
        "Code": "HVR"
    },
    {
        "City": "Hays, KS",
        "Country": "USA ",
        "Code": "HYS"
    },
    {
        "City": "Hefei",
        "Country": "China",
        "Code": "HFE"
    },
    {
        "City": "Helena, MT ",
        "Country": "USA ",
        "Code": "HLN"
    },
    {
        "City": "Helsingborg",
        "Country": "Sweden",
        "Code": "AGH"
    },
    {
        "City": "Helsinki",
        "Country": "Finland",
        "Code": "HEL"
    },
    {
        "City": "Hemavan",
        "Country": "Sweden",
        "Code": "HMV"
    },
    {
        "City": "Hengchun",
        "Country": "Taiwan",
        "Code": "HCN"
    },
    {
        "City": "Heraklion, Crete",
        "Country": "Greece",
        "Code": "HER"
    },
    {
        "City": "Hermosillo",
        "Country": "Mexico",
        "Code": "HMO"
    },
    {
        "City": "Hibbing, MN ",
        "Country": "USA ",
        "Code": "HIB"
    },
    {
        "City": "Hilo, Hawaii ",
        "Country": "USA ",
        "Code": "Big"
    },
    {
        "City": "Hilton Head Island, SC ",
        "Country": "USA ",
        "Code": "HHH"
    },
    {
        "City": "Ho Chi Minh (Saigon)",
        "Country": "Vietnam",
        "Code": "SGN"
    },
    {
        "City": "Hobart, Tas.",
        "Country": "Australia",
        "Code": "HBA"
    },
    {
        "City": "Hobbs, NM ",
        "Country": "USA ",
        "Code": "HOB"
    },
    {
        "City": "Hof",
        "Country": "Germany",
        "Code": "HOQ"
    },
    {
        "City": "Hohhot",
        "Country": "China",
        "Code": "HET"
    },
    {
        "City": "Hokitika",
        "Country": "New Zealand",
        "Code": "HKK"
    },
    {
        "City": "Homer, AK",
        "Country": "USA ",
        "Code": "HOM"
    },
    {
        "City": "Hong Kong",
        "Country": "China",
        "Code": "HKG"
    },
    {
        "City": "Honiara, Guadalcanal",
        "Country": "Solomon Islands",
        "Code": "HIR"
    },
    {
        "City": "Honolulu, Oahu, HI",
        "Country": "USA ",
        "Code": "HNL"
    },
    {
        "City": "Hoolehua, Molokai, HI",
        "Country": "USA ",
        "Code": "MKK"
    },
    {
        "City": "Horn Island, Qld.",
        "Country": "Australia",
        "Code": "HID"
    },
    {
        "City": "Hot Springs, AR ",
        "Country": "USA ",
        "Code": "HOT"
    },
    {
        "City": "Houmt Souk",
        "Country": "Tunisia",
        "Code": "DJE"
    },
    {
        "City": "Houston, TX ",
        "Country": "USA ",
        "Code": "EFD"
    },
    {
        "City": "Houston, TX ",
        "Country": "USA ",
        "Code": "HOU"
    },
    {
        "City": "Houston, TX ",
        "Country": "USA ",
        "Code": "IAH"
    },
    {
        "City": "Hualien",
        "Country": "Taiwan",
        "Code": "HUN"
    },
    {
        "City": "Hue",
        "Country": "Vietnam",
        "Code": "HUI"
    },
    {
        "City": "Humberside",
        "Country": "England",
        "Code": "HUY"
    },
    {
        "City": "Huntington, WV ",
        "Country": "USA ",
        "Code": "HTS"
    },
    {
        "City": "Huntsville, AL ",
        "Country": "USA ",
        "Code": "HSV"
    },
    {
        "City": "Hurghada",
        "Country": "Egypt",
        "Code": "HRG"
    },
    {
        "City": "Huron, SD",
        "Country": "USA ",
        "Code": "HON"
    },
    {
        "City": "Hyannis, MA ",
        "Country": "USA ",
        "Code": "HYA"
    },
    {
        "City": "Hyderabad",
        "Country": "India",
        "Code": "HYD"
    },
    {
        "City": "Ibadan",
        "Country": "Nigeria",
        "Code": "IBA"
    },
    {
        "City": "Ibiza",
        "Country": "Spain",
        "Code": "IBZ"
    },
    {
        "City": "Idaho Falls, ID ",
        "Country": "USA ",
        "Code": "IDA"
    },
    {
        "City": "Ikaria Island",
        "Country": "Greece",
        "Code": "JIK"
    },
    {
        "City": "Iloilo",
        "Country": "Philippines",
        "Code": "ILO"
    },
    {
        "City": "Indianapolis, IN ",
        "Country": "USA ",
        "Code": "IND"
    },
    {
        "City": "Innsbruck",
        "Country": "Austria",
        "Code": "INN"
    },
    {
        "City": "International Falls, MN ",
        "Country": "USA ",
        "Code": "INL"
    },
    {
        "City": "Invercargill",
        "Country": "New Zealand",
        "Code": "IVC"
    },
    {
        "City": "Inverness",
        "Country": "Scotland",
        "Code": "INV"
    },
    {
        "City": "Inyokern, CA ",
        "Country": "USA ",
        "Code": "IYK"
    },
    {
        "City": "Ioannina",
        "Country": "Greece",
        "Code": "IOA"
    },
    {
        "City": "Ipoh",
        "Country": "Malaysia",
        "Code": "IPH"
    },
    {
        "City": "Iqaluit, Nunavut",
        "Country": "Canada",
        "Code": "YFB"
    },
    {
        "City": "Iqaluit, Nunavut ",
        "Country": "Canada ",
        "Code": "YFB"
    },
    {
        "City": "Irkutsk, Siberia",
        "Country": "Russia",
        "Code": "IKT"
    },
    {
        "City": "Iron Mountain, MI ",
        "Country": "USA ",
        "Code": "IMT"
    },
    {
        "City": "Ironwood, MI",
        "Country": "USA ",
        "Code": "IWD"
    },
    {
        "City": "Islamabad-Rawalpindi",
        "Country": "Pakistan",
        "Code": "ISB"
    },
    {
        "City": "Islay Island",
        "Country": "Scotland",
        "Code": "ILY"
    },
    {
        "City": "Islip, NY ",
        "Country": "USA ",
        "Code": "ISP"
    },
    {
        "City": "Istanbul",
        "Country": "Turkey",
        "Code": "IST"
    },
    {
        "City": "Istanbul",
        "Country": "Turkey",
        "Code": "SAW"
    },
    {
        "City": "Ithaca, NY ",
        "Country": "USA ",
        "Code": "ITH"
    },
    {
        "City": "Ivalo",
        "Country": "Finland",
        "Code": "IVL"
    },
    {
        "City": "Iwami",
        "Country": "Japan",
        "Code": "IWJ"
    },
    {
        "City": "Ixtapa",
        "Country": "Mexico",
        "Code": "ZIH"
    },
    {
        "City": "Izmir",
        "Country": "Turkey",
        "Code": "ADB"
    },
    {
        "City": "Izumo",
        "Country": "Japan",
        "Code": "IZO"
    },
    {
        "City": "Jackson Hole, WY ",
        "Country": "USA ",
        "Code": "JAC"
    },
    {
        "City": "Jackson, MS ",
        "Country": "USA ",
        "Code": "JAN"
    },
    {
        "City": "Jackson, TN ",
        "Country": "USA ",
        "Code": "MKL"
    },
    {
        "City": "Jacksonville, FL ",
        "Country": "USA ",
        "Code": "JAX"
    },
    {
        "City": "Jacksonville, NC ",
        "Country": "USA ",
        "Code": "OAJ"
    },
    {
        "City": "Jakarta, Java",
        "Country": "Indonesia",
        "Code": "CGK"
    },
    {
        "City": "Jakarta, Java",
        "Country": "Indonesia",
        "Code": "JKT - All Airports"
    },
    {
        "City": "Jalapa",
        "Country": "Mexico",
        "Code": "JAL"
    },
    {
        "City": "Jamestown, ND ",
        "Country": "USA ",
        "Code": "JMS"
    },
    {
        "City": "Jamestown, NY ",
        "Country": "USA ",
        "Code": "JHW"
    },
    {
        "City": "Jeddah",
        "Country": "Saudi Arabia",
        "Code": "JED"
    },
    {
        "City": "Jeju, Korea",
        "Country": "Republic of",
        "Code": "CJU"
    },
    {
        "City": "Jerez de La Frontera",
        "Country": "Spain",
        "Code": "XRY"
    },
    {
        "City": "Jersey",
        "Country": "Channel Islands",
        "Code": "JER"
    },
    {
        "City": "Jiamusi",
        "Country": "China",
        "Code": "JMU"
    },
    {
        "City": "Jijiga",
        "Country": "Ethiopia",
        "Code": "JIJ"
    },
    {
        "City": "Joensuu",
        "Country": "Finland",
        "Code": "JOE"
    },
    {
        "City": "Johannesburg",
        "Country": "South Africa",
        "Code": "JNB"
    },
    {
        "City": "Johnstown, PA ",
        "Country": "USA ",
        "Code": "JST"
    },
    {
        "City": "Johor Bahru",
        "Country": "Malaysia",
        "Code": "JHB"
    },
    {
        "City": "Jonesboro, AR ",
        "Country": "USA ",
        "Code": "JBR"
    },
    {
        "City": "Jonkoping",
        "Country": "Sweden",
        "Code": "JKG"
    },
    {
        "City": "Joplin, MO ",
        "Country": "USA ",
        "Code": "JLN"
    },
    {
        "City": "Juneau, AK ",
        "Country": "USA ",
        "Code": "JNU"
    },
    {
        "City": "Jyvaskyla",
        "Country": "Finland",
        "Code": "JYV"
    },
    {
        "City": "Kabul",
        "Country": "Afghanistan",
        "Code": "KBL"
    },
    {
        "City": "Kagoshima",
        "Country": "Japan",
        "Code": "KOJ"
    },
    {
        "City": "Kahului, Maui, HI ",
        "Country": "USA ",
        "Code": "OGG"
    },
    {
        "City": "Kailua-Kona, HI",
        "Country": "USA ",
        "Code": "KOA"
    },
    {
        "City": "Kaitaia",
        "Country": "New Zealand",
        "Code": "KAT"
    },
    {
        "City": "Kajaani",
        "Country": "Finland",
        "Code": "KAJ"
    },
    {
        "City": "Kalamazoo, MI ",
        "Country": "USA ",
        "Code": "AZO"
    },
    {
        "City": "Kalaupapa, Molokai, HI",
        "Country": "USA ",
        "Code": "LUP"
    },
    {
        "City": "Kalgoorlie, W.A.",
        "Country": "Australia",
        "Code": "KGI"
    },
    {
        "City": "Kalibo",
        "Country": "Philippines",
        "Code": "KLO"
    },
    {
        "City": "Kaliningrad",
        "Country": "Russia",
        "Code": "KGD"
    },
    {
        "City": "Kalispell, MT ",
        "Country": "USA ",
        "Code": "FCA"
    },
    {
        "City": "Kalmar",
        "Country": "Sweden",
        "Code": "KLR"
    },
    {
        "City": "Kamloops, BC",
        "Country": "Canada",
        "Code": "YKA"
    },
    {
        "City": "Kamloops, BC ",
        "Country": "Canada ",
        "Code": "YKA"
    },
    {
        "City": "Kamuela, Hawaii ",
        "Country": "USA ",
        "Code": "KOA"
    },
    {
        "City": "Kansas, MO ",
        "Country": "USA ",
        "Code": "MCI"
    },
    {
        "City": "Kaohsiung",
        "Country": "Taiwan",
        "Code": "KHH"
    },
    {
        "City": "Kapalua, Maui, HI",
        "Country": "USA ",
        "Code": "JHM"
    },
    {
        "City": "Karachi",
        "Country": "Pakistan",
        "Code": "KHI"
    },
    {
        "City": "Karaganda",
        "Country": "Kazakhstan",
        "Code": "KGF"
    },
    {
        "City": "Karlstad",
        "Country": "Sweden",
        "Code": "KSD"
    },
    {
        "City": "Karratha, W.A.",
        "Country": "Australia",
        "Code": "KTA"
    },
    {
        "City": "Karup",
        "Country": "Denmark",
        "Code": "KRP"
    },
    {
        "City": "Kashi",
        "Country": "China",
        "Code": "KHG"
    },
    {
        "City": "Kathmandu",
        "Country": "Nepal",
        "Code": "KTM"
    },
    {
        "City": "Katowice",
        "Country": "Poland",
        "Code": "KTW"
    },
    {
        "City": "Kearney, NE ",
        "Country": "USA ",
        "Code": "EAR"
    },
    {
        "City": "Kelowna, BC",
        "Country": "Canada",
        "Code": "YLW"
    },
    {
        "City": "Kelowna, BC ",
        "Country": "Canada ",
        "Code": "YLW"
    },
    {
        "City": "Kenai, AK ",
        "Country": "USA ",
        "Code": "ENA"
    },
    {
        "City": "Kenora, ON",
        "Country": "Canada",
        "Code": "YQK"
    },
    {
        "City": "Kenora, ON ",
        "Country": "Canada ",
        "Code": "YQK"
    },
    {
        "City": "Kerikeri",
        "Country": "New Zealand",
        "Code": "KKE"
    },
    {
        "City": "Ketchikan, AK ",
        "Country": "USA ",
        "Code": "KTN"
    },
    {
        "City": "Key West, FL ",
        "Country": "USA ",
        "Code": "EYW"
    },
    {
        "City": "Kharkov",
        "Country": "Ukraine",
        "Code": "HRK"
    },
    {
        "City": "Khartoum",
        "Country": "Sudan",
        "Code": "KRT"
    },
    {
        "City": "Khon Kaen",
        "Country": "Thailand",
        "Code": "KKC"
    },
    {
        "City": "Kiel",
        "Country": "Germany",
        "Code": "KEL"
    },
    {
        "City": "Kiev",
        "Country": "Ukraine",
        "Code": "KBP"
    },
    {
        "City": "Kigali",
        "Country": "Rwanda",
        "Code": "KGL"
    },
    {
        "City": "Killeen, TX ",
        "Country": "USA ",
        "Code": "GRK"
    },
    {
        "City": "King Salmon, AK",
        "Country": "USA ",
        "Code": "AKN"
    },
    {
        "City": "Kingman, AZ ",
        "Country": "USA ",
        "Code": "IGM"
    },
    {
        "City": "Kingscote, Kangaroo Island, S.A.",
        "Country": "Australia",
        "Code": "KGC"
    },
    {
        "City": "Kingston",
        "Country": "Jamaica",
        "Code": "KIN"
    },
    {
        "City": "Kingston",
        "Country": "Jamaica",
        "Code": "KTP"
    },
    {
        "City": "Kingston, ON",
        "Country": "Canada",
        "Code": "YGK"
    },
    {
        "City": "Kingston, ON ",
        "Country": "Canada ",
        "Code": "YGK"
    },
    {
        "City": "Kingstown",
        "Country": "St. Vincent",
        "Code": "SVD"
    },
    {
        "City": "Kinshasa",
        "Country": "Zaire",
        "Code": "FIH"
    },
    {
        "City": "Kirksville, MO ",
        "Country": "USA ",
        "Code": "IRK"
    },
    {
        "City": "Kirkwall, Orkney Islands",
        "Country": "Scotland",
        "Code": "KOI"
    },
    {
        "City": "Kiruna",
        "Country": "Sweden",
        "Code": "KRN"
    },
    {
        "City": "Kisangani",
        "Country": "Zaire",
        "Code": "FKI"
    },
    {
        "City": "Kittila",
        "Country": "Finland",
        "Code": "KTT"
    },
    {
        "City": "Klagenfurt",
        "Country": "Austria",
        "Code": "KLU"
    },
    {
        "City": "Klamath Falls, OR ",
        "Country": "USA ",
        "Code": "LMT"
    },
    {
        "City": "Knoxville, TN ",
        "Country": "USA ",
        "Code": "TYS"
    },
    {
        "City": "Kodiak, AK ",
        "Country": "USA ",
        "Code": "ADQ"
    },
    {
        "City": "Koh Samui",
        "Country": "Thailand",
        "Code": "USM"
    },
    {
        "City": "Kokkola",
        "Country": "Finland",
        "Code": "KOK"
    },
    {
        "City": "Komatsu",
        "Country": "Japan",
        "Code": "KMQ"
    },
    {
        "City": "Koror",
        "Country": "Palau",
        "Code": "ROR"
    },
    {
        "City": "Kos",
        "Country": "Greece",
        "Code": "KGS"
    },
    {
        "City": "Kosrae",
        "Country": "Micronesia",
        "Code": "KSA"
    },
    {
        "City": "Kota Bharu",
        "Country": "Malaysia",
        "Code": "KBR"
    },
    {
        "City": "Kota Kinabalu, Sabah",
        "Country": "Malaysia",
        "Code": "BKI"
    },
    {
        "City": "Krabi",
        "Country": "Thailand",
        "Code": "KBV"
    },
    {
        "City": "Krakow",
        "Country": "Poland",
        "Code": "KRK"
    },
    {
        "City": "Kralendijk",
        "Country": "Bonaire",
        "Code": "BON"
    },
    {
        "City": "Kramfors",
        "Country": "Sweden",
        "Code": "KRF"
    },
    {
        "City": "Kristiansand",
        "Country": "Norway",
        "Code": "KRS"
    },
    {
        "City": "Kristianstad",
        "Country": "Sweden",
        "Code": "KID"
    },
    {
        "City": "Kuala Lumpur",
        "Country": "Malaysia",
        "Code": "KUL"
    },
    {
        "City": "Kuala Terengganu",
        "Country": "Malaysia",
        "Code": "TGG"
    },
    {
        "City": "Kuantan",
        "Country": "Malaysia",
        "Code": "KUA"
    },
    {
        "City": "Kuching, Sarawak",
        "Country": "Malaysia",
        "Code": "KCH"
    },
    {
        "City": "Kumamoto",
        "Country": "Japan",
        "Code": "KMJ"
    },
    {
        "City": "Kunming",
        "Country": "China",
        "Code": "KMG"
    },
    {
        "City": "Kuopio",
        "Country": "Finland",
        "Code": "KUO"
    },
    {
        "City": "Kushiro",
        "Country": "Japan",
        "Code": "KUH"
    },
    {
        "City": "Kuwait",
        "Country": "Kuwait",
        "Code": "KWI"
    },
    {
        "City": "Kzyl Orda",
        "Country": "Kazakhstan",
        "Code": "KZO"
    },
    {
        "City": "La Coruna",
        "Country": "Spain",
        "Code": "LCG"
    },
    {
        "City": "La Crosse, WI ",
        "Country": "USA ",
        "Code": "LSE"
    },
    {
        "City": "La Paz",
        "Country": "Mexico",
        "Code": "LAP"
    },
    {
        "City": "La Paz",
        "Country": "Bolivia",
        "Code": "LPB"
    },
    {
        "City": "La Romana",
        "Country": "Dominican Republic",
        "Code": "LRM"
    },
    {
        "City": "La Serena",
        "Country": "Chile",
        "Code": "LSC"
    },
    {
        "City": "Lafayette, LA ",
        "Country": "USA ",
        "Code": "LFT"
    },
    {
        "City": "Lagos",
        "Country": "Nigeria",
        "Code": "LOS"
    },
    {
        "City": "Lahad Datu, Sabah",
        "Country": "Malaysia",
        "Code": "LDU"
    },
    {
        "City": "Lahore",
        "Country": "Pakistan",
        "Code": "LHE"
    },
    {
        "City": "Lake Charles, LA ",
        "Country": "USA ",
        "Code": "LCH"
    },
    {
        "City": "Lake Havasu, AZ ",
        "Country": "USA ",
        "Code": "HII"
    },
    {
        "City": "Lamezia Terme",
        "Country": "Italy",
        "Code": "SUF"
    },
    {
        "City": "Lanai, Lanai, HI",
        "Country": "USA ",
        "Code": "LNY"
    },
    {
        "City": "Lancaster, PA ",
        "Country": "USA ",
        "Code": "LNS"
    },
    {
        "City": "Langkawi",
        "Country": "Malaysia",
        "Code": "LGK"
    },
    {
        "City": "Lannion",
        "Country": "France",
        "Code": "LAI"
    },
    {
        "City": "Lansing, MI ",
        "Country": "USA ",
        "Code": "LAN"
    },
    {
        "City": "Lanzhou",
        "Country": "China",
        "Code": "LHW"
    },
    {
        "City": "Laramie, WY ",
        "Country": "USA ",
        "Code": "LAR"
    },
    {
        "City": "Laredo, TX ",
        "Country": "USA ",
        "Code": "LRD"
    },
    {
        "City": "Larnaca",
        "Country": "Cyprus",
        "Code": "LCA"
    },
    {
        "City": "Las Cruces, NM ",
        "Country": "USA ",
        "Code": "LRU"
    },
    {
        "City": "Las Palmas, Grand Canary Island",
        "Country": "Canary Islands",
        "Code": "LPA"
    },
    {
        "City": "Las Vegas, NV ",
        "Country": "USA ",
        "Code": "LAS"
    },
    {
        "City": "Latrobe, PA ",
        "Country": "USA ",
        "Code": "LBE"
    },
    {
        "City": "Launceston, Tas.",
        "Country": "Australia",
        "Code": "LST"
    },
    {
        "City": "Laurel, MS ",
        "Country": "USA ",
        "Code": "PIB"
    },
    {
        "City": "Lawton, OK ",
        "Country": "USA ",
        "Code": "LAW"
    },
    {
        "City": "Lazaro Cardenas",
        "Country": "Mexico",
        "Code": "LZC"
    },
    {
        "City": "Le Havre",
        "Country": "France",
        "Code": "LEH"
    },
    {
        "City": "Lebanon, NH ",
        "Country": "USA ",
        "Code": "LEB"
    },
    {
        "City": "Leeds",
        "Country": "England",
        "Code": "LBA"
    },
    {
        "City": "Legaspi",
        "Country": "Philippines",
        "Code": "LGP"
    },
    {
        "City": "Leipzig",
        "Country": "Germany",
        "Code": "LEJ"
    },
    {
        "City": "Leon",
        "Country": "Mexico",
        "Code": "BJX"
    },
    {
        "City": "Leon",
        "Country": "Spain",
        "Code": "LEN"
    },
    {
        "City": "Lerwick, Shetland Islands",
        "Country": "Scotland",
        "Code": "LSI"
    },
    {
        "City": "Lethbridge, AB",
        "Country": "Canada",
        "Code": "YQL"
    },
    {
        "City": "Lethbridge, AB ",
        "Country": "Canada ",
        "Code": "YQL"
    },
    {
        "City": "Lewiston, ID ",
        "Country": "USA ",
        "Code": "LWS"
    },
    {
        "City": "Lewiston, ME ",
        "Country": "USA ",
        "Code": "LEW"
    },
    {
        "City": "Lewistown, MT ",
        "Country": "USA ",
        "Code": "LWT"
    },
    {
        "City": "Lexington, KY ",
        "Country": "USA ",
        "Code": "LEX"
    },
    {
        "City": "Lhasa, Tibet",
        "Country": "China",
        "Code": "LXA"
    },
    {
        "City": "Liberal, KS",
        "Country": "USA ",
        "Code": "LBL"
    },
    {
        "City": "Liberia",
        "Country": "Costa Rica",
        "Code": "LIR"
    },
    {
        "City": "Libreville",
        "Country": "Gabon",
        "Code": "LBV"
    },
    {
        "City": "Lihue, Kauai, HI",
        "Country": "USA ",
        "Code": "LIH"
    },
    {
        "City": "Lijiang",
        "Country": "China",
        "Code": "LJG"
    },
    {
        "City": "Lille",
        "Country": "France",
        "Code": "LIL"
    },
    {
        "City": "Lilongwe",
        "Country": "Malawi",
        "Code": "LLW"
    },
    {
        "City": "Lima",
        "Country": "Peru",
        "Code": "LIM"
    },
    {
        "City": "Limoges",
        "Country": "France",
        "Code": "LIG"
    },
    {
        "City": "Lincang",
        "Country": "China",
        "Code": "LNJ"
    },
    {
        "City": "Lincoln, NE ",
        "Country": "USA ",
        "Code": "LNK"
    },
    {
        "City": "Linkoping",
        "Country": "Sweden",
        "Code": "LPI"
    },
    {
        "City": "Linz",
        "Country": "Austria",
        "Code": "LNZ"
    },
    {
        "City": "Lisbon",
        "Country": "Portugal",
        "Code": "LIS"
    },
    {
        "City": "Little Rock, AR ",
        "Country": "USA ",
        "Code": "LIT"
    },
    {
        "City": "Liverpool",
        "Country": "England",
        "Code": "LPL"
    },
    {
        "City": "Ljubljana",
        "Country": "Slovenia",
        "Code": "LJU"
    },
    {
        "City": "Logrono",
        "Country": "Spain",
        "Code": "RJL"
    },
    {
        "City": "Lome",
        "Country": "Togo",
        "Code": "LFW"
    },
    {
        "City": "London",
        "Country": "England",
        "Code": "LCY"
    },
    {
        "City": "London",
        "Country": "England",
        "Code": "LGW"
    },
    {
        "City": "London",
        "Country": "England",
        "Code": "LHR"
    },
    {
        "City": "London",
        "Country": "England",
        "Code": "LON - All Airports"
    },
    {
        "City": "London",
        "Country": "England",
        "Code": "STN"
    },
    {
        "City": "London, ON",
        "Country": "Canada",
        "Code": "YXU"
    },
    {
        "City": "London, ON ",
        "Country": "Canada ",
        "Code": "YXU"
    },
    {
        "City": "Londonderry",
        "Country": "Northern Ireland",
        "Code": "LDY"
    },
    {
        "City": "Long Banga",
        "Country": "Malaysia",
        "Code": "LBP"
    },
    {
        "City": "Long Beach, CA ",
        "Country": "USA ",
        "Code": "LGB"
    },
    {
        "City": "Longreach, Qld.",
        "Country": "Australia",
        "Code": "LRE"
    },
    {
        "City": "Longview, TX ",
        "Country": "USA ",
        "Code": "GGG"
    },
    {
        "City": "Longyearbyen, Svalbard",
        "Country": "Norway",
        "Code": "LYR"
    },
    {
        "City": "Loreto",
        "Country": "Mexico",
        "Code": "LTO"
    },
    {
        "City": "Lorient",
        "Country": "France",
        "Code": "LRT"
    },
    {
        "City": "Los Angeles, CA ",
        "Country": "USA ",
        "Code": "LAX"
    },
    {
        "City": "Los Angeles, CA ",
        "Country": "USA ",
        "Code": "QLA-All"
    },
    {
        "City": "Los Cabos",
        "Country": "Mexico",
        "Code": "SJD"
    },
    {
        "City": "Louisville, KY, USA ",
        "Country": "USA ",
        "Code": "SDF"
    },
    {
        "City": "Lourdes",
        "Country": "France",
        "Code": "LDE"
    },
    {
        "City": "Luanda",
        "Country": "Angola",
        "Code": "LAD"
    },
    {
        "City": "Lubbock, TX ",
        "Country": "USA ",
        "Code": "LBB"
    },
    {
        "City": "Lubumbashi",
        "Country": "Zaire",
        "Code": "FBM"
    },
    {
        "City": "Lugano",
        "Country": "Switzerland",
        "Code": "LUG"
    },
    {
        "City": "Lugansk",
        "Country": "Ukraine",
        "Code": "VSG"
    },
    {
        "City": "Lukla",
        "Country": "Nepal",
        "Code": "LUA"
    },
    {
        "City": "Lulea",
        "Country": "Sweden",
        "Code": "LLA"
    },
    {
        "City": "Lusaka",
        "Country": "Zambia",
        "Code": "LUN"
    },
    {
        "City": "Luton",
        "Country": "England",
        "Code": "LTN"
    },
    {
        "City": "Luxembourg",
        "Country": "Luxembourg",
        "Code": "LUX"
    },
    {
        "City": "Luxor",
        "Country": "Egypt",
        "Code": "LXR"
    },
    {
        "City": "Lycksele",
        "Country": "Sweden",
        "Code": "LYC"
    },
    {
        "City": "Lynchburg, VA ",
        "Country": "USA ",
        "Code": "LYH"
    },
    {
        "City": "Lyon",
        "Country": "France",
        "Code": "LYS"
    },
    {
        "City": "Mackay, Qld.",
        "Country": "Australia",
        "Code": "MKY"
    },
    {
        "City": "Macon, GA ",
        "Country": "USA ",
        "Code": "MCN"
    },
    {
        "City": "Madison, WI ",
        "Country": "USA ",
        "Code": "MSN"
    },
    {
        "City": "Madrid",
        "Country": "Spain",
        "Code": "MAD"
    },
    {
        "City": "Mae Hong Son",
        "Country": "Thailand",
        "Code": "HGN"
    },
    {
        "City": "Magdalens Islands, PQ",
        "Country": "",
        "Code": ""
    },
    {
        "City": "Mahebourg",
        "Country": "Mauritius",
        "Code": "MRU"
    },
    {
        "City": "Majuro",
        "Country": "Marshall Islands",
        "Code": "MAJ"
    },
    {
        "City": "Mala Mala",
        "Country": "South Africa",
        "Code": "AAM"
    },
    {
        "City": "Malabo, Bioko Island",
        "Country": "Equatorial Guinea",
        "Code": "SSG"
    },
    {
        "City": "Malaga",
        "Country": "Spain",
        "Code": "AGP"
    },
    {
        "City": "Male",
        "Country": "Maldives",
        "Code": "MLE"
    },
    {
        "City": "Malmo",
        "Country": "Sweden",
        "Code": "MMA - All Airports"
    },
    {
        "City": "Malmo",
        "Country": "Sweden",
        "Code": "MMX"
    },
    {
        "City": "Managua",
        "Country": "Nicaragua",
        "Code": "MGA"
    },
    {
        "City": "Manama",
        "Country": "Bahrain",
        "Code": "BAH"
    },
    {
        "City": "Manaus",
        "Country": "Brazil",
        "Code": "MAO"
    },
    {
        "City": "Manchester",
        "Country": "England",
        "Code": "MAN"
    },
    {
        "City": "Manchester, NH ",
        "Country": "USA ",
        "Code": "MHT"
    },
    {
        "City": "Mangrove Cay, Andros Island",
        "Country": "Bahamas",
        "Code": "MAY"
    },
    {
        "City": "Manhattan, KS ",
        "Country": "USA ",
        "Code": "MHK"
    },
    {
        "City": "Manila",
        "Country": "Philippines",
        "Code": "MNL"
    },
    {
        "City": "Manistee, MI ",
        "Country": "USA ",
        "Code": "MBL"
    },
    {
        "City": "Manzanillo",
        "Country": "Mexico",
        "Code": "ZLO"
    },
    {
        "City": "Manzini",
        "Country": "Swaziland",
        "Code": "MTS"
    },
    {
        "City": "Mao, Minorca",
        "Country": "Spain",
        "Code": "MAH"
    },
    {
        "City": "Maputo",
        "Country": "Mozambique",
        "Code": "MPM"
    },
    {
        "City": "Maracaibo",
        "Country": "Venezuela",
        "Code": "MAR"
    },
    {
        "City": "Mariehamn, Aland",
        "Country": "Finland",
        "Code": "MHQ"
    },
    {
        "City": "Marilia",
        "Country": "Brazil",
        "Code": "MII"
    },
    {
        "City": "Marion, IL ",
        "Country": "USA ",
        "Code": "MWA"
    },
    {
        "City": "Mariupol",
        "Country": "Ukraine",
        "Code": "MPW"
    },
    {
        "City": "Maroochydore, Qld.",
        "Country": "Australia",
        "Code": "MCY"
    },
    {
        "City": "Marquette, MI ",
        "Country": "USA ",
        "Code": "MQT"
    },
    {
        "City": "Marrakech",
        "Country": "Morocco",
        "Code": "RAK"
    },
    {
        "City": "Marrakech",
        "Country": "Morocco",
        "Code": "RAK"
    },
    {
        "City": "Marseille",
        "Country": "France",
        "Code": "MRS"
    },
    {
        "City": "Marsh Harbour, Abaco Island",
        "Country": "Bahamas",
        "Code": "MHH"
    },
    {
        "City": "Martha's Vineyard, MA ",
        "Country": "USA ",
        "Code": "MVY"
    },
    {
        "City": "Maseru",
        "Country": "Lesotho",
        "Code": "MSU"
    },
    {
        "City": "Mason, IA ",
        "Country": "USA ",
        "Code": "MCW"
    },
    {
        "City": "Massena, NY ",
        "Country": "USA ",
        "Code": "MSS"
    },
    {
        "City": "Matsu",
        "Country": "Taiwan",
        "Code": "MFK"
    },
    {
        "City": "Matsuyama",
        "Country": "Japan",
        "Code": "MYJ"
    },
    {
        "City": "Mazatlan",
        "Country": "Mexico",
        "Code": "MZT"
    },
    {
        "City": "McAllen, TX ",
        "Country": "USA ",
        "Code": "MFE"
    },
    {
        "City": "McCook, NE ",
        "Country": "USA ",
        "Code": "MCK"
    },
    {
        "City": "Medan, Sumatra",
        "Country": "Indonesia",
        "Code": "MES"
    },
    {
        "City": "Medellin",
        "Country": "Colombia",
        "Code": "MDE"
    },
    {
        "City": "Medford, OR ",
        "Country": "USA ",
        "Code": "MFR"
    },
    {
        "City": "Medicine Hat, AB",
        "Country": "Canada",
        "Code": "YXH"
    },
    {
        "City": "Medicine Hat, AB ",
        "Country": "Canada ",
        "Code": "YXH"
    },
    {
        "City": "Melbourne, FL ",
        "Country": "USA ",
        "Code": "MLB"
    },
    {
        "City": "Melbourne, Vic.",
        "Country": "Australia",
        "Code": "AVV"
    },
    {
        "City": "Melbourne, Vic.",
        "Country": "Australia",
        "Code": "MEL"
    },
    {
        "City": "Melilla",
        "Country": "Spain",
        "Code": "MLN"
    },
    {
        "City": "Memanbetsu",
        "Country": "Japan",
        "Code": "MMB"
    },
    {
        "City": "Memphis, TN ",
        "Country": "USA ",
        "Code": "MEM"
    },
    {
        "City": "Mendoza",
        "Country": "Argentina",
        "Code": "MDZ"
    },
    {
        "City": "Merida",
        "Country": "Mexico",
        "Code": "MID"
    },
    {
        "City": "Meridian, MS ",
        "Country": "USA ",
        "Code": "MEI"
    },
    {
        "City": "Merimbula, N.S.W.",
        "Country": "Australia",
        "Code": "MIM"
    },
    {
        "City": "Metz",
        "Country": "France",
        "Code": "ETZ"
    },
    {
        "City": "Mexicali",
        "Country": "Mexico",
        "Code": "MXL"
    },
    {
        "City": "Mexico",
        "Country": "Mexico",
        "Code": "MEX"
    },
    {
        "City": "Miami, FL ",
        "Country": "USA ",
        "Code": "MIA"
    },
    {
        "City": "Mian Yang",
        "Country": "China",
        "Code": "MIG"
    },
    {
        "City": "Midland, TX ",
        "Country": "USA ",
        "Code": "MAF"
    },
    {
        "City": "Mikonos",
        "Country": "Greece",
        "Code": "JMK"
    },
    {
        "City": "Milan",
        "Country": "Italy",
        "Code": "LIN"
    },
    {
        "City": "Milan",
        "Country": "Italy",
        "Code": "MIL - All"
    },
    {
        "City": "Milan",
        "Country": "Italy",
        "Code": "MXP"
    },
    {
        "City": "Mildura, Vic.",
        "Country": "Australia",
        "Code": "MQL"
    },
    {
        "City": "Miles, MT",
        "Country": "USA ",
        "Code": "MLS"
    },
    {
        "City": "Milwaukee, WI ",
        "Country": "USA ",
        "Code": "MKE"
    },
    {
        "City": "Minneapolis, MN ",
        "Country": "USA ",
        "Code": "MSP"
    },
    {
        "City": "Minot, ND ",
        "Country": "USA ",
        "Code": "MOT"
    },
    {
        "City": "Minsk",
        "Country": "Belarus",
        "Code": "MHP"
    },
    {
        "City": "Minsk",
        "Country": "Belarus",
        "Code": "MSQ"
    },
    {
        "City": "Miri, Sarawak",
        "Country": "Malaysia",
        "Code": "MYY"
    },
    {
        "City": "Misawa",
        "Country": "Japan",
        "Code": "MSJ"
    },
    {
        "City": "Missoula, MT ",
        "Country": "USA ",
        "Code": "MSO"
    },
    {
        "City": "Miyazaki",
        "Country": "Japan",
        "Code": "KMI"
    },
    {
        "City": "Mobile, AL ",
        "Country": "USA ",
        "Code": "MOB"
    },
    {
        "City": "Modesto, CA ",
        "Country": "USA ",
        "Code": "MOD"
    },
    {
        "City": "Mogadishu",
        "Country": "Somalia",
        "Code": "MGQ"
    },
    {
        "City": "Moline, IL ",
        "Country": "USA ",
        "Code": "MLI"
    },
    {
        "City": "Mombasa",
        "Country": "Kenya",
        "Code": "MBA"
    },
    {
        "City": "Monastir",
        "Country": "Tunisia",
        "Code": "MIR"
    },
    {
        "City": "Monclova",
        "Country": "Mexico",
        "Code": "LOV"
    },
    {
        "City": "Moncton, NB",
        "Country": "Canada",
        "Code": "YQM"
    },
    {
        "City": "Moncton, NB ",
        "Country": "Canada ",
        "Code": "YQM"
    },
    {
        "City": "Monroe, LA ",
        "Country": "USA ",
        "Code": "MLU"
    },
    {
        "City": "Monrovia",
        "Country": "Liberia",
        "Code": "ROB"
    },
    {
        "City": "Mont-Joli, PQ",
        "Country": "Canada",
        "Code": "YYY"
    },
    {
        "City": "Mont-Joli, PQ ",
        "Country": "Canada ",
        "Code": "YYY"
    },
    {
        "City": "Montego Bay",
        "Country": "Jamaica",
        "Code": "MBJ"
    },
    {
        "City": "Monterey, CA ",
        "Country": "USA ",
        "Code": "MRY"
    },
    {
        "City": "Monterrey",
        "Country": "Mexico",
        "Code": "MTY"
    },
    {
        "City": "Montevideo",
        "Country": "Uruguay",
        "Code": "MVD"
    },
    {
        "City": "Montgomery, AL ",
        "Country": "USA ",
        "Code": "MGM"
    },
    {
        "City": "Montpellier",
        "Country": "France",
        "Code": "MPL"
    },
    {
        "City": "Montreal, PQ",
        "Country": "Canada",
        "Code": "YMQ-All"
    },
    {
        "City": "Montreal, PQ",
        "Country": "Canada",
        "Code": "YUL"
    },
    {
        "City": "Montreal, PQ ",
        "Country": "Canada ",
        "Code": "YMQ All "
    },
    {
        "City": "Montreal, PQ ",
        "Country": "Canada ",
        "Code": "YUL"
    },
    {
        "City": "Montrose, CO ",
        "Country": "USA ",
        "Code": "MTJ"
    },
    {
        "City": "Mora",
        "Country": "Sweden",
        "Code": "MXX"
    },
    {
        "City": "Moree, N.S.W.",
        "Country": "Australia",
        "Code": "MRZ"
    },
    {
        "City": "Morelia",
        "Country": "Mexico",
        "Code": "MLM"
    },
    {
        "City": "Morgantown, WV ",
        "Country": "USA ",
        "Code": "MGW"
    },
    {
        "City": "Moroni",
        "Country": "Comoro Islands",
        "Code": "HAH"
    },
    {
        "City": "Moruya, N.S.W.",
        "Country": "Australia",
        "Code": "MYA"
    },
    {
        "City": "Moscow",
        "Country": "Russia",
        "Code": "MOW All"
    },
    {
        "City": "Moscow",
        "Country": "Russia",
        "Code": "SVO"
    },
    {
        "City": "Moscow",
        "Country": "Russia",
        "Code": "VKO"
    },
    {
        "City": "Moses Lake, WA",
        "Country": "USA ",
        "Code": "MWH"
    },
    {
        "City": "Mount Gambier, S.A.",
        "Country": "Australia",
        "Code": "MGB"
    },
    {
        "City": "Mount Isa, Qld.",
        "Country": "Australia",
        "Code": "ISA"
    },
    {
        "City": "Mudanjiang",
        "Country": "China",
        "Code": "MDG"
    },
    {
        "City": "Mulhouse",
        "Country": "France",
        "Code": "EAP - All"
    },
    {
        "City": "Mulhouse",
        "Country": "France",
        "Code": "MLH"
    },
    {
        "City": "Mulu",
        "Country": "Malaysia",
        "Code": "MZV"
    },
    {
        "City": "Mumbai (Bombay)",
        "Country": "India",
        "Code": "BOM"
    },
    {
        "City": "Munich",
        "Country": "Germany",
        "Code": "MUC"
    },
    {
        "City": "Munster",
        "Country": "Germany",
        "Code": "FMO"
    },
    {
        "City": "Murcia",
        "Country": "Spain",
        "Code": "MJV"
    },
    {
        "City": "Murmansk",
        "Country": "Russia",
        "Code": "MMK"
    },
    {
        "City": "Muscat",
        "Country": "Oman",
        "Code": "MCT"
    },
    {
        "City": "Muscle Shoals, AL",
        "Country": "USA ",
        "Code": "MSL"
    },
    {
        "City": "Muskegon, MI ",
        "Country": "USA ",
        "Code": "MKG"
    },
    {
        "City": "Myrtle Beach, SC ",
        "Country": "USA ",
        "Code": "MYR"
    },
    {
        "City": "Mytilene, Lesbos Island",
        "Country": "Greece",
        "Code": "MJT"
    },
    {
        "City": "N'Djamena",
        "Country": "Chad",
        "Code": "NDJ"
    },
    {
        "City": "Nadi",
        "Country": "Fiji",
        "Code": "NAN"
    },
    {
        "City": "Nador",
        "Country": "Morocco",
        "Code": "NDR"
    },
    {
        "City": "Nagasaki",
        "Country": "Japan",
        "Code": "NGS"
    },
    {
        "City": "Nagoya",
        "Country": "Japan",
        "Code": "NGO"
    },
    {
        "City": "Nagoya",
        "Country": "Japan",
        "Code": "NKM"
    },
    {
        "City": "Naha, Okinawa",
        "Country": "Japan",
        "Code": "OKA"
    },
    {
        "City": "Nairobi",
        "Country": "Kenya",
        "Code": "NBO"
    },
    {
        "City": "Nakhon Si Thammarat",
        "Country": "Thailand",
        "Code": "NST"
    },
    {
        "City": "Nanaimo, BC",
        "Country": "Canada",
        "Code": "YCD"
    },
    {
        "City": "Nanaimo, BC ",
        "Country": "Canada ",
        "Code": "YCD"
    },
    {
        "City": "Nanchang",
        "Country": "China",
        "Code": "KHN"
    },
    {
        "City": "Nangen",
        "Country": "Taiwan",
        "Code": "LZN"
    },
    {
        "City": "Nanjing",
        "Country": "China",
        "Code": "NKG"
    },
    {
        "City": "Nanki Shirahama",
        "Country": "Japan",
        "Code": "SHM"
    },
    {
        "City": "Nanning",
        "Country": "China",
        "Code": "NNG"
    },
    {
        "City": "Nantes",
        "Country": "France",
        "Code": "NTE"
    },
    {
        "City": "Nantucket, MA ",
        "Country": "USA ",
        "Code": "ACK"
    },
    {
        "City": "Napier",
        "Country": "New Zealand",
        "Code": "NPE"
    },
    {
        "City": "Naples",
        "Country": "Italy",
        "Code": "NAP"
    },
    {
        "City": "Naples, FL ",
        "Country": "USA ",
        "Code": "APF"
    },
    {
        "City": "Narrabri, N.S.W.",
        "Country": "Australia",
        "Code": "NAA"
    },
    {
        "City": "Narrandera, N.S.W.",
        "Country": "Australia",
        "Code": "NRA"
    },
    {
        "City": "Narvik",
        "Country": "Norway",
        "Code": "NVK"
    },
    {
        "City": "Naryan Mar",
        "Country": "Russia",
        "Code": "NNM"
    },
    {
        "City": "Nashville, TN ",
        "Country": "USA ",
        "Code": "BNA"
    },
    {
        "City": "Nassau",
        "Country": "Bahamas",
        "Code": "NAS"
    },
    {
        "City": "Nassau",
        "Country": "Bahamas",
        "Code": "PID"
    },
    {
        "City": "Naze, Amami-O Island",
        "Country": "Japan",
        "Code": "ASJ"
    },
    {
        "City": "Nelson",
        "Country": "New Zealand",
        "Code": "NSN"
    },
    {
        "City": "Nelspruit",
        "Country": "South Africa",
        "Code": "MQP"
    },
    {
        "City": "New Bern, NC ",
        "Country": "USA ",
        "Code": "EWN"
    },
    {
        "City": "New Haven, CT ",
        "Country": "USA ",
        "Code": "HVN"
    },
    {
        "City": "New Orleans, LA ",
        "Country": "USA ",
        "Code": "MSY"
    },
    {
        "City": "New York, NY ",
        "Country": "USA ",
        "Code": "JFK"
    },
    {
        "City": "New York, NY ",
        "Country": "USA ",
        "Code": "LGA"
    },
    {
        "City": "New York, NY ",
        "Country": "USA ",
        "Code": "NYC -All"
    },
    {
        "City": "Newark, NJ ",
        "Country": "USA ",
        "Code": "EWR"
    },
    {
        "City": "Newburgh, NY ",
        "Country": "USA ",
        "Code": "SWF"
    },
    {
        "City": "Newcastle",
        "Country": "England",
        "Code": "NCL"
    },
    {
        "City": "Newcastle, N.S.W.",
        "Country": "Australia",
        "Code": "NTL"
    },
    {
        "City": "Newman, W.A.",
        "Country": "Australia",
        "Code": "ZNE"
    },
    {
        "City": "Newport News, VA ",
        "Country": "USA ",
        "Code": "PHF"
    },
    {
        "City": "Niamey",
        "Country": "Niger",
        "Code": "NIM"
    },
    {
        "City": "Nice",
        "Country": "France",
        "Code": "NCE"
    },
    {
        "City": "Niigata",
        "Country": "Japan",
        "Code": "KIJ"
    },
    {
        "City": "Ningbo",
        "Country": "China",
        "Code": "NGB"
    },
    {
        "City": "Nizhniy Novgorod",
        "Country": "Russia",
        "Code": "GOJ"
    },
    {
        "City": "Nome, AK ",
        "Country": "USA ",
        "Code": "OME"
    },
    {
        "City": "Norfolk, VA ",
        "Country": "USA ",
        "Code": "ORF"
    },
    {
        "City": "Norrkoping",
        "Country": "Sweden",
        "Code": "NRK"
    },
    {
        "City": "North Bay, ON",
        "Country": "Canada",
        "Code": "YYB"
    },
    {
        "City": "North Bay, ON ",
        "Country": "Canada ",
        "Code": "YYB"
    },
    {
        "City": "North Bend, OR ",
        "Country": "USA ",
        "Code": "OTH"
    },
    {
        "City": "North Eleuthera",
        "Country": "Bahamas",
        "Code": "ELH"
    },
    {
        "City": "North Platte, NE ",
        "Country": "USA ",
        "Code": "LBF"
    },
    {
        "City": "Norwich",
        "Country": "England",
        "Code": "NWI"
    },
    {
        "City": "Nouakchott",
        "Country": "Mauritania",
        "Code": "NKC"
    },
    {
        "City": "Noumea",
        "Country": "New Caledonia",
        "Code": "GEA"
    },
    {
        "City": "Noumea",
        "Country": "New Caledonia",
        "Code": "NOU"
    },
    {
        "City": "Novosibirsk, Siberia",
        "Country": "Russia",
        "Code": "OVB"
    },
    {
        "City": "Nuevo Laredo",
        "Country": "Mexico",
        "Code": "NLD"
    },
    {
        "City": "Nuremberg",
        "Country": "Germany",
        "Code": "NUE"
    },
    {
        "City": "Oakland, CA ",
        "Country": "USA ",
        "Code": "OAK"
    },
    {
        "City": "Oaxaca",
        "Country": "Mexico",
        "Code": "OAX"
    },
    {
        "City": "Obihiro",
        "Country": "Japan",
        "Code": "OBO"
    },
    {
        "City": "Ocho Rios",
        "Country": "Jamaica",
        "Code": "OCJ"
    },
    {
        "City": "Odate Noshiro",
        "Country": "Japan",
        "Code": "ONJ"
    },
    {
        "City": "Odessa",
        "Country": "Ukraine",
        "Code": "ODS"
    },
    {
        "City": "Ogdensburg, NY ",
        "Country": "USA ",
        "Code": "OGS"
    },
    {
        "City": "Oita",
        "Country": "Japan",
        "Code": "OIT"
    },
    {
        "City": "Oklahoma, OK ",
        "Country": "USA ",
        "Code": "OKC"
    },
    {
        "City": "Olbia, Sardinia",
        "Country": "Italy",
        "Code": "OLB"
    },
    {
        "City": "Olympic Dam, S.A.",
        "Country": "Australia",
        "Code": "OLP"
    },
    {
        "City": "Omaha, NE ",
        "Country": "USA ",
        "Code": "OMA"
    },
    {
        "City": "Omsk, Siberia",
        "Country": "Russia",
        "Code": "OMS"
    },
    {
        "City": "Ontario, CA ",
        "Country": "USA ",
        "Code": "ONT"
    },
    {
        "City": "Orange County, CA ",
        "Country": "USA ",
        "Code": "SNA"
    },
    {
        "City": "Orange, N.S.W.",
        "Country": "Australia",
        "Code": "OAG"
    },
    {
        "City": "Oranjestad",
        "Country": "Aruba",
        "Code": "AUA"
    },
    {
        "City": "Oranjestad",
        "Country": "St. Eustatius",
        "Code": "EUX"
    },
    {
        "City": "Orebro",
        "Country": "Sweden",
        "Code": "ORB"
    },
    {
        "City": "Orlando, FL ",
        "Country": "USA ",
        "Code": "MCO"
    },
    {
        "City": "Ornskoldsvik",
        "Country": "Sweden",
        "Code": "OER"
    },
    {
        "City": "Osaka",
        "Country": "Japan",
        "Code": "ITM"
    },
    {
        "City": "Osaka",
        "Country": "Japan",
        "Code": "KIX"
    },
    {
        "City": "Osaka",
        "Country": "Japan",
        "Code": "OSA - All Airports"
    },
    {
        "City": "Oshkosh, WI ",
        "Country": "USA ",
        "Code": "OSH"
    },
    {
        "City": "Oslo",
        "Country": "Norway",
        "Code": "OSL"
    },
    {
        "City": "Osorno",
        "Country": "Chile",
        "Code": "ZOS"
    },
    {
        "City": "Ostersund",
        "Country": "Sweden",
        "Code": "OSD"
    },
    {
        "City": "Ostrava",
        "Country": "Czech Republic",
        "Code": "OSR"
    },
    {
        "City": "Ottawa, ON",
        "Country": "Canada",
        "Code": "YOW"
    },
    {
        "City": "Ottawa, ON ",
        "Country": "Canada ",
        "Code": "YOW"
    },
    {
        "City": "Ouagadougou",
        "Country": "Burkina Faso",
        "Code": "OUA"
    },
    {
        "City": "Oulu",
        "Country": "Finland",
        "Code": "OUL"
    },
    {
        "City": "Owensboro, KY ",
        "Country": "USA ",
        "Code": "OWB"
    },
    {
        "City": "Oxnard, CA ",
        "Country": "USA ",
        "Code": "OXR"
    },
    {
        "City": "Paderborn",
        "Country": "Germany",
        "Code": "PAD"
    },
    {
        "City": "Paducah, KY ",
        "Country": "USA ",
        "Code": "PAH"
    },
    {
        "City": "Page, AZ",
        "Country": "USA ",
        "Code": "PGA"
    },
    {
        "City": "Pago Pago, Tutuila",
        "Country": "American Samoa",
        "Code": "PPG"
    },
    {
        "City": "Pajala",
        "Country": "Sweden",
        "Code": "PJA"
    },
    {
        "City": "Palembang, Sumatra",
        "Country": "Indonesia",
        "Code": "PLM"
    },
    {
        "City": "Palermo, Sicily",
        "Country": "Italy",
        "Code": "PMO"
    },
    {
        "City": "Palm Springs, CA ",
        "Country": "USA ",
        "Code": "PSP"
    },
    {
        "City": "Palma, Mallorca",
        "Country": "Spain",
        "Code": "PMI"
    },
    {
        "City": "Palmas",
        "Country": "Brazil",
        "Code": "PMW"
    },
    {
        "City": "Palmerston North",
        "Country": "New Zealand",
        "Code": "PMR"
    },
    {
        "City": "Pamplona",
        "Country": "Spain",
        "Code": "PNA"
    },
    {
        "City": "Panama",
        "Country": "Panama",
        "Code": "PTY"
    },
    {
        "City": "Panama, FL ",
        "Country": "USA ",
        "Code": "PFN"
    },
    {
        "City": "Papeete, Tahiti",
        "Country": "French Polynesia",
        "Code": "PPT"
    },
    {
        "City": "Paphos",
        "Country": "Cyprus",
        "Code": "PFO"
    },
    {
        "City": "Paraburdoo, W.A.",
        "Country": "Australia",
        "Code": "PBO"
    },
    {
        "City": "Paramaribo",
        "Country": "Suriname",
        "Code": "PBM"
    },
    {
        "City": "Paris",
        "Country": "France",
        "Code": "CDG"
    },
    {
        "City": "Paris",
        "Country": "France",
        "Code": "ORY"
    },
    {
        "City": "Paris",
        "Country": "France",
        "Code": "PAR -All"
    },
    {
        "City": "Parkersburg, WV ",
        "Country": "USA ",
        "Code": "PKB"
    },
    {
        "City": "Parkes, N.S.W.",
        "Country": "Australia",
        "Code": "PKE"
    },
    {
        "City": "Pasco, WA ",
        "Country": "USA ",
        "Code": "PSC"
    },
    {
        "City": "Pau",
        "Country": "France",
        "Code": "PUF"
    },
    {
        "City": "Pellston, MI ",
        "Country": "USA ",
        "Code": "PLN"
    },
    {
        "City": "Penang, Penang Island",
        "Country": "Malaysia",
        "Code": "PEN"
    },
    {
        "City": "Pendleton, OR ",
        "Country": "USA ",
        "Code": "PDT"
    },
    {
        "City": "Pensacola, FL ",
        "Country": "USA ",
        "Code": "PNS"
    },
    {
        "City": "Penticton, BC",
        "Country": "Canada",
        "Code": "YYF"
    },
    {
        "City": "Penticton, BC ",
        "Country": "Canada ",
        "Code": "YYF"
    },
    {
        "City": "Peoria, IL ",
        "Country": "USA ",
        "Code": "PIA"
    },
    {
        "City": "Perpignan",
        "Country": "France",
        "Code": "PGF"
    },
    {
        "City": "Perth, W.A.",
        "Country": "Australia",
        "Code": "PER"
    },
    {
        "City": "Perugia",
        "Country": "Italy",
        "Code": "PEG"
    },
    {
        "City": "Peshawar",
        "Country": "Pakistan",
        "Code": "PEW"
    },
    {
        "City": "Petropavlovsk-Kamchatsky, Siberia",
        "Country": "Russia",
        "Code": "PKC"
    },
    {
        "City": "Philadelphia, PA ",
        "Country": "USA ",
        "Code": "PHL"
    },
    {
        "City": "Philipsburg",
        "Country": "St. Maarten",
        "Code": "SXM"
    },
    {
        "City": "Phitsanulok",
        "Country": "Thailand",
        "Code": "PHS"
    },
    {
        "City": "Phnom Penh",
        "Country": "Cambodia",
        "Code": "PNH"
    },
    {
        "City": "Phoenix, AZ ",
        "Country": "USA ",
        "Code": "PHX"
    },
    {
        "City": "Phuket",
        "Country": "Thailand",
        "Code": "HKT"
    },
    {
        "City": "Piedras Negras",
        "Country": "Mexico",
        "Code": "PDS"
    },
    {
        "City": "Pierre, SD ",
        "Country": "USA ",
        "Code": "PIR"
    },
    {
        "City": "Pinehurst, NC ",
        "Country": "USA ",
        "Code": "SOP"
    },
    {
        "City": "Pisa",
        "Country": "Italy",
        "Code": "PSA"
    },
    {
        "City": "Pittsburgh, PA ",
        "Country": "USA ",
        "Code": "PIT"
    },
    {
        "City": "Placencia",
        "Country": "Belize",
        "Code": "PLJ"
    },
    {
        "City": "Plattsburgh, NY ",
        "Country": "USA ",
        "Code": "PLB"
    },
    {
        "City": "Pleiku",
        "Country": "Vietnam",
        "Code": "PXU"
    },
    {
        "City": "Pocatello, ID ",
        "Country": "USA ",
        "Code": "PIH"
    },
    {
        "City": "Pohnpei",
        "Country": "Micronesia",
        "Code": "PNI"
    },
    {
        "City": "Pointe-A-Pitre, Grande-Terre",
        "Country": "Guadeloupe",
        "Code": "PTP"
    },
    {
        "City": "Ponca, OK ",
        "Country": "USA ",
        "Code": "PNC"
    },
    {
        "City": "Ponta Delgada, Sao Miguel Island",
        "Country": "Azores",
        "Code": "PDL"
    },
    {
        "City": "Port Hardy, BC",
        "Country": "Canada",
        "Code": "YZT"
    },
    {
        "City": "Port Hardy, BC",
        "Country": "Canada ",
        "Code": "YZT"
    },
    {
        "City": "Port Hedland, W.A.",
        "Country": "Australia",
        "Code": "PHE"
    },
    {
        "City": "Port Lincoln, S.A.",
        "Country": "Australia",
        "Code": "PLO"
    },
    {
        "City": "Port Macquarie, N.S.W.",
        "Country": "Australia",
        "Code": "PQQ"
    },
    {
        "City": "Port Moresby",
        "Country": "Papua-New Guinea",
        "Code": "POM"
    },
    {
        "City": "Port of Spain",
        "Country": "Trinidad and Tobago",
        "Code": "POS"
    },
    {
        "City": "Port Vila, Efate Island",
        "Country": "Vanuatu",
        "Code": "VLI"
    },
    {
        "City": "Port-au-Prince",
        "Country": "Haiti",
        "Code": "PAP"
    },
    {
        "City": "Portland, ME ",
        "Country": "USA ",
        "Code": "PWM"
    },
    {
        "City": "Portland, OR ",
        "Country": "USA ",
        "Code": "PDX"
    },
    {
        "City": "Portland, Vic.",
        "Country": "Australia",
        "Code": "PTJ"
    },
    {
        "City": "Porto",
        "Country": "Portugal",
        "Code": "OPO"
    },
    {
        "City": "Porto Alegre",
        "Country": "Brazil",
        "Code": "POA"
    },
    {
        "City": "Porto Santo",
        "Country": "Madeira",
        "Code": "PXO"
    },
    {
        "City": "Portsmouth, NH ",
        "Country": "USA ",
        "Code": "PSM"
    },
    {
        "City": "Powell River, BC",
        "Country": "Canada",
        "Code": "YPW"
    },
    {
        "City": "Powell River, BC ",
        "Country": "Canada ",
        "Code": "YPW"
    },
    {
        "City": "Poznan",
        "Country": "Poland",
        "Code": "POZ"
    },
    {
        "City": "Prague",
        "Country": "Czech Republic",
        "Code": "PRG"
    },
    {
        "City": "Prescott, AZ ",
        "Country": "USA ",
        "Code": "PRC"
    },
    {
        "City": "Presque Isle, ME ",
        "Country": "USA ",
        "Code": "PQI"
    },
    {
        "City": "Prince George, BC",
        "Country": "Canada",
        "Code": "YXS"
    },
    {
        "City": "Prince George, BC ",
        "Country": "Canada ",
        "Code": "YXS"
    },
    {
        "City": "Prince Rupert, BC",
        "Country": "Canada",
        "Code": "YPR"
    },
    {
        "City": "Prince Rupert, BC ",
        "Country": "Canada ",
        "Code": "YPR"
    },
    {
        "City": "Proserpine, Qld.",
        "Country": "Australia",
        "Code": "PPP"
    },
    {
        "City": "Providence, RI ",
        "Country": "USA ",
        "Code": "PVD"
    },
    {
        "City": "Providenciales Island",
        "Country": "Turks and Caicos Islands",
        "Code": "PLS"
    },
    {
        "City": "Provincetown, MA ",
        "Country": "USA ",
        "Code": "PVC"
    },
    {
        "City": "Pucon",
        "Country": "Chile",
        "Code": "ZPC"
    },
    {
        "City": "Puebla",
        "Country": "Mexico",
        "Code": "PBC"
    },
    {
        "City": "Pueblo, CO ",
        "Country": "USA ",
        "Code": "PUB"
    },
    {
        "City": "Puerto del Rosario, Fuerteventura Island",
        "Country": "Canary Islands",
        "Code": "FUE"
    },
    {
        "City": "Puerto Escondido",
        "Country": "Mexico",
        "Code": "PXM"
    },
    {
        "City": "Puerto Montt",
        "Country": "Chile",
        "Code": "PMC"
    },
    {
        "City": "Puerto Plata",
        "Country": "Dominican Republic",
        "Code": "POP"
    },
    {
        "City": "Puerto Princesa",
        "Country": "Philippines",
        "Code": "PPS"
    },
    {
        "City": "Puerto Vallarta",
        "Country": "Mexico",
        "Code": "PVR"
    },
    {
        "City": "Pullman, WA ",
        "Country": "USA ",
        "Code": "PUW"
    },
    {
        "City": "Punta Arenas, Tierra del Fuego",
        "Country": "Chile",
        "Code": "PUQ"
    },
    {
        "City": "Punta Cana",
        "Country": "Dominican Republic",
        "Code": "PUJ"
    },
    {
        "City": "Punta Gorda",
        "Country": "Belize",
        "Code": "PND"
    },
    {
        "City": "Puttaparthi",
        "Country": "India",
        "Code": "PUT"
    },
    {
        "City": "Pyongyang",
        "Country": "North Korea",
        "Code": "FNJ"
    },
    {
        "City": "Qingdao",
        "Country": "China",
        "Code": "TAO"
    },
    {
        "City": "Quebec, PQ",
        "Country": "Canada",
        "Code": "YQB"
    },
    {
        "City": "Quebec, PQ ",
        "Country": "Canada ",
        "Code": "YQB"
    },
    {
        "City": "Queenstown",
        "Country": "New Zealand",
        "Code": "ZQN"
    },
    {
        "City": "Queretaro",
        "Country": "Mexico",
        "Code": "QRO"
    },
    {
        "City": "Quesnel, BC",
        "Country": "Canada",
        "Code": "YQZ"
    },
    {
        "City": "Quesnel, BC ",
        "Country": "Canada ",
        "Code": "YQZ"
    },
    {
        "City": "Quimper",
        "Country": "France",
        "Code": "UIP"
    },
    {
        "City": "Quincy, IL ",
        "Country": "USA ",
        "Code": "UIN"
    },
    {
        "City": "Quito",
        "Country": "Ecuador",
        "Code": "UIO"
    },
    {
        "City": "Rach Gia",
        "Country": "Vietnam",
        "Code": "VKG"
    },
    {
        "City": "Raleigh, NC ",
        "Country": "USA ",
        "Code": "RDU"
    },
    {
        "City": "Rangoon",
        "Country": "Myanmar (Burma)",
        "Code": "RGN"
    },
    {
        "City": "Rankin Inlet, NWT",
        "Country": "Canada",
        "Code": "YRT"
    },
    {
        "City": "Rankin Inlet, NWT",
        "Country": "Canada ",
        "Code": "YRT"
    },
    {
        "City": "Ranong",
        "Country": "Thailand",
        "Code": "UNN"
    },
    {
        "City": "Rapid, SD ",
        "Country": "USA ",
        "Code": "RAP"
    },
    {
        "City": "Recife",
        "Country": "Brazil",
        "Code": "REC"
    },
    {
        "City": "Red Lake, ON",
        "Country": "Canada",
        "Code": "YRL"
    },
    {
        "City": "Red Lake, ON ",
        "Country": "Canada ",
        "Code": "YRL"
    },
    {
        "City": "Redding, CA ",
        "Country": "USA ",
        "Code": "RDD"
    },
    {
        "City": "Redmond, OR ",
        "Country": "USA ",
        "Code": "RDM"
    },
    {
        "City": "Reggio di Calabria",
        "Country": "Italy",
        "Code": "REG"
    },
    {
        "City": "Regina, SK",
        "Country": "Canada",
        "Code": "YQR"
    },
    {
        "City": "Regina, SK ",
        "Country": "Canada ",
        "Code": "YQR"
    },
    {
        "City": "Rennes",
        "Country": "France",
        "Code": "RNS"
    },
    {
        "City": "Reno, NV ",
        "Country": "USA ",
        "Code": "RNO"
    },
    {
        "City": "Reykjavik",
        "Country": "Iceland",
        "Code": "KEF"
    },
    {
        "City": "Reykjavik",
        "Country": "Iceland",
        "Code": "REK - All Airports"
    },
    {
        "City": "Reykjavik",
        "Country": "Iceland",
        "Code": "RKV"
    },
    {
        "City": "Reynosa",
        "Country": "Mexico",
        "Code": "REX"
    },
    {
        "City": "Rhinelander, WI",
        "Country": "USA ",
        "Code": "RHI"
    },
    {
        "City": "Rhodes",
        "Country": "Greece",
        "Code": "RHO"
    },
    {
        "City": "Richmond, VA ",
        "Country": "USA ",
        "Code": "RIC"
    },
    {
        "City": "Riga",
        "Country": "Latvia",
        "Code": "RIX"
    },
    {
        "City": "Rimini",
        "Country": "Italy",
        "Code": "RMI"
    },
    {
        "City": "Rio de Janeiro",
        "Country": "Brazil",
        "Code": "GIG"
    },
    {
        "City": "Rio de Janeiro",
        "Country": "Brazil",
        "Code": "RIO - All Airports"
    },
    {
        "City": "Rio de Janeiro",
        "Country": "Brazil",
        "Code": "SDU"
    },
    {
        "City": "Riverton, WY ",
        "Country": "USA ",
        "Code": "RIW"
    },
    {
        "City": "Riyadh",
        "Country": "Saudi Arabia",
        "Code": "RUH"
    },
    {
        "City": "Roanoke, VA ",
        "Country": "USA ",
        "Code": "ROA"
    },
    {
        "City": "Roberval, PQ",
        "Country": "Canada",
        "Code": "YRJ"
    },
    {
        "City": "Roberval, PQ ",
        "Country": "Canada ",
        "Code": "YRJ"
    },
    {
        "City": "Rochester, MN ",
        "Country": "USA ",
        "Code": "RST"
    },
    {
        "City": "Rochester, NY ",
        "Country": "USA ",
        "Code": "ROC"
    },
    {
        "City": "Rock Sound",
        "Country": "Bahamas",
        "Code": "RSD"
    },
    {
        "City": "Rock Springs, WY ",
        "Country": "USA ",
        "Code": "RKS"
    },
    {
        "City": "Rockford, IL ",
        "Country": "USA ",
        "Code": "RFD"
    },
    {
        "City": "Rockhampton, Qld.",
        "Country": "Australia",
        "Code": "ROK"
    },
    {
        "City": "Rockland, ME ",
        "Country": "USA ",
        "Code": "RKD"
    },
    {
        "City": "Rodez",
        "Country": "France",
        "Code": "RDZ"
    },
    {
        "City": "Roma, Qld.",
        "Country": "Australia",
        "Code": "RMA"
    },
    {
        "City": "Rome",
        "Country": "Italy",
        "Code": "CIA"
    },
    {
        "City": "Rome",
        "Country": "Italy (FCO-Leonardo Da Vinci",
        "Code": "FCO"
    },
    {
        "City": "Rome",
        "Country": "Italy",
        "Code": "ROM-All"
    },
    {
        "City": "Ronne",
        "Country": "Denmark",
        "Code": "RNN"
    },
    {
        "City": "Ronneby",
        "Country": "Sweden",
        "Code": "RNB"
    },
    {
        "City": "Roseau",
        "Country": "Dominica",
        "Code": "DOM"
    },
    {
        "City": "Rostov",
        "Country": "Russia",
        "Code": "ROV"
    },
    {
        "City": "Roswell, NM ",
        "Country": "USA ",
        "Code": "ROW"
    },
    {
        "City": "Rotorua",
        "Country": "New Zealand",
        "Code": "ROT"
    },
    {
        "City": "Rotterdam",
        "Country": "Netherlands",
        "Code": "RTM"
    },
    {
        "City": "Rouen",
        "Country": "France",
        "Code": "URO"
    },
    {
        "City": "Rouyn-Noranda, PQ",
        "Country": "Canada",
        "Code": "YUY"
    },
    {
        "City": "Rouyn-Noranda, PQ ",
        "Country": "Canada ",
        "Code": "YUY"
    },
    {
        "City": "Rovaniemi",
        "Country": "Finland",
        "Code": "RVN"
    },
    {
        "City": "Roxas",
        "Country": "Philippines",
        "Code": "RXS"
    },
    {
        "City": "Rutland, VT ",
        "Country": "USA ",
        "Code": "RUT"
    },
    {
        "City": "Rzeszow",
        "Country": "Poland",
        "Code": "RZE"
    },
    {
        "City": "Saba Island",
        "Country": "Saba",
        "Code": "SAB"
    },
    {
        "City": "Sacramento, CA ",
        "Country": "USA ",
        "Code": "SMF"
    },
    {
        "City": "Saga",
        "Country": "Japan",
        "Code": "HSG"
    },
    {
        "City": "Saginaw, MI ",
        "Country": "USA ",
        "Code": "MBS"
    },
    {
        "City": "Saint John, NB",
        "Country": "Canada",
        "Code": "YSJ"
    },
    {
        "City": "Saint John, NB ",
        "Country": "Canada ",
        "Code": "YSJ"
    },
    {
        "City": "Saint-Denis",
        "Country": "Reunion",
        "Code": "RUN"
    },
    {
        "City": "Saint-Pierre",
        "Country": "Reunion",
        "Code": "ZSE"
    },
    {
        "City": "Saipan",
        "Country": "Northern Mariana Islands",
        "Code": "SPN"
    },
    {
        "City": "Salina, KS ",
        "Country": "USA ",
        "Code": "SLN"
    },
    {
        "City": "Salisbury, MD ",
        "Country": "USA ",
        "Code": "SBY"
    },
    {
        "City": "Salt Lake, UT ",
        "Country": "USA ",
        "Code": "SLC"
    },
    {
        "City": "Saltillo",
        "Country": "Mexico",
        "Code": "SLW"
    },
    {
        "City": "Salvador",
        "Country": "Brazil",
        "Code": "SSA"
    },
    {
        "City": "Salzburg",
        "Country": "Austria",
        "Code": "SZG"
    },
    {
        "City": "Samsun",
        "Country": "Turkey",
        "Code": "SZF"
    },
    {
        "City": "San Andres",
        "Country": "Colombia",
        "Code": "ADZ"
    },
    {
        "City": "San Andros",
        "Country": "Bahamas",
        "Code": "SAQ"
    },
    {
        "City": "San Angelo, TX ",
        "Country": "USA ",
        "Code": "SJT"
    },
    {
        "City": "San Antonio, TX ",
        "Country": "USA ",
        "Code": "SAT"
    },
    {
        "City": "San Carlos de Bariloche",
        "Country": "Argentina",
        "Code": "BRC"
    },
    {
        "City": "San Diego, CA ",
        "Country": "USA ",
        "Code": "SAN"
    },
    {
        "City": "San Francisco, CA ",
        "Country": "USA ",
        "Code": "QSF -All"
    },
    {
        "City": "San Francisco, CA ",
        "Country": "USA ",
        "Code": "SFO"
    },
    {
        "City": "San Jose",
        "Country": "Costa Rica",
        "Code": "SJO"
    },
    {
        "City": "San Jose, CA ",
        "Country": "USA ",
        "Code": "SJC"
    },
    {
        "City": "San Juan",
        "Country": "Puerto Rico",
        "Code": "SJU"
    },
    {
        "City": "San Luis Obispo, CA ",
        "Country": "USA ",
        "Code": "SBP"
    },
    {
        "City": "San Luis Potosi",
        "Country": "Mexico",
        "Code": "SLP"
    },
    {
        "City": "San Salvador",
        "Country": "El Salvador",
        "Code": "SAL"
    },
    {
        "City": "San Sebastian",
        "Country": "Spain",
        "Code": "EAS"
    },
    {
        "City": "Sanaa",
        "Country": "Yemen",
        "Code": "SAH"
    },
    {
        "City": "Sand Point, AK ",
        "Country": "USA ",
        "Code": "SDP"
    },
    {
        "City": "Sandakan, Sabah",
        "Country": "Malaysia",
        "Code": "SDK"
    },
    {
        "City": "Sandefjord",
        "Country": "Norway",
        "Code": "TRF"
    },
    {
        "City": "Sandspit, Queen Charlotte Islands, BC",
        "Country": "Canada",
        "Code": "YZP"
    },
    {
        "City": "Sandspit, Queen Charlotte Islands, BC",
        "Country": "Canada ",
        "Code": "YZP"
    },
    {
        "City": "Santa Barbara, CA ",
        "Country": "USA ",
        "Code": "SBA"
    },
    {
        "City": "Santa Cruz",
        "Country": "Spain",
        "Code": "SPC"
    },
    {
        "City": "Santa Cruz de Tenerife",
        "Country": "Canary Islands",
        "Code": "TFN"
    },
    {
        "City": "Santa Fe, NM ",
        "Country": "USA ",
        "Code": "SAF"
    },
    {
        "City": "Santa Maria, CA ",
        "Country": "USA ",
        "Code": "SMX"
    },
    {
        "City": "Santa Maria, Sal Island",
        "Country": "Cape Verde",
        "Code": "SID"
    },
    {
        "City": "Santa Rosa",
        "Country": "Argentina",
        "Code": "RSA"
    },
    {
        "City": "Santa Rosa, CA ",
        "Country": "USA ",
        "Code": "STS"
    },
    {
        "City": "Santander",
        "Country": "Spain",
        "Code": "SDR"
    },
    {
        "City": "Santiago",
        "Country": "Chile",
        "Code": "SCL"
    },
    {
        "City": "Santiago",
        "Country": "Dominican Republic",
        "Code": "STI"
    },
    {
        "City": "Santo Domingo",
        "Country": "Dominican Republic",
        "Code": "SDQ"
    },
    {
        "City": "Sanya",
        "Country": "China",
        "Code": "SYX"
    },
    {
        "City": "Sao Jose So Rio Preto",
        "Country": "Brazil",
        "Code": "SJP"
    },
    {
        "City": "Sao Paulo",
        "Country": "Brazil",
        "Code": "CGH"
    },
    {
        "City": "Sao Paulo",
        "Country": "Brazil",
        "Code": "GRU"
    },
    {
        "City": "Sao Paulo",
        "Country": "Brazil",
        "Code": "SAO - All Airports"
    },
    {
        "City": "Sapporo",
        "Country": "Japan",
        "Code": "CTS"
    },
    {
        "City": "Sapporo",
        "Country": "Japan",
        "Code": "OKD"
    },
    {
        "City": "Sarajevo",
        "Country": "Bosnia and Herzegovina",
        "Code": "SJJ"
    },
    {
        "City": "Saranac Lake, NY ",
        "Country": "USA ",
        "Code": "SLK"
    },
    {
        "City": "Sarasota, FL ",
        "Country": "USA ",
        "Code": "SRQ"
    },
    {
        "City": "Sarnia, ON",
        "Country": "Canada",
        "Code": "YZR"
    },
    {
        "City": "Sarnia, ON ",
        "Country": "Canada ",
        "Code": "YZR"
    },
    {
        "City": "Saskatoon, SK",
        "Country": "Canada",
        "Code": "YXE"
    },
    {
        "City": "Saskatoon, SK ",
        "Country": "Canada ",
        "Code": "YXE"
    },
    {
        "City": "Sault Ste. Marie, MI",
        "Country": "USA ",
        "Code": "CIU"
    },
    {
        "City": "Sault Ste. Marie, ON",
        "Country": "Canada",
        "Code": "YAM"
    },
    {
        "City": "Sault Ste. Marie, ON",
        "Country": "Canada ",
        "Code": "YAM"
    },
    {
        "City": "Savannah, GA ",
        "Country": "USA ",
        "Code": "SAV"
    },
    {
        "City": "Scottsbluff, NE ",
        "Country": "USA ",
        "Code": "BFF"
    },
    {
        "City": "Seattle, WA ",
        "Country": "USA ",
        "Code": "SEA"
    },
    {
        "City": "Semipalatinsk",
        "Country": "Kazakhstan",
        "Code": "PLX"
    },
    {
        "City": "Seoul, Korea",
        "Country": "Republic of",
        "Code": "GMP"
    },
    {
        "City": "Seoul, Korea",
        "Country": "Republic of",
        "Code": "ICN"
    },
    {
        "City": "Sept-Iles, PQ",
        "Country": "Canada",
        "Code": "YZV"
    },
    {
        "City": "Sept-Iles, PQ",
        "Country": "Canada ",
        "Code": "YZV"
    },
    {
        "City": "Seville",
        "Country": "Spain",
        "Code": "SVQ"
    },
    {
        "City": "Shanghai",
        "Country": "China",
        "Code": "PVG"
    },
    {
        "City": "Shanghai",
        "Country": "China",
        "Code": "SHA"
    },
    {
        "City": "Shannon",
        "Country": "Ireland",
        "Code": "SNN"
    },
    {
        "City": "Shantou",
        "Country": "China",
        "Code": "SWA"
    },
    {
        "City": "Sharjah",
        "Country": "United Arab Emirates",
        "Code": "SHJ"
    },
    {
        "City": "Sharm El Sheikh",
        "Country": "Egypt",
        "Code": "SSH"
    },
    {
        "City": "Shenandoah Valley Airport, VA ",
        "Country": "USA ",
        "Code": "SHD"
    },
    {
        "City": "Shenyang",
        "Country": "China",
        "Code": "SHE"
    },
    {
        "City": "Shenzhen",
        "Country": "China",
        "Code": "SZX"
    },
    {
        "City": "Sheridan, WY ",
        "Country": "USA ",
        "Code": "SHR"
    },
    {
        "City": "Shijiazhuang",
        "Country": "China",
        "Code": "SJW"
    },
    {
        "City": "Shreveport, LA ",
        "Country": "USA ",
        "Code": "SHV"
    },
    {
        "City": "Sibu, Sarawak",
        "Country": "Malaysia",
        "Code": "SBW"
    },
    {
        "City": "Sidney, MT ",
        "Country": "USA ",
        "Code": "SDY"
    },
    {
        "City": "Silver, NM ",
        "Country": "USA ",
        "Code": "SVC"
    },
    {
        "City": "Singapore",
        "Country": "Singapore",
        "Code": "SIN"
    },
    {
        "City": "Sioux, IA ",
        "Country": "USA ",
        "Code": "SUX"
    },
    {
        "City": "Sioux Falls, SD ",
        "Country": "USA ",
        "Code": "FSD"
    },
    {
        "City": "Sioux Lookout, ON",
        "Country": "Canada",
        "Code": "YXL"
    },
    {
        "City": "Sioux Lookout, ON",
        "Country": "Canada ",
        "Code": "YXL"
    },
    {
        "City": "Sitka, AK ",
        "Country": "USA ",
        "Code": "SIT"
    },
    {
        "City": "Skagway, AK ",
        "Country": "USA ",
        "Code": "SGY"
    },
    {
        "City": "Skelleftea",
        "Country": "Sweden",
        "Code": "SFT"
    },
    {
        "City": "Skopje",
        "Country": "Macedonia",
        "Code": "SKP"
    },
    {
        "City": "Smithers, BC",
        "Country": "Canada",
        "Code": "YYD"
    },
    {
        "City": "Smithers, BC",
        "Country": "Canada ",
        "Code": "YYD"
    },
    {
        "City": "Sofia",
        "Country": "Bulgaria",
        "Code": "SOF"
    },
    {
        "City": "Sonderborg",
        "Country": "Denmark",
        "Code": "SGD"
    },
    {
        "City": "Songpan Jiuzhai Huanglong",
        "Country": "China",
        "Code": "JZH"
    },
    {
        "City": "South Andros",
        "Country": "Bahamas",
        "Code": "TZN"
    },
    {
        "City": "South Bend, IN ",
        "Country": "USA ",
        "Code": "SBN"
    },
    {
        "City": "Southampton",
        "Country": "England",
        "Code": "SOU"
    },
    {
        "City": "Split",
        "Country": "Croatia",
        "Code": "SPU"
    },
    {
        "City": "Spokane, WA ",
        "Country": "USA ",
        "Code": "GEG"
    },
    {
        "City": "Springfield, IL ",
        "Country": "USA ",
        "Code": "SPI"
    },
    {
        "City": "Springfield, MA ",
        "Country": "USA ",
        "Code": "CEF"
    },
    {
        "City": "Springfield, MO ",
        "Country": "USA ",
        "Code": "SGF"
    },
    {
        "City": "Springfield, VT ",
        "Country": "USA ",
        "Code": "VSF"
    },
    {
        "City": "St. Cloud, MN ",
        "Country": "USA ",
        "Code": "STC"
    },
    {
        "City": "St. George, UT ",
        "Country": "USA ",
        "Code": "SGU"
    },
    {
        "City": "St. George's",
        "Country": "Grenada",
        "Code": "GND"
    },
    {
        "City": "St. John's",
        "Country": "Antigua",
        "Code": "ANU"
    },
    {
        "City": "St. John's, NF",
        "Country": "Canada",
        "Code": "YYT"
    },
    {
        "City": "St. John's, NF ",
        "Country": "Canada ",
        "Code": "YYT"
    },
    {
        "City": "St. Louis, MO ",
        "Country": "USA ",
        "Code": "STL"
    },
    {
        "City": "St. Petersburg",
        "Country": "Russia",
        "Code": "LED"
    },
    {
        "City": "St. Petersburg, FL ",
        "Country": "USA ",
        "Code": "PIE"
    },
    {
        "City": "State College, PA ",
        "Country": "USA ",
        "Code": "SCE"
    },
    {
        "City": "Stavanger",
        "Country": "Norway",
        "Code": "SVG"
    },
    {
        "City": "Steamboat Springs, CO ",
        "Country": "USA ",
        "Code": "SBS"
    },
    {
        "City": "Stella Maris, Long Island",
        "Country": "Bahamas",
        "Code": "SML"
    },
    {
        "City": "Stockholm",
        "Country": "Sweden",
        "Code": "ARN"
    },
    {
        "City": "Stockholm",
        "Country": "Sweden",
        "Code": "BMA"
    },
    {
        "City": "Stockholm",
        "Country": "Sweden",
        "Code": "STO-All"
    },
    {
        "City": "Stornoway, Isle of Lewis",
        "Country": "Scotland",
        "Code": "SYY"
    },
    {
        "City": "Storuman",
        "Country": "Sweden",
        "Code": "SQO"
    },
    {
        "City": "Strasbourg",
        "Country": "France",
        "Code": "SXB"
    },
    {
        "City": "Stuttgart",
        "Country": "Germany",
        "Code": "STR"
    },
    {
        "City": "Subic Bay",
        "Country": "Philippines",
        "Code": "SFS"
    },
    {
        "City": "Sudbury, ON",
        "Country": "Canada",
        "Code": "YSB"
    },
    {
        "City": "Sudbury, ON ",
        "Country": "Canada ",
        "Code": "YSB"
    },
    {
        "City": "Sukhothai",
        "Country": "Thailand",
        "Code": "THS"
    },
    {
        "City": "Sun Valley, ID ",
        "Country": "USA ",
        "Code": "SUN"
    },
    {
        "City": "Sundsvall",
        "Country": "Sweden",
        "Code": "SDL"
    },
    {
        "City": "Surabaya, Java",
        "Country": "Indonesia",
        "Code": "SUB"
    },
    {
        "City": "Surat Thani",
        "Country": "Thailand",
        "Code": "URT"
    },
    {
        "City": "Suva",
        "Country": "Fiji",
        "Code": "SUV"
    },
    {
        "City": "Sydney, Cape Breton Island, NS",
        "Country": "Canada",
        "Code": "YQY"
    },
    {
        "City": "Sydney, Cape Breton Island, NS",
        "Country": "Canada ",
        "Code": "YQY"
    },
    {
        "City": "Sydney, N.S.W.",
        "Country": "Australia",
        "Code": "SYD"
    },
    {
        "City": "Syracuse, NY ",
        "Country": "USA ",
        "Code": "SYR"
    },
    {
        "City": "Szczecin",
        "Country": "Poland",
        "Code": "SZZ"
    },
    {
        "City": "Taba",
        "Country": "Egypt",
        "Code": "TCP"
    },
    {
        "City": "Tabriz",
        "Country": "Iran",
        "Code": "TBZ"
    },
    {
        "City": "Tacloban",
        "Country": "Philippines",
        "Code": "TAC"
    },
    {
        "City": "Taegu",
        "Country": "South Korea",
        "Code": "TAE"
    },
    {
        "City": "Tagbilaran",
        "Country": "Philippines",
        "Code": "TAG"
    },
    {
        "City": "Taichung",
        "Country": "Taiwan",
        "Code": "RMQ"
    },
    {
        "City": "Taipei",
        "Country": "Taiwan",
        "Code": "TPE"
    },
    {
        "City": "Taipei",
        "Country": "Taiwan",
        "Code": "TSA"
    },
    {
        "City": "Taiyuan",
        "Country": "China",
        "Code": "TYN"
    },
    {
        "City": "Takamatsu",
        "Country": "Japan",
        "Code": "TAK"
    },
    {
        "City": "Tallahassee, FL ",
        "Country": "USA ",
        "Code": "TLH"
    },
    {
        "City": "Tallinn",
        "Country": "Estonia",
        "Code": "TLL"
    },
    {
        "City": "Tampa, FL ",
        "Country": "USA ",
        "Code": "TPA"
    },
    {
        "City": "Tampere",
        "Country": "Finland",
        "Code": "TMP"
    },
    {
        "City": "Tampico",
        "Country": "Mexico",
        "Code": "TAM"
    },
    {
        "City": "Tamworth, N.S.W.",
        "Country": "Australia",
        "Code": "TMW"
    },
    {
        "City": "Tangier",
        "Country": "Morocco",
        "Code": "TNG"
    },
    {
        "City": "Tapachula",
        "Country": "Mexico",
        "Code": "TAP"
    },
    {
        "City": "Tashkent",
        "Country": "Uzbekistan",
        "Code": "TAS"
    },
    {
        "City": "Taupo",
        "Country": "New Zealand",
        "Code": "TUO"
    },
    {
        "City": "Tauranga",
        "Country": "New Zealand",
        "Code": "TRG"
    },
    {
        "City": "Tawau, Sabah",
        "Country": "Malaysia",
        "Code": "TWU"
    },
    {
        "City": "Tbilisi",
        "Country": "Georgia",
        "Code": "TBS"
    },
    {
        "City": "Teesside",
        "Country": "England",
        "Code": "MME"
    },
    {
        "City": "Tegucigalpa",
        "Country": "Honduras",
        "Code": "TGU"
    },
    {
        "City": "Tehran",
        "Country": "Iran",
        "Code": "THR"
    },
    {
        "City": "Tel Aviv",
        "Country": "Israel",
        "Code": "TLV"
    },
    {
        "City": "Telluride, CO",
        "Country": "USA ",
        "Code": "TEX"
    },
    {
        "City": "Temuco",
        "Country": "Chile",
        "Code": "ZCO"
    },
    {
        "City": "Tenerife Island",
        "Country": "Canary Islands",
        "Code": "TCI - All Airports"
    },
    {
        "City": "Tenerife Island",
        "Country": "Canary Islands",
        "Code": "TFS"
    },
    {
        "City": "Tenerife Island",
        "Country": "Canary Islands",
        "Code": "TVI - All Airports"
    },
    {
        "City": "Tepic",
        "Country": "Mexico",
        "Code": "TPQ"
    },
    {
        "City": "Termez",
        "Country": "Uzbekistan",
        "Code": "TMJ"
    },
    {
        "City": "Terrace, BC",
        "Country": "Canada",
        "Code": "YXT"
    },
    {
        "City": "Terrace, BC",
        "Country": "Canada ",
        "Code": "YXT"
    },
    {
        "City": "Texarkana, AR ",
        "Country": "USA ",
        "Code": "TXK"
    },
    {
        "City": "The Valley",
        "Country": "Anguilla",
        "Code": "AXA"
    },
    {
        "City": "Thessaloniki",
        "Country": "Greece",
        "Code": "SKG"
    },
    {
        "City": "Thief River Falls, MN ",
        "Country": "USA ",
        "Code": "TVF"
    },
    {
        "City": "Thira, Santorini Island",
        "Country": "Greece",
        "Code": "JTR"
    },
    {
        "City": "Thompson, MB",
        "Country": "Canada",
        "Code": "YTH"
    },
    {
        "City": "Thompson, MB ",
        "Country": "Canada ",
        "Code": "YTH"
    },
    {
        "City": "Thunder Bay, ON",
        "Country": "Canada",
        "Code": "YQT"
    },
    {
        "City": "Thunder Bay, ON ",
        "Country": "Canada ",
        "Code": "YQT"
    },
    {
        "City": "Tianjin",
        "Country": "China",
        "Code": "TSN"
    },
    {
        "City": "Tijuana",
        "Country": "Mexico",
        "Code": "TIJ"
    },
    {
        "City": "Timaru",
        "Country": "New Zealand",
        "Code": "TIU"
    },
    {
        "City": "Timmins, ON",
        "Country": "Canada",
        "Code": "YTS"
    },
    {
        "City": "Timmins, ON ",
        "Country": "Canada ",
        "Code": "YTS"
    },
    {
        "City": "Tirana",
        "Country": "Albania",
        "Code": "TIA"
    },
    {
        "City": "Tobago",
        "Country": "Trinidad and Tobago",
        "Code": "TAB"
    },
    {
        "City": "Toksook Bay, AK ",
        "Country": "USA ",
        "Code": "OOK"
    },
    {
        "City": "Tokyo",
        "Country": "Japan",
        "Code": "HND"
    },
    {
        "City": "Tokyo",
        "Country": "Japan",
        "Code": "NRT"
    },
    {
        "City": "Tokyo",
        "Country": "Japan",
        "Code": "TYO - All Airports"
    },
    {
        "City": "Toledo, OH ",
        "Country": "USA ",
        "Code": "TOL"
    },
    {
        "City": "Toluca",
        "Country": "Mexico",
        "Code": "TLC"
    },
    {
        "City": "Tongren",
        "Country": "China",
        "Code": "TEN"
    },
    {
        "City": "Topeka, KS ",
        "Country": "USA ",
        "Code": "TOP"
    },
    {
        "City": "Toronto, ON",
        "Country": "Canada",
        "Code": "YTO - All Airports"
    },
    {
        "City": "Toronto, ON",
        "Country": "Canada",
        "Code": "YTZ"
    },
    {
        "City": "Toronto, ON",
        "Country": "Canada",
        "Code": "YYZ"
    },
    {
        "City": "Toronto, ON ",
        "Country": "Canada ",
        "Code": "YTO -All "
    },
    {
        "City": "Toronto, ON ",
        "Country": "Canada ",
        "Code": "YTZ"
    },
    {
        "City": "Toronto, ON ",
        "Country": "Canada ",
        "Code": "YYZ"
    },
    {
        "City": "Torreon",
        "Country": "Mexico",
        "Code": "TRC"
    },
    {
        "City": "Toulon",
        "Country": "France",
        "Code": "TLN"
    },
    {
        "City": "Toulouse",
        "Country": "France",
        "Code": "TLS"
    },
    {
        "City": "Townsville, Qld.",
        "Country": "Australia",
        "Code": "TSV"
    },
    {
        "City": "Trang",
        "Country": "Thailand",
        "Code": "TST"
    },
    {
        "City": "Trat",
        "Country": "Thailand",
        "Code": "TDX"
    },
    {
        "City": "Traverse, MI ",
        "Country": "USA ",
        "Code": "TVC"
    },
    {
        "City": "Treasure Cay, Abaco Island",
        "Country": "Bahamas",
        "Code": "TCB"
    },
    {
        "City": "Trenton, NJ ",
        "Country": "USA ",
        "Code": "TTN"
    },
    {
        "City": "Tri-Airport, TN ",
        "Country": "USA ",
        "Code": "TRI"
    },
    {
        "City": "Trieste",
        "Country": "Italy",
        "Code": "TRS"
    },
    {
        "City": "Tripoli",
        "Country": "Libya",
        "Code": "TIP"
    },
    {
        "City": "Tromso",
        "Country": "Norway",
        "Code": "TOS"
    },
    {
        "City": "Trondheim",
        "Country": "Norway",
        "Code": "TRD"
    },
    {
        "City": "Truk",
        "Country": "Micronesia",
        "Code": "TKK"
    },
    {
        "City": "Tucson, AZ ",
        "Country": "USA ",
        "Code": "TUS"
    },
    {
        "City": "Tulsa, OK ",
        "Country": "USA ",
        "Code": "TUL"
    },
    {
        "City": "Tunis",
        "Country": "Tunisia",
        "Code": "TUN"
    },
    {
        "City": "Tupelo, MS ",
        "Country": "USA ",
        "Code": "TUP"
    },
    {
        "City": "Turin",
        "Country": "Italy",
        "Code": "TRN"
    },
    {
        "City": "Turku",
        "Country": "Finland",
        "Code": "TKU"
    },
    {
        "City": "Tuxtla Gutierrez",
        "Country": "Mexico",
        "Code": "TGZ"
    },
    {
        "City": "Twin Falls, ID ",
        "Country": "USA ",
        "Code": "TWF"
    },
    {
        "City": "Tyler, TX ",
        "Country": "USA ",
        "Code": "TYR"
    },
    {
        "City": "Ubon Ratchathani",
        "Country": "Thailand",
        "Code": "UBP"
    },
    {
        "City": "Udon Thani",
        "Country": "Thailand",
        "Code": "UTH"
    },
    {
        "City": "Ujung Pandang, Sulawesi",
        "Country": "Indonesia",
        "Code": "UPG"
    },
    {
        "City": "Ulan Bator",
        "Country": "Mongolia",
        "Code": "ULN"
    },
    {
        "City": "Umea",
        "Country": "Sweden",
        "Code": "UME"
    },
    {
        "City": "Una",
        "Country": "Brazil",
        "Code": "UNA"
    },
    {
        "City": "Unalakleet, AK ",
        "Country": "USA ",
        "Code": "UNK"
    },
    {
        "City": "Urumqi, Sinkiang",
        "Country": "China",
        "Code": "URC"
    },
    {
        "City": "Ushuaia, Tierra del Fuego",
        "Country": "Argentina",
        "Code": "USH"
    },
    {
        "City": "Ust Kamenogorsk",
        "Country": "Kazakhstan",
        "Code": "UKK"
    },
    {
        "City": "Utapao",
        "Country": "Thailand",
        "Code": "UTP"
    },
    {
        "City": "Vaasa",
        "Country": "Finland",
        "Code": "VAA"
    },
    {
        "City": "Vail, CO ",
        "Country": "USA ",
        "Code": "EGE"
    },
    {
        "City": "Val-d'Or, PQ",
        "Country": "Canada",
        "Code": "YVO"
    },
    {
        "City": "Val-d'Or, PQ",
        "Country": "Canada ",
        "Code": "YVO"
    },
    {
        "City": "Valdez, AK ",
        "Country": "USA ",
        "Code": "VDZ"
    },
    {
        "City": "Valdivia",
        "Country": "Chile",
        "Code": "ZAL"
    },
    {
        "City": "Valdosta, GA ",
        "Country": "USA ",
        "Code": "VLD"
    },
    {
        "City": "Valencia",
        "Country": "Spain",
        "Code": "VLC"
    },
    {
        "City": "Valladolid",
        "Country": "Spain",
        "Code": "VLL"
    },
    {
        "City": "Valletta",
        "Country": "Malta",
        "Code": "MLA"
    },
    {
        "City": "Vancouver, BC",
        "Country": "Canada",
        "Code": "YVR"
    },
    {
        "City": "Vancouver, BC ",
        "Country": "Canada ",
        "Code": "YVR"
    },
    {
        "City": "Varanasi",
        "Country": "India",
        "Code": "VNS"
    },
    {
        "City": "Varna",
        "Country": "Bulgaria",
        "Code": "VAR"
    },
    {
        "City": "Vasteras",
        "Country": "Sweden",
        "Code": "VST"
    },
    {
        "City": "Vaxjo",
        "Country": "Sweden",
        "Code": "VXO"
    },
    {
        "City": "Venice",
        "Country": "Italy",
        "Code": "VCE"
    },
    {
        "City": "Veracruz",
        "Country": "Mexico",
        "Code": "VER"
    },
    {
        "City": "Verona",
        "Country": "Italy",
        "Code": "VRN"
    },
    {
        "City": "Victoria, BC",
        "Country": "Canada",
        "Code": "YYJ"
    },
    {
        "City": "Victoria, BC ",
        "Country": "Canada ",
        "Code": "YYJ"
    },
    {
        "City": "Victoria, Mahe Island",
        "Country": "Seychelles",
        "Code": "SEZ"
    },
    {
        "City": "Victoria, TX ",
        "Country": "USA ",
        "Code": "VCT"
    },
    {
        "City": "Vienna",
        "Country": "Austria",
        "Code": "VIE"
    },
    {
        "City": "Vientiane",
        "Country": "Laos",
        "Code": "VTE"
    },
    {
        "City": "Vieques",
        "Country": "Puerto Rico",
        "Code": "VQS"
    },
    {
        "City": "Vieux Fort",
        "Country": "St. Lucia",
        "Code": "UVF"
    },
    {
        "City": "Vigo",
        "Country": "Spain",
        "Code": "VGO"
    },
    {
        "City": "Vilhelmina",
        "Country": "Sweden",
        "Code": "VHM"
    },
    {
        "City": "Villahermosa",
        "Country": "Mexico",
        "Code": "VSA"
    },
    {
        "City": "Vilnius",
        "Country": "Lithuania",
        "Code": "VNO"
    },
    {
        "City": "Vinh",
        "Country": "Vietnam",
        "Code": "VII"
    },
    {
        "City": "Visalia, CA ",
        "Country": "USA ",
        "Code": "VIS"
    },
    {
        "City": "Visby",
        "Country": "Sweden",
        "Code": "VBY"
    },
    {
        "City": "Vitoria",
        "Country": "Spain",
        "Code": "VIT"
    },
    {
        "City": "Vladivostok, Siberia",
        "Country": "Russia",
        "Code": "VVO"
    },
    {
        "City": "Volgograd",
        "Country": "Russia",
        "Code": "VOG"
    },
    {
        "City": "Wabush, Labrador, NF",
        "Country": "Canada",
        "Code": "YWK"
    },
    {
        "City": "Wabush, Labrador, NF ",
        "Country": "Canada ",
        "Code": "YWK"
    },
    {
        "City": "Waco, TX ",
        "Country": "USA ",
        "Code": "ACT"
    },
    {
        "City": "Wagga-Wagga, N.S.W.",
        "Country": "Australia",
        "Code": "WGA"
    },
    {
        "City": "Wajima",
        "Country": "Japan",
        "Code": "NTQ"
    },
    {
        "City": "Walla Walla, WA ",
        "Country": "USA ",
        "Code": "ALW"
    },
    {
        "City": "Wanganui",
        "Country": "New Zealand",
        "Code": "WAG"
    },
    {
        "City": "Warri",
        "Country": "Nigeria",
        "Code": "QRW"
    },
    {
        "City": "Warsaw",
        "Country": "Poland",
        "Code": "WAW"
    },
    {
        "City": "Washington DC ",
        "Country": "USA ",
        "Code": "DCA"
    },
    {
        "City": "Washington DC ",
        "Country": "USA ",
        "Code": "IAD"
    },
    {
        "City": "Washington DC ",
        "Country": "USA ",
        "Code": "WAS - All Airports "
    },
    {
        "City": "Waterloo, IA ",
        "Country": "USA ",
        "Code": "ALO"
    },
    {
        "City": "Watertown, NY ",
        "Country": "USA ",
        "Code": "ART"
    },
    {
        "City": "Watertown, SD ",
        "Country": "USA ",
        "Code": "ATY"
    },
    {
        "City": "Wausau, WI ",
        "Country": "USA ",
        "Code": "CWA"
    },
    {
        "City": "Weipa, Qld.",
        "Country": "Australia",
        "Code": "WEI"
    },
    {
        "City": "Wellington",
        "Country": "New Zealand",
        "Code": "WLG"
    },
    {
        "City": "Wenatchee, WA ",
        "Country": "USA ",
        "Code": "EAT"
    },
    {
        "City": "West Palm Beach, FL ",
        "Country": "USA ",
        "Code": "PBI"
    },
    {
        "City": "West Yellowstone, MT",
        "Country": "USA ",
        "Code": "WYS"
    },
    {
        "City": "Westport",
        "Country": "New Zealand",
        "Code": "WSZ"
    },
    {
        "City": "Whakatane",
        "Country": "New Zealand",
        "Code": "WHK"
    },
    {
        "City": "Whangarei",
        "Country": "New Zealand",
        "Code": "WRE"
    },
    {
        "City": "White Plains, NY ",
        "Country": "USA ",
        "Code": "HPN"
    },
    {
        "City": "Whitehorse, YT",
        "Country": "Canada",
        "Code": "YXY"
    },
    {
        "City": "Whitehorse, YT ",
        "Country": "Canada ",
        "Code": "YXY"
    },
    {
        "City": "Whyalla, S.A.",
        "Country": "Australia",
        "Code": "WYA"
    },
    {
        "City": "Wichita Falls, TX ",
        "Country": "USA ",
        "Code": "SPS"
    },
    {
        "City": "Wichita, KS ",
        "Country": "USA ",
        "Code": "ICT"
    },
    {
        "City": "Wick",
        "Country": "Scotland",
        "Code": "WIC"
    },
    {
        "City": "Wilkes-Barre, PA ",
        "Country": "USA ",
        "Code": "AVP"
    },
    {
        "City": "Willemstad",
        "Country": "Curacao",
        "Code": "CUR"
    },
    {
        "City": "Williams Lake, BC",
        "Country": "Canada",
        "Code": "YWL"
    },
    {
        "City": "Williams Lake, BC",
        "Country": "Canada ",
        "Code": "YWL"
    },
    {
        "City": "Williamsport, PA ",
        "Country": "USA ",
        "Code": "IPT"
    },
    {
        "City": "Williston, ND ",
        "Country": "USA ",
        "Code": "ISN"
    },
    {
        "City": "Wilmington, DE ",
        "Country": "USA ",
        "Code": "ILG"
    },
    {
        "City": "Wilmington, NC ",
        "Country": "USA ",
        "Code": "ILM"
    },
    {
        "City": "Windsor, ON",
        "Country": "Canada",
        "Code": "YQG"
    },
    {
        "City": "Windsor, ON ",
        "Country": "Canada ",
        "Code": "YQG"
    },
    {
        "City": "Winnipeg, MB",
        "Country": "Canada",
        "Code": "YWG"
    },
    {
        "City": "Winnipeg, MB ",
        "Country": "Canada ",
        "Code": "YWG"
    },
    {
        "City": "Wolf Point, MT",
        "Country": "USA ",
        "Code": "OLF"
    },
    {
        "City": "Worland, WY ",
        "Country": "USA ",
        "Code": "WRL"
    },
    {
        "City": "Wrangell, AK ",
        "Country": "USA ",
        "Code": "WRG"
    },
    {
        "City": "Wroclaw",
        "Country": "Poland",
        "Code": "WRO"
    },
    {
        "City": "Wuhan",
        "Country": "China",
        "Code": "WUH"
    },
    {
        "City": "Xi'an",
        "Country": "China",
        "Code": "XIY"
    },
    {
        "City": "Xiamen",
        "Country": "China",
        "Code": "XMN"
    },
    {
        "City": "Xichang",
        "Country": "China",
        "Code": "XIC"
    },
    {
        "City": "Yakima, WA ",
        "Country": "USA ",
        "Code": "YKM"
    },
    {
        "City": "Yakutat, AK ",
        "Country": "USA ",
        "Code": "YAK"
    },
    {
        "City": "Yamagata",
        "Country": "Japan",
        "Code": "GAJ"
    },
    {
        "City": "Yangyang, Korea",
        "Country": "Republic of",
        "Code": "YNY"
    },
    {
        "City": "Yanji",
        "Country": "China",
        "Code": "YNJ"
    },
    {
        "City": "Yantai",
        "Country": "China",
        "Code": "YNT"
    },
    {
        "City": "Yaounde",
        "Country": "Cameroon",
        "Code": "NSI"
    },
    {
        "City": "Yaounde",
        "Country": "Cameroon",
        "Code": "YAO"
    },
    {
        "City": "Yap",
        "Country": "Micronesia",
        "Code": "YAP"
    },
    {
        "City": "Yellowknife, NWT",
        "Country": "Canada",
        "Code": "YZF"
    },
    {
        "City": "Yellowknife, NWT",
        "Country": "Canada ",
        "Code": "YZF"
    },
    {
        "City": "Yerevan",
        "Country": "Armenia",
        "Code": "EVN"
    },
    {
        "City": "Yogyakarta, Java",
        "Country": "Indonesia",
        "Code": "JOG"
    },
    {
        "City": "Yuma, AZ ",
        "Country": "USA ",
        "Code": "YUM"
    },
    {
        "City": "Zacatecas",
        "Country": "Mexico",
        "Code": "ZCL"
    },
    {
        "City": "Zadar",
        "Country": "Croatia",
        "Code": "ZAD"
    },
    {
        "City": "Zagreb",
        "Country": "Croatia",
        "Code": "ZAG"
    },
    {
        "City": "Zamboanga",
        "Country": "Philippines",
        "Code": "ZAM"
    },
    {
        "City": "Zanzibar",
        "Country": "Tanzania",
        "Code": "ZNZ"
    },
    {
        "City": "Zaragoza",
        "Country": "Spain",
        "Code": "ZAZ"
    },
    {
        "City": "Zhengzhou",
        "Country": "China",
        "Code": "CGO"
    },
    {
        "City": "Zhoushan",
        "Country": "China",
        "Code": "HSN"
    },
    {
        "City": "Zurich",
        "Country": "Switzerland",
        "Code": "ZRH"
    }
);

exports.airport_arr = airport_arr;
import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ReportList = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [ReportDetails, setReportDetails] = useState([]);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [ReportName, setReportName] = useState("");
    const [ReportExpression, setReportExpression] = useState("");
    const [description, setdescription] = useState("");
    const [trip_id, settrip_id] = useState("");
    let [report_id, setreport_id] = useState("");
    const [status, setstatus] = useState("");
    const [approverlist, setapproverlist] = useState([])
    let [approver, setapprover] = useState("");
    const [DateFormat, setDateFormat] = useState("");
    const [start, setstart] = useState(moment().format('YYYY-MM-DD'))
    const [end, setend] = useState(moment().add(7, "days").format('YYYY-MM-DD'))

    let maxdate = moment().format('YYYY-MM-DD');
    const [TripList, setTripList] = useState([])
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([])

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    let [folder_name, setfolder_name] = useState("report_documents");
    const [Prefix, setPrefix] = useState("");
    let [Serial_number, setSerial_number] = useState();
    const [Post_parameter, setPost_parameter] = useState("");
    let [page_actions, setpage_actions] = useState([]);

    var page_name = "report-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Project',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "report_name",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "expenses",
            label: "Expenses",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})      
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])
    
    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setPost_parameter((res.data.name).replaceAll(' ', '_'))
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name) 
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_actions_permission.access.length; i++) {
                                if(res.data.user_actions_permission.access[i].key=="Report")
                                {
                                    setpage_actions(res.data.user_actions_permission.access[i].actions)
                                }
                            }
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);

    useEffect(() => {
        async function getreportdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-my-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setloading(true);
                setReportDetails(body);
            }
            else {
                setstate(0);
            }  
        }
        if (read || write) {
            (user.email && visibility) && getreportdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        
        async function getapproverdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/approver-list`, { method: 'POST', body: JSON.stringify({"email": user.email, "page_name":page_name,"organization_id":organization_id,"type":"Report"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                var temp_obj = {};
                temp_obj.label = obj.user_id;
                temp_obj.value = obj.user_id;
                temp_dropdown.push(temp_obj);
            })
            setapproverlist(temp_dropdown);

        }
        if(read || write)
        {
            (user.email && visibility && loading!=false) && getapproverdetails();
        }
        async function gettripdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-approved-trip`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Approved") {
                    var temp_obj = {};
                    temp_obj.label = obj.trip_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setTripList(temp_dropdown);
        }
        if(read || write)
        {
            (user.email && visibility && loading!=false) && gettripdetails();
        }

    }, [user, visibility, read, write,loading])

    useEffect(() => {
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setPrefix(body[0].report_prefix)
                setDateFormat(body[0].date_format)
            }
        }
        if(read || write)
        {
            (user.email && visibility) && getbasecurrency();
        }

    }, [user, visibility, read, write])
 
    useEffect(() => {
        async function getreportspecdetails() {
           
            var temp_dropdown = [];
            ReportDetails.map((obj) => {
                //if (obj.add_status === "Active") {
                    temp_dropdown.push(obj);
                //}
            })
            if(ReportDetails.length>0)
            {  
                let arr=ReportDetails.filter(option => option.report_prefix == Prefix);
                if(arr.length>0)
                {
                    setSerial_number(Math.max.apply(Math, arr.map(function(o) { return o.report_number; }))+1);
                    let report=Prefix+"-"+(Math.max.apply(Math, arr.map(function(o) { return o.report_number; }))+1)+"-"+Post_parameter;
                    setReportExpression(report);
                }
                else
                {
                    setSerial_number(1);
                    let report=Prefix+"-"+1+"-"+Post_parameter;
                    setReportExpression(report);
                }
            }
            else
            {
                setSerial_number(1);
                let report=Prefix+"-"+1+"-"+Post_parameter;
                setReportExpression(report);
            }
            setEditModal(temp_dropdown);
        }
        if (read || write) {
            (user.email && visibility && Prefix!="") && getreportspecdetails();
        }

    }, [user, visibility, read, write,Prefix])

    useEffect(() => {
        async function geteditdetails() {
            console.log(editmodal.length)
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.report_name = obj.report_name;
                    temp_obj.duration = moment(obj.start_date).format(DateFormat)+" to "+moment(obj.end_date).format(DateFormat);
                    temp_obj.expenses = obj.expense_details.length;
                    if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else if(obj.add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else if(obj.add_status=="Active")
                    {
                        temp_obj.status ="Draft";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                            <i className="fas fa-bars px-0"></i>
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateReport(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a href={`/report-details/${id}`} class="dropdown-item">View Details</a></li>
                                        {obj.add_status=="Active" &&<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>}
                                        {(obj.expense_details.length>0 && obj.add_status=="Active") && <li><a onClick={(e) => { submitReport(id) }} data-bs-toggle="modal" data-bs-target="#submitReport" class="dropdown-item" href="#">Submit Report</a></li>}
                                        {obj.add_status=="Submitted" && <li><a onClick={(e) => { submitReport(id) }} data-bs-toggle="modal" data-bs-target="#Recall" class="dropdown-item" href="#">Recall</a></li>}
                                        {obj.add_status=="Recalled" && <li><a onClick={(e) => { RecallReport(id) }} data-bs-toggle="modal" data-bs-target="#submitReport" class="dropdown-item" href="#">ReSubmit</a></li>}
                                        {/* <li><a href="#" onClick={(e) => { updateReport(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>
                    temp_data.push(temp_obj);
                })
            var lookup = {};
            var items = temp_data;
            var result = [];
            result.push({label:"All",value:"All"});
            for (var item, i = 0; item = items[i++];) {
            var name = item.status;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push({label:name,value:name});
                }
            }
            setStatusList(result);
            //console.log(temp_data)
            setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    const RecallReport = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                settrip_id(editmodal[i].id)
                setapprover(editmodal[i].approver)
            }
        }
    }

    const getReportList= (id) => {
        if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            
            editmodal.map((obj) => {
                var temp_obj = {};
                    temp_obj.report_name = obj.report_name;
                    temp_obj.duration = moment(obj.start_date).format(DateFormat)+" to "+moment(obj.end_date).format(DateFormat);
                    temp_obj.expenses = obj.expense_details.length;
                    if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else if(obj.add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else if(obj.add_status=="Active")
                    {
                        temp_obj.status ="Draft";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                            <i className="fas fa-bars px-0"></i>
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateReport(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><Link to={`/report-details/${id}`} class="dropdown-item">View Details</Link></li>
                                        {obj.add_status=="Active" &&<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>}
                                        {obj.expense_details.length>0 && <li><a onClick={(e) => { submitReport(id) }} data-bs-toggle="modal" data-bs-target="#submitReport" class="dropdown-item" href="#">Submit Report</a></li>}
                                        {obj.add_status=="Submitted" && <li><a onClick={(e) => { submitReport(id) }} data-bs-toggle="modal" data-bs-target="#Recall" class="dropdown-item" href="#">Recall</a></li>}
                                        {obj.add_status=="Recalled" && <li><a onClick={(e) => { RecallReport(id) }} data-bs-toggle="modal" data-bs-target="#submitReport" class="dropdown-item" href="#">ReSubmit</a></li>}
                                        {/* <li><a href="#" onClick={(e) => { updateReport(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>
                    temp_data.push(temp_obj);
            })
            var lookup = {};
            var items = temp_data;
            var result = [];
            result.push({label:"All",value:"All"});
            for (var item, i = 0; item = items[i++];) {
            var name = item.status;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push({label:name,value:name});
                }
            }
            if(id!="All")
            {
                arr=temp_data.filter(option => option.status == id);
            }
            else
            {
                arr=temp_data;
            }
            setData(arr);
        }
        else {
            setData([]);
        }
    }

    const updateReport = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                setreport_id(editmodal[i].id)
                setReportName(editmodal[i].report_name)
                setdescription(editmodal[i].objective)
                setstart(moment(editmodal[i].start_date).format("YYYY-MM-DD"))
                setend(moment(editmodal[i].end_date).format("YYYY-MM-DD"))
                settrip_id(editmodal[i].trip)
                setPrefix(editmodal[i].report_prefix) 
                setPost_parameter(editmodal[i].post_exp)
                setSerial_number(editmodal[i].report_number)
            }
        }
    }

    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                setreport_id(editmodal[i].id)
                setstatus(editmodal[i].add_status);
            }
        }
    }
    
    const submitReport = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                setreport_id(editmodal[i].id)
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();
    
        var values = { "id": report_id,"folder_name":folder_name,"status": status, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitReports = (e) => {
        e.preventDefault();

        if (approver == "") {
            swal("Warning!", "Approver name cannot be a empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var values = { "id": report_id,"approver":approver,"page_name": page_name,"organization_id":organization_id };
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(approver!=user.email)
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-reports`, values, headers).then((res) => {
                setMainSubmitButtonState(false);
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Reports submitted successfully.", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-reports`, values, headers).then((res) => {
                //console.log(res)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Reports submitted and approved successfully.", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });

        }
    }

    const submitReportDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = report_id;
        object.trip_id = trip_id;
        object.report_name = ReportName;
        object.objective = description;
        object.start = start;
        object.end = end;
        object.Prefix = Prefix;
		object.Serial_number = Serial_number;
		object.Post_parameter = Post_parameter;
        object.page_name = page_name;
        object.organization_id=organization_id;
        
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (ReportName == "") {
            swal("Warning!", "Report Name cannot be empty", "warning");
            return false;
        }
        /*else if (!ReportName.match(nexp)) {
            swal("Warning!", "Report Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = editmodal.filter((item) => { return item.report_id !== report_id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].ReportName == ReportName) {
                    swal("Oops!", "Report Name already exists, try different one!", "error");
                    return false;
                }
            }
        }
        if (object.trip_id == "") {
            swal("Warning!", "Trip name cannot be a empty", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-reports`, object, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
     }
     
     const Recall_reports = (e) => {
        e.preventDefault();
        setMainSubmitButtonState(true)
        var values = { "id": report_id,"page_name": page_name,organization_id:organization_id };
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/recall-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report recalled successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    const addReport = () => {
        setReportName(ReportExpression);
        setdescription("");
    }

    const register = (e) => {
        e.preventDefault();
        var object = {};
        const dateRange = document.getElementById('daterangepicker').value;
        object.trip_id = trip_id;
        object.report_name = ReportName;
        object.objective_description = description;
        object.start = moment(dateRange.split(' - ')[0]).format('YYYY-MM-DD');
        object.end = moment(dateRange.split(' - ')[1]).format('YYYY-MM-DD');
        object.Prefix = Prefix;
        object.Serial_number = Serial_number;
        object.Post_parameter= Post_parameter;
        object.page_name = page_name;
        object.organization_id=organization_id;

        var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (object.report_name == "") {
            swal("Warning!", "Report Name cannot be a empty", "warning");
            return false;
        }
        /*else if (!isNaN(object.report_name)) {
            swal("Warning!", "Report Name cannot be a number", "warning");
            return false;
        }
        else if (object.report_name.match(nexp)) {
            swal("Warning!", "Report Name cannot contain symbols ", "warning"); 
            return false;
        }
        
		var cexp=/^[0-9]+$/
        if (object.trip_id == "") {
            swal("Warning!", "Trip name cannot be a empty", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-reports`, object, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Reports added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
			else if (res.data.operation === "same_report_name")
			{
                swal("Oops!", "Use a different report name!!", "error");
            }
            else
			{
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
        });
            
            
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Report List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <span className="badge badge-light fw-bolder my-2">
                            <a id="button_create_alert" onClick={(e) => { addReport(); setloading(true);}} type="button"data-bs-toggle="modal" data-bs-target="#AddReport"  className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Add Report</a>
                        </span>    
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Select status:</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="category"
                                                        id="category"
                                                        className="form-control"
                                                        options={StatusList}
                                                        onChange={(event) => { 
                                                            setStatus(event.value)
                                                            getReportList(event.value)
                                                        }}
                                                        value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                        placeholder="--Choose a Type--"
                                                    />
                                                </div>
                                            </div>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Report List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                        </MuiThemeProvider>
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Report List</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="AddReport" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Add Report</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Report name</label>
                                            <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={ReportName} onChange={(e) => { setReportName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row"><label className="fs-6 fw-bold mb-2">Duration</label>
                                        <DateRangePickerComponent id="daterangepicker" placeholder='Select a range'
                                            startDate={start}
                                            endDate={end}
                                            max={maxdate}
                                            maxDays={30}
                                        />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Trip:</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={TripList}
                                                onChange={(event) => { settrip_id(event.value) }}
                                                value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                                placeholder="--Choose a Trip--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Purpose</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" autocomplete="off" value={description} onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                    </div>    
                                </div>      
                            </div>
                                
                            <div class="modal-footer flex-center">
                            {write == 1 &&
                            <button type="submit"
                                className="btn btn-primary rounded"
                                data-kt-indicator={mainSubmitButtonState?"on":"off"}
                                disabled={mainSubmitButtonState}
                                onClick={(e) => { register(e) }}
                            >
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                            } 
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="submitReport" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Submit Report</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                            <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Select an approver for the report:</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            options={approverlist}
                                            onChange={(event) => { setapprover(event.value) }}
                                            value={approverlist.filter(option => option.value == approver).length != 0 && approverlist.filter(option => option.value == approver)[0]}
                                            placeholder="--Choose a approver--"
                                        />
                                    </div>
                                </div> 

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                            {approver==""? <button type="button" className="btn btn-primary" onClick={(e) => { submitReports(e); }} disabled>Submit</button>
                            :
                            approver==user.email ?<button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitReports(e); }}  ><span className="indicator-label">Approve</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            :
                            <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitReports(e); }}  ><span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            }
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="Recall" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Recall Trip</h4>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <h3>Are you sure you want to Recall this reports?</h3>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                                <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { Recall_reports(e); }}  ><span className="indicator-label">Recall</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Report Details</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    
                                    <h3>Are you sure you want to delete this Report?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                                <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitStatus(e); }} ><span className="indicator-label">Delete</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Edit Report</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Report name</label>
                                        <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={ReportName} onChange={(e) => { setReportName(e.target.value) }} readOnly/>
                                    </div>
                                </div>
                                
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">Start Date</label>
                                        <input type="date" name="start_date" className="form-control" id="start_date" placeholder="Sales Start Date" value={moment(start).format("yyyy-MM-DD")}
                                            onChange={(e) => {
                                                setstart(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">End Date</label>
                                        <input type="date" min={moment(start).format("yyyy-MM-DD")} name="end_date" className="form-control" id="end_date" placeholder="Sales End Date" value={moment(end).format('yyyy-MM-DD')}
                                            onChange={(e) => {
                                                setend(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">Trip:</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            options={TripList}
                                            onChange={(event) => { settrip_id(event.value) }}
                                            value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                            placeholder="--Choose a Trip--"
                                        />
                                    </div>
                                </div> 
                                <div className="row mb-5">

                                    <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Purpose</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" autocomplete="off" value={description}  onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                </div>
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                                {write==1&&<button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitReportDetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded">
                                    <span class="indicator-label">Update</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ReportList;

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'
import {MuiThemeProvider, createTheme} from '@material-ui/core';

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const Support = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    
    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=2;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [selected_document_list, setselected_document_list] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);

    let [image_id, setimage_id] = useState("");
    let [file_name, setfile_name] = useState("");
    let [original_file_name, setoriginal_file_name] = useState("");
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    let [fileNames, setFileNames] = useState([])
    let [travel_document_id, settravel_document_id] = useState("");
    let [country_array, set_country_array] = useState([]);
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    let [country, setcountry] = useState("");
    const [category, setcategory] = useState("");
    const [document_list, setdocument_list] = useState([]);
    let [folder_name, setfolder_name] = useState("travel_documents");
    const [traveldocumentlist, settraveldocumentlist] = useState([{ label: "Passport", value: "Passport" }, { label: "Visa", value: "Visa" }, { label: "ESTA", value: "ESTA" },{ label: "Others", value: "Others" }])
	var page_name = "travel-documents-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Document',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "user_name",
            label: "User Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "document_type",
            label: "Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "country",
            label: "Country",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "validity",
            label: "Validity",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "document_number",
            label: "Document number",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    
    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])


    useEffect(() => {
        var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);

    }, []);

    useEffect(() => {
        async function getdocumentdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-travel-document-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            setloading(true);
        }
        if (read || write) {
            user.email && visibility && getdocumentdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.user_name = obj.user_id;
                    temp_obj.document_type = obj.document_type;
                    temp_obj.document_number = obj.document_number;
                    temp_obj.validity = moment(obj.issued_date).format("yyyy-MM-DD")+"-"+moment(obj.expiry_date).format("yyyy-MM-DD");
                    temp_obj.country = obj.country;
                    temp_obj.document_number = obj.document_number;
                    temp_obj.unique_id = obj.unique_id;
                    var id = obj.unique_id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateProject(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                        {/* <li><a href="#" onClick={(e) => { updateProject(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }
                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    const updateProject = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].unique_id) {
                setcountry(editmodal[i].country)
                setstart_date(editmodal[i].issued_date)
                setend_date(editmodal[i].expiry_date)
                setcategory(editmodal[i].document_type);
                setDocument_number(editmodal[i].document_number);
                settravel_document_id(editmodal[i].unique_id) 
                setdocument_list(editmodal[i].travel_document_details) 
                let result = editmodal[i].travel_document_details.map(ele=>ele.image_original_name);;
                setselected_document_list(result)
            }
        }
    }

    const updateStatus = (id) => {

         settravel_document_id(id)
           
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": travel_document_id,"folder_name":folder_name,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-travel-document`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Document deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    const delete_document = (e) => {
        e.preventDefault();
        
        var values = { "id": travel_document_id,"image_id":image_id,"file_name":file_name,"original_file_name":original_file_name,"folder":folder_name,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-travel-related-document`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Document deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitTravelDocumentDetails = (e) => {
        e.preventDefault();
        
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;

        if (category == "") {
            swal("Warning!", "Document type cannot be a empty", "warning");
            return false;
        }
        if (start_date == "") {
            swal("Warning!", "Issued Date cannot be a empty", "warning");
            return false;
        }
        if (end_date == "") {
            swal("Warning!", "Expiry Date cannot be a empty", "warning");
            return false;
        }
        if (Document_number == "") {
            swal("Warning!", "Document Number cannot be a empty", "warning");
            return false;
        }
        /*else if (!Document_number.match(nexp)) {
            swal("Warning!", "Document Number cannot contain certain symbols ", "warning");
            return false;
        }
        */
        if (country == "") {
            swal("Warning!", "Country field cannot be a empty", "warning");
            return false;
        }
        if (fileNames.length==0) {
            swal("Warning!", "You must have to upload atleast one documemnt", "warning");
            return false;
        }
        
        var values = { "unique_id":id,"travel_document_id":travel_document_id, "email": user.email, 'document_type': category, 'country': country,'issued_date':start_date,'expiry_date':end_date,'document_number':Document_number,'filenames': fileNames,'page_name':page_name,"organization_id":organization_id }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
       axios.post(`${process.env.REACT_APP_SERVER_URL}/update-travel-document-details`, values, headers).then((res) => {
        setMainSubmitButtonState(false)    
        if (res.data && res.data.operation == "success") {
                swal("Great!", "Travel document details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    function loadScripts() {
        var se = document.createElement('script');
        se.src = "assets/js/custom/pages/company/support.js";
        document.body.appendChild(se);
    }

    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames]
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if (status === "error_file_size") {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        //setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();

        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('organization_id', organization_id);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-travel-documents`, headers, body }
    }

    const removeattachments = (file_name,original_file_name,id) => {
        setimage_id(id);
        setfile_name(file_name); 
        setoriginal_file_name(original_file_name);   
   }

    function loadCurrency(country) {
		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		setcountry(country.value);
    }

    

    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Travel Document List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-body">
                                <>
                                    <h4 id="notice_create_alert" style={{ "text-align": "center" }} className="text-gray-800 fw-bolder">Add Travel Document</h4>

                                    <div className="d-flex justify-content-center pb-0 px-0">
                                        {
                                            write ?
                                                <Link id="button_create_alert" type="button" to={`/add-travel-document`} className="btn btn-sm btn-primary me-3 rounded">Add Travel Document</Link>
                                                :
                                                <button id="button_create_alert" type="button" className="btn btn-sm btn-primary me-3 rounded" disabled>Add Travel Document</button>
                                        }

                                    </div>
                                </>

                                <br />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Travel Document List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                            </MuiThemeProvider>
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Travel Document List</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Travel Document</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12"> 
                                    <h3>Are you sure you want to delete this travel document?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==true &&
                            <form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span class="indicator-label">Delete</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Update Document Details</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Document Type:</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="category"
                                                id="category"
                                                className="form-control-solid"
                                                options={traveldocumentlist}
                                                onChange={(event) => { setcategory(event.value) }}
                                                value={traveldocumentlist.filter(option => option.value == category).length != 0 && traveldocumentlist.filter(option => option.value == category)[0]}
                                                placeholder="--Choose a Document type--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Country</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="country"
                                                id="country"
                                                options={country_array}
                                                value={
													country_array.filter(option =>
														option.value == country)[0]
												}
                                                onChange={(e) => { loadCurrency(e); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Issued Date</label>
                                            <input type="date" name="start_date" className="form-control" id="start_date" placeholder="Sales Start Date" value={moment(start_date).format("yyyy-MM-DD")}
                                                onChange={(e) => {
                                                    setstart_date(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Expiry Date</label>
                                            <input type="date" min={moment(start_date).format("yyyy-MM-DD")} name="end_date" className="form-control" id="end_date" placeholder="Sales End Date" value={moment(end_date).format('yyyy-MM-DD')}
                                                onChange={(e) => {
                                                    setend_date(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column mb-5 fv-row">
                                        <label class="required fs-5 fw-bold mb-2">Document Number</label>
                                        <input class="form-control form-control-solid" placeholder="" name="Document_number" value={Document_number} onChange={(e) => {setDocument_number(e.target.value)}}  autoComplete="off" />
                                    </div>
                                    
                                    {document_list.length ?
                                    <div className="overflow-auto pb-5 ">
                                        <label class="required fs-5 fw-bold mb-2">Documents</label>
                                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-350px p-2">
                                            {
                                                document_list.map((obj1, index) => {
                                                    return (
                                                        <>
                                                            <div className="overlay me-10">
                                                                <div className="overlay-wrapper">
                                                                    <img id='inside_map_img' alt='img' className="rounded w-200px" src={`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`} />
                                                                </div>
                                                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                    <a onClick={() => showImage(`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                    <a onClick={() => removeattachments(obj1.file_name,obj1.original_file_name,obj1.unique_id)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#DeleteDocument">Delete</a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                    }
                                    {(document_list.length<document_count)&&
                                        <div>
                                            <label class="fs-5 fw-bold mb-2">Maximum {document_count-document_list.length} files</label>
                                            <Dropzone
                                                getUploadParams={getUploadParams}
                                                onChangeStatus={ handleChangeStatus2}
                                                onSubmit={handleSubmit}
                                                validate={handleValidation2}
                                                LayoutComponent={CustomLayout}
                                                styles={{
                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                }}
                                                maxFiles={document_count-document_list.length}
                                                // canRemove={false}
                                                PreviewComponent={Preview}
                                                accept="image/*,.pdf"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                            {write==1 &&
                                <button onClick={(e) => { submitTravelDocumentDetails(e) }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
                                    <span class="indicator-label">Save</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button> 
                            }   
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>

            <div className="modal fade" id="DeleteDocument" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Documents</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12"> 
                                    <h3>Are you sure you want to delete this Documents?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&
                            <form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { delete_document(e);}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span class="indicator-label">Delete</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span></button>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default Support;

import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";

import Dashboard from './includes/dashboard.js';
import AdminDashboard from './includes/admin-dashboard.js';
import Signin from './includes/sign-in.js';
import Signup from './includes/sign-up.js';
import QuickSetUp from './includes/quick-set-up.js';

import ForgotPassword from"./includes/forgot-password.js";
import FirstStepForgotPassword from "./includes/first-step-forgot-password.js";

import VerificationAccount from"./includes/verification-account.js";

import CreateAccount from"./includes/create-account.js";
import FirstStepInvitation from "./includes/first-step-invitation.js";

import NotFound from "./includes/NotFound.js";
import UnAuthorized from "./includes/unAuthorized.js";

import AsideNavbar from "./includes/aside-navbar.js";
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";

import Createtrip from "./includes/create-trip.js";
import TripList from "./includes/trip-list.js";
import TripDetails from "./includes/trip-details.js";
import EditTripDetails from "./includes/edit-trip-details.js";

import ReportList from "./includes/report-list.js";
import ReportDetails from "./includes/report-details.js";

import ExpenseList from "./includes/expense-list.js";
import ExpenseDetails from "./includes/expense-details.js";

import Customer from"./includes/add-customer.js";
import ShowCustomer from "./includes/show-customer.js";
import EditCustomerDetails from "./includes/edit-customer-details.js";

import AddDepartment from "./includes/add-department.js";
import ListDepartment from "./includes/department-list.js";

import AddProject from "./includes/add-project.js";
import ListProject from "./includes/project-list.js";

import ListMerchant from "./includes/merchant-list.js";

import AddTravelDocument from "./includes/add-travel-documents.js";
import TravelDocumentList from "./includes/travel-documents-list.js";

import TaxList from "./includes/tax-list.js";
import TaxGroupList from "./includes/tax-group-list.js";

import AddPaidThroughAccounts from "./includes/add-paid-through-accounts.js";
import PaidThroughAccountsList from "./includes/paid-through-accounts-list.js";

import CurrencyList from "./includes/currency-list.js";

import Advancepayments from "./includes/record-advance.js";

import ListApproval from "./includes/approval-list.js";

import TripApproval from "./includes/trip-approval.js";
import ReportApproval from "./includes/report-approval.js";
import AdvanceApproval from "./includes/advance-approval.js";

import AddUser from "./includes/add-user";
import ShowUsers from "./includes/show-users.js";
import UpdateUserRole from "./includes/update-user-role.js";

import ShowCategory from "./includes/show-category.js";

import ShowOrganizations from "./includes/show-organizations.js";

import Roles from "./includes/add-roles.js";
import ShowRoles from "./includes/show-roles.js";
import ViewRoles from "./includes/view-roles.js";
import UpdateRoles from "./includes/update-roles.js";

import AddProfile from "./includes/add-profile.js";
import ShowProfiles from "./includes/show-profiles.js";
import ViewProfiles from "./includes/view-profiles.js";
import UpdateProfiles from "./includes/update-profile.js";

import Policies from "./includes/add-policies.js";
import ShowPolicies from "./includes/show-policies.js";
import UpdatePolicies from "./includes/update-policies.js";

import OrganizationProfile from './includes/organization-profile.js';
import AccountOverview from './includes/account-overview.js';
import AllModules from './includes/all-modules.js';

import ActivityLogAlert from './includes/activity-log-alert.js';

export default function BasePage() {

	return (
		<>
		<Switch>
			<Route exact path={"/login"} component={Signin} />
			<Route exact path={"/register"} component={Signup} />
			<Route exact path={"/quick-set-up"} component={QuickSetUp} />
			<Route exact path={"/verification-account/:id"} component={VerificationAccount} />
			<Route exact path={"/forgot-password/:id"} component={ForgotPassword} />
			<Route exact path={"/forgot-password-email"} component={FirstStepForgotPassword} />
			<Route exact path={"/create-account/:id"} component={CreateAccount} />
			<Route exact path={"/first-step-invitation"} component={FirstStepInvitation} />
			<Route exact from="/not-found" component={NotFound} />
			<Route exact path={"/unauthorized"} component={UnAuthorized} />
			<Route component={DefaultContainer} />
		</Switch>
		</>
	);
}

const DefaultContainer = () => (
	<>
		
		
			<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
				<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				<ActivityLogAlert />
				<Header />
					<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

					<AsideNavbar />
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						<Switch>
							<Route exact path={"/dashboard"} component={Dashboard} />
							<Route exact path={"/admin-dashboard"} component={AdminDashboard} />

							<Route exact path={"/create-trip"} component={Createtrip} />
							<Route exact path={"/trip-list"} component={TripList} />
							<Route exact path={"/trip-details/:id"} component={TripDetails} />
							<Route exact path={"/edit-trip-details/:id"} component={EditTripDetails} />

							<Route exact path={"/report-list"} component={ReportList} />
							<Route exact path={"/report-details/:id"} component={ReportDetails} />

							<Route exact path={"/expense-list"} component={ExpenseList} />
							<Route exact path={"/expense-details/:id"} component={ExpenseDetails} />

							<Route exact from="/add-customer" component={Customer} />
							<Route exact from="/customer-list" component={ShowCustomer} />
							<Route exact from={"/edit-customer-details/:id"} render={(props) => <EditCustomerDetails {...props} />} />
							
							
							<Route exact path={"/add-department"} component={AddDepartment} />
                            <Route exact path={"/department-list"} component={ListDepartment} />

							<Route exact path={"/add-project"} component={AddProject} />
                            <Route exact path={"/project-list"} component={ListProject} />

							<Route exact path={"/merchant-list"} component={ListMerchant} />

							<Route exact path={"/advance-payments"} component={Advancepayments} />

							<Route exact path={"/add-travel-document"} component={AddTravelDocument} />
							<Route exact path={"/travel-documents-list"} component={TravelDocumentList} />

							<Route exact path={"/tax-list"} component={TaxList} />
							<Route exact path={"/tax-group-list"} component={TaxGroupList} />

							<Route exact path={"/add-paid-through-accounts"} component={AddPaidThroughAccounts} />
							<Route exact path={"/paid-through-accounts-list"} component={PaidThroughAccountsList} />
							
							<Route exact path={"/currency-list"} component={CurrencyList} />

							<Route exact path={"/approvals-list"} component={ListApproval} />

							<Route exact path={"/trip-approval"} component={TripApproval} />
							<Route exact path={"/report-approval"} component={ReportApproval} />
							<Route exact path={"/advance-approval"} component={AdvanceApproval} />

							<Route exact from="/add-user" component={AddUser} />
							<Route exact from="/show-user" component={ShowUsers} />
							<Route exact from="/update-user-role/:id" component={UpdateUserRole} />

							<Route exact from="/category-list" component={ShowCategory} />

							<Route exact from="/show-organizations" component={ShowOrganizations} />

							<Route exact from="/add-roles" component={Roles} />
							<Route exact from="/roles" component={ShowRoles} />
							<Route exact from="/view-roles/:id" component={ViewRoles} />
							<Route exact from="/update-roles/:id" component={UpdateRoles} />

							<Route exact from="/add-profile" component={AddProfile} />
							<Route exact from="/profiles" component={ShowProfiles} />
							<Route exact from="/view-profiles/:id" component={ViewProfiles} />
							<Route exact from="/update-profile/:id" component={UpdateProfiles} />

							<Route exact from="/add-policies" component={Policies} />
							<Route exact from="/policies" component={ShowPolicies} />
							<Route exact from="/update-policies/:id" component={UpdatePolicies} />

							<Route exact from="/organization-profile" component={OrganizationProfile} />
							<Route exact from="/account-overview" component={AccountOverview} />
							<Route exact from="/all-modules" component={AllModules} />

							<Route exact from="/" component={Dashboard} />
														
							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>
						</div>
						<Footer />
					</div>
				</div>
			
		</div>
	</>
)
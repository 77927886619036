import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'
import {MuiThemeProvider, createTheme} from '@material-ui/core';

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const AdvanceApproval = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;
    let [state, setstate] = useState(2);

    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=5;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [fileNames, setFileNames] = useState(["", ""])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [Currency_conversion_value, setCurrency_conversion_value] = useState("");
    const [AllCurrencylist, setAllCurrencylist] = useState([])

    const [Expense_date, setExpense_date] = useState("");
    const [description, setdescription] = useState("");
    const [References, setReferences] = useState("");
    const [Amount, setAmount] = useState("");
    const [trip_id, settrip_id] = useState("");
    const [TripList, setTripList] = useState([])
    const [Currencylist, setCurrencylist] = useState([])
    const [PaidThrouhAccountlist, setPaidThrouhAccountlist] = useState([])
    const [Currency, setCurrency] = useState("");
    const [Currency1, setCurrency1] = useState("");
    const [Account, setAccount] = useState("");
    const [AllReportlist, setAllReportlist] = useState([])
    const [Reportlist, setReportlist] = useState([])
    const [Report, setReport] = useState("");
    let [advance_id, setadvance_id] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [LiveCurrencyData, setLiveCurrencyData] = useState([]);
    const [BaseCurrencyCode, setBaseCurrencyCode] = useState("");
    const [TargetCurrencyCode, setTargetCurrencyCode] = useState("");
    const [BaseCurrency, setBaseCurrency] = useState("");

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [DateFormat, setDateFormat] = useState("");
    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([{ label: "All", value: "All" }, { label: "UnReported", value: "UnReported" }, { label: "Reported", value: "Reported" }])

	var page_name = "advance-approval";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Document',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "submitter",
            label: "Submitter",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "trip",
            label: "Trip",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reference",
            label: "Reference",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "report_name",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    setFileNames = (name) => {
        fileNames.push(name)
        let uniq = fileNames => [...new Set(fileNames)];
        fileNames = fileNames.slice(-2)
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])
    
    useEffect(() => {
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setCurrency(body[0].currency_id);
                setCurrency1(body[0].currency_id);
                setBaseCurrency(body[0].currency_symbol);
                setBaseCurrencyCode(body[0].currency_code)
                setDateFormat(body[0].date_format)
            }
        }
        if(read || write)
        {
            user.email && visibility && getbasecurrency();
        }
        async function getreportdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-my-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            //setAllReportlist(body)
            var temp_dropdown = [];
            body.map((obj) => {
                    var temp_obj = {};
                    temp_obj.label = obj.report_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
            })
            setReportlist(temp_dropdown);  
            
        }
        if (read || write) {
            user.email && visibility && getreportdetails();
        }
        async function getlivecurrency() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                let arr=[];
                arr.push(body[0].data.quotes)
                setLiveCurrencyData(arr);
            }
        }
        if(read || write)
        {
            (user.email && visibility) && getlivecurrency();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function gettripdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-approved-trip`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                
                var temp_obj = {};
                temp_obj.label = obj.trip_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);  
            })
            setTripList(temp_dropdown);

        }
        if(read || write)
        {
            (user.email && visibility) && gettripdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function getreportdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/paid-through-accounts-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.account_name;
                    temp_obj.value = obj.unique_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setPaidThrouhAccountlist(temp_dropdown);
        }
        if(read || write)
        {
            user.email && visibility && getreportdetails();
        }

    }, [user, visibility,read, write])

    useEffect(() => {
        async function getcurrencydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.currency_code;
                    temp_obj.value = obj.currency_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCurrencylist(temp_dropdown);
            setAllCurrencylist(body);
        }
        if(read || write)
        {
            user.email && visibility && getcurrencydetails();
        }

    }, [user, visibility,read, write])

	
    useEffect(() => {
        async function getexpensedetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/payment-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            let temp_dropdown=[]
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    temp_dropdown.push(obj);
                }
            })
            if(temp_dropdown.length>0)
            {
            setEditModal(temp_dropdown);
            setloading(true);
            }
            else
            {
                setstate(0)
            }
        }
        if (read || write) {
            user.email && visibility && getexpensedetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.submitter = obj.user_id;
                    temp_obj.Amount = obj.amount;
                    if(obj.report_id!="")
                    {
                        temp_obj.report_name = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    }
                    else
                    {
                        temp_obj.report_name ="-----"
                    }
                    temp_obj.date = moment(obj.date).format(DateFormat)
                    if(obj.reference!="")
                    {
                        temp_obj.reference = obj.reference;
                    }
                    else
                    {
                        temp_obj.reference ="-----"
                    }
                    if(obj.trip_id!="")
                    {
                        temp_obj.trip =TripList.filter(option => option.value == obj.trip_id)[0].label;
                    }
                    else
                    {
                        temp_obj.trip ="-----"
                    }
                    temp_obj.status = obj.add_status;
                    temp_obj.advance_id = obj.unique_id;
                    var id = obj.unique_id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updatePayment(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]); 
                //setstate(0) 
            }
        }
        user.email && geteditdetails();

    }, [editmodal,TripList,Reportlist])

    const getAdvanceList= (id) => {
        if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            if(id=="Reported")
            {
                arr=editmodal.filter(option => option.report_id !="")
            }
            else if(id=="UnReported")
            {
                arr=editmodal.filter(option => option.report_id =="")
            }
            arr.map((obj) => {
                var temp_obj = {};
                    temp_obj.submitter = obj.user_id;
                    temp_obj.Amount = obj.amount;
                    if(obj.report_id!="")
                    {
                        temp_obj.report_name = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    }
                    else
                    {
                        temp_obj.report_name ="-----"
                    }
                    temp_obj.date = moment(obj.date).format(DateFormat)
                    if(obj.reference!="")
                    {
                        temp_obj.reference = obj.reference;
                    }
                    else
                    {
                        temp_obj.reference ="-----"
                    }
                    if(obj.trip_id!="")
                    {
                        temp_obj.trip =TripList.filter(option => option.value == obj.trip_id)[0].label;
                    }
                    else
                    {
                        temp_obj.trip ="-----"
                    }
                    temp_obj.status = obj.add_status;
                    temp_obj.advance_id = obj.unique_id;
                    var id = obj.unique_id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updatePayment(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
            })
            setData(temp_data);
        }
        else {
            setData([]);
        }
    }

    const updatePayment = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].unique_id) {
                setadvance_id(id)
                setExpense_date(moment(editmodal[i].date).format("yyyy-MM-DD"))
                settrip_id(editmodal[i].trip_id);
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setdescription(editmodal[i].notes);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const updateStatus = (id) => {

         setadvance_id(id)        
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": advance_id, "page_name": page_name,"organization_id":organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-advance`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitPaymentDetails = (e) => {
        e.preventDefault();

        var object = {};
        object.id = advance_id;
        object.Expense_date = Expense_date;
        object.trip_id = trip_id;
        object.Currency = Currency;
        object.Amount = Amount;
        object.References = References;
        object.report_id = "";
        object.description = description;
        object.Account = Account;
        object.page_name = page_name;
        object.organization_id=organization_id;
        
        if (Expense_date == "") {
            swal("Warning!", "Date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Amount == "") {
            swal("Warning!", "Amount cannot be a empty", "warning");
            return false;
        }
        /*else if (!Amount.match(cexp)) {
            swal("Warning!", " Amount cannot contain certain symbols ", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(advance_id!="")
        {
            
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-payment`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Advance payment updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-payment`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Advance payment(s) created successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }

    const submitAdvance = () => {
        setadvance_id("")
        setExpense_date(moment().format("yyyy-MM-DD"))
        settrip_id("");
        setAccount("");
        setCurrency("");
        setAmount("");
        setdescription("");
        setReferences("");
    }

    const load_currency_conversion_value = (val) => {
        let target_currency_code=AllCurrencylist.filter(option =>
            option.currency_id == val)[0].currency_code;
        let conversion_value=LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code];
        setTargetCurrencyCode(target_currency_code)
        setCurrency_conversion_value(conversion_value);
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Advances List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Select status:</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="category"
                                                        id="category"
                                                        className="form-control"
                                                        options={StatusList}
                                                        onChange={(event) => { 
                                                            setStatus(event.value)
                                                            getAdvanceList(event.value)
                                                        }}
                                                        value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                        placeholder="--Choose a Type--"
                                                    />
                                                </div>
                                            </div>
                                            <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Record Advances List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                            </MuiThemeProvider>
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    state==0 &&
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">All Advances</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Advance</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    
                                    <h3>Are you sure you want to delete this Advance Record?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>}
                        </div>

                    </div>

                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>{advance_id!=""?"Update":"Submit"} Advance Details</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                <div className="row mb-5">
                                    <div className="col-md-6 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Date:</label>
                                        <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                            onChange={(e) => {
                                                setExpense_date(e.target.value);
                                            }}
                                        />
                                    </div>
                                    </div>
                                
                                    <div className="row mb-5">
                                        <div className="col-md-3 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="Currency"
                                                id="Currency"
                                                className="form-control-solid"
                                                options={Currencylist}
                                                onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value); }}
                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-md-3 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Amount </label>
                                            <input id="subcategory_name" type="number" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={Amount} onChange={(e) => { setAmount(e.target.value) }} />
                                        </div>
                                        
                                    </div>
                                    {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                        <>
                                        <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                        <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*Amount}</span>
                                        </>
                                    }
                                    
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="Account"
                                                id="Account"
                                                className="form-control form-control-solid"
                                                options={PaidThrouhAccountlist}
                                                onChange={(event) => { setAccount(event.value) }}
                                                value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                placeholder="--Choose a Account--"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">References#</label>
                                            <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                        </div>

                                    </div>
                                    <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">Apply to Trip:</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            options={TripList}
                                            onChange={(event) => { settrip_id(event.value) }}
                                            value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                            placeholder="--Choose a Trip--"
                                        />
                                    </div>
                                </div> 
                                    <div className="row mb-5">

                                        <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Notes</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                            {write==1 &&
                                <button onClick={(e) => { submitPaymentDetails(e) }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
                                    <span class="indicator-label">{advance_id!=""?"Update":"submit"}</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            }   
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default AdvanceApproval;

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const ReportDetails = (props) => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    const [loading_modal, setloading_modal] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let [redirect_to_login, setRedirect_to_login] = useState(2);
    let timer_flag = 0;
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=5;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [fileNames, setFileNames] = useState([])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [Currency_conversion_value, setCurrency_conversion_value] = useState("");
    const [Expense_date, setExpense_date] = useState("");
    const [description, setdescription] = useState("");
    const [References, setReferences] = useState("");
    const [Amount, setAmount] = useState("");
    const [DateFormat, setDateFormat] = useState("");

    const [Total_amount, setTotal_amount] = useState("");
    const [Nonreimbursable_amount, setNonreimbursable_amount] = useState("");
    const [Applied_advance_amount, setApplied_advance_amount] = useState("");
    const [Amount_to_reimbursed, setAmount_to_reimbursed] = useState("");

    const [BaseCurrencyCode, setBaseCurrencyCode] = useState("");
    const [TargetCurrencyCode, setTargetCurrencyCode] = useState("");

    const [Currencylist, setCurrencylist] = useState([])
    const [PaidThrouhAccountlist, setPaidThrouhAccountlist] = useState([])
    const [CategoryList, setCategoryList] = useState([])
    const [CategoryList1, setCategoryList1] = useState([])
    const [MerchantList, setMerchantList] = useState([])

    const [navigation, setNavigation] = useState({ comment: 1, history: 0});
    const [Wizard, setWizard] = useState("");
    let [comment_id, setcomment_id] = useState("");
    let [alert_log, setAlertLog] = useState([]);
    const [Count, setCount] = useState(6);
    const [comments, setcomments] = useState("");
    let [timezone_change,set_timezone_change] = useState("+00:00");

    const [Expense_Amount_Limit,setExpense_Amount_Limit] = useState("");
    const [Receipt_Required_Limit,setReceipt_Required_Limit] = useState("");
    const [Make_Description_Mandatory,setMake_Description_Mandatory] = useState("");
    const [Category_Limits, setCategory_Limits] = useState([]);
    const [General_Limits, setGeneral_Limits] = useState([]);
    const [Index, setIndex] = useState("");

    let [approver, setapprover] = useState("");
    const [approverlist, setapproverlist] = useState([]);
    const [Violation, setViolation] = useState(false);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [ReportName, setReportName] = useState("");
    const [start, setstart] = useState(moment().format(''))
    const [end, setend] = useState('')

    const [AllCurrencylist, setAllCurrencylist] = useState([])
    const [multipleMode, setMultipleMode] = useState(true)
    const [expenseAccounts,setExpenseAccounts] = useState([{account: null, amount: 0, notes: "",Violation:false}])

    const [Currency, setCurrency] = useState("");
    const [Currency1, setCurrency1] = useState("");
    const [LiveCurrencyData, setLiveCurrencyData] = useState([]);

    const [BaseCurrency, setBaseCurrency] = useState("");
    const [Account, setAccount] = useState("");
    const [Claim_reimbursement, setClaim_reimbursement] = useState(true);
    const [SelectedUnreportedPayment, setSelectedUnreportedPayment] = useState([]);
    const [UnreportedPayment, setUnreportedPayment] = useState([]);
    const [UnreportedExpense, setUnreportedExpense] = useState([]);
    const [trip_id, settrip_id] = useState("");
    const [TripList, setTripList] = useState([])
    let [advance_id, setadvance_id] = useState("");
    let [expense_id, setexpense_id] = useState("");
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);

    const [Category, setCategory] = useState("");
    const [Merchant, setMerchant] = useState("");
    const [Report, setReport] = useState(props.match.params.id);

    const [inputFields, setInputFields] = useState([]);
    const [advance_list, setadvance_list] = useState([]);
    const [expense_list, setexpense_list] = useState([]);

    const [selected_document_list, setselected_document_list] = useState([]);
    const [document_list, setdocument_list] = useState([]);
    let [ReportDetails, set_ReportDetails] = useState([]);
    let [ReportDetails1, setReportDetails1] = useState([]);

    let [image_id, setimage_id] = useState("");
    let [file_name, setfile_name] = useState("");
    let [original_file_name, setoriginal_file_name] = useState("");
    let [folder_name, setfolder_name] = useState("report_documents");
    
	var page_name = "report-details";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }


    // const id = uniqid();
    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        function isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
        async function getReportDetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/report-details`, { method: 'POST', body: JSON.stringify({"id": props.match.params.id,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(isEmpty(body)===false)
            {
                setReportDetails1(body);
                setloading(true);    
            }
            else
            {
                setstate(1);
            }
        }
        if(read || write)
        {
            (user.email && visibility)  && getReportDetails();
        }

    }, [user, visibility, read, write])


        async function getapproverdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/approver-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id,"type":"Report"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                var temp_obj = {};
                temp_obj.label = obj.user_id;
                temp_obj.value = obj.user_id;
                temp_dropdown.push(temp_obj);
            })
            setapproverlist(temp_dropdown);
        }

        async function getmerchantdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/merchant-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.merchant_name;
                    temp_obj.value = obj.merchant_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setMerchantList(temp_dropdown);
        }

        async function getcategorydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.category_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCategoryList1(temp_dropdown);
        }

        async function getadvancedetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/unreported-payment`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => { 
                obj.selected=false;
            })
            setUnreportedPayment(body);

        }

        async function getcurrencydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.currency_code;
                    temp_obj.value = obj.currency_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCurrencylist(temp_dropdown);
            setAllCurrencylist(body)
        }

        async function getexpensedetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/unreported-expense`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => { 
                obj.selected=false;
            })
            setUnreportedExpense(body);
        }

        async function getbasecurrency() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setCurrency(body[0].currency_id);
                setCurrency1(body[0].currency_id);
                setBaseCurrency(body[0].currency_symbol);
                setBaseCurrencyCode(body[0].currency_code)
                setDateFormat(body[0].date_format)
            }
        }

        async function getapprovedtripdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-approved-trip`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                
                var temp_obj = {};
                temp_obj.label = obj.trip_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);  
            })
            setTripList(temp_dropdown);

        }

        async function getaccountsdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/paid-through-accounts-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.account_name;
                    temp_obj.value = obj.unique_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setPaidThrouhAccountlist(temp_dropdown);
        }

        async function getlivecurrency() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                let arr=[];
                arr.push(body[0].data.quotes)
                setLiveCurrencyData(arr);
            }
        }

        async function getactivitydetails() {
            var user_object = {};
            user_object.email = user.email;
            user_object.page_name = page_name;
            user_object.report_id = props.match.params.id;
            var headers = {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "*/*",
                  "token" : getCookie("access_token")
                }
              }
            
            axios.post(`${process.env.REACT_APP_SERVER_URL}/report-activity`,user_object, headers).then(async (res) => {
                if(res.data.length>0)
                {    
                    const response = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body : JSON.stringify({"email" : user_object.email,"page_name" : page_name}) , headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }}
                    );
                    const body = await response.json();
                    
                    if(body.length > 0)
                    {
                        if(body[0].timezone_value != "" && body[0].timezone_value != null)
                        {
                            let timezone_change = body[0].timezone_value;
                            set_timezone_change(timezone_change)
                        }
                    }
                    setAlertLog(res.data);
                }
            })
        }

    useEffect(() => {
        if (loading) {
            let p1 = getapproverdetails();
            let p2 = getmerchantdetails();
            let p3 = getcategorydetails();
            let p4 = getadvancedetails();
            let p5 = getactivitydetails();
            let p6 = getcurrencydetails();
            let p7 = getlivecurrency();
            let p8 = getaccountsdetails();
            let p9 = getexpensedetails();
            let p10 = getbasecurrency();
            let p11=getapprovedtripdetails
            Promise.all([p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11])
            .then(()=>{
                setloading_modal(true);
                console.log("all apis done")
            })
            .catch((err)=>{
                setloading_modal(false);
                console.log(err)
            })
        }
    }, [loading])
    
    useEffect(() => {
        async function getpolicycategorydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-policy-related-categories`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            if(body.length>0)
            {
                if(body[0].general_limits!="")
                {
                    setGeneral_Limits(JSON.parse(body[0].general_limits))
                }
                if(body[0].category_limits!="")
                {
                    setCategory_Limits(JSON.parse(body[0].category_limits))
                }
                JSON.parse(body[0].category_limits).map((obj) => {
                    if (obj.acceptability ===true) {
                        var temp_obj = {};
                        temp_obj.label = CategoryList1.filter(option => option.value == obj.category_id)[0].label;
                        temp_obj.value = obj.category_id;
                        temp_dropdown.push(temp_obj);
                    }
                })
            }
            setCategoryList(temp_dropdown);
        }
        if(read || write)
        {
            (user.email && visibility && CategoryList1.length>0) && getpolicycategorydetails();
        }
    }, [user, visibility,read, write,CategoryList1])

    useEffect(() => {
        function isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
        async function getReportDetails() {
            if(isEmpty(ReportDetails1)===false)
            {
                let sum=0;
                let expense_amount=0;
                let claim_reimbursement_amount=0;
                
                let doc=[]
                for(let i=0;i<ReportDetails1[0].trip_document_details.length;i++)
                {
                    doc.push(ReportDetails1[0].trip_document_details[i].original_file_name)
                }
                setselected_document_list(doc) 
                setadvance_list(ReportDetails1[0].advance_details)  
                setexpense_list(ReportDetails1[0].expense_details)    
                ReportDetails1[0].advance_details.map((obj) => {
                    sum=sum+obj.amount;
                })
                setApplied_advance_amount(sum)
                ReportDetails1[0].expense_details.map((obj) => {
                    expense_amount=expense_amount+obj.amount;
                    if(obj.claim_reimbursement==0)
                    {
                        claim_reimbursement_amount=claim_reimbursement_amount+obj.amount;
                    }
                })
                setNonreimbursable_amount(claim_reimbursement_amount);
                setTotal_amount(expense_amount)
                set_ReportDetails(ReportDetails1);
                setdocument_list(ReportDetails1[0].trip_document_details)     
            }
        }
        if(read || write)
        {
            (user.email && visibility && ReportDetails1.length>0)  && getReportDetails();
        }
    }, [user, visibility, read, write,ReportDetails1])

    const add_expense = (e) => {
        expenseAccounts.forEach(object => {
            delete object['Violation'];
          });
        e.preventDefault();
        var object = {};
        object.organization_id = organization_id;
        object.Expense_date = Expense_date;
        object.Merchant = Merchant;
        object.Report = Report;
        object.Currency = Currency;
        object.Amount = expenseAccounts.reduce((p,o)=>{return p+o.amount},0);
        object.expenseAccounts = expenseAccounts;
        object.References = References;
        object.Claim_reimbursement = Claim_reimbursement;
        object.Account = Account;
        object.page_name = page_name;
        //console.log(AllCurrencylist.filter(option =>
        //    option.currency_id == Currency1)[0].currency_code)
        object.Currency_conversion_value = Currency_conversion_value;

        var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (object.Expense_date == "") {
            swal("Warning!", "Expense date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Currency == "") {
            swal("Warning!", "Currency cannot be a empty", "warning");
            return false;
        }
        let t = expenseAccounts.find(o=>{
            if((o.account==null)||(o.amount==0)){
                return o
            }
        })
        if(t!=undefined)
        {
            swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
            return
        }

        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(expense_id=="")
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-expense`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Expense Created successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else
                {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })
        }
        else
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-expense`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Expense Created successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else
                {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })

        }
            
            
    }

    const submitcomments = (e) => {
        e.preventDefault();

        var values = { "id": props.match.params.id,"type":"report",'comments': comments,"page_name": page_name };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-comment`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Comment added to trip successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
        
    }

    const submitattachments = (e) => {
        e.preventDefault();

        var values = { "report_id": props.match.params.id,"folder_name":folder_name,"unique_id":id,'filenames': fileNames,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-report-document-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report doccuments saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }



    var files = [];
    var sendButton = document.getElementById("kt_contact_submit_button");
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames]
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if (status === "error_file_size") {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        //setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        
        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('organization_id', organization_id);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-report-documents`, headers, body }
    }

    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }

    const delete_document = (e) => {
        e.preventDefault();
        
        var values = { "id": props.match.params.id,"image_id":image_id,"file_name":file_name,"original_file_name":original_file_name,"folder":folder_name,"page_name": page_name,"organization_id":organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-report-document`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Document deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Select_Advance = (e) => {
        e.preventDefault();

        var values = { "id": props.match.params.id,"unreported_payment":UnreportedPayment.filter(option => option.selected == true), "page_name": page_name };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/select-advance`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance selected successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Select_Expense = (e) => {
        e.preventDefault();

        var values = { "id": props.match.params.id,"unreported_expense":UnreportedExpense.filter(option => option.selected == true), "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/select-expense`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense selected successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const removeattachments = (file_name,original_file_name,id) => {
        setimage_id(id);
        setfile_name(file_name); 
        setoriginal_file_name(original_file_name);   
   }

   const submitPaymentDetails = (e) => {
    e.preventDefault();

    var object = {};
    object.id = advance_id;
    object.Expense_date = Expense_date;
    object.trip_id = trip_id;
    object.report_id = props.match.params.id;
    object.Currency = Currency;
    object.Amount = Amount;
    object.References = References;
    object.description = description;
    object.Account = Account;
    object.page_name = page_name;
    
    if (Expense_date == "") {
        swal("Warning!", "Date cannot be a empty", "warning");
        return false;
    }
    var cexp=/^[0-9]+$/
    if (Amount == "") {
        swal("Warning!", "Amount cannot be a empty", "warning");
        return false;
    }
    /*else if (!Amount.match(cexp)) {
        swal("Warning!", " Amount cannot contain certain symbols ", "warning");
        return false;
    }
    */
    setMainSubmitButtonState(true)
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }
    if(advance_id=="")
    {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-payment`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance payment(s) created successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    else
    {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-payment`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance payment(s) updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }   
}

const updatePayment = (id) => {
    for (let i = 0; i < advance_list.length; i++) {
        if (id == advance_list[i].unique_id) {
            setadvance_id(id)
            setExpense_date(moment(advance_list[i].date).format("yyyy-MM-DD"))
            settrip_id(advance_list[i].trip_id);
            setAccount(advance_list[i].paid_through_account);
            setCurrency(advance_list[i].currency);
            setAmount(advance_list[i].amount);
            setdescription(advance_list[i].notes);
            setReferences(advance_list[i].reference);
        }
    }
}

const submitPayment = (id) => {
    setadvance_id("")
    setExpense_date("")
    settrip_id("");
    setAccount("");
    setCurrency("");
    setAmount("");
    setdescription("");
    setReferences("");       
}

const removePayment = (id) => {
    setadvance_id(id)
}

const removeExpense = (id) => {
    setexpense_id(id)
}

const updateExpense = (id) => {
    for (let i = 0; i < expense_list.length; i++) {
        if (id == expense_list[i].expense_id) {
            setexpense_id(id)
            setExpense_date(moment(expense_list[i].expense_date).format("yyyy-MM-DD"))
            setMultipleMode(true)
            setExpenseAccounts(JSON.parse(expense_list[i].accounts));
            setMerchant(expense_list[i].merchant_id);
            setReport(expense_list[i].report_id);
            setClaim_reimbursement(expense_list[i].claim_reimbursement) 
            setAccount(expense_list[i].paid_through_account);
            setCurrency(expense_list[i].currency);
            setAmount(expense_list[i].amount);
            setReferences(expense_list[i].reference);
        }
    }
}

const submitExpense = (id) => {
    setexpense_id("")
    setExpense_date("")
    setMultipleMode(false)
    setExpenseAccounts([{account: null, amount: 0, notes: ""}])
    setMerchant("");
    setClaim_reimbursement(true) 
    setAccount("");
    setCurrency(Currency1);
    setAmount("");
    setReferences("");       
}

const Remove_Advance = () => {
    
    var values = { "id": props.match.params.id,"advance_id":advance_id, "page_name": page_name };
    setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/remove-advance`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense removed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });     
}

const Remove_Expense = () => {

    var values = { "id": props.match.params.id,"expense_id":expense_id, "page_name": page_name,"organization_id":organization_id };
    setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/remove-expense`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance removed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });       
}

const submitReports = (e) => {
    e.preventDefault();

    if (approver == "") {
        swal("Warning!", "Approver name cannot be a empty", "warning");
        return false;
    }
    setMainSubmitButtonState(true)
    var values = { "id": props.match.params.id,"approver":approver,"page_name": page_name,"organization_id":organization_id };
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }
    if(approver!=user.email)
    {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Reports submitted successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    else
    {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Reports submitted and approved successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }
}

const submitReportDetails = (e) => {
    e.preventDefault();
    var object = {};
    object.id = props.match.params.id;
    object.trip_id = trip_id;
    object.report_name = ReportName;
    object.objective = description;
    object.start = start;
    object.end = end;
    object.page_name = page_name;
    object.organization_id=organization_id;
    
    var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
    if (ReportName == "") {
        swal("Warning!", "Report Name cannot be empty", "warning");
        return false;
    }
    else if (!ReportName.match(nexp)) {
        swal("Warning!", "Report Name cannot contain certain symbols ", "warning");
        return false;
    }
    else if (true) {
        var oname = editmodal.filter((item) => { return item.report_id !== props.match.params.id })
        for (var i = 0; i < oname.length; ++i) {
            if (oname[i].ReportName == ReportName) {
                swal("Oops!", "Report Name already exists, try different one!", "error");
                return false;
            }
        }
    }
    if (object.trip_id == "") {
        swal("Warning!", "Trip name cannot be a empty", "warning");
        return false;
    }

    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }
    axios.post(`${process.env.REACT_APP_SERVER_URL}/update-reports`, object, headers).then((res) => {
        if (res.data && res.data.operation == "success") {
            swal("Great!", "Report details updated successfully!", "success").then((value) => {
                window.location.reload();
            });
        }
        else {
            swal("Oops!", "Something went wrong!", "error");
        }
    });
 }
 const submit_Reort = () => {
    setReportName(ReportDetails[0].report_name)
    setdescription(ReportDetails[0].objective)
    setstart(moment(ReportDetails[0].start_date).format("YYYY-MM-DD"))
    setend(moment(ReportDetails[0].end_date).format("YYYY-MM-DD"))
    settrip_id(ReportDetails[0].trip)    
}

const submitStatus = (e) => {
    e.preventDefault();

    var values = { "id": props.match.params.id, "description": description, "page_name": page_name,"organization_id":organization_id };
    setMainSubmitButtonState(true)
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}/reject-reports`, values, headers).then((res) => {
        setMainSubmitButtonState(false)
        if (res.data && res.data.operation == "success") {
            swal("Great!", "Report rejected successfully!", "success").then((value) => {
                window.location.reload();
            });
        }
        else {
            swal("Oops!", "Something went wrong!", "error");
        }
    });
}


const approveReports = (e) => {
    e.preventDefault();
    setMainSubmitButtonState(true)
    var values = { "id": props.match.params.id,"page_name": page_name,"organization_id":organization_id };
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-reports`, values, headers).then((res) => {
        setMainSubmitButtonState(false)
        if (res.data && res.data.operation == "success") {
            swal("Great!", "Reports approved successfully.", "success").then((value) => {
                window.location.reload();
            });
        }
        else {
            swal("Oops!", "Something went wrong!", "error");
        }
    });
}

const expense_rule_check = (account,indx) => {
    if(account!=null)
    {
        setIndex(indx);
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
        {
            setExpense_Amount_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
        }
        else
        {
            setExpense_Amount_Limit(General_Limits[0].Expense_Amount_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
        {
            setReceipt_Required_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
        }
        else
        {
            setReceipt_Required_Limit(General_Limits[0].Receipt_Required_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
        {
            setMake_Description_Mandatory(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
        }
        else
        {
            setMake_Description_Mandatory(General_Limits[0].Make_Description_Mandatory);
        }     
    }
}

const violation_check = (index,account,notes,amount) => {
    if(account!=null)
    {       
        let Expense_Amount= "";
        let Receipt_Required= "";
        let Make_Description= false;
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
        {
            Expense_Amount= (Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
        }
        else
            {if(General_Limits!="")
            {
                Expense_Amount= (General_Limits[0].Expense_Amount_Limit);
            }
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
        {
            Receipt_Required= (Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
        }
        else
        {
            if(General_Limits!="")
            {
                Receipt_Required= (General_Limits[0].Receipt_Required_Limit);
            }
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
        {
            Make_Description=(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
        }
        else
        {
            if(General_Limits!="")
            {
                Make_Description=(General_Limits[0].Make_Description_Mandatory);
            }
        }
        console.log(Expense_Amount,Receipt_Required,amount)
        if(parseInt(Expense_Amount)< parseInt(amount) || parseInt(Receipt_Required)< parseInt(amount) || (notes=="" && Make_Description=="Yes"))
        {
            let temp = [...expenseAccounts];
            temp[index].Violation=true;
            temp[index].account=account;
            temp[index].amount=parseInt(amount);
            temp[index].notes=notes;
            setExpenseAccounts(temp);
        }
        else
        {
            let temp = [...expenseAccounts];
            temp[index].account=account;
            temp[index].Violation=false;
            temp[index].amount=parseInt(amount);
            temp[index].notes=notes;
            setExpenseAccounts(temp);
        }  
    }
}

const load_currency_conversion_value = (val) => {
    let target_currency_code=AllCurrencylist.filter(option =>
        option.currency_id == val)[0].currency_code;
        let conversion_value=0;
        if(target_currency_code!="USD")
        {
            conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code])*100)/100;
        }
        else
        {
            conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode])*100)/100;
        }
    setTargetCurrencyCode(target_currency_code)
    setCurrency_conversion_value(conversion_value);
}

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Report Details
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-stretch">
                            <div className="topbar-item px-3 px-lg-5" id="kt_activities_toggle1" title="Activity Logs">
                                <button id="ember2443" class="btn btn-link p-2 ember-view" onClick={(e) => { setWizard("History") }} type="button"> Comments &amp; History
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card  pt-0 pb-15 px-3 px-xl-10">
                            <br />
                            {loading ?
                                <>
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card mt-5 mt-xxl-8">
                                            <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                                <ul className="nav flex-wrap border-transparent fw-bolder col-12" role="tablist">
                                                    <li className="nav-item my-1 col-3" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Basic Information</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-3" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">Expenses</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-3" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Advances</a>
                                                        <span class="badge badge-count badge-default">{advance_list.length}</span>
                                                    </li>
                                                    <li className="nav-item my-1 col-3" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_4">Documents</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-xxl-stretch mb-5 mb-xl-10">
                                        <div className="card-body py-3">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                        <div className="card-header cursor-pointer">
                                                            <div className="card-title m-0">
                                                                <h3 className="fw-bolder m-0">Report Details</h3>
                                                            </div>
                                                                <span className="badge badge-light fw-bolder my-2">
                                                                <div className="btn-toolbar">
                                                                    {ReportDetails[0].add_status=="Active" && <button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-bs-toggle="modal" data-bs-target="#submitReport">Submit Report</button>}
                                                                    <button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-ember-action=""  data-bs-toggle="modal" data-bs-target="#EditReport" onClick={(e) => { submit_Reort() }}>Edit Report</button>
                                                                    {ReportDetails[0].add_status=="Submitted" &&<button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-ember-action=""  data-bs-toggle="modal" data-bs-target="#approveReport" >Approve</button>} 
                                                                    {ReportDetails[0].add_status=="Approved" &&<button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-ember-action=""  data-bs-toggle="modal" data-bs-target="#rejectReport" onClick={(e) => { submit_Reort() }}>Reject</button>}  
                                                                </div>
                                                                </span>
                                                        </div>
                                                        <div className="card-body p-9">
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Report Name</label>
                                                                <div className="col-lg-8">
                                                                    <span className="fw-bolder fs-6 text-dark">{ReportDetails[0].report_name}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Duration</label>
                                                                <div className="col-lg-8 fv-row">
                                                                    <span className="fw-bold fs-6">{moment(ReportDetails[0].start_date).format(DateFormat)+" to "+moment(ReportDetails[0].end_date).format(DateFormat)} </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                            <label className="col-lg-4 fw-bold text-muted">Report Status
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    {ReportDetails[0].add_status=="Approved" ?<span className="badge badge-success">Approved</span>
                                                                    :
                                                                    ReportDetails[0].add_status=="Submitted" ?<span className="badge badge-warning">Awaiting Approval</span>
                                                                    :
                                                                    <span className="badge badge-primary">Draft</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Total
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    <span className="fw-bolder fs-6 me-2">{Currency} {Total_amount}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Non-reimbursable Amount
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    <span className="fw-bolder fs-6 me-2">{Currency} (-){Nonreimbursable_amount}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Applied Advance Amount
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    <span className="fw-bolder fs-6 me-2">{Currency} (-){Applied_advance_amount}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Amount to be Reimbursed:
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    <span className="fw-bolder fs-6 me-2">{Currency} {Total_amount-Nonreimbursable_amount-Applied_advance_amount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_table_widget_5_tab_2">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view2">
                                                        <div className="card-header cursor-pointer">
                                                            <div className="card-title m-0">
                                                                <h3 className="fw-bolder m-0">Expenses</h3>
                                                            </div>
                                                            {ReportDetails[0].add_status=="Active" &&
                                                            <span className="badge badge-light fw-bolder my-2">
                                                                <div className="btn-toolbar">
                                                                {UnreportedExpense.length>0 &&<button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-bs-toggle="modal" data-bs-target="#SelectExpense">Add Unreported Expense ({UnreportedExpense.length})</button>}
                                                                <button className="btn btn-sm btn-primary font-weight-semibold mt-2" type="button" data-ember-action=""  data-bs-toggle="modal" data-bs-target="#AddExpense" onClick={(e) => { submitExpense(); setloading(true); }}>
                                                                   New Expense</button> 
                                                                </div>
                                                            </span>
                                                            }
                                                        </div>
                                                        <div className="row gy-5 g-xl-8">
                                                        {expense_list.length > 0 && expense_list.map((obj, index) => {
                                                            return (
                                                                <>
                                                                    <div className="col-md-6 fv-row">
                                                                        <div className="card card-flush bg-light-success h-lg-100">
                                                                            <div className="card-header mt-6">
                                                                                <div className="card-title flex-column">
                                                                                    <div className="d-flex align-items-sm-center mb-7">
                                                                                        
                                                                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                                                            <div className="flex-grow-1 me-2">
                                                                                                <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">
                                                                                                    <span>{obj.category_name}</span>
                                                                                                    
                                                                                                </a>
                                                                                                <span className="text-muted fw-bold d-block fs-7"></span>
                                                                                            </div> 
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-toolbar">
                                                                                {ReportDetails[0].add_status=="Active" &&
                                                                                    <div class="dropdown">
                                                                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                                                                        Action
                                                                                        </button>
                                                                                        {
                                                                                            write ?
                                                                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                    <li><a href="#" data-bs-toggle="modal" data-bs-target="#AddExpense" onClick={(e) => { updateExpense(obj.expense_id); setloading(true); }} className="dropdown-item">Edit Expense</a></li>
                                                                                                    <li><a  data-bs-toggle="modal" onClick={(e) => { removeExpense(obj.expense_id) }} data-bs-target="#RemoveExpense" class="dropdown-item" href="#">Remove Expense</a></li>
                                                                                                </ul>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                    </div>
                                                                                }
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-wrap">
                                                                            <div className="card-body p-9">
                                                                                    <div className="fs-6 d-flex justify-content-between mb-4">
                                                                                        <div className="fw-bold">Date</div>
                                                                                        <div className="d-flex fw-bolder">
                                                                                        {moment(obj.expense_date).format("ll")}</div>
                                                                                    </div>
                                                                                    <div className="separator separator-dashed"></div>
                                                                                    <div className="fs-6 d-flex justify-content-between my-4">
                                                                                        <div className="fw-bold">Claim reimbursement </div>
                                                                                        <div className="d-flex fw-bolder">
                                                                                        {obj.claim_reimbursement==1 ?<span className="badge badge-success">True</span>:<span className="badge badge-danger">False</span>}</div>
                                                                                    </div>
                                                                                    <div className="separator separator-dashed"></div>
                                                                                    <div className="fs-6 d-flex justify-content-between mt-4">
                                                                                        <div className="fw-bold">Amount</div>
                                                                                        <div className="d-flex fw-bolder">
                                                                                            {obj.currency_symbol}{obj.amount}</div>
                                                                                    </div><br />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                        {
                                                            expense_list.length === 0 &&
                                                            <>
                                                            <div className="flex-lg-row-fluid me-lg-15">
                                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                                    <div className="card-body pt-0">
                                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                                        <div className="d-flex align-items-center p-3 mb-2">
                                                                        </div>
                                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                                <div className="fw-bold">
                                                                                    <h4 className="text-gray-800 fw-bolder">No Expense Available</h4>
                                                                                    <div className="fs-6 text-gray-600">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_table_widget_5_tab_3">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view1">
                                                        <div className="card-header cursor-pointer">
                                                            <div className="card-title m-0">
                                                                <h3 className="fw-bolder m-0">Advances</h3>
                                                            </div>
                                                            {ReportDetails[0].add_status=="Active" &&
                                                            <span className="badge badge-light fw-bolder my-2">
                                                                <div className="btn-toolbar">
                                                                    {(UnreportedPayment.length>0 ) &&<button className="btn btn-sm btn-primary font-weight-semibold mt-2 me-1" type="button" data-bs-toggle="modal" data-bs-target="#SelectAdvance">Apply Advance ({UnreportedPayment.length})</button>}
                                                                    <button className="btn btn-sm btn-primary font-weight-semibold mt-2" onClick={(e) => { submitPayment() }} type="button" data-ember-action=""  data-bs-toggle="modal" data-bs-target="#EditModal">
                                                                    Record Advance
                                                                    </button>
                                                                </div>
                                                            </span>
                                                            }
                                                        </div>
                                                        {advance_list.length > 0 && 
                                                            <div className="card-body p-9">
                                                                <div className="table-responsive mb-10">
                                                                    <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700" data-kt-element="items">
                                                                        <thead>
                                                                            <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                                                                <th className="min-w-100px w-150px">Date </th>
                                                                                <th className="min-w-100px w-150px">Recorded By</th>
                                                                                <th className="min-w-100px w-150px">Reference </th>
                                                                                <th className="min-w-100px w-150px">Amount </th>
                                                                                {ReportDetails[0].add_status=="Active" &&<th className="min-w-25px w-25px">Action</th>}
                                                                            </tr>
                                                                        </thead>
                                                                        {advance_list.map((obj, index) => {
                                                                        return (
                                                                        <>
                                                                        <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                                            <td className="pt-8 text-nowrap">{moment(obj.date).format("ll")}</td>
                                                                            <td className="pt-8 text-nowrap">{obj.user_id}</td>
                                                                            <td className="pt-8 text-nowrap">{obj.reference}</td>
                                                                            <td className="pt-8 text-nowrap">{obj.amount}</td>
                                                                            <td className="pt-8 text-nowrap">
                                                                            {ReportDetails[0].add_status=="Active" &&<div class="dropdown">
                                                                                    <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                                                                    Action
                                                                                    </button>
                                                                                
                                                                                    
                                                                                    {
                                                                                        write ?
                                                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li><a href="#" onClick={(e) => { updatePayment(obj.unique_id) }}  data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit Advance</a></li>
                                                                                                <li><a  data-bs-toggle="modal" onClick={(e) => { removePayment(obj.unique_id) }} data-bs-target="#RemoveAdvance" class="dropdown-item" href="#">Remove Advance</a></li>
                                                                                            </ul>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                </div>
                                                                            }
                                                                            </td>
                                                                        </tr>
                                                                        </>
                                                                        );
                                                                    })}

                                                                        <tbody>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            advance_list.length === 0 &&
                                                            <>
                                                            <div className="flex-lg-row-fluid me-lg-15">
                                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                                    <div className="card-body pt-0">
                                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                                        <div className="d-flex align-items-center p-3 mb-2">
                                                                        </div>
                                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                                <div className="fw-bold">
                                                                                    <h4 className="text-gray-800 fw-bolder">No Advances Available</h4>
                                                                                    <div className="fs-6 text-gray-600">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_table_widget_5_tab_4">
                                                    <div className="timeline-content mb-10 mt-n1 py-4">
                                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                            <div className="flex-grow-1 me-2">
                                                                <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Report Documents</a>
                                                                <span className="text-muted fw-bold d-block fs-7"></span>
                                                            </div>
                                                            <span className="badge badge-light fw-bolder my-2">
                                                            {(document_list.length<document_count && write==1)&&<button type="button" className="btn font-weight-bold btn-primary" data-bs-toggle="modal" data-bs-target="#EditAttachments"><i className="la la-plus"></i>Add Document</button>}
                                                            </span>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                                                {document_list.length>0 &&
                                                                    <div className="overflow-auto pb-5 ">
                                                                        
                                                                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-350px p-2">
                                                                            {
                                                                                document_list.map((obj1, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="overlay me-10">
                                                                                                <div className="overlay-wrapper">
                                                                                                    <img id='inside_map_img' alt='img' className="rounded w-200px" src={`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`} />
                                                                                                </div>
                                                                                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                                    <a onClick={() => showImage(`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                                    <a onClick={(e) => { removeattachments(obj1.file_name,obj1.original_file_name,obj1.unique_id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="btn btn-sm btn-primary me-3 rounded">Delete</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    document_list.length === 0 &&
                                                                    <>
                                                                    <div className="flex-lg-row-fluid me-lg-15">
                                                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                                            <div className="card-body pt-0">
                                                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                                                <div className="d-flex align-items-center p-3 mb-2">


                                                                                </div>


                                                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                                    <div className="d-flex flex-stack flex-grow-1">
                                                                                        <div className="fw-bold">
                                                                                            <h4 className="text-gray-800 fw-bolder">No Documents Available</h4>
                                                                                            <div className="fs-6 text-gray-600">
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    </>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="modal fade" id="approveReport" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                        <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Approve Report</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <h3>Are you sure you want to Approve this Report?</h3>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { approveReports(e); }}><span className="indicator-label">Approve</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="modal fade" id="rejectReport" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Reject Report</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <label className="fs-6 fw-bold mb-2">Please specify an appropriate reason for rejection.</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={description} onChange={(e) => { setdescription(e.target.value) }} readOnly={write?false:true}></textarea>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        {description ?<button type="button" className="btn btn-danger"  onClick={(e) => { submitStatus(e); }} disabled>Reject</button>:
                                                        <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"} 
                                                        disabled={mainSubmitButtonState} onClick={(e) => { submitStatus(e); }} ><span className="indicator-label">Reject</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                        }
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="submitReport" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Submit Report</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    {ReportDetails[0].expense_details.length>0 ?
                                                    <div className="form-group row">
                                                        <div className="row mb-5">
                                                            <div className="col-md-12 fv-row">
                                                                <label className="required fs-5 fw-bold mb-2">Select an approver for the report:</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    name="category"
                                                                    id="category"
                                                                    className="form-control form-control-solid"
                                                                    options={approverlist}
                                                                    onChange={(event) => { setapprover(event.value) }}
                                                                    value={approverlist.filter(option => option.value == approver).length != 0 && approverlist.filter(option => option.value == approver)[0]}
                                                                    placeholder="--Choose a approver--"
                                                                />
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    :
                                                    <h4>
                                                        You haven't added any expense to this report yet. Add expenses to submit this report.
                                                    </h4>
                                                    }
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                    {approver==""? <button type="button" className="btn btn-primary" onClick={(e) => { submitReports(e); }} disabled>Submit</button>
                                                    :
                                                    approver==user.email ?<button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitReports(e); }}  ><span className="indicator-label">Approve</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    :
                                                    <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitReports(e); }} ><span className="indicator-label">Submit</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    }
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="AddExpense" tabindex="-1" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered mw-850px">
                                            <div class="modal-content">
                                                <form class="form" action="#" id="kt_modal_new_address_form">
                                                    <div class="modal-header" id="kt_modal_new_address_header">
                                                        <h2>{expense_id=="" ?"Add":"Update"} Expense</h2>
                                                        <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span class="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="modal-body py-10 px-lg-17">
                                                        <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                        {loading_modal ?
                                                            <>
                                                            <div className="row mb-5">
                                                                <div className="col-md-6 fv-row">
                                                                    <label className="required fs-5 fw-bold mb-2">Expense Date</label>
                                                                    <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                                        onChange={(e) => {
                                                                            setExpense_date(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Merchant</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="category"
                                                                        id="category"
                                                                        className="form-control-solid"
                                                                        options={MerchantList}
                                                                        onChange={(event) => { setMerchant(event.value) }}
                                                                        value={MerchantList.filter(option => option.value == Merchant).length != 0 && MerchantList.filter(option => option.value == Merchant)[0]}
                                                                        placeholder="--Choose a Merchant--"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {
                                                                multipleMode?
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6">
                                                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="Currency"
                                                                                id="Currency"
                                                                                className="form-control-solid"
                                                                                options={Currencylist}
                                                                                onChange={(event) => { setCurrency(event.value);load_currency_conversion_value(event.value);}}
                                                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                placeholder="--Choose a Currency--"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="fs-5 fw-bold mb-2"></label><br/>
                                                                            {(LiveCurrencyData.length>0 && AllCurrencylist.length>0 && Currency!=Currency1)&&
                                                                                <>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row mb-5">
                                                                        <div className=" col-md-3"><p className=" required fs-5">Expense Account</p></div>
                                                                        <div className="col-md-3"><p className="fs-5">Notes</p></div>
                                                                        <div className="col-md-2"><p className="required fs-5">Amount</p></div>
                                                                        <div className="col-md-3"><p className="required fs-5">Violation</p></div>
                                                                    </div>
                                                                    {
                                                                        expenseAccounts.map((obj,indx,arr)=>{
                                                                            return(
                                                                                <div className="row mb-5">
                                                                                    <div className="col-md-3 mb-1">
                                                                                    <Select
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control-solid"
                                                                                        options={CategoryList}
                                                                                        onChange={(val) => {
                                                                                            let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                            temp[indx].account = val
                                                                                            setExpenseAccounts(temp)
                                                                                            violation_check(indx,val,expenseAccounts[indx].notes,expenseAccounts[indx].amount)
                                                                                        }}        
                                                                                        value={expenseAccounts[indx].account}
                                                                                        placeholder="Select"
                                                                                    />
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-1">
                                                                                        <textarea className="form-control form-control-solid" rows="1" value={expenseAccounts[indx].notes} 
                                                                                            onChange={(e) => {
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[indx].notes = e.target.value
                                                                                                setExpenseAccounts(temp);
                                                                                                violation_check(indx,expenseAccounts[indx].account,e.target.value,expenseAccounts[indx].amount) 
                                                                                            }}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                    <div className="col-md-2 mb-1">
                                                                                        <input type="number" className="form-control form-control-solid" value={expenseAccounts[indx].amount} 
                                                                                            onChange={(e) => {
                                                                                                if(parseFloat(e.target.value)>=0){
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp[indx].amount = parseFloat(e.target.value)
                                                                                                    setExpenseAccounts(temp);
                                                                                                    violation_check(indx,expenseAccounts[indx].account,expenseAccounts[indx].notes,parseFloat(e.target.value))  
                                                                                                }
                                                                                            }}
                                                                                        />                                                                        
                                                                                    </div>
                                                                                    {expenseAccounts[indx].Violation==true ?
                                                                                        <div className="col-md-3 mb-1">
                                                                                            <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(obj.account,indx) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-md-3 mb-1"></div>
                                                                                    }
                                                                                    {
                                                                                        arr.length>1 &&
                                                                                        <div className="col-md-1 mb-1">
                                                                                            <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                                onClick={()=>{
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp.splice(indx,1)
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >&#10006;</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="row mb-5">
                                                                        <div className="col-3">
                                                                            <button className='btn btn-primary rounded fs-7' type="button" onClick={()=>{setExpenseAccounts([...expenseAccounts, {account: null, amount: 0, notes: ""}])}}>&#10011; Add a row</button>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <button className='btn btn-primary rounded fs-7' 
                                                                                onClick={()=>{
                                                                                    setMultipleMode(false)
                                                                                    setExpenseAccounts([expenseAccounts[0]])
                                                                                }}
                                                                            >
                                                                                
                                                                            Back to single expense view
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <p className='fw-bolder fs-4 mt-2'>Total Amount{Currency!=""&&<>({Currency})</>}
                                                                                <span className='ps-15'>{expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                            </p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="required fs-5 fw-bold mb-2">Category Name</label>
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="category"
                                                                                id="category"
                                                                                className="form-control form-control-solid"
                                                                                options={CategoryList}
                                                                                onChange={(val) => {
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].account = val
                                                                                    setExpenseAccounts(temp);
                                                                                    violation_check(0,val,temp[0].notes,temp[0].amount);
                                                                                }}        
                                                                                value={expenseAccounts[0].account}
                                                                                placeholder="--Select--"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="required fs-5 fw-bold mb-2">Amount</label>
                                                                            <div className='row'>
                                                                                <div className='col-md-6'>
                                                                                <Select
                                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                    name="Currency"
                                                                                    id="Currency"
                                                                                    className="form-control form-control-solid"
                                                                                    options={Currencylist}
                                                                                    onChange={(event) => { setCurrency(event.value);load_currency_conversion_value(event.value);}}
                                                                                    value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                    placeholder="--Currency--"
                                                                                />
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <input type="number" className="form-control form-control-solid" value={expenseAccounts[0].amount} 
                                                                                        onChange={(e) => {
                                                                                            if(parseFloat(e.target.value)>=0){
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[0].amount = parseFloat(e.target.value)
                                                                                                setExpenseAccounts(temp)
                                                                                                violation_check(0,temp[0].account,temp[0].notes,parseFloat(e.target.value));
                                                                                            }
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                                <>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                                </>
                                                                             }
                                                                        </div>
                                                                        <div className="col-md-4 mt-9">
                                                                            <button className='btn btn-primary rounded' onClick={()=>{setMultipleMode(true)}}>Itemize</button>
                                                                        </div>
                                                                        {expenseAccounts[0].Violation==true ?
                                                                            <div className="col-md-3  mt-9">
                                                                                <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(expenseAccounts[0].account,0) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                            </div>
                                                                            :
                                                                            <div className="col-md-3 mb-1"></div>
                                                                        }
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="fs-6 fw-bold mb-2">Note</label>
                                                                            <textarea className="form-control form-control-solid" rows="3" value={expenseAccounts[0].notes} 
                                                                                onChange={(e) => {
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].notes = e.target.value
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(0,temp[0].account,e.target.value,parseFloat(temp[0].amount));
                                                                                }} 
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                                <input name="deactivate" className="form-check-input" type="checkbox" value="" id="deactivate" onChange={(event) => { setClaim_reimbursement(event.target.checked) }} checked={Claim_reimbursement}/>
                                                                <label className="form-check-label fw-bold ps-2 fs-6" for="deactivate">Claim reimbursement </label>
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {Claim_reimbursement==false &&
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Account"
                                                                        id="Account"
                                                                        className="form-control form-control-solid"
                                                                        options={PaidThrouhAccountlist}
                                                                        onChange={(event) => { setAccount(event.value) }}
                                                                        value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                                        placeholder="Select"
                                                                    />
                                                                </div>
                                                            </div>
                                                            }<br/>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">References </label>
                                                                    <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">description</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                                            </div>
                                                            </>
                                                            :
                                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                        }
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer flex-center">
                                                    {write==1 &&
                                                        <button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { add_expense(e) }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button">
                                                            <span class="indicator-label">{expense_id=="" ?"Submit":"Update"}</span>
                                                            <span class="indicator-progress">Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    }   
                                                    </div>
                                                    <input type="hidden" id="id" name="activeId" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="ViolationModal" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered mw-650px">
                                            <div className="modal-content">
                                                <form className="form" action="#" id="kt_modal_new_address_form">
                                                    <div className="modal-header" id="kt_modal_new_address_header">
                                                        <h2>Warning Violations</h2>
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="modal-body py-10 px-lg-17">
                                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                        {(Expense_Amount_Limit!="" || Receipt_Required_Limit!="" || Make_Description_Mandatory!="")&&
                                                            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                                                {parseInt(Expense_Amount_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Exceeds Expense Amount Limit {BaseCurrency}{parseInt(Expense_Amount_Limit)}</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }<br/>
                                                                {parseInt(Receipt_Required_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Exceeds Receipt Required Limit {BaseCurrency}{parseInt(Receipt_Required_Limit)}</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }<br/>
                                                                {Make_Description_Mandatory=="Yes" && expenseAccounts[Index].notes=="" &&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Description is mandatory.</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }
                                                            </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="RemoveExpense" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Remove Expense</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                    <p className="font-small">If you remove the expense, it will be removed from this report and will be listed as an unreported expense. Are you sure you want to remove it?</p>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { Remove_Expense(e); }} ><span className="indicator-label">Remove</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="RemoveAdvance" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Remove Advance</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                    <p className="font-small">Once you remove this advance, it will be listed as an unreported advance. Are you sure you want to remove it?</p>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { Remove_Advance(e); }} ><span className="indicator-label">Remove</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="SelectExpense" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Select Expense</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <label className="d-flex align-items-center form-label mb-5">Select Expense 
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Monthly billing will be based on your account plan" aria-label="Monthly billing will be based on your account plan"></i></label>
                                                            <div className="mb-0">	
                                                            {(UnreportedExpense.length > 0 && AllCurrencylist.length>0 ) && UnreportedExpense.map((obj1, index) => {
                                                            return (
                                                            <>
                                                            <div class="d-flex align-items-center mb-8">
                                                                <span class="bullet bullet-vertical h-40px bg-success"></span>
                                                                    <div class="form-check form-check-custom form-check-solid mx-5">
                                                                        <input class="form-check-input" type="checkbox" id={obj1.advance_id} value="" onClick={(e) => {
                                                                            const values1 = [...UnreportedExpense];
                                                                            values1.filter(obj => obj.advance_id ==obj1.advance_id)[0].selected=e.target.checked;
                                                                            setUnreportedExpense(values1); 
                                                                        }}/>
                                                                    </div>
                                                                    <div class="flex-grow-1">
                                                                        <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{AllCurrencylist.filter(option =>
                                                                    option.currency_id == obj1.currency)[0].currency_symbol}{obj1.amount}</a>
                                                                        <span class="text-muted fw-bold d-block">{moment(obj1.date).format("ll")} , Category:{JSON.parse(obj1.accounts).length>1 ?<span>Itemize</span> : JSON.parse(obj1.accounts)[0].account.label}</span>
                                                                    </div>
                                                                <span class="badge badge-light-success fs-8 fw-bolder">Unreported</span>
                                                            </div>  
                                                            </>
                                                            );
                                                        })}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { Select_Expense(e); }} ><span className="indicator-label">Save</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="modal fade" id="SelectAdvance" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Select Advance</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">

                                                        <div className="col-lg-12">
                                                            <label className="d-flex align-items-center form-label mb-5">Select Advance 
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Monthly billing will be based on your account plan" aria-label="Monthly billing will be based on your account plan"></i></label>
                                                            <div className="mb-0">	
                                                            {UnreportedPayment.length > 0 && UnreportedPayment.map((obj1, index) => {
                                                            return (
                                                            <>

                                                                <div class="d-flex align-items-center mb-8">
                                                                    
                                                                    <span class="bullet bullet-vertical h-40px bg-success"></span>
                                                                    
                                                                    <div class="form-check form-check-custom form-check-solid mx-5">
                                                                        <input class="form-check-input" type="checkbox" id={obj1.unique_id} value="" onClick={(e) => {
                                                                            const values1 = [...UnreportedPayment];
                                                                            values1.filter(obj => obj.unique_id ==obj1.unique_id)[0].selected=e.target.checked;
                                                                            setUnreportedPayment(values1);  
                                                                            
                                                                        }}/>
                                                                    </div>
                                                                    
                                                                    <div class="flex-grow-1">
                                                                        <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{Currency}{obj1.amount}</a>
                                                                        <span class="text-muted fw-bold d-block">{moment(obj1.date).format("ll")} ,  Reference:{obj1.reference}</span>
                                                                    </div>
                                                                    
                                                                    <span class="badge badge-light-success fs-8 fw-bolder">Unreported</span>
                                                                </div>  
                                                            </>
                                                            );
                                                        })}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-primary" onClick={(e) => { Select_Advance(e); }} >Save</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Delete Documents</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12"> 
                                                            <h3>Are you sure you want to delete this Documents?</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"}  disabled={mainSubmitButtonState} onClick={(e) => { delete_document(e);}} ><span className="indicator-label">Delete</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered mw-650px">
                                            <div className="modal-content">
                                                <form className="form" action="#" id="kt_modal_new_address_form">
                                                    <div className="modal-header" id="kt_modal_new_address_header">
                                                        <h2>Record Advance</h2>
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body py-10 px-lg-17">
                                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                                        <div className="row mb-5">
                                                            <div className="col-md-6 fv-row">
                                                                <label className="required fs-5 fw-bold mb-2">Date:</label>
                                                                <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                                    onChange={(e) => {
                                                                        setExpense_date(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            </div>
                                                        
                                                            <div className="row mb-5">
                                                                <div className="col-md-3 fv-row">
                                                                    <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Currency"
                                                                        id="Currency"
                                                                        className="form-control form-control-solid"
                                                                        options={Currencylist}
                                                                        onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                                        value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                        placeholder="Select"
                                                                    />
                                                                </div>
                                                                <div className="col-md-3 fv-row">
                                                                    <label className="required fs-5 fw-bold mb-2">Amount </label>
                                                                    <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={Amount} onChange={(e) => { setAmount(e.target.value) }} />
                                                                </div>
                                                                {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                    <>
                                                                    <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                    <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                    </>
                                                                }
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Account"
                                                                        id="Account"
                                                                        className="form-control form-control-solid"
                                                                        options={PaidThrouhAccountlist}
                                                                        onChange={(event) => { setAccount(event.value) }}
                                                                        value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                                        placeholder="Select"
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">References#</label>
                                                                    <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                                                </div>

                                                            </div>
                                                            <div className="row mb-5">
                                                            <div className="col-md-12 fv-row">
                                                                <label className="fs-5 fw-bold mb-2">Apply to Trip:</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    name="category"
                                                                    id="category"
                                                                    className="form-control form-control-solid"
                                                                    options={TripList}
                                                                    onChange={(event) => { settrip_id(event.value) }}
                                                                    value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                                                    placeholder="Select"
                                                                />
                                                            </div>
                                                        </div> 
                                                            <div className="row mb-5">

                                                                <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Notes</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer flex-center">
                                                    {write==1 &&
                                                        <button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitPaymentDetails(e) }} type="button" className="btn btn-primary rounded" id="kt_contact_submit_button">
                                                            <span className="indicator-label">{advance_id!=""?"Update":"submit"}</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    }   
                                                    </div>
                                                    <input type="hidden" id="id" name="activeId" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="EditAttachments" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Attach Documents</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        {(document_list.length<document_count)&&
                                                            <div>
                                                                <label className="fs-5 fw-bold mb-2">Maximum {document_count-document_list.length} files</label>
                                                                <Dropzone
                                                                    getUploadParams={getUploadParams}
                                                                    onChangeStatus={handleChangeStatus2}
                                                                    onSubmit={handleSubmit}
                                                                    validate={handleValidation2}
                                                                    LayoutComponent={CustomLayout}
                                                                    styles={{
                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                    }}
                                                                    maxFiles={document_count-document_list.length}
                                                                    // canRemove={false}
                                                                    PreviewComponent={Preview}
                                                                    accept="image/*,.pdf"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" id="kt_contact_submit_button" data-bs-dismiss="modal">Cancel</button>
                                                    {write==1&&
                                                    <form >
                                                        <button type="button" className="btn btn-primary" id="kt_contact_submit_button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitattachments(e); }} ><span className="indicator-label">Save</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </form>
                                                    }
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </>
                                :
                                (state == 1 ) ?
                                <>

                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2">


                                                </div>


                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                            <div className="fs-6 text-gray-600">
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </>
                                : <>
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />

                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                        </div>
                                    </div>
                                </>
                            }

                            <br />
                        </div>
                    </div>
                </div>
                <div id="kt_activities" className="bg-white" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true"  data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle1" data-kt-drawer-close="#kt_activities_close">
                    <div className="card shadow-none"> 
                        <div className="card-header" id="kt_activities_header">
                            <div className="card-toolbar ">
                                <div className="card-toolbar flex-row align-items-center me-18" style={{ minHeight: "40px", padding: "0px" }}>
                                    <div className={`btn btn${navigation.history ? "" : "-light"}-primary flex-fill`} onClick={(e) => { setWizard("History");}}>History</div>
                                </div>
                                <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                    
                                </button>
                            </div>
                        </div>
                        
                        
                        <div className="card-body position-relative" id="kt_activities_body">
                                {Wizard=="Comment" ?
                                <div className="timeline">
                                    <span className="svg-icon svg-icon-3 svg-icon-primary">
                                        
                                        <div class="ml-3 w-100">
                                            <textarea rows="2" placeholder="Add your comment here." id="ember285" class="ember-text-area ember-view form-control" onChange={(event) => {setcomments(event.target.value); }} value={comments}></textarea>
                                            {write==1&&
                                            <button class="btn btn-primary mt-2" type="button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitcomments(e) }}>
                                            <span className="indicator-label">Add Comment</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            }
                                        </div><br/>
                                        
                                    </span>
                                    {
                                        alert_log.length > 0 && alert_log.map((obj, index) => {
                                            if(obj.operation=="comment")
                                            {
                                                return(
                                                    <>
                                                        <div class="d-flex flex-column align-items-start">
                                                            
                                                            <div class="d-flex align-items-center mb-2">
                                                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                                <div className="symbol-label bg-light">
                                                                <svg id="edit-svg" style={{"width":"50%","height": "60%"}} mi-view-box="0 0 1024 1024" viewBox="0 0 1024 1024">
                                                                <rect fill="white" x="0" y="0" mi-width="1024" mi-height="1024" width="1024" height="1024"></rect>
                                                                <g>
                                                                    <path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M935.724 58.138h-847.448c-48.057 0-88.276 40.218-88.276 88.276v582.621c0 48.057 40.218 88.276 88.276 88.276h488.713l-78.371-91.118c-6.356-7.38-5.526-18.538 1.871-24.894s18.556-5.508 24.911 1.871l88.241 102.594 145.532 160.097v-148.551h176.552c48.057 0 88.276-40.218 88.276-88.276v-582.621c0-48.057-40.218-88.276-88.276-88.276z" mi-fill="rgb(38, 185, 154)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M935.724 58.138h-847.448c-48.057 0-88.276 40.218-88.276 88.276v582.621c0 48.057 40.218 88.276 88.276 88.276h488.713l-78.371-91.118c-6.356-7.38-5.526-18.538 1.871-24.894s18.556-5.508 24.911 1.871l88.241 102.594 145.532 160.097v-148.551h176.552c48.057 0 88.276-40.218 88.276-88.276v-582.621c0-48.057-40.218-88.276-88.276-88.276z" fill="rgb(38, 185, 154)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M459.034 322.966h-264.828c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h264.828c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M459.034 322.966h-264.828c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h264.828c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M794.483 464.207h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M794.483 464.207h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M794.483 605.449h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M794.483 605.449h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path>
                                                                </g>
                                                                <g ng-show="pref.showGrid" class="edit-grid ng-hide">
                                                                    <g class="hLines"></g>
                                                                    <g class="vLines"></g>
                                                                </g>
                                                                </svg>
                                                                </div>
                                                            </div>
                                                                
                                                                <div class="ms-3">
                                                                    <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">{obj.user_id}</a>
                                                                    <span class="text-muted fs-7 mb-1">{moment(obj.log_date_time).zone(timezone_change).format('llll')}</span>
                                                                </div>
                                                                
                                                            </div>
                                                            <div class="d-flex justify-content-end flex-shrink-0">
                                                            <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start me-2" data-kt-element="message-text">{obj.comment}
                                                            
                                                            </div>
                                                            <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-lg">			
                                                                <span class="svg-icon svg-icon-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </>

                                            
                                            );
                                        }
                                    })}
                            
                                </div>
                                :
                                <div className="timeline">
                                    <br/>
                                    {
                                        alert_log.length > 0 ? alert_log.map((obj, index) => {
                                            if(index < Count)
                                            {
                                                return(
                                                <div className="timeline-item">
                                        
                                                    <div className="timeline-line w-40px"></div>
                                                    
                                                    
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            
                                                            
                                                                {obj.operation=="comment" ? 
                                                                <svg id="edit-svg" style={{"width":"50%","height": "60%"}} mi-view-box="0 0 1024 1024" viewBox="0 0 1024 1024">
                                                                <rect fill="white" x="0" y="0" mi-width="1024" mi-height="1024" width="1024" height="1024"></rect>
                                                                <g>
                                                                    <path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M935.724 58.138h-847.448c-48.057 0-88.276 40.218-88.276 88.276v582.621c0 48.057 40.218 88.276 88.276 88.276h488.713l-78.371-91.118c-6.356-7.38-5.526-18.538 1.871-24.894s18.556-5.508 24.911 1.871l88.241 102.594 145.532 160.097v-148.551h176.552c48.057 0 88.276-40.218 88.276-88.276v-582.621c0-48.057-40.218-88.276-88.276-88.276z" mi-fill="rgb(38, 185, 154)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M935.724 58.138h-847.448c-48.057 0-88.276 40.218-88.276 88.276v582.621c0 48.057 40.218 88.276 88.276 88.276h488.713l-78.371-91.118c-6.356-7.38-5.526-18.538 1.871-24.894s18.556-5.508 24.911 1.871l88.241 102.594 145.532 160.097v-148.551h176.552c48.057 0 88.276-40.218 88.276-88.276v-582.621c0-48.057-40.218-88.276-88.276-88.276z" fill="rgb(38, 185, 154)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M459.034 322.966h-264.828c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h264.828c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M459.034 322.966h-264.828c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h264.828c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M794.483 464.207h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M794.483 464.207h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path><path class="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M794.483 605.449h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" mi-fill="rgb(255, 255, 255)" mi-stroke="inherit" mi-stroke-width="inherit" mi-stroke-linejoin="inherit" mi-stroke-linecap="inherit" mi-stroke-miterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M794.483 605.449h-600.276c-9.763 0-17.655-7.91-17.655-17.655s7.892-17.655 17.655-17.655h600.276c9.763 0 17.655 7.91 17.655 17.655s-7.892 17.655-17.655 17.655z" fill="rgb(255, 255, 255)" stroke="inherit" stroke-width="inherit" stroke-linejoin="inherit" stroke-linecap="inherit" stroke-miterlimit="inherit" opacity="1">
                                                                    </path>
                                                                </g>
                                                                <g ng-show="pref.showGrid" class="edit-grid ng-hide">
                                                                    <g class="hLines"></g>
                                                                    <g class="vLines"></g>
                                                                </g>
                                                                </svg>
                                                                :
                                                                <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <path d="M11.6734943,8.3307728 L14.9993074,6.09979492 L14.1213255,5.22181303 C13.7308012,4.83128874 13.7308012,4.19812376 14.1213255,3.80759947 L15.535539,2.39338591 C15.9260633,2.00286161 16.5592283,2.00286161 16.9497526,2.39338591 L22.6066068,8.05024016 C22.9971311,8.44076445 22.9971311,9.07392943 22.6066068,9.46445372 L21.1923933,10.8786673 C20.801869,11.2691916 20.168704,11.2691916 19.7781797,10.8786673 L18.9002333,10.0007208 L16.6692373,13.3265608 C16.9264145,14.2523264 16.9984943,15.2320236 16.8664372,16.2092466 L16.4344698,19.4058049 C16.360509,19.9531149 15.8568695,20.3368403 15.3095595,20.2628795 C15.0925691,20.2335564 14.8912006,20.1338238 14.7363706,19.9789938 L5.02099894,10.2636221 C4.63047465,9.87309784 4.63047465,9.23993286 5.02099894,8.84940857 C5.17582897,8.69457854 5.37719743,8.59484594 5.59418783,8.56552292 L8.79074617,8.13355557 C9.76799113,8.00149544 10.7477104,8.0735815 11.6734943,8.3307728 Z" fill="#000000" />
                                                                    <polygon fill="#000000" opacity="0.3" transform="translate(7.050253, 17.949747) rotate(-315.000000) translate(-7.050253, -17.949747)" points="5.55025253 13.9497475 5.55025253 19.6640332 7.05025253 21.9497475 8.55025253 19.6640332 8.55025253 13.9497475" />
                                                                </svg>
                                                                </span>
                                                                }
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="timeline-content mb-10 mt-n2">
                                                        
                                                        <div className="overflow-auto pe-3">
                                                            
                                                            <div className="fs-5 fw-bold mb-2">{obj.operation == "insert" ? <>Report Created.</> :obj.operation == "update" ? <>Report Updated.</>:obj.operation == "approve" ? <>Report Approved.</>:obj.operation == "reject" ? <>Report Rejected.</>:obj.operation == "cancel" ? <>Report Cancelled.</>:obj.operation == "comment" ? <>{obj.comment}</>:<>Report submitted to {obj.user_id}.</>} </div>
                                                            
                                                            
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                            {obj.user_id}: <div className="text-muted me-2 fs-7">{moment(obj.log_date_time).zone(timezone_change).format('llll')}</div>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                );
                                            }
                                        })
                                        :
                                        <>
                                        { state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                            :
                                            null
                                        }
                                        </>
                                    }
                                </div>
                                }
                                
                            </div>
                        {(Wizard=="History" && Count<7) &&<div className="card-footer py-5 text-center" id="kt_activities_footer">
                            <a onClick={(e) => { setCount(50) }} className="btn btn-bg-white text-primary">View All Activities
                            <span className="svg-icon svg-icon-3 svg-icon-primary">
                                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                    </g>
                                </svg>
                            </span>
                            </a>
                        </div>
                        }
                    </div>
                </div>

                </div>
                <div class="modal fade" id="EditReport" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Edit Report</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Report name</label>
                                        <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={ReportName} onChange={(e) => { setReportName(e.target.value) }} />
                                    </div>

                                </div>
                                
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">Start Date</label>
                                        <input type="date" name="start_date" className="form-control" id="start_date" placeholder="Sales Start Date" value={moment(start).format("yyyy-MM-DD")}
                                            onChange={(e) => {
                                                setstart(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">End Date</label>
                                        <input type="date" min={moment(start).format("yyyy-MM-DD")} name="end_date" className="form-control" id="end_date" placeholder="Sales End Date" value={moment(end).format('yyyy-MM-DD')}
                                            onChange={(e) => {
                                                setend(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Trip:</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            options={TripList}
                                            onChange={(event) => { settrip_id(event.value) }}
                                            value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                            placeholder="Select"
                                        />
                                    </div>
                                </div> 
                                <div className="row mb-5">

                                    <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Purpose</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" autocomplete="off" value={description}  onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                </div>
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                                <button onClick={(e) => { submitReportDetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded">
                                    <span class="indicator-label">Update</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ReportDetails;

import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ListApproval = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"));

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data1, setData1] = useState([]);
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [content_flag, setcontent_flag] = useState(0);
    let [Description, setDescription] = useState("");
    const [ReportName, setReportName] = useState("");
    const [description, setdescription] = useState("");
    const [trip_id, settrip_id] = useState("");
    let [report_id, setreport_id] = useState("");
    const [status, setstatus] = useState("");
    let [approver, setapprover] = useState({});
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);

    const [start, setstart] = useState(moment().format(''))
    const [end, setend] = useState('')
    const [userlist, setuserList] = useState([])
    const [DateFormat, setDateFormat] = useState("");
    //let maxdate = moment().format('YYYY-MM-DD');
    const [PendingReportList, setPendingReportList] = useState([])
    const [TripList, setTripList] = useState([])
    const [PendingTripList, setPendingTripList] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    var page_name = "report-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Report',
    };

    const options_trip = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Trip',
    };


    const columns_trip = [
        {
            name: "submitter",
            label: "Submitter",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Trips",
            label: "Trips",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "travel_type",
            label: "Travel Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const columns = [
        {
            name: "submitter",
            label: "Submitter",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "report_name",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "expenses",
            label: "Expenses",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                //setCurrency(body[0].currency);
                //setBaseCurrency(body[0].currency);
                setDateFormat(body[0].date_format)
            }
        }
        if(read || write)
        {
            user.email && visibility && getbasecurrency();
        }
        async function gettripdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-pending-trip`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setTripList(body);

        }
        if(read || write)
        {
            (user.email && visibility) && gettripdetails();
        }

    }, [user, visibility, read, write])


    useEffect(() => {
        async function getreportdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-pending-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            setloading(true);
        }
        if (read || write) {
            user.email && visibility && getreportdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                let temp=[]
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.submitter = obj.user_id;
                    temp_obj.report_name = obj.report_name;
                    temp_obj.duration = moment(obj.start_date).format(DateFormat)+" to "+moment(obj.end_date).format(DateFormat);
                    temp_obj.expenses = obj.expense_details.length;
                    if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                        temp.push(obj)
                    }
                    else if(obj.add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={`/report-details/${id}`}  className="dropdown-item">View details</Link></li>
                                        {obj.add_status=="Submitted" && <li><a onClick={(e) => { approveReport(id) }} data-bs-toggle="modal" data-bs-target="#approveReport" class="dropdown-item" href="#">Approve</a></li>}
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Reject</a></li>
                                        
                                        {/* <li><a href="#" onClick={(e) => { updateReport(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>
                    temp_data.push(temp_obj);
                })
                setPendingReportList(temp)
                setData(temp_data);
                setcontent_flag(1);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    useEffect(() => {
        async function gettripdetails() {
            console.log(TripList)
            if (TripList.length > 0) {
                let temp=[]
                var temp_data = [];
                TripList.map((obj) => {
                    var temp_obj = {};
                    temp_obj.Trips = obj.trip_name;
                    temp_obj.submitter = obj.user_id;
                    temp_obj.travel_type = obj.travel_type;
                    temp_obj.submitter = obj.user_id;
                    temp_obj.duration =  moment(obj.departure_date).format(DateFormat)+" to "+moment(obj.return_date).format(DateFormat);
                    if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                        temp.push(obj)
                    }
                    else if(obj.add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={`/trip-details/${id}`} className="dropdown-item">View details</Link></li>
                                        {obj.add_status=="Approved" && <li><a onClick={(e) => { approveTrip(id) }} data-bs-toggle="modal" data-bs-target="#MarkAsClose" class="dropdown-item" href="#">Mark As Closed</a></li>}
                                        {obj.add_status=="Closed" && <li><a onClick={(e) => { approveTrip(id) }} data-bs-toggle="modal" data-bs-target="#Reopen" class="dropdown-item" href="#">Re-Open</a></li>}
                                        {obj.add_status=="Submitted" && <li><a onClick={(e) => { approveTrip(id) }} data-bs-toggle="modal" data-bs-target="#ApproveTrip" class="dropdown-item" href="#">Approve</a></li>}
                                         <li><a onClick={(e) => { approveTrip(id) }} data-bs-toggle="modal" data-bs-target="#RejectTrip" class="dropdown-item" href="#">Reject</a></li>
                                         <li><a onClick={(e) => { approveTrip(id) }} data-bs-toggle="modal" data-bs-target="#CancelTrip" class="dropdown-item" href="#">Cancel</a></li>

                                    </ul>
                                    :
                                    null
                            }

                        </div>
                    temp_data.push(temp_obj);
                })
                setPendingTripList(temp)
                setData1(temp_data);
            }
            else {
                setData1([]);
            }
        }
        TripList.length > 0 && gettripdetails();

    }, [TripList])

    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                setreport_id(editmodal[i].id)
                setstatus(editmodal[i].add_status);
            }
        }
    }
    const approveReport = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                setreport_id(editmodal[i].id)
            }
        }
    }

    const approveTrip = (id) => {
        for (let i = 0; i < TripList.length; i++) {
            if (id == TripList[i].id) {
                settrip_id(TripList[i].id)
            }
        }
    }

    const Cancel_trip = (e) => {
        e.preventDefault();
    
        var values = { "id": report_id, "description": Description, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-trip`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Trip Cancelled successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const approve_Trip = (e) => {
        e.preventDefault();
        setMainSubmitButtonState(true)
        var values = { "id": trip_id,"page_name": page_name,"organization_id":organization_id };
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-trips`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Trip approved successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const mark_as_close = (e) => {
        e.preventDefault();
    
        var values = { "id": trip_id,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/mark-as-close`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Trip closed successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Reopen_trip = (e) => {
        e.preventDefault();
    
        var values = { "id": trip_id,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/reopen-trip`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Trip reopened successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Reject_Trip = (e) => {
        e.preventDefault();
    
        var values = { "id": report_id, "description": Description, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/reject-trip`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Trip rejected successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitStatus = (e) => {
        e.preventDefault();
    
        var values = { "id": report_id, "description": Description, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/reject-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report rejected successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const approveReports = (e) => {
        e.preventDefault();
        setMainSubmitButtonState(true)
        var values = { "id": report_id,"page_name": page_name,"organization_id":organization_id };
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-reports`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Reports approved successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Approval List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                {state==1 ?
                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

                    <div className="card mb-5 mb-xl-10">
                        <div class="card mt-5 mt-xxl-8">

                            <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                <ul className="nav flex-wrap border-transparent fw-bolder col-12" role="tablist">
                                    <li className="nav-item my-1 col-4" role="presentation">
                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Pending Approval</a>
                                    </li>
                                    <li className="nav-item my-1 col-4" role="presentation">
                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">Trips</a>
                                    </li>
                                    <li className="nav-item my-1 col-4" role="presentation">
                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Reports</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card card-xxl-stretch mb-5 mb-xl-10">
                        <div class="card-body py-3">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                    <div class="row g-0">			
                                        <div class="col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
                                            
                                            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="8" y="9" width="3" height="10" rx="1.5" fill="black"></rect>
                                                    <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="black"></rect>
                                                    <rect x="18" y="11" width="3" height="8" rx="1.5" fill="black"></rect>
                                                    <rect x="3" y="13" width="3" height="6" rx="1.5" fill="black"></rect>
                                                </svg>
                                            </span>
                                            
                                            <a href="#" class="text-warning fw-bold fs-6">{PendingTripList.length} Pending Trips</a>
                                        </div>
                                                                
                                        <div class="col bg-light-warning px-6 py-8 rounded-2 mb-7">
                                            
                                            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black"></path>
                                                    <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black"></path>
                                                </svg>
                                            </span>
                                            
                                            <a href="#" class="text-warning fw-bold fs-6">{PendingReportList.length} Pending Reports</a>
                                        </div>
                                    </div>
                                    <div class="row g-0">
                                        <div class="col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7">						
                                            <div class="card card-flush h-md-100">     
                                                <div class="card-body">
                                                    <div class="d-flex flex-stack">              
                                                        <div class="d-flex align-items-center me-3">
                                                            <img src="/metronic8/demo1/assets/media/svg/brand-logos/instagram-2-1.svg" class="me-3 w-30px" alt=""/>
                                                            <div class="flex-grow-1">
                                                                <a  class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Trip Name</a>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        <div class="d-flex align-items-center w-100 mw-125px">
                                                            <span class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Duration</span>
                                                        </div>
                                                    </div>
                                                    <div class="separator separator-dashed my-4"></div>
                                                    {
                                                        PendingTripList.map((obj1, index) => {
                                                            return (
                                                                <>
                                                                <div class="d-flex flex-stack">
                                                                    
                                                                    <div class="d-flex align-items-center me-3">
                                                                        
                                                                        <img src="/metronic8/demo1/assets/media/svg/brand-logos/instagram-2-1.svg" class="me-3 w-30px" alt=""/>
                                                                        
                                                                        <div class="flex-grow-1">
                                                                            <a href="#" class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Linked In</a>
                                                                            <span class="text-gray-400 fw-bold d-block fs-6">{obj1.trip_name}</span>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    <div class="d-flex align-items-center w-100 mw-125px">
                                                                        <span class="text-gray-400 fw-bold">{moment(obj1.departure_date).format(DateFormat)}-{moment(obj1.return_date).format(DateFormat)}</span>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div class="separator separator-dashed my-4"></div> 
                                                                </>
                                                                )
                                                        })
                                                    }
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                                                
                                        <div class="col bg-light-primary px-6 py-8 rounded-2 mb-7">						
                                            <div class="card card-flush h-md-100">     
                                                <div class="card-body">
                                                <div class="d-flex flex-stack">              
                                                        <div class="d-flex align-items-center me-3">
                                                            <img src="/metronic8/demo1/assets/media/svg/brand-logos/instagram-2-1.svg" class="me-3 w-30px" alt=""/>
                                                            <div class="flex-grow-1">
                                                                <a  class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Report Name</a>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        <div class="d-flex align-items-center w-100 mw-125px">
                                                            <span class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Duration</span>
                                                        </div>
                                                    </div>
                                                    <div class="separator separator-dashed my-4"></div>
                                                    {
                                                        PendingReportList.map((obj1, index) => {
                                                            return (
                                                                <>
                                                                <div class="d-flex flex-stack">
                                                                    
                                                                    <div class="d-flex align-items-center me-3">
                                                                        
                                                                        <img src="/metronic8/demo1/assets/media/svg/brand-logos/instagram-2-1.svg" class="me-3 w-30px" alt=""/>
                                                                        
                                                                        <div class="flex-grow-1">
                                                                            <a href="#" class="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">{obj1.report_name}</a>
                                                                            
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    <div class="d-flex align-items-center w-100 mw-125px">
                                                                        <span class="text-gray-400 fw-bold">{moment(obj1.start_date).format(DateFormat)}-{moment(obj1.end_date).format(DateFormat)}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="separator separator-dashed my-4"></div> 
                                                                </>
                                                                )
                                                        })
                                                    }
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="kt_table_widget_5_tab_2">
                                    {data1.length>0 ?
                                        <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                        <MUIDataTable
                                            title={"Trip List"}
                                            data={data1}
                                            columns={columns_trip}
                                            options={options_trip}
                                        />
                                        </MuiThemeProvider>
                                        </div>
                                        :
                                        <div class="d-flex flex-column px-9">
                                            <div class="pt-10 pb-0">
                                                <h3 class="text-dark text-center fw-bolder">No Data Available</h3>
                                            </div>
                                            <div class="text-center px-4">
                                                <img class="mw-100 mh-200px" alt="metronic" src="assets/media/illustrations/work.png" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="tab-pane fade" id="kt_table_widget_5_tab_3">
                                {data.length>0 ?
                                    <div class="timeline-content mb-10 mt-n1 py-4">
                                    <MUIDataTable
                                        title={"Report List"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                    </div>
                                    :
                                    <div class="d-flex flex-column px-9">
                                        <div class="pt-10 pb-0">
                                            <h3 class="text-dark text-center fw-bolder">No Data Available</h3>
                                        </div>
                                        <div class="text-center px-4">
                                            <img class="mw-100 mh-200px" alt="metronic" src="assets/media/illustrations/work.png" />
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                     :
                    <>
                    {
                    state == 2 ?
                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                        :
                        <>
                            <div className="card-body pt-0">
                                <div className="text-gray-500 fw-bold fs-5 mb-5">Approval List</div>
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                <div className="fs-6 text-gray-600"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </>
                    }
                </>
                }
                </div>
            </div>
            </div>
            <div className="modal fade" id="Reopen" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Reopen Trip</h4>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <h3>Are you sure you want to reopen this trip?</h3>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                                <button type="button" className="btn btn-primary" onClick={(e) => { Reopen_trip(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Confirm</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>}
                        </div>

                    </div>

                </div>

            </div>

            <div className="modal fade" id="MarkAsClose" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Mark as Closed?</h4>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <h5>Generally, it is recommended that you mark a trip as closed after all the processes related to it are completed.</h5>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                <button type="button" className="btn btn-primary" onClick={(e) => { mark_as_close(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Mark As Closed</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>}
                        </div>

                    </div>

                </div>

            </div>
            
            <div className="modal fade" id="ApproveTrip" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Approve Trip</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <h3>Are you sure you want to Approve this Trip?</h3>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                <button type="button" className="btn btn-primary" onClick={(e) => { approve_Trip(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Submit</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>}
                        </div>

                    </div>

                </div>

            </div>
            <div className="modal fade" id="RejectTrip" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reject Trip</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="fs-6 fw-bold mb-2">Please specify an appropriate reason for rejection.</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={Description} onChange={(e) => { setDescription(e.target.value) }} readOnly={write?false:true}></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                            {Description=="" ?<button type="button" className="btn btn-danger" onClick={(e) => { Reject_Trip(e); }} disabled>Reject</button>:
                                <button type="button" className="btn btn-danger" onClick={(e) => { Reject_Trip(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Reject</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            }
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="CancelTrip" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Cancel Trip</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="fs-6 fw-bold mb-2">Please specify an appropriate reason for cancelling trip.</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={Description} onChange={(e) => { setDescription(e.target.value) }} readOnly={write?false:true}></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                {Description=="" ?<button type="button" className="btn btn-danger" onClick={(e) => { Cancel_trip(e); }} disabled>Confirm</button>:
                                <button type="button" className="btn btn-danger" onClick={(e) => { Cancel_trip(e); }}data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Confirm</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="approveReport" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Approve Report</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <h3>Are you sure you want to Approve this Report?</h3>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                <button type="button" className="btn btn-primary" onClick={(e) => { approveReports(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Submit</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>}
                        </div>

                    </div>

                </div>

            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reject Report</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="fs-6 fw-bold mb-2">Please specify an appropriate reason for rejection.</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={Description} onChange={(e) => { setDescription(e.target.value) }} readOnly={write?false:true}></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1&&<form >
                                {Description ?<button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} disableds>Reject</button>:
                                <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Reject</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                }
                            </form>}
                        </div>

                    </div>

                </div>

            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ListApproval;

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'

import uuid from 'react-uuid';

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function UpdatePolicies(props) {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    let [user, setUser] = useState({});

    let [currency_price, setCurrencyPrice] = useState("");
    let [currency_date, setCurrencyDate] = useState("");

    let [loader, setLoader] = useState(0);

    let [currency_value, setCurrencyValue] = useState("");
    let [currency_id, setCurrencyId] = useState("");
    let [Category_name, setCategory_name] = useState("");

    let [state, setstate] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    let [subscription, set_subscription] = useState("");
    let [duration, setduration] = useState("");
    const [PlatformDetails, setPlatformDetails] = useState([]);

    let [photoName, setPhotoName] = useState([""]);
    let [idNames, setIdNames] = useState(["", "", ""]);
    const [profilePhoto, setProfilePhoto] = useState("");
    const [fileNames, setFileNames] = useState([]);
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());
    let [lead_source, setLead_source] = useState("");
    //let [address_type, setAddress_type] = useState("");
    let [identity_type, setIdentity_type] = useState("");
    const [unique_id, setUnique_id] = useState(uuid());
    //const [organization_id, setOrganization_id] = useState("");
	
    const [BaseCurrency, setBaseCurrency] = useState("");
	let [country_array, set_country_array] = useState([]);
    let [state_array, set_state_array] = useState([]);
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [VehicleName, setVehicleName] = useState("");
    const [RecordMileage, setRecordMileage] = useState("");
    const [RecordMileageList, setRecordMileageList] = useState([{label:"Manually",value:"Manually"}])
    const [Hints, setHints] = useState("");
    const [VehicleList, setVehicleList] = useState([]);
    const [Index, setIndex] = useState("");
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"));

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [Policy_name, setPolicy_name] = useState("");
    let [Description, setDescription] = useState("");
    let [Policy_admin, setPolicy_admin] = useState("");
    const [Category, setCategory] = useState("")
    let [Default_unit, setDefault_unit] = useState("Km");
    let [image, setImage] = useState("");
    let [original_image, setOriginalImage] = useState("");

    const [Description_Permission, setDescription_Permission] = useState([{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }])
    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])
    const [CategoryList, setCategoryList] = useState([])
    const [CategoryList1, setCategoryList1] = useState([])
    const [GeneralLimit, setGeneralLimit] = useState([{Expense_Amount_Limit:"",Receipt_Required_Limit:"",Make_Description_Mandatory:"No"}])
    

	var page_name = "update-policies";
    
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    //console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if(res.data.organization_flag==0){
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id!="" && validateJWT();

    }, [organization_id])
    
    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

	useEffect(() => {
        async function getbasecurrency() {
            var object = {};
            object.email = user.email;
    
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setBaseCurrency(body[0].currency_symbol);
            }
        }
        if(read || write)
        {
            user.email && visibility && getbasecurrency();
        }

        async function getpolicydetails() {
            var object = {};
            object.email = user.email;
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-travel-policy-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"id":props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {   
                
                setPolicy_name(body[0].policy_name) 
                setPolicy_admin(body[0].policy_admin) 
                setDescription(body[0].description) 
                setDefault_unit(body[0].default_unit) 
                setCategory(body[0].default_category)
                setOriginalImage(body[0].original_file_name) 
                if(body[0].file_name!="")
                {
                    setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/expense_travel_policy/${body[0].file_name}`)
                }
                if(body[0].vehicle_details!="")
                { 
                    setVehicleList(JSON.parse(body[0].vehicle_details))
                }
                if(body[0].category_limits!="")
                { 
                    setCategoryList1(JSON.parse(body[0].category_limits))
                }
                if(body[0].general_limits!="")
                { 
                    setGeneralLimit(JSON.parse(body[0].general_limits))
                }
                setstate(1)
                
            } 
            else
            {
                setstate(0)
            } 
        }
        if(read || write)
        {
            (user.email && visibility && CategoryList.length>0) && getpolicydetails();
        }
}, [user, visibility, read, write,CategoryList])

useEffect(() => {
    async function getuserdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        let temp=[];
        temp.push({value:"user_all" ,label:"All User"});
        for(let i=0;i<body.length;i++)
        {
            temp.push({value: body[i].user_id ,label:body[i].user_id});
        }
        setuserList1(temp);
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.log_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.name;
                temp_obj.value = obj.user_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setuserList(temp_dropdown);
    }
    if(read || write)
    {
    (user.email && visibility) && getuserdetails();
    }

    async function getcategorydetails() {   
        var object = {};
        object.email = user.email;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.category_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);
            }
        })
        setCategoryList(temp_dropdown);
    }
    if(read || write)
    {
        (user.email && visibility) && getcategorydetails();
    }
}, [user, visibility, read, write])
//console.log(CategoryList)
const delete_document = (e) => {
    e.preventDefault();
    var values = { "id": props.match.params.id, "page_name": page_name,"organization_id":organization_id };
    setMainSubmitButtonState(true)
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-policy-document`, values, headers).then((res) => {
        setMainSubmitButtonState(false)
        if (res.data && res.data.operation == "success") {
            swal("Great!", "Policy Document deleted successfully!", "success").then((value) => {
                window.location.reload();
            });
        }
        else {
            swal("Oops!", "Something went wrong!", "error");
        }
    });
}

    const register = () => {
        var object = {};
        object.unique_id =props.match.params.id;
        object.unique_id1 = unique_id;
        object.organization_id = organization_id;
        object.page_name=page_name;
        object.Policy_name = Policy_name;
        object.Policy_admin = Policy_admin;
        object.Description = Description;
        object.Default_unit = Default_unit;
        object.Default_category = Category;
        object.GeneralLimit=GeneralLimit;
        object.CategoryLimit = CategoryList1;
        let arr=[];
        object.file_names=fileNames;
        if (object.Policy_name == "") {
            swal("Warning!", "Policy Name cannot be a empty", "warning");
            return false;
        }
        let t=true;
        t = GeneralLimit.find(o=>{ 
            if(isNaN(o.Expense_Amount_Limit))
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else if(isNaN(o.Receipt_Required_Limit))
            {
                swal("Warning!", "Please enter a valid receipt required  limit", "warning");
                return false;
            }   
        })
        t = CategoryList1.find(o1=>{ 
            if(isNaN(o1.Expense_Amount_Limit))
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else if(isNaN(o1.Receipt_Required_Limit))
            {
                swal("Warning!", "Please enter a valid receipt required limit", "warning");
                return false;
            }   
        })
        console.log("ac",t)
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(t!=false)
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-travel-policy`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data.operation === "success") {
                    swal("Great!", "You travel policy details updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else if (res.data.operation === "filenotuploaded") {
                    swal("Oops!", "You have not uploaded any images or documents!", "error");
                }

                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })
            .catch(function (error) {
            });
        }
    }
    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(meta);
        setPhotoName(String(name))
        setFileNames(photoName,idNames)
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }
    /*const Preview2 = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(meta);
        setIdNames(String(name))
        setFileNames(photoName,idNames)
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }
    */
   

    const handleChangeStatus1 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setProfilePhoto(name)
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            setProfilePhoto("")
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const getUploadParams1 = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-travel-policy`, headers, body }
    }

    const New_Vehicle= () =>{
        setVehicleName("");
        setHints("");
        setRecordMileage("");
        setIndex("");
    }

    const Edit_Vehicle= (i) =>{
        setIndex(i);
        setVehicleName(VehicleList[i].VehicleName);
        setHints(VehicleList[i].Hints);
        setRecordMileage(VehicleList[i].RecordMileage);
    }

    const Delete_Vehicle= (i) =>{
        setVehicleName(VehicleList[i].VehicleName);
    }
    const DeleteVehicleDetails= () =>{
        const values1 = [...VehicleList];
        for (let i = 0; i < values1.length; i++) {
            if (values1[i].VehicleName === VehicleName) {
                values1.splice(i, 1);
            break;
            }
        }

        var values = { "policy_id": props.match.params.id,"VehicleList":values1,"page_name": page_name,"organization_id":organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-vehicle-details`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Vehicle details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const UpdateVehicleDetails= () =>{
        const values1 = [...VehicleList];
        values1[Index].VehicleName = VehicleName;
        values1[Index].RecordMileage = RecordMileage;
        values1[Index].Hints = Hints;

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        var values = { "policy_id": props.match.params.id,"VehicleList":values1,"page_name": page_name,"organization_id":organization_id };
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-vehicle-details`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Vehicle details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });   
    }

    const submitVehicleDetails= () =>{
        let obj={};
        obj.VehicleName=VehicleName;
        obj.RecordMileage=RecordMileage;
        obj.Hints=Hints;
        const values1 = [...VehicleList];
        values1.push(obj);
    
        var values = { "policy_id": props.match.params.id,"VehicleList":values1,"page_name": page_name,"organization_id":organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-vehicle-details`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Vehicle details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const handle_validation = (type,value) => {
        if(isNaN(value))
        {
            if(type=="expense")
            {
                swal("Warning!", "Please enter a valid expense amount limit", "warning");
                return false;
            }
            else
            {
                swal("Warning!", "Please enter a valid receipt required limit", "warning");
                return false;
            }
        }   
    }

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Policy Details
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                    { state==1?
                    <>
                    
                        <div className="card  pt-0 pb-15 px-3 px-xl-10">
                            <br />
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card mt-5 mt-xxl-8">
                                        <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                            <ul className="nav flex-wrap border-transparent fw-bolder col-12" role="tablist">
                                                <li className="nav-item my-1 col-4" role="presentation">
                                                    <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Policy Details</a>
                                                </li>
                                                <li className="nav-item my-1 col-4" role="presentation">
                                                    <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">Mileage</a>
                                                </li>
                                                <li className="nav-item my-1 col-4" role="presentation">
                                                    <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Category</a>
                                                </li>
                                                {/*<li className="nav-item my-1 col-3" role="presentation">
                                                    <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_4">Per Diem</a>
            </li                                >*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-xxl-stretch mb-5 mb-xl-10">
                                    <div className="card-body py-3">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                                <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                    <div className="card-header cursor-pointer">
                                                        <div className="card-title m-0">
                                                            <h3 className="fw-bolder m-0">Policy Details</h3>
                                                        </div>
                                                        <span className="badge badge-light fw-bolder my-2">
                                                        
                                                        </span>
                                                    </div>
                                                    <div className="card-body p-9">
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Policy Name</label>
                                                            <input id="first_name" type="text" className="form-control form-control-solid" placeholder="" name="first-name" value={Policy_name}
                                                                onChange={(event) => {setPolicy_name(event.target.value);}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Description</label>
                                                            <textarea id="email" type="email" className="form-control form-control-solid" placeholder="" name="email" value={Description}
                                                                onChange={(event) => { setDescription(event.target.value);}} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Policy Admin</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                isMulti
                                                                className="form-control form-control-solid"
                                                                options={userlist}
                                                                onChange={(val) => { setPolicy_admin(val.map(a => a.value)) }}
                                                                value={userlist.filter(option => option.value == Policy_admin).length != 0 && userlist.filter(option => option.value == Policy_admin)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {image=="" ?
                                                        <>
                                                        <label className="fs-5 fw-bold mb-2">Upload Travel Policy (Single file upload)</label>
                                                        <Dropzone
                                                            getUploadParams={getUploadParams1}
                                                            onChangeStatus={handleChangeStatus1}
                                                            LayoutComponent={CustomLayout}
                                                            PreviewComponent={Preview}
                                                                styles={{
                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                }}
                                                                maxFiles={1}
                                                                maxSizeBytes={1024*1024*5}
                                                            accept="image/*,.pdf"
                                                        />
                                                        </>
                                                        :
                                                        <>
                                                            <label className="fs-5 fw-bold mb-2">Travel Policy</label>
                                                            <div className="d-flex align-items-center">
                                                            
                                                                <div className="symbol symbol-50px me-3">
                                                                    <img src={image} className="" alt=""/>
                                                                </div>
                                                                <div className="d-flex justify-content-start flex-column">
                                                                    <a href="#" className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6">{original_image}</a>
                                                                    <span className="text-gray-400 fw-bold d-block fs-7"></span>
                                                                </div>
                                                                <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" data-bs-toggle="modal" data-bs-target="#DeleteDocument">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div> 
                                                        </>
                                                        }
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                        <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={(e) => {register(e)}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </div>     
                                                </div>
                                            </div>
                                            </div>
                                            <div className="tab-pane fade" id="kt_table_widget_5_tab_2">
                                                <div className="card mb-5 mb-xl-10" id="kt_profile_details_view2">
                                                    <div className="card-header cursor-pointer">
                                                        <div className="card-title m-0">
                                                            <h3 className="fw-bolder m-0"></h3>
                                                        </div>
                                                        <span className="badge badge-light fw-bolder my-2">
                                                        </span>
                                                    </div>
                                                    <div className="row gy-5 g-xl-8">
                                                        <div className="row mb-5">
                                                            <div className="col-md-12 fv-row">
                                                                <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                                                        <span className="fs-5 fw-bold mb-2">Default Unit</span>
                                                                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify the bid amount to place in." aria-label="Specify the bid amount to place in."></i>
                                                                    </label>
                                                                    <div className="col-lg-8 fv-row">
                                                                        <div className="d-flex align-items-center mt-3">
                                                                            <label className="form-check form-check-inline form-check-solid me-5">
                                                                                <input className="form-check-input" name="communication[]" id="email" type="checkbox" onClick={() => {setDefault_unit("Km")}} value="email" checked={Default_unit =="Km"}/><span className="fw-bold ps-2 fs-6">Km</span>
                                                                            </label>
                                                                            <label className="form-check form-check-inline form-check-solid">
                                                                                <input className="form-check-input" name="communication[]" id="phone" type="checkbox" onClick={() => {setDefault_unit("Mile")}} value="phone" checked={Default_unit =="Mile"}/><span className="fw-bold ps-2 fs-6">Mile</span>
                                                                            </label>
                                                                        </div>
                                                                        <span id="communication1" className="text-danger font-weight-bold"></span>
                                                                    </div>
                                                                    {/*<div className="d-flex flex-stack gap-5 mb-3">
                                                                        <button type="button" className="btn btn-light-primary w-100" data-kt-modal-bidding="option" onClick={() => {setDefault_unit("Km")}}>Km</button>
                                                                        <button type="button" className="btn btn-light-primary w-100" data-kt-modal-bidding="option" onClick={() => { register("Mile") }}>Mile</button>
                                                                    </div>
                                                                    */}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <label className="fs-5 fw-bold mb-2">Default Category</label>
                                                            <Select
                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={CategoryList}
                                                                onChange={(event) => { setCategory(event.value) }}
                                                                value={CategoryList.filter(option => option.value == Category).length != 0 && CategoryList.filter(option => option.value == Category)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div><br/>
                                                        <div className="overflow-auto pb-5">
                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                                                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                                        <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                                    <div className="mb-3 mb-md-0 fw-bold">
                                                                        {VehicleList.length>0 &&<a href="/metronic8/demo1/../demo1/apps/projects/project.html" className="fs-6 text-hover-primary fw-bolder" data-bs-toggle="modal" data-bs-target="#VehicleList">Your Organization Have {VehicleList.length} vehicle</a>}
                                                                        <div className="fs-6 text-gray-700 pe-7">Added vehicles used in your organization.</div>
                                                                    </div>
                                                                    <a href="#" className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#NewVehicle" onClick={(e) => { New_Vehicle() }}>New vehicle</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                        <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={(e) => {register(e)}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="kt_table_widget_5_tab_3">
                                                <div className="card mb-5 mb-xl-10" id="kt_profile_details_view1">
                                                    <div className="card-header border-0 pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-3 mb-1">General Limits</span>
                                                            <span className="text-muted mt-1 fw-bold fs-7">Over 500 members</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">  
                                                                <thead>
                                                                    <tr className="fw-bolder text-muted">
                                                                        <th className="min-w-150px">Expense Amount Limit</th>
                                                                        <th className="min-w-150px">Receipt Required Limit</th>
                                                                        <th className="min-w-150px">Description Required</th>
                                                                        <th className="min-w-100px text-end">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {GeneralLimit.map((obj, index) => {
                                                                    return (
                                                                    <>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{obj.Expense_Amount_Limit==""?<>--</>:obj.Expense_Amount_Limit}</a>
                                                                                    <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="#" className="text-dark fw-bolder text-hover-primary d-block fs-6">{obj.Receipt_Required_Limit==""?<>--</>:obj.Receipt_Required_Limit}</a>
                                                                            <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{obj.Make_Description_Mandatory}</a>
                                                                                    <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-end flex-shrink-0">   
                                                                                <a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#GeneralLimit">
                                                                                    <span className="svg-icon svg-icon-3">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="card-header border-0 pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-3 mb-1">Category-specific Limits</span>
                                                            <span className="text-muted mt-1 fw-bold fs-7">Over 500 members</span>
                                                        </h3>
                                                    </div>         
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">  
                                                                <thead>
                                                                    <tr className="fw-bolder text-muted">
                                                                        <th className="w-25px">
                                                                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                                <input className="form-check-input" type="checkbox" value="1" data-kt-check="true" data-kt-check-target=".widget-9-check"/>
                                                                            </div>
                                                                        </th>
                                                                        <th className="min-w-200px">Category Name</th>
                                                                        <th className="min-w-150px">Expense Amount Limit</th>
                                                                        <th className="min-w-150px">Receipt Required Limit</th>
                                                                        <th className="min-w-150px text-end">Description Required</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {CategoryList1.map((obj, index) => {
                                                                    return (
                                                                    <>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                                <input className="form-check-input widget-9-check" type="checkbox" checked={obj.acceptability} value={obj.acceptability}
                                                                                onChange={(event) => { 
                                                                                    const values1 = [...CategoryList1];
                                                                                    values1[index].acceptability=event.target.checked;
                                                                                    setCategoryList1(values1);
                                                                                }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        {console.log(obj.category_id)}
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{CategoryList.filter(option => option.value == obj.category_id)[0].label}</a>
                                                                                    <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"> {BaseCurrency} </span>
                                                                            </div>
                                                                            <input id="ember537" className="form-control ember-text-field text-left ember-view" type="text" value={obj.Expense_Amount_Limit}
                                                                            onChange={(event) => { 
                                                                                const values1 = [...CategoryList1];
                                                                                values1[index].Expense_Amount_Limit=event.target.value;
                                                                                setCategoryList1(values1);
                                                                                handle_validation("expense",event.target.value);
                                                                            }}
                                                                            />
                                                                        </div>
                                                                        </td>
                                                                        <td>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"> {BaseCurrency} </span>
                                                                            </div>
                                                                            <input id="ember537" className="form-control ember-text-field text-left ember-view" type="text" value={obj.Receipt_Required_Limit}
                                                                            onChange={(event) => { 
                                                                                const values1 = [...CategoryList1];
                                                                                values1[index].Receipt_Required_Limit=event.target.value;
                                                                                setCategoryList1(values1);
                                                                                handle_validation("receipt",event.target.value);
                                                                            }}
                                                                            />
                                                                        </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <Select
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control"
                                                                                        options={Description_Permission}
                                                                                        onChange={(event) => { 
                                                                                            const values1 = [...CategoryList1];
                                                                                            values1[index].Make_Description_Mandatory=event.value;
                                                                                            setCategoryList1(values1);
                                                                                        }}
                                                                                        value={Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory).length != 0 && Description_Permission.filter(option => option.value == obj.Make_Description_Mandatory)[0]}
                                                                                        placeholder=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                        <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={(e) => {register(e)}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="kt_table_widget_5_tab_4">
                                                <div className="timeline-content mb-10 mt-n1 py-4">
                                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                        <div className="flex-grow-1 me-2">
                                                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Report Documents</a>
                                                            <span className="text-muted fw-bold d-block fs-7"></span>
                                                        </div>
                                                        <span className="badge badge-light fw-bolder my-2">
                                                        
                                                        </span>
                                                    </div>
                                                    <div className="overflow-auto pb-5">
                                                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <>
                            {state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <>
                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2 className="fw-bolder">Update Policy</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2">
                                                </div>
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Something Went Wrong.</h4>
                                                            <div className="fs-6 text-gray-600">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            </>
                        }
                        </div>
                    </div>
                </div>

           <div className="modal fade" id="GeneralLimit" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">General Limit</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="fv-row mb-8 fv-plugins-icon-container">
                                        <label className="fs-6 fw-bold mb-2">Expense Amount Limit</label>
                                        <div className="col-8">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{BaseCurrency}</span>
                                            </div>
                                            <input id="ember537" className="form-control ember-text-field text-left ember-view" type="text" value={GeneralLimit[0].Expense_Amount_Limit}
                                            onChange={(event) => { 
                                                const values1 = [...GeneralLimit];
                                                values1[0].Expense_Amount_Limit=event.target.value;
                                                setGeneralLimit(values1);
                                                handle_validation("expense",event.target.value);
                                            }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    <div className="fv-row mb-8 fv-plugins-icon-container">
                                        <label className="fs-6 fw-bold mb-2">Receipt Required Limit</label>
                                        <div className="col-8">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> {BaseCurrency} </span>
                                                </div>
                                                <input id="ember537" className="form-control ember-text-field text-left ember-view" type="text" value={GeneralLimit[0].Receipt_Required_Limit}
                                                onChange={(event) => { 
                                                    const values1 = [...GeneralLimit];
                                                    values1[0].Receipt_Required_Limit=event.target.value;
                                                    setGeneralLimit(values1);
                                                    handle_validation("receipt",event.target.value);
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fv-row mb-8 fv-plugins-icon-container">
                                        <label className="fs-6 fw-bold mb-2">Make description mandatory</label>
                                        <div className="col-8">
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control"
                                            options={Description_Permission}
                                            onChange={(event) => { 
                                                const values1 = [...GeneralLimit];
                                                values1[0].Make_Description_Mandatory=event.value;
                                                setGeneralLimit(values1);
                                            }}
                                            value={Description_Permission.filter(option => option.value == GeneralLimit[0].Make_Description_Mandatory).length != 0 && Description_Permission.filter(option => option.value == GeneralLimit[0].Make_Description_Mandatory)[0]}
                                            placeholder="--Select Type--"
                                        />
                                        </div>
                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <button type="button" className="btn btn-primary" onClick={(e) => {register(e)}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="DeleteDocument" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Documents</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12"> 
                                    <h3>Are you sure you want to delete this Documents?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { delete_document(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="VehicleList" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Vehicle List</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                {VehicleList.length > 0 && 
                                    <div className="card-body p-9">
                                        <div className="table-responsive mb-10">
                                            <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700" data-kt-element="items">
                                                <thead>
                                                    <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                                        <th className="min-w-100px w-150px">Vehicle Name</th>
                                                        <th className="min-w-100px w-150px">Mileage Type</th>
                                                        <th className="min-w-100px w-150px">Hints</th>
                                                        <th className="min-w-25px w-25px">Action</th>
                                                    </tr>
                                                </thead>
                                                {VehicleList.map((obj, index) => {
                                                return (
                                                <>
                                                <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                    <td className="pt-8 text-nowrap">{obj.VehicleName}</td>
                                                    <td className="pt-8 text-nowrap">{obj.RecordMileage}</td>
                                                    <td className="pt-8 text-nowrap">{obj.Hints}</td>
                                                    <td className="pt-8 text-nowrap">
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                                            Action
                                                            </button>
                                                            {
                                                                write ?
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a href="#"  data-bs-toggle="modal" data-bs-target="#NewVehicle" onClick={(e) => { Edit_Vehicle(index) }} className="dropdown-item">Edit</a></li>
                                                                    <li><a  data-bs-toggle="modal" data-bs-target="#DeleteVehicle" className="dropdown-item" href="#" onClick={(e) => { Delete_Vehicle(index) }}>Delete</a></li>
                                                                </ul>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                </>
                                                );
                                            })}

                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                            {/*<div className="modal-footer flex-center">
                                <button onClick={(e) => { submitVehicleDetails(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            */}
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="NewVehicle" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>{Index=="" ? <>New</>:<>Update</>} Vehicle</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Vehicle name</label>
                                        <input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={VehicleName} onChange={(e) => { setVehicleName(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Record Mileage Using :</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="category"
                                            id="category"
                                            className="form-control form-control-solid"
                                            options={RecordMileageList}
                                            onChange={(event) => {
                                                console.log(event.value) 
                                                setRecordMileage(event.value) }}
                                            value={RecordMileageList.filter(option => option.value == RecordMileage).length != 0 && RecordMileageList.filter(option => option.value == RecordMileage)[0]}
                                            placeholder="--Select--"
                                        />
                                    </div>
                                </div> 
                                <div className="row mb-5">
                                    <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Purpose</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" autocomplete="off" value={Hints}  onChange={(e) => { setHints(e.target.value) }}></textarea></div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer flex-center">
                                <button onClick={(e) => {{Index=="" ? submitVehicleDetails(e):UpdateVehicleDetails()}}} type="button" id="kt_modal_new_address_submit" className="btn btn-primary rounded">
                                    <span className="indicator-label">{Index==""?<>Submit</>:<>Update</>}</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="DeleteVehicle" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Vehicle</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <h3>Are you sure you want to delete this Vehicle({VehicleName})?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { DeleteVehicleDetails(e); }} >Delete</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>
    );
}
export default UpdatePolicies;
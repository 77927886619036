import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import uuid from 'react-uuid';
import moment from 'moment';

import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function AllModules() {
	const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	const [accessToken, setaccessToken] = useState('');
	const [access, setaccess] = useState([]);
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);

	let [redirect_to_login, setRedirect_to_login] = useState(2);
	const [unique_id, setUnique_id] = useState(uuid());
	let [date, setdate] = useState(new Date());
	const [showForm, setShowForm] = useState(false);
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);

	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	let not_show=["expense-details","report-details","trip-details","edit-trip-details","add-travel-document","update-policies","update-roles","edit-customer-details"]
	var page_name = "all-modules"

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
		
    }, [])

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1,false)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
				object.organization_id=organization_id;
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setaccess(res.data.user_access.access);
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else
									{
										temp = 2;
									}
								}
							}
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						var token = res.data.audience_license;

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1,true)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						setRedirect_to_login(1);
					}
				})
				.catch(function (error) {
					console.log(error);
					setRedirect_to_login(1);
				});
			}
			else {
				setRedirect_to_login(1);
			}
		}

		organization_id!="" && validateJWT();

	}, [organization_id]);

	useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

	//Function to format Menu Header and submenu items 
	const formatString = (str) => {
		//console.log(str)
		if (str.includes("_")) {
			//Removing "_" and formatting first letter of each word to Capital
			return str.split("_")[0].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
		}
		else if (str.includes("account")) {
			//Removing "-" and formatting first letter of each word to Capital and showing last word of the string
			let allWords = str.replace(/-/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-1).join(" ")
		}
		else if (str.includes("-")) {
			//Removing "-" and formatting first letter of each word to Capital and showing last 2 word of the string
			let allWords = str.replace(/-/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-2).join(" ")
		}
		else {
			let allWords = str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-2).join(" ")
		}
	}

	//Function for custom link as per item name in the json
	const customLink = (option) => {
		return `/${option}`
	}

	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}
			{
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

			
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Merchant List
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
							{visibility==1 ?
							<>
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />
									<div className="card mb-5 mb-xl-10">
										<div className="card-body p-5 px-lg-19 py-lg-16">
										{access.length > 0 && access.map((obj, index) => {
											return (
											<>
											{(obj.hasOwnProperty("key") && obj.visibility == 1) &&
											<>
											<div className="mb-15">
												<h1 className="fs-2x text-dark mb-6">{obj.key}</h1>
												<div className="fs-5 text-muted fw-semibold">First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words as per minute and your writing skills are sharp.</div>
											</div>	
											<div className="row g-10 mb-15">
												{obj.inside_components.length > 0 && obj.inside_components.map((obj1, index1) => {
													return ( 
													<>
													{(obj1.hasOwnProperty("key")) ?
														<div className="col-sm-4">
															<h3 className="fw-bold text-dark mb-7">{formatString(obj1.key)}</h3>
															{obj1.inside_components.length > 0 && obj1.inside_components.map((obj2, index2) => {
																return (
																	<>
																	{(Object.values(obj2)[0] == 1 && !not_show.includes(Object.keys(obj2)[0])) ?
																	<div className="d-flex flex-column fw-semibold fs-4">
																		<Link to={customLink(Object.keys(obj2)[0])} className="link-primary mb-6"><span class="badge py-3 px-4 fs-7 badge-light-primary">{formatString(Object.keys(obj2)[0])}</span></Link>
																	</div>
																	:
																	null
																	}
																	</>
																	)
																})}
														</div>
														:
														<div className="col-sm-4">
															<h3 className="fw-bold text-dark mb-7">{formatString(Object.keys(obj1)[0])}</h3>
															<div className="d-flex flex-column fw-semibold fs-4">
																<Link to={customLink(Object.keys(obj1)[0])} className="link-primary mb-6"><span className="badge py-3 px-4 fs-7 badge-light-primary">{formatString(Object.keys(obj1)[0])}</span></Link>
															</div>		
														</div>
													}
													</>
													)
												})}
											</div>
											</>
										}
										</>
											)
										})}
											<div className="card mb-4 bg-light text-center mb-4">
												<div className="card-body py-12">
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/facebook-4.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/instagram-2-1.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/github.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/behance.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/pinterest-p.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/twitter.svg" className="h-30px my-2" alt="" />
													</a>
													<a href="#" className="mx-4">
														<img src="assets/media/svg/brand-logos/dribbble-icon-1.svg" className="h-30px my-2" alt="" />
													</a>
												</div>
											</div>
										</div>
									</div>
									<br />
								</div>
								</>
								:
								<>
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">

												<div className="card-header">
													<div className="card-title">
														<h2 className="fw-bolder">All Modules</h2>
													</div>
												</div>
												<div className="card-body pt-0">
													<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
													<div className="d-flex align-items-center p-3 mb-2">
													</div>
													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								}
								</>}
							</div>
						</div>
					</div>
					{/* {notification_content.length > 0 && <div style={{ "display": "none" }}> setTimeout({loadScripts()},500) </div>} */}
				
					<Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
		</>
	);
}
export default AllModules;
import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
//import './previewbox.css'
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const Support = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"));

    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [fileNames, setFileNames] = useState([])

    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    let [country_array, set_country_array] = useState([]);
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    let [country, setcountry] = useState("");
    const [category, setcategory] = useState("");
    const [traveldocumentlist, settraveldocumentlist] = useState([{ label: "Passport", value: "Passport" }, { label: "Visa", value: "Visa" }, { label: "ESTA", value: "ESTA" },{ label: "Others", value: "Others" }])
	var page_name = "add-travel-document";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }


    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])
    
    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);


    useEffect(() => {
        var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);

    }, []);

    function loadScripts() {
        var se = document.createElement('script');
        se.src = "assets/js/custom/pages/company/support.js";
        document.body.appendChild(se);
    }

    async function save_data() {

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;

        if (category == "") {
            swal("Warning!", "Document type cannot be a empty", "warning");
            return false;
        }
        if (start_date == "") {
            swal("Warning!", "Issued Date cannot be a empty", "warning");
            return false;
        }
        if (end_date == "") {
            swal("Warning!", "Expiry Date cannot be a empty", "warning");
            return false;
        }
        if (Document_number == "") {
            swal("Warning!", "Document Number cannot be a empty", "warning");
            return false;
        }
        /*else if (!Document_number.match(nexp)) {
            swal("Warning!", "Document Number cannot contain certain symbols ", "warning");
            return false;
        }*/
        if (country == "") {
            swal("Warning!", "Country field cannot be a empty", "warning");
            return false;
        }
        if (fileNames.length==0) {
            swal("Warning!", "You must have to upload atleast one documemnt", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/save-travel-document-details`, { method: 'POST', body: JSON.stringify({ "unique_id": id, "email": user.email, 'document_type': category, 'country': country,'issued_date':start_date,'expiry_date':end_date,'document_number':Document_number,'filenames': fileNames,'page_name':page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        setMainSubmitButtonState(false)
        if (body.operation == 'success') {
            swal("", "Travel document is successfully save!", "success").then((res) => {
                window.location.href = "/"
            });
        }
        else {
            swal("Oops!", "Something went wrong", "error").then((res) => {
                window.location.href = "/"
            });
        }

        setDocument_number('');
        setMessage('');
    }

    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames]
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if (status === "error_file_size") {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        //setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();

        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('organization_id', organization_id);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-travel-documents`, headers, body }
    }


    function loadCurrency(country) {
		//setstate1("");
		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		setcountry(country.value);
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Add Travel Document
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div class="card">
                            <div class="card-body p-lg-17">
                                <div class="row mb-3">
                                    <div class="col-md-12 pe-lg-10">
                                        <form class="form mb-15" id="kt_contact_form">
                                        
                                            <h1 class="fw-bolder text-dark mb-9">New  Travel Document</h1>
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="required fs-5 fw-bold mb-2">Document Type:</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="category"
                                                        id="category"
                                                        className="form-control-solid"
                                                        options={traveldocumentlist}
                                                        onChange={(event) => { setcategory(event.value) }}
                                                        value={traveldocumentlist.filter(option => option.value == category).length != 0 && traveldocumentlist.filter(option => option.value == category)[0]}
                                                        placeholder="--Choose a Document type--"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-12 fv-row">
                                                    <label className="required fs-5 fw-bold mb-2">Country</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="country"
                                                        id="country"
                                                        options={country_array}
                                                        onChange={(e) => { loadCurrency(e); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                            <div className="col-xl-6">
                                                <label className="required fs-5 fw-bold mb-2">Issued Date</label>
                                                <input type="date" name="start_date" className="form-control" id="start_date" placeholder="Sales Start Date" value={start_date}
                                                    onChange={(e) => {
                                                        setstart_date(e.target.value);
                                                    }}
                                                />
                                                <span className="form-text text-muted">Please enter Issued Date.</span>

                                            </div>

                                            <div className="col-xl-6">
                                                <label className="required fs-5 fw-bold mb-2">Expiry Date</label>
                                                <input type="date" min={start_date} name="end_date" className="form-control" id="end_date" placeholder="Sales End Date" value={end_date}
                                                    onChange={(e) => {
                                                        setend_date(e.target.value);
                                                    }}
                                                />
                                                <span className="form-text text-muted">Please enter Expiry Date.</span>

                                            </div>
                                            </div>
                                            <div class="d-flex flex-column mb-5 fv-row">
                                                <label class="required fs-5 fw-bold mb-2">Document Number</label>
                                                <input class="form-control form-control-solid" placeholder="" name="Document_number" value={Document_number} onChange={(e) => {setDocument_number(e.target.value)}}  autoComplete="off" />
                                            </div>
                                            <div>
                                                <label class="fs-5 fw-bold mb-2">Maximum 2 files</label>
                                                <Dropzone
                                                    getUploadParams={getUploadParams}
                                                    onChangeStatus={handleChangeStatus2}
                                                    onSubmit={handleSubmit}
                                                    validate={handleValidation2}
                                                    LayoutComponent={CustomLayout}
                                                    styles={{
                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                    }}
                                                    maxFiles={2}
                                                    // canRemove={false}
                                                    PreviewComponent={Preview}
                                                    accept="image/*,.pdf"
                                                />
                                            </div>
                                            
                                            {write==1 &&
                                            <button style={{ marginTop: '15px',float:'right' }} onClick={save_data} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
                                                <span class="indicator-label">Save Details</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            }
                                           
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default Support;

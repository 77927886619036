import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import currency_arr from './currency-arr.js';
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import {MuiThemeProvider, createTheme} from '@material-ui/core';

export const CurrencyList = () => {
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    let [CurrencyCode, setCurrencyCode] = useState("");
    let [CurrencySymbol, setCurrencySymbol] = useState("");
    let [Format,setFormat] = useState("2");
    let [Decimal,setDecimal] = useState("");
    let [currency_id, setcurrency_id] = useState("");
    const [status, setstatus] = useState("");

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [userlist, setuserList] = useState([])
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    
    const [DecimalPlaceslist, setDecimalPlaceslist] = useState([{ label: "0", value: "0" }, { label: "2", value: "2" }, { label: "3", value: "3" }])
    const [Currencylist, setCurrencylist] = useState([{ label: "INR-Indian Rupee", value: "INR" }, { label: "AED-UAE dirham", value: "AED" }, { label: "AUD-Australian Dollar", value: "AUD" }])
    const [Formatlist, setFormatlist] = useState([{ label: "1,234,567.89", value: "1,234,567.89" }, { label: "1.234.567,89", value: "1.234.567,89" }, { label: "1 234 567,89", value: "1 234 567,89" }])

    var page_name = "currency-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Currency',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "currency_code",
            label: "Currency Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "currency_symbol",
            label: "Symbol",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setorganization_list(res.data)
						window.$("#ModalOrganization").modal("show")
						//setstate1(1)
					}
				})     
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);

    useEffect(() => {
		var obj_array = [];
        var jsonParsedArray = currency_arr.currency_arr[0]
        for ( let key in jsonParsedArray) {
		    var obj2 = {};
            if (jsonParsedArray.hasOwnProperty(key)) {
                obj2.label = jsonParsedArray[key].currency_name;
                obj2.value = key;
                obj_array.push(obj2);
            } 
        }
        setCurrencylist(obj_array);	
	}, []);

    useEffect(() => {
        async function getcurrencydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            setloading(true);
        }
        if (read || write) {
            user.email && visibility && getcurrencydetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.currency_code = obj.currency_code;
                    temp_obj.currency_symbol = obj.currency_symbol;
                    temp_obj.status = obj.status;
                    temp_obj.currency_id = obj.currency_id;
                    var id = obj.currency_id;
                    // console.log(id)
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updateCurrency(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                        {/* <li><a href="#" onClick={(e) => { updateCurrency(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    function loadCurrency(Currency) {
        
        var jsonParsedArray = currency_arr.currency_arr[0]

        if (jsonParsedArray.hasOwnProperty(Currency)) {
            setCurrencySymbol(jsonParsedArray[Currency].currency_symbol)
            setCurrencyCode(Currency);
            if(jsonParsedArray[Currency].currency_format.length==12)
            {
                setFormatlist([{ label: "1,234,567.89", value: "1,234,567.89" }, { label: "1.234.567,89", value: "1.234.567,89" }, { label: "1 234 567,89", value: "1 234 567,89" }])
            }
            else
            {
                setFormatlist([{ label: "1,234,567", value: "1,234,567" }, { label: "1.234.567", value: "1.234.567" }, { label: "1 234 567", value: "1 234 567" }])
            }
            setFormat(jsonParsedArray[Currency].currency_format)
            setDecimal(jsonParsedArray[Currency].price_precision)
        }           
	}

    const updateCurrency = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].currency_id) {

                setcurrency_id(editmodal[i].currency_id)
                setCurrencySymbol(editmodal[i].currency_symbol)
                setCurrencyCode(editmodal[i].currency_code);
                setFormat(editmodal[i].format)
                setDecimal(editmodal[i].decimal_place)
                //console.log(editmodal[i].format.length)
                if(editmodal[i].format.length==12)
                {
                    setFormatlist([{ label: "1,234,567.89", value: "1,234,567.89" }, { label: "1.234.567,89", value: "1.234.567,89" }, { label: "1 234 567,89", value: "1 234 567,89" }])
                }
                else
                {
                    setFormatlist([{ label: "1,234,567", value: "1,234,567" }, { label: "1.234.567", value: "1.234.567" }, { label: "1 234 567", value: "1 234 567" }])
                }
            }
        }
    }
    const submitCurrency = () => {
        
        setcurrency_id("")
        setCurrencySymbol("")
        setCurrencyCode("");
        setFormat("")
        setDecimal("2")
           
    }
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].currency_id) {
            
                setcurrency_id(editmodal[i].currency_id)
                setstatus(editmodal[i].status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        //var id = currency_id;
        //var status_value = status;
        var values = { "id": currency_id, "status": status, "page_name": page_name,"organization_id":organization_id };
        console.log(values);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-currency`, values, headers).then((res) => {
            //console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Currency details deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitCurrencyDetails = (e) => {
        e.preventDefault();
        //var id = document.getElementById("id").value;
        
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (CurrencyCode == "") {
            swal("Warning!", "Currency Code cannot be empty", "warning");
            return false;
        }
        else if (!CurrencyCode.match(nexp)) {
            swal("Warning!", "Currency Code cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = editmodal.filter((item) => { return item.currency_id !== currency_id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].currency_code == CurrencyCode) {
                    swal("Oops!", "Currency Code already exists, try different one!", "error");
                    return false;
                }
            }
        }
        if (CurrencySymbol == "") {
            swal("Warning!", "Currency Symbol cannot be empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

    if(currency_id!="")
    {
        var values = { "id": currency_id, "currency_code": CurrencyCode,"currency_symbol": CurrencySymbol,"decimal_place": Decimal,"format":Format, "page_name": page_name,"organization_id":organization_id };
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-currency`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Currency details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    else
    {
        var values = { "currency_code": CurrencyCode,"currency_symbol": CurrencySymbol,"decimal_place": Decimal,"format":Format, "page_name": page_name,"organization_id":organization_id };
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Currency details added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Currency List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-body">
                                <>
                                    <h4 id="notice_create_alert" style={{ "text-align": "center" }} className="text-gray-800 fw-bolder">Add New Currency</h4>

                                    <div className="d-flex justify-content-center pb-0 px-0">
                                        {
                                            write &&
                                                <a id="button_create_alert" type="button" onClick={(e) => { submitCurrency() }}  data-bs-toggle="modal" data-bs-target="#EditModal" className="btn btn-sm btn-primary me-3 rounded">New Currency</a>
                                        }

                                    </div>
                                </>

                                <br />

                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Currency List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                        </MuiThemeProvider>

                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Currency List</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Currency</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12"> 
                                    <h3>Are you sure you want to delete this Currency details?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                                <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} ><span class="indicator-label">Delete</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span></button>
                            </form>}
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>{currency_id==""?"New ":"Update"} Currency</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Currency Code</label>
                                            {currency_id==""?
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="currency"
                                                id="currency"
                                                className="form-control form-control-solid"
                                                options={Currencylist}
                                                onChange={(event) => { setCurrencyCode(event.value); loadCurrency(event.value) }}
                                                value={Currencylist.filter(option => option.value == CurrencyCode).length != 0 && Currencylist.filter(option => option.value == CurrencyCode)[0]}
                                                placeholder="--Choose a Currency--"
                                            />
                                            :
                                            <input id='CurrencySymbol' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={CurrencyCode} onChange={(e) => { setCurrencySymbol(e.target.value) }} readOnly={true}/>
                                            }
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                        <label className="fs-5 fw-bold mb-2">Currency Symbol</label>
                                        <input id='CurrencySymbol' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={CurrencySymbol} onChange={(e) => { setCurrencySymbol(e.target.value) }} readOnly={write?false:true}/>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="category"
                                                id="category"
                                                className="form-control form-control-solid"
                                                options={DecimalPlaceslist}
                                                onChange={(event) => { setDecimal(event.value) }}
                                                value={DecimalPlaceslist.filter(option => option.value == Decimal).length != 0 && DecimalPlaceslist.filter(option => option.value == Decimal)[0]}
                                                placeholder="--Choose Decimal Places--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Format</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="currency"
                                                id="currency"
                                                className="form-control form-control-solid"
                                                options={Formatlist}
                                                onChange={(event) => { setFormat(event.value) }}
                                                value={Formatlist.filter(option => option.value == Format).length != 0 && Formatlist.filter(option => option.value == Format)[0]}
                                                placeholder="--Choose a Format--"
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                            {write==1 &&<button onClick={(e) => { submitCurrencyDetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
                                    <span class="indicator-label">{currency_id==""?"Submit":"Update"}</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>}
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="ModalOrganization" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-label="myModalLabel" aria-hidden="true"  data-pause="99999999999999999">
                <div class="modal-dialog mw-700px">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h2>Select Organization</h2>
                            {/*<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div> */}
                        </div>
                        <div className="modal-body py-10 px-lg-17">
                            <form class="form-default">
                                <div  class="modal-body tt-background">
                                    <div class="tt-modal-discount row">
                                        <div className="row mb-5">
                                            {
                                            organization_list.length > 0 ? organization_list.map((obj, index) => {
                                                if(index < 5)
                                                {
                                                    return(
                                                        <>									
                                                        <div class="col-lg-12">
                                                            {(getCookie("organization_id")==obj.organization_id)?
                                                            <input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} name="account_type" value="personal" id={obj.organization_id} checked/>
                                                            :
                                                            <input type="radio" class="btn-check" name="account_type" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} value="personal"  id={obj.organization_id}/>
                                                            }
                                                            <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
                                                                <span class="svg-icon svg-icon-3x me-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
                                                                        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="d-block fw-bold text-start">
                                                                    <span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
                                                                    <span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
                                                                </span>
                                                            </label>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </>
                                                    );
                                                }
                                            })
                                            :
                                            <>
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default CurrencyList;

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'
import {MuiThemeProvider, createTheme} from '@material-ui/core';

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const Advancepayments = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    const [loading_modal, setloading_modal] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [email, setEmail] = useState("");
    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=5;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [fileNames, setFileNames] = useState(["", ""])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [DateFormat, setDateFormat] = useState("");
    const [Expense_date, setExpense_date] = useState("");
    const [description, setdescription] = useState("");
    const [References, setReferences] = useState("");
    const [Amount, setAmount] = useState("");
    const [Amount1, setAmount1] = useState("");
    const [trip_id, settrip_id] = useState("");
    const [TripList, setTripList] = useState([])
    const [Currencylist, setCurrencylist] = useState([])
    const [PaidThrouhAccountlist, setPaidThrouhAccountlist] = useState([])
    const [Currency, setCurrency] = useState("");
    const [Currency1, setCurrency1] = useState("");
    const [BaseCurrency, setBaseCurrency] = useState("");
    const [Account, setAccount] = useState("");
    const [AllReportlist, setAllReportlist] = useState([])
    const [Reportlist, setReportlist] = useState([])
    const [Report, setReport] = useState("");
    let [expense_id, setexpense_id] = useState("");
    
    const [Count, setCount] = useState(6);
    let [alert_log, setAlertLog] = useState([]);
    let [timezone_change,set_timezone_change] = useState("+00:00");
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [LiveCurrencyData, setLiveCurrencyData] = useState([]);
    const [BaseCurrencyCode, setBaseCurrencyCode] = useState("");
    const [TargetCurrencyCode, setTargetCurrencyCode] = useState("");

    let [currency_state, setcurrency_state] = useState(2);
    const [Currency_conversion_value, setCurrency_conversion_value] = useState("");
    const [AllCurrencylist, setAllCurrencylist] = useState([]);
    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([{ label: "All", value: "All" }, { label: "UnReported", value: "UnReported" }, { label: "Reported", value: "Reported" }])

    
	var page_name = "expense-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Document',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "trip",
            label: "Trip",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reference",
            label: "Reference",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "report_name",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    setFileNames = (name) => {
        fileNames.push(name)
        let uniq = fileNames => [...new Set(fileNames)];
        fileNames = fileNames.slice(-2)
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])
    
    useEffect(() => {
        async function getpaymentdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/payment-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            let temp_dropdown=[]
            body.map((obj) => {
                if (obj.add_status === "Active" && obj.user_id === user.email) {
                    temp_dropdown.push(obj);
                }
            })
            if(temp_dropdown.length>0)
            {
            setEditModal(temp_dropdown);
            //setloading(true);
            }
            else
            {
                setstate(0)
            }
        }
        if (read || write) {
            user.email && visibility && getpaymentdetails();
        }   
    }, [user, visibility, read, write])

    async function getactivitydetails() {
        var user_object = {};
        user_object.email = user.email;
        user_object.page_name = page_name;
        user_object.organization_id=organization_id;
        var headers = {
            headers: {
              "Content-Type": "application/json",
              "Accept": "*/*",
              "token" : getCookie("access_token")
            }
          }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/record-advance-activity`,user_object, headers).then(async (res) => {
            if(res.data.length>0)
            {    
                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body : JSON.stringify({"email" : user_object.email,"page_name" : page_name,"organization_id":organization_id}) , headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }}
                );
                const body = await response.json();
                
                if(body.length > 0)
                {
                    if(body[0].timezone_value != "" && body[0].timezone_value != null)
                    {
                        let timezone_change = body[0].timezone_value;
                        set_timezone_change(timezone_change)
                    }
                }
                setAlertLog(res.data);
            }
        })
    }

    async function getbasecurrency() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if(body.length>0)
        {
            setCurrency(body[0].currency_id);
            setCurrency1(body[0].currency_id);
            setBaseCurrency(body[0].currency_symbol);
            setBaseCurrencyCode(body[0].currency_code);
            setDateFormat(body[0].date_format)
        }
    }

    async function getreportdetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-my-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        setAllReportlist(body)
        var temp_dropdown = [];
        body.map((obj) => {
                var temp_obj = {};
                temp_obj.label = obj.report_name;
                temp_obj.value = obj.id;
                temp_dropdown.push(temp_obj);
        })
        setReportlist(temp_dropdown);       
    }

    async function gettripdetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-approved-trip`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        var temp_dropdown = [];
        body.map((obj) => {
            
            var temp_obj = {};
            temp_obj.label = obj.trip_name;
            temp_obj.value = obj.id;
            temp_dropdown.push(temp_obj);  
        })
        setTripList(temp_dropdown);
    }

    async function getaccountdetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/paid-through-accounts-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.add_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.account_name;
                temp_obj.value = obj.unique_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setPaidThrouhAccountlist(temp_dropdown);
    }

    async function getcurrencydetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.currency_code;
                temp_obj.value = obj.currency_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setCurrencylist(temp_dropdown);
        setAllCurrencylist(body)
    }

    async function getlivecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if(body.length>0)
        {
            let arr=[];
            arr.push(body[0].data.quotes)
            setLiveCurrencyData(arr);
        }
    }

    useEffect(() => {
        if (loading) {
            let p1 = getactivitydetails();
            let p2 = getbasecurrency();
            let p3 = getreportdetails();
            let p4 = gettripdetails();
            let p5 = getaccountdetails();
            let p6 = getcurrencydetails();
            let p7 = getlivecurrency();
            Promise.all([p1,p2,p3,p4,p5,p6,p7])
            .then(()=>{
                setloading_modal(true);
                console.log("all apis done")
            })
            .catch((err)=>{
                setloading_modal(false);
                console.log(err)
            })
        }
    }, [loading])


    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.Amount = obj.currency_symbol+obj.amount;
                    if(obj.report_id!="")
                    {
                        temp_obj.report_name = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                        temp_obj.status = "REPORTED";
                    }
                    else
                    {
                        temp_obj.report_name ="-----";
                        temp_obj.status = "UNREPORTED";
                    }
                    temp_obj.date = moment(obj.date).format(DateFormat)
                    if(obj.reference!="")
                    {
                        temp_obj.reference = obj.reference;
                    }
                    else
                    {
                        temp_obj.reference ="-----"
                    }
                    if(obj.trip_id!="")
                    {
                        temp_obj.trip =TripList.filter(option => option.value == obj.trip_id)[0].label;
                    }
                    else
                    {
                        temp_obj.trip ="-----"
                    }
                    //temp_obj.status = obj.add_status;
                    temp_obj.expense_id = obj.unique_id;
                    var id = obj.unique_id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                            <i className="fas fa-bars px-0"></i>
                            </button>
                            {
                                write ?
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { updatePayment(id); setloading(true); }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#AdvanceHistory" class="dropdown-item" href="#">History</a></li>
                                        {obj.report_id=="" && <li><a onClick={(e) => { updateadvances(obj.trip_id,id) }} data-bs-toggle="modal" data-bs-target="#EditAttachments" class="dropdown-item" href="#">Apply to Report</a></li>}
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            } 
        }
        (editmodal.length>0) && geteditdetails();

    }, [editmodal,TripList,Reportlist])

    const getAdvanceList= (id) => {
        if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            if(id=="Reported")
            {
                arr=editmodal.filter(option => option.report_id !="")
            }
            else if(id=="UnReported")
            {
                arr=editmodal.filter(option => option.report_id =="")
            }
            arr.map((obj) => {
                var temp_obj = {};
                temp_obj.Amount =obj.currency_symbol+obj.amount;
                if(obj.report_id!="")
                {
                    temp_obj.report_name = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    temp_obj.status = "REPORTED";
                }
                else
                {
                    temp_obj.report_name ="-----";
                    temp_obj.status = "UNREPORTED";
                }
                temp_obj.date = moment(obj.date).format(DateFormat)
                if(obj.reference!="")
                {
                    temp_obj.reference = obj.reference;
                }
                else
                {
                    temp_obj.reference ="-----"
                }
                if(obj.trip_id!="")
                {
                    temp_obj.trip =TripList.filter(option => option.value == obj.trip_id)[0].label;
                }
                else
                {
                    temp_obj.trip ="-----"
                }
                //temp_obj.status = obj.add_status;
                temp_obj.expense_id = obj.unique_id;
                var id = obj.unique_id;
                temp_obj.actions =
                    <div class="dropdown">
                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                        <i className="fas fa-bars px-0"></i>
                        </button>
                        {
                            write ?
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a href="#" onClick={(e) => { updatePayment(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                    <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Delete</a></li>
                                    <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#AdvanceHistory" class="dropdown-item" href="#">History</a></li>
                                    {obj.report_id=="" && <li><a onClick={(e) => { updateadvances(obj.trip_id,id) }} data-bs-toggle="modal" data-bs-target="#EditAttachments" class="dropdown-item" href="#">Apply to Report</a></li>}
                                </ul>
                                :
                                null
                        }

                    </div>

                temp_data.push(temp_obj);
            })
            setData(temp_data);
        }
        else {
            setData([]);
        }
    }

    const updatePayment = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].unique_id) {
                if(editmodal[i].currency_conversion_value!="")
                {
                    //setcurrency_state(1);
                    setCurrency_conversion_value(editmodal[i].currency_conversion_value);
                }
                setexpense_id(id)
                setExpense_date(moment(editmodal[i].date).format("yyyy-MM-DD"))
                settrip_id(editmodal[i].trip_id);
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setAmount1(editmodal[i].amount);
                setdescription(editmodal[i].notes);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const updateadvances = (trip_id,id) => {
        setexpense_id(id)
        var temp_dropdown = [];
            AllReportlist.map((obj) => {
                if (obj.add_status === "Active" && obj.trip==trip_id) {
                    var temp_obj = {};
                    temp_obj.label = obj.report_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            console.log(temp_dropdown)
            setReportlist(temp_dropdown);    
   }

    const updateStatus = (id) => {
         setexpense_id(id)        
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": expense_id, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-expense`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitPaymentDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = expense_id;
        object.Expense_date = Expense_date;
        object.trip_id = trip_id;
        object.Currency = Currency;
        object.Amount = Amount;
        object.Amount1 = Amount1;
        object.References = References;
        object.report_id = "";
        object.description = description;
        object.Account = Account;
        object.page_name = page_name;
        object.Currency_conversion_value =Currency_conversion_value;
        object.organization_id=organization_id;
        
        if (Expense_date == "") {
            swal("Warning!", "Date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Amount == "") {
            swal("Warning!", "Amount cannot be a empty", "warning");
            return false;
        }
        /*else if (!Amount.match(cexp)) {
            swal("Warning!", " Amount cannot contain certain symbols ", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(expense_id!="")
        {
            
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-payment`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Advance payment updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
            
        }
        else
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-payment`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Advance payment(s) created successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }

    const submitPayment = (e) => {
        e.preventDefault();

        if (Report == "") {
            swal("Warning!", "Report name cannot be a empty", "warning");
            return false;
        }
    
        var values = { "id": expense_id,"Report":Report,"page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-reports-to-advance`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Advance has been applied successfully.", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitAdvance = () => {
        setexpense_id("")
        setExpense_date(moment().format("yyyy-MM-DD"))
        settrip_id("");
        setAccount("");
        console.log(Currency1)
        setCurrency(Currency1);
        setAmount("");
        setdescription("");
        setReferences("");
    }

    const load_currency_conversion_value = (val) => {
        let target_currency_code=AllCurrencylist.filter(option =>
            option.currency_id == val)[0].currency_code;
            let conversion_value=0;
            if(target_currency_code!="USD")
            {
                conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code])*100)/100;
            }
            else
            {
                conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode])*100)/100;
            }
        setTargetCurrencyCode(target_currency_code)
        setCurrency_conversion_value(conversion_value);
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Record Advances
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <span className="badge badge-light fw-bolder my-2">
                        {write==1&&<button type="button" className="btn btn-sm btn-primary me-3 rounded" onClick={(e) => { submitAdvance(); setloading(true);}}  data-bs-toggle="modal" data-bs-target="#EditModal"><i className="la la-plus"></i>Record Advance</button>}
                        </span>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    data.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Select status:</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="category"
                                                        id="category"
                                                        className="form-control"
                                                        options={StatusList}
                                                        onChange={(event) => { 
                                                            setStatus(event.value)
                                                            getAdvanceList(event.value)
                                                        }}
                                                        value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                        placeholder="--Choose a Type--"
                                                    />
                                                </div>
                                            </div>
                                            <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Record Advances List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                            </MuiThemeProvider>
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">All Advances</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}
            <div className="modal fade" id="EditAttachments" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add reports</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                            <div className="row mb-5">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Select reports:</label>
                                        <Select
                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                            name="Report"
                                            id="Report"
                                            className="form-control form-control-solid"
                                            options={Reportlist}
                                            onChange={(event) => { setReport(event.value) }}
                                            value={Reportlist.filter(option => option.value == Report).length != 0 && Reportlist.filter(option => option.value == Report)[0]}
                                            placeholder="--Choose a Report--"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                                <button type="button" className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitPayment(e) }}><span className="indicator-label">Save</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AdvanceHistory" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">History</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div class="m-0">							
                                        <div class="timeline ms-n1">
                                        {
                                            alert_log.length > 0 ? alert_log.map((obj, index) => {
                                                if(index < Count && obj.advance_payment_id==expense_id)
                                                {
                                                    return(
                                                        <>
                                                        {index%2==0 ?
                                                        <>
                                                        <br/>                                    
                                                        <div class="timeline-item align-items-center mb-4">
                                                            
                                                            <div class="timeline-line w-20px mt-9 mb-n14"></div>
                                                            
                                                            <div class="timeline-icon pt-1" style={{"margin-left": "0.7px"}}>
                                                                
                                                                <span class="svg-icon svg-icon-2 svg-icon-success">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z" fill="black"></path>
                                                                        <path d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                
                                                            </div>
                                                            
                                                            <div class="timeline-content m-0">
                                                                
                                                                <span class="fs-8 fw-boldest text-success text-uppercase"></span>
                                                                
                                                                <a href="#" class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{obj.operation == "insert" ? <>An Advance of Rs.{obj.amount} was recorded.</> :obj.operation == "update" ? <>Advance Updated.{obj.amount!=""&&<>Amount change to Rs.{obj.amount}</>}</>:obj.operation == "insertreport" && <>An Advance of Rs.{obj.amount} has been applied to the report {obj.report_name}</>}</a>
                                                                
                                                                <span class="fw-bold text-gray-400">{obj.user_id},{moment(obj.log_date_time).zone(timezone_change).format('llll')}</span>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                        <br/>
                                                        <div class="timeline-item align-items-center">
                                                            
                                                            <div class="timeline-line w-20px"></div>
                                                            
                                                            <div class="timeline-icon pt-1" style={{"margin-left": "0.5px"}}>
                                                                
                                                                <span class="svg-icon svg-icon-2 svg-icon-info">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                                                        <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                
                                                            </div>
                                                            
                                                            <div class="timeline-content m-0">
                                                            
                                                                <span class="fs-8 fw-boldest text-info text-uppercase"></span>
                                                                
                                                                <a href="#" class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{obj.operation == "insert" ? <>An Advance of Rs.{obj.amount} was recorded.</> :obj.operation == "update" ? <>Advance Updated.{obj.amount!=""&&<>Amount change to Rs.{obj.amount}</>}</>:obj.operation == "insertreport" && <>An Advance of Rs.{obj.amount} has been applied to the report {obj.report_name}</>}</a>
                                                                
                                                                <span class="fw-bold text-gray-400">{obj.user_id},{moment(obj.log_date_time).zone(timezone_change).format('llll')}</span>
                                                                
                                                            </div>
                                                        </div>
                                                        </>
                                                        }
                                                        
                                                        </>   
                                                        );
                                                }
                                            })
                                                :
                                                <>
                                                { state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                                    :
                                                    null
                                                }
                                                </>
                                            }   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>

                    </div>

                </div>

            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Advance</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    
                                    <h3>Are you sure you want to delete this Advance Record?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                                <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitStatus(e); }} ><span className="indicator-label">Delete</span>
                                    <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                            </form>
                            }
                        </div>

                    </div>

                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>{expense_id!=""?"Update":"Submit"} Advance Details</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                {loading_modal ?
                                    <>
                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Date:</label>
                                            <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                onChange={(e) => {
                                                    setExpense_date(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-3 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="Currency"
                                                id="Currency"
                                                className="form-control-solid"
                                                options={Currencylist}
                                                onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-md-3 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Amount </label>
                                            <input id="subcategory_name" type="number" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={Amount} onChange={(e) => { setAmount(e.target.value) }} />
                                        </div>
                                    </div>
                                    
                                        {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                            <>
                                             <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                            <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*Amount}</span>
                                            </>
                                        }
                                        
                                        
                                    
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="Account"
                                                id="Account"
                                                className="form-control-solid"
                                                options={PaidThrouhAccountlist}
                                                onChange={(event) => { setAccount(event.value) }}
                                                value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                placeholder="--Choose a Account--"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">References#</label>
                                            <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                        </div>

                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Apply to Trip:</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="category"
                                                id="category"
                                                className="form-control-solid"
                                                options={TripList}
                                                onChange={(event) => { settrip_id(event.value) }}
                                                value={TripList.filter(option => option.value == trip_id).length != 0 && TripList.filter(option => option.value == trip_id)[0]}
                                                placeholder="--Choose a Trip--"
                                            />
                                        </div>
                                    </div> 
                                    <div className="row mb-5">

                                        <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Notes</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                    </div>
                                    </>
                                    :
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                } 
                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                            {write==1 &&
                                <button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitPaymentDetails(e) }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button">
                                    <span class="indicator-label">{expense_id!=""?"Update":"submit"}</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            }   
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default Advancepayments;

import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {setCookie,getCookie} from '../common_component/cookie.js';

function Signin() {
	
	let [RedirectState, setRedirectState] = useState(2);
	const [organization_id, setOrganization_id] = useState("");
	let [captchaToken, setCaptchaToken] = useState("");
	
	function onChange(value) {
		setCaptchaToken(value)
	}
		
	const login =(e)=>{
		
		//let domain = (new URL(url));
			e.preventDefault();
			var object = {};
			object.email = document.getElementById("email").value;
			object.password = document.getElementById("password").value;
			object.token = captchaToken;
			
			var exp=/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if(object.email=="")
			{
				swal("Oops!", "Please enter an email id", "error");
				return false;
			}
			else if (!object.email.match(exp))
			{
				swal("Oops!", "Please enter a valid email id", "error");
				return false;
			}
			
			if(object.password=="")
			{
				swal("Oops!", "Please enter password.", "error");
				return false;
			}
			else if(!isNaN(object.password))
			{
				//swal("Oops!", "Please enter a valid password.", "error");
				//return false;
			}
			
			if(object.token=="")
			{
				swal("Oops!", "Please Verify the captcha", "error");
				return false;
			}
			else if(!isNaN(object.token))
			{
				swal("Oops!", "Please Verify the captcha", "error");
				return false;
			}
			
			if(e.target.id == "kt_sign_in_submit")
			{
				e.target.setAttribute("data-kt-indicator", "on");
				e.target.disabled = true;
			}
			else
			{
				document.getElementById("kt_sign_in_submit").setAttribute("data-kt-indicator", "on");
				document.getElementById("kt_sign_in_submit").disabled = true;
			}
			
			var headers = {
			  headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			  }
			}
			
			axios.post(`${process.env.REACT_APP_SERVER_URL}/login`,object, headers).then((res) => {
				
				if(res.data.operation == "success")
				{
					if(res.data.quick_set_up==0)
					{
						var object = {};
						var headers = {
							headers: {
								"Content-Type": "application/json",
								"token": res.data.token,
								"Accept": "*/*"
							}
						}
						axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res1) => {
							if (res1.data.length>0) {
								setCookie('organization_id', res1.data[0].organization_id, 1,true);
								setOrganization_id(res1.data[0].organization_id)
								setCookie('access_token', res.data.token, 1,true)
								setRedirectState(1);
								onsubmit();
							}
						})
					}
					else
					{
						setCookie('access_token', res.data.token, 1,true);
						window.location.href = "/quick-set-up";
					}
				}
				else
				{
					if(res.data.message != "danger")
					{
						swal("Oops!", res.data.message, "error").then((res) => {
							window.location.reload();
						});
					}
					else
					{
						swal("Oops!", "Username or Password is wrong", "error").then((res) => {
							window.location.reload();
						});
					}
				}
			})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}
	
	useEffect(() => {
			var se = document.createElement('script');
			se.src = "/assets/js/custom/authentication/sign-in/general.js";
			document.body.appendChild(se);
	},[])

	const onsubmit = () =>{		
		if(getCookie("lastpage") != "")
		{
			window.location.href = getCookie("lastpage");
		}
		else{
			window.location.href = "/dashboard"
		}
	}
	
	return (
		<>
		<div className="d-flex flex-column flex-root" id="kt_app_root">
			
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">

			<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2" style={{"background-image":"url(assets/media/misc/auth-bg.png)"}}>
					
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						
						<a href="../../demo1/dist/index.html" className="mb-12">
							<img alt="Logo" src=" https://cdnstatic.adocrm.com/static/ado-crm-logo-light.png" className="h-75px" />
						</a>
						
						<img className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="assets/media/misc/auth-screens.png" alt="" />
						
						<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
						
						<div className="text-white fs-base text-center">In this kind of post,
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
						<br />and provides some background information about
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
						<br />work following this is a transcript of the interview.</div>
						
					</div>
					
				</div>
				
				<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">
					
					<div className="d-flex flex-center flex-column flex-lg-row-fluid">
						
						<div className="w-lg-500px p-10">
							
							<form className="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#">
								
								<div className="text-center mb-11">
									
									<h1 className="text-dark fw-bolder mb-3">Sign In</h1>
									
									<div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
									
								</div>
								
								<div className="row g-3 mb-9">
									
									<div className="col-md-6">
										
										<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
										
									</div>
									
									<div className="col-md-6">
										
										<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
										
									</div>
									
								</div>
								
								<div className="separator separator-content my-14">
									<span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
								</div>
								
								<div className="fv-row mb-8">
									
									<input type="text" placeholder="Email" name="email" id="email" autocomplete="off" className="form-control bg-transparent" />
									
								</div>
								
								<div className="fv-row mb-3">
									
									<input type="password" placeholder="Password" name="password" id="password" autocomplete="off" className="form-control bg-transparent" />
									
								</div>
								
								<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
									<div></div>
									
									<a href="/forgot-password-email" className="link-primary">Forgot Password ?</a>
									
								</div>
								
								<div className="d-grid mb-10">
								<div className="text-center">
									<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
									<br />
									<button type="submit" onClick={(e) => {login(e)}} id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
										<span className="indicator-label">Continue</span>
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								</div>
									{/*<button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
										
										<span className="indicator-label">Sign In</span>
										
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										
									</button>*/}
								</div>
								
								<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
								<a href="/register" className="link-primary">Sign up</a></div>
								
							</form>
							
						</div>
						
					</div>
					
					<div className="d-flex flex-center flex-wrap px-5">
						
						<div className="d-flex fw-semibold text-primary fs-base">
							<a href="../../demo1/dist/pages/team.html" className="px-5" target="_blank">Terms</a>
							<a href="../../demo1/dist/pages/pricing/column.html" className="px-5" target="_blank">Plans</a>
							<a href="../../demo1/dist/pages/contact.html" className="px-5" target="_blank">Contact Us</a>
						</div>
						
					</div>
					
				</div>
				

				
			</div>
			
		</div>
		{/*
		<div className="bg-dark header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{"--kt-toolbar-height":"55px", "--kt-toolbar-height-tablet-and-mobile":"55px"}} >
			<div className="d-flex flex-column flex-root">
				<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{"backgroundSize1": "100% 50%" , "backgroundImage": "url(assets/media/misc/outdoor.png)" }}>
					<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
						<a className="mb-12">
							<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-45px" />
						</a>
						<div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
							<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_sign_in_form" onSubmit={(e) => {login(e)}}>
								<div className="text-center mb-10"> 
									<h1 className="text-dark mb-3">Sign In to ADOCRM</h1>
									<div className="text-gray-400 fw-bold fs-4">New Here?
									<a href="/register" className="link-primary fw-bolder"> Create an Account</a></div>
								</div>
								<div className="fv-row mb-10">
									<label className="form-label fs-6 fw-bolder text-dark">Email</label>
									<input className="form-control form-control-lg form-control-solid" type="text" name="email" id="email" autocomplete="off" />
								</div>
								<div className="fv-row mb-10">
									<div className="d-flex flex-stack mb-2">
										<label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
										<a href="/forgot-password-email" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>
									</div>
									<input className="form-control form-control-lg form-control-solid" type="password" id="password" name="password" autocomplete="off" />
								</div>
								<div className="text-center">
									<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
									<br />
									
									<button type="submit" onClick={(e) => {login(e)}} id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
										<span className="indicator-label">Continue</span>
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
									
									
								</div>
							</form>
						</div>
					</div>
					<div className="d-flex flex-center flex-column-auto p-10">
						<div className="d-flex align-items-center fw-bold fs-6">
						</div>
					</div>
				</div>
			</div>
	</div>*/}
		</>
	);
}
export default Signin;
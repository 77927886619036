import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ReportApproval = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([{ label: "Domestric", value: "Domestric" }, { label: "International", value: "International" }])


    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    let [Description, setDescription] = useState("");
    const [trip_id, settrip_id] = useState("");
    const [status, setstatus] = useState("");
    let [approver, setapprover] = useState("");

    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [DateFormat, setDateFormat] = useState("");
    const [userlist, setuserList] = useState([])
    //let maxdate = moment().format('YYYY-MM-DD');
    //const [StatusList, setStatusList] = useState([])
    let [page_actions, setpage_actions] = useState([]);

    var page_name = "report-approval";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const onRowClick = (rowData,rowMeta) => {
        window.location.href=`/report-details/${rowData[0]}`
    }

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Project',
        onRowClick: onRowClick,
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                display:false,
                filter: true,
                sort: true
            }
        },
        {
            name: "report_name",
            label: "Report Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "submitter",
            label: "Submitter",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "total_amount",
            label: "Total",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "amount_to_reimbursed",
            label: "To Be Reimbursed",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "duration",
            label: "Duration",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "Approver",
            label: "Approver",
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_actions_permission.access.length; i++) {
                                if(res.data.user_actions_permission.access[i].key=="Report")
                                {
                                    setpage_actions(res.data.user_actions_permission.access[i].actions)
                                }
                            }
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        function uniqueByKey(array, key) {
            return [...new Map(array.map((x) => [x[key], x])).values()];
          }
        async function gettripdetails() {
            console.log(page_actions)
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-custom-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id,"type":page_actions.filter(option =>
                    option.key =="View Report")[0].value}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setstate(1);
            }
            else
            {
                setstate(0);
            }
            setEditModal(body);
            body.map((obj1) => {
                let sum=0;
                let expense_amount=0;
                let claim_reimbursement_amount=0;
                obj1.advance_details.map((obj) => {
                    sum=sum+obj.amount;
                })
                obj1.expense_details.map((obj) => {
                    expense_amount=expense_amount+obj.amount;
                    if(obj.claim_reimbursement==0)
                    {
                        claim_reimbursement_amount=claim_reimbursement_amount+obj.amount;
                    }
                })
                obj1.total_amount=expense_amount;
                obj1.amount_to_reimbursed=(expense_amount-claim_reimbursement_amount-sum);
            })  
            var lookup = {};
            var items = body;
            var result = [];
            result.push({label:"All",value:"All"});
            for (var item, i = 0; item = items[i++];) {
            var name = item.add_status;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push({label:name,value:name});
                }
            }
            setStatusList(result)

        }
        if(read || write)
        {
            (user.email && visibility) && gettripdetails();
        }
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                //setCurrency(body[0].currency);
                //setBaseCurrency(body[0].currency);
                setDateFormat(body[0].date_format)
            }
        }
        if(read || write)
        {
            user.email && visibility && getbasecurrency();
        }

    }, [user, visibility, read, write])


    useEffect(() => {
        
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.report_name = obj.report_name;
                    temp_obj.trip_type = obj.travel_type;
                    temp_obj.submitter = obj.user_id;
                    temp_obj.Approver = obj.approver;
                    temp_obj.total_amount = obj.total_amount;
                    temp_obj.amount_to_reimbursed = obj.amount_to_reimbursed;
                    temp_obj.duration = moment(obj.start_date).format(DateFormat)+" to "+moment(obj.end_date).format(DateFormat);
                    if(obj.add_status=="Active")
                    {
                        temp_obj.status = "Draft";
                    }
                    else if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status;
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])


    const getReportList= (id) => {
        if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            if(id!="All")
            {
                arr=editmodal.filter(option => option.add_status == id)
            }
            arr.map((obj) => {
                var temp_obj = {};
                    temp_obj.report_name = obj.report_name;
                    temp_obj.trip_type = obj.travel_type;
                    temp_obj.submitter = obj.user_id;
                    temp_obj.Approver = obj.approver;
                    temp_obj.total_amount = obj.total_amount;
                    temp_obj.amount_to_reimbursed = obj.amount_to_reimbursed;
                    temp_obj.duration = moment(obj.start_date).format(DateFormat)+" to "+moment(obj.end_date).format(DateFormat);
                    if(obj.add_status=="Active")
                    {
                        temp_obj.status = "Draft";
                    }
                    else if(obj.add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else
                    {
                        temp_obj.status =obj.add_status;
                    }
                    temp_obj.id = obj.id;
                    var id = obj.id;
                    temp_data.push(temp_obj);
            })
            setData(temp_data);
        }
        else {
            setData([]);
        }
    }


    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Report List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                { 
                                    data.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Select status:</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        name="category"
                                                        id="category"
                                                        className="form-control"
                                                        options={StatusList}
                                                        onChange={(event) => { 
                                                            setStatus(event.value)
                                                            getReportList(event.value)
                                                        }}
                                                        value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                        placeholder="--Choose a Type--"
                                                    />
                                                </div>
                                            </div>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Report List"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                        </MuiThemeProvider>
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Report List</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ReportApproval;

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
import moment from 'moment';
//import './previewbox.css'
import MUIDataTable from "mui-datatables";
import SetColor from './SetColor.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom'

import removeImg from './icons/remove.svg'
import country_arr from './countries-arr.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const ExpenseDetails = (props) => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    const [loading_modal, setloading_modal] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    let timer_flag = 0;
    let [state, setstate] = useState(2);
    let [currency_state, setcurrency_state] = useState(2);

    const [email, setEmail] = useState("");
    const [Document_number, setDocument_number] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;
    const document_count=5;
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [fileNames, setFileNames] = useState([])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [Amount1, setAmount1] = useState("");
    const [Total_amount, setTotal_amount] = useState("");
    const [Nonreimbursable_amount, setNonreimbursable_amount] = useState("");
    const [Applied_advance_amount, setApplied_advance_amount] = useState("");
    const [Amount_to_reimbursed, setAmount_to_reimbursed] = useState("");
    const [DateFormat, setDateFormat] = useState("");

    const [item_category, setitem_category] = useState([]);
    const [Expense_date, setExpense_date] = useState("");
    const [description, setdescription] = useState("");
    const [References, setReferences] = useState("");
    const [Amount, setAmount] = useState("");
    const [Expense_amount, setExpense_amount] = useState("");

    const [AllCurrencylist, setAllCurrencylist] = useState([])
    const [multipleMode, setMultipleMode] = useState(true)
    
    const [DuplicateExpense, setDuplicateExpense] = useState([])
    const [CategoryList1, setCategoryList1] = useState([])
    const [CategoryList, setCategoryList] = useState([])
    const [MerchantList, setMerchantList] = useState([])
    const [Currencylist, setCurrencylist] = useState([])
    const [Reportlist, setReportlist] = useState([])
    const [Reportlist1, setReportlist1] = useState([])
    const [PaidThrouhAccountlist, setPaidThrouhAccountlist] = useState([])
    const [SplitTypelist, setSplitTypelist] = useState([{ label: "Split by amount", value: "Split by amount" }, { label: "Split by days", value: "Split by days" }])
    const [ExpenseNumberlist, setExpenseNumberlist] = useState([{ label: "2", value: "2" }, { label: "3", value: "3" },{ label: "4", value: "4" },{ label: "5", value: "5" }])
    
    const [Expense_Start_date, setExpense_Start_date] = useState("");
    const [Expense_End_date, setExpense_End_date] = useState("");
    const [expense_number, setexpense_number] = useState("2");
    const [Split_type, setSplit_type] = useState("Split by amount");
    const [Category, setCategory] = useState("");
    const [Merchant, setMerchant] = useState("");
    const [Currency, setCurrency] = useState("");
    const [Currency1, setCurrency1] = useState("");
    const [Currency_conversion_value, setCurrency_conversion_value] = useState("");
    const [Report, setReport] = useState("");
    const [Account, setAccount] = useState("");
    const [Claim_reimbursement, setClaim_reimbursement] = useState(true);
    const [LiveCurrencyData, setLiveCurrencyData] = useState([]);

    const [BaseCurrencyCode, setBaseCurrencyCode] = useState("");
    const [TargetCurrencyCode, setTargetCurrencyCode] = useState("");

    let [redirect_to_login, setRedirect_to_login] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [BaseCurrency, setBaseCurrency] = useState("");
    const [Expense_Amount_Limit,setExpense_Amount_Limit] = useState("");
    const [Receipt_Required_Limit,setReceipt_Required_Limit] = useState("");
    const [Make_Description_Mandatory,setMake_Description_Mandatory] = useState("");
    const [Category_Limits, setCategory_Limits] = useState([])
    const [General_Limits, setGeneral_Limits] = useState([])
    const [selected_document_list, setselected_document_list] = useState([]);
    const [expenseAccounts,setExpenseAccounts] = useState([{account: null, amount: 0, notes: "",Violation:false}])

    const [ExpenseList,setExpenseList] = useState([])
    let [expense_id, setexpense_id] = useState("");
    const [document_list, setdocument_list] = useState([]);

    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([])

    let [ExpenseDetails, set_ExpenseDetails] = useState([]);
    let [ExpenseDetails1, setExpenseDetails1] = useState([]);
    const [Index, setIndex] = useState("");
    const [Violation, setViolation] = useState(false);

    let [image_id, setimage_id] = useState("");
    let [file_name, setfile_name] = useState("");
    let [original_file_name, setoriginal_file_name] = useState("");
    let [folder_name, setfolder_name] = useState("expense_documents");

    const [Count1, setCount1] = useState(6);
    let [alert_log, setAlertLog] = useState([]);
    let [timezone_change,set_timezone_change] = useState("+00:00");
    
	var page_name = "expense-details";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])
    
    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})   
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });

            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
        //loadScripts();
        setId(uuid());
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        function isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
        async function getExpenseDetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/expense-details`, { method: 'POST', body: JSON.stringify({"id": props.match.params.id,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(isEmpty(body)===false)
            {
                setExpenseDetails1(body);     
                //setloading(true);      
            }
            else
            {
                setstate(1);
            }
        }
        if(read || write)
        {
            (user.email && visibility)  && getExpenseDetails();
            (user.email && visibility)  && getReportDetails();
            (user.email && visibility)  && getactivitydetails();
            (user.email && visibility)  && getcurrencydetails();
        }
    }, [user, visibility, read, write])

        async function getactivitydetails() { 
            var user_object = {};
            user_object.email = user.email;
            user_object.organization_id = organization_id;
            user_object.page_name = page_name;
            user_object.expense_id = props.match.params.id;
            var headers = {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "*/*",
                  "token" : getCookie("access_token")
                }
              }
            
            axios.post(`${process.env.REACT_APP_SERVER_URL}/expense-activity`,user_object, headers).then(async (res) => {
                if(res.data.length>0)
                {    
                    const response = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body : JSON.stringify({"email" : user_object.email,"page_name" : page_name}) , headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }}
                    );
                    const body = await response.json();
                    
                    if(body.length > 0)
                    {
                        if(body[0].timezone_value != "" && body[0].timezone_value != null)
                        {
                            let timezone_change = body[0].timezone_value;
                            set_timezone_change(timezone_change)
                        }
                    }
                    setAlertLog(res.data);
                }
            })
        }

        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setCurrency1(body[0].currency_id);
                setBaseCurrency(body[0].currency_symbol);
                setBaseCurrencyCode(body[0].currency_code)
                setDateFormat(body[0].date_format)
            }
        }

        async function getReportDetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/show-my-reports`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status != "deactivated" && obj.user_id==user.email) {
                    var temp_obj = {};
                    temp_obj.label = obj.report_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setReportlist(temp_dropdown);
            var temp_dropdown1 = [];
            body.map((obj) => {
                if (obj.add_status != "deactivated" && obj.user_id==user.email) {
                    var temp_obj1 = {};
                    temp_obj1.label = obj.report_name;
                    temp_obj1.value = obj.id;
                    temp_obj1.add_status = obj.add_status;
                    temp_dropdown1.push(temp_obj1);
                }
            })
            setReportlist1(temp_dropdown1);
        }

        async function getAccountDetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/paid-through-accounts-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.account_name;
                    temp_obj.value = obj.unique_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setPaidThrouhAccountlist(temp_dropdown);
        }

        async function getcurrencydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.currency_code;
                    temp_obj.value = obj.currency_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCurrencylist(temp_dropdown);
            setAllCurrencylist(body)
        }

        async function getcategorydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.category_name;
                    temp_obj.value = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCategoryList1(temp_dropdown);
        }

        async function getlivecurrency() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                let arr=[];
                arr.push(body[0].data.quotes)
                setLiveCurrencyData(arr);
            }
        }

        async function getmerchantdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/merchant-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.merchant_name;
                    temp_obj.value = obj.merchant_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setMerchantList(temp_dropdown);
        }

    useEffect(() => {
        if (loading) {
            //let p1 = getactivitydetails();
            let p2 = getbasecurrency();
            //let p3 = getReportDetails();
            let p4 = getAccountDetails();
            //let p5 = getcurrencydetails();
            let p6 = getcategorydetails();
            let p7 = getlivecurrency();
            let p8 = getmerchantdetails();
            Promise.all([p2,p4,p6,p7,p8])
            .then(()=>{
                setloading_modal(true);
                console.log("all apis done")
            })
            .catch((err)=>{
                setloading_modal(false);
                console.log(err)
            })
        }
    }, [loading])

    useEffect(() => {
        async function getpolicycategorydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-policy-related-categories`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            if(body.length>0)
            {
                if(body[0].general_limits!="")
                {
                    setGeneral_Limits(JSON.parse(body[0].general_limits))
                }
                if(body[0].category_limits!="")
                {
                    setCategory_Limits(JSON.parse(body[0].category_limits))
                }
                JSON.parse(body[0].category_limits).map((obj) => {
                    if (obj.acceptability ===true) {
                        var temp_obj = {};
                        temp_obj.label = CategoryList1.filter(option => option.value == obj.category_id)[0].label;
                        temp_obj.value = obj.category_id;
                        temp_dropdown.push(temp_obj);
                    }
                })
            }
            setCategoryList(temp_dropdown);
        }
        if(read || write)
        {
            (user.email && visibility && CategoryList1.length>0) && getpolicycategorydetails();
        }
    }, [user, visibility,read, write,CategoryList1])
 	
    useEffect(() => {
        async function geteditdetails() {
            let claim_reimbursement="";
            if(ExpenseDetails1[0].claim_reimbursement==1)
            {
                ExpenseDetails1[0].claim_reimbursement=true;
            }
            else
            {
                ExpenseDetails1[0].claim_reimbursement=false;
            }
            setCurrency(ExpenseDetails1[0].currency);
            
            if(ExpenseDetails1[0].currency_conversion_value!="")
            {
                //setcurrency_state(1);
                setCurrency_conversion_value(ExpenseDetails1[0].currency_conversion_value);
            }
            if(JSON.parse(ExpenseDetails1[0].accounts).length>1)
            {
                ExpenseDetails1[0].category ="Itemized";
                setitem_category(JSON.parse(ExpenseDetails1[0].accounts))
            }
            else
            {
                ExpenseDetails1[0].category =JSON.parse(ExpenseDetails1[0].accounts)[0].account.label;
            }

            //for status
            if(ExpenseDetails1[0].report_id=="")
            {
                ExpenseDetails1[0].status ="UNREPORTED";
            }
            else if(Reportlist1.filter(option => option.value == ExpenseDetails1[0].report_id)[0].add_status=="Submitted")
            {
                ExpenseDetails1[0].status ="AWAITING APPROVAL";
            }
            else if(Reportlist1.filter(option => option.value == ExpenseDetails1[0].report_id)[0].add_status=="Approved")
            {
                ExpenseDetails1[0].status ="APPROVED";
            }
            else
            {
                ExpenseDetails[0].status ="UNSUBMITTED";
            }
            set_ExpenseDetails(ExpenseDetails1);
            setEditModal(ExpenseDetails1);
            setdocument_list(ExpenseDetails1[0].expense_document_details)
            let doc=[]
            for(let i=0;i<ExpenseDetails1[0].expense_document_details.length;i++)
            {
                doc.push(ExpenseDetails1[0].expense_document_details[i].original_file_name)
            }
            setselected_document_list(doc)       
        }
        if(read || write)
        {
            (user.email && visibility && ExpenseDetails1.length>0 && AllCurrencylist.length>0)  && geteditdetails();
        }
    }, [user, visibility, read, write,Reportlist1,AllCurrencylist,ExpenseDetails1])

    
    const submitattachments = (e) => {
        e.preventDefault();
        var values = { "expense_id": props.match.params.id,"unique_id":id,'filenames': fileNames,"page_name": page_name,"organization_id":organization_id};
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-expense-document-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Report doccuments saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    var files = [];
    var sendButton = document.getElementById("kt_contact_submit_button");
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames]
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if (status === "error_file_size") {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        //setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        
        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('organization_id', organization_id);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-expense-documents`, headers, body }
    }


    const removeattachments = (file_name,original_file_name,id) => {
        setimage_id(id)
        setfile_name(file_name); 
        setoriginal_file_name(original_file_name);     
   }
    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }

    const delete_document = (e) => {
        e.preventDefault();
    
        var values = { "id": props.match.params.id,"image_id":image_id,"file_name":file_name,"original_file_name":original_file_name,"folder":folder_name,"page_name": page_name,"organization_id":organization_id};

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-expense-document`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Document deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const updateExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setexpense_id(id)
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setClaim_reimbursement(editmodal[i].claim_reimbursement) 
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setAmount1(editmodal[i].amount);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const cloneExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                setexpense_id("")
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setClaim_reimbursement(editmodal[i].claim_reimbursement) 
                setAccount(editmodal[i].paid_through_account);
                setCurrency(editmodal[i].currency);
                setAmount(editmodal[i].amount);
                setReferences(editmodal[i].reference);
            }
        }
    }

    const addExpense = () => {
        setexpense_id("")
        setExpense_date("")
        setExpenseAccounts([{account: null, amount: 0, notes: ""}])
        setMerchant("");
        setReport("");
        setClaim_reimbursement("") 
        setAccount("");
        setAmount("");
        setCurrency(Currency1);
        setReferences("");
        setExpense_Amount_Limit("");
        setReceipt_Required_Limit(""); 
        setMake_Description_Mandatory("");         
    }

    const duplicateExpense = (id) => {
        let duplicate=[];
        let amount2=editmodal.filter(option => option.expense_id == id)[0].amount;
        let currency1=editmodal.filter(option => option.expense_id == id)[0].currency;
        let expense_date1=editmodal.filter(option => option.expense_id == id)[0].expense_date;
        for(let i=0;i<editmodal.length;i++)
        { 
            if((editmodal[i].expense_date==expense_date1) && (editmodal[i].amount==amount2) && (editmodal[i].currency==currency1))
            {
                duplicate.push(editmodal[i])
            }
        }
        setDuplicateExpense(duplicate)        
    }

    const splitExpense = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].expense_id) {
                //console.log(editmodal)
                setexpense_id(id)
                setMerchant(editmodal[i].merchant_id);
                setReport(editmodal[i].report_id);
                setReferences(editmodal[i].reference);
                setExpense_date(moment(editmodal[i].expense_date).format("yyyy-MM-DD"))
                setExpenseAccounts(JSON.parse(editmodal[i].accounts))
                setAmount(parseFloat(editmodal[i].amount).toFixed(2));
                setCurrency(editmodal[i].currency);
                setAccount(editmodal[i].paid_through_account);
                let list=[];
                let date=moment(editmodal[i].expense_date).format("yyyy-MM-DD");
                let amount1=parseInt((editmodal[i].amount/expense_number)*100)/100;
                let percentage=parseFloat(100/expense_number).toFixed(2);
                let claim_reimbursement=editmodal[i].claim_reimbursement;
                setClaim_reimbursement(editmodal[i].claim_reimbursement); 
                let category=JSON.parse(editmodal[i].accounts)[0].account;
                var amnt=0;
                for(let i=0;i<parseInt(expense_number);i++)
                {
                    amnt=parseFloat(amnt)+parseFloat(amount1);
                    list.push({Claim_reimbursement:claim_reimbursement,expense_date:date,category: category, amount: amount1, percentage: percentage})
                }
                setExpense_amount(parseFloat(amnt).toFixed(2))
                setExpenseList(list);
            }
        }
    }
  
    const updateStatus = (id) => {
         setexpense_id(id)      
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": expense_id,"folder_name":folder_name,"page_name": page_name,"organization_id":organization_id};
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-expense`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const Mark_duplicate = (e) => {
        e.preventDefault();

        var values = { "id": expense_id, "page_name": page_name,"organization_id":organization_id};

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/mark-as-not-duplicate`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense is marked as not duplicate!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitExpenseDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = expense_id;
        object.organization_id = organization_id;
        object.Expense_date = Expense_date;
        object.Merchant = Merchant;
        object.Report = Report;
        object.Currency = Currency;
        object.Amount = expenseAccounts.reduce((p,o)=>{return p+o.amount},0);
        object.expenseAccounts = expenseAccounts;
        object.References = References;
        object.Claim_reimbursement = Claim_reimbursement;
        object.Account = Account;
        object.page_name = page_name;
        object.Currency_conversion_value =  Currency_conversion_value;
        ;
        
        if (Expense_date == "") {
            swal("Warning!", "Expense date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Currency == "") {
            swal("Warning!", "Currency cannot be a empty", "warning");
            return false;
        }
        let t = expenseAccounts.find(o=>{
            if((o.account==null)||(o.amount==0)){
                return o
            }
        })
        if(t!=undefined)
        {
            swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
            return
        }
        /*else if (!Amount.match(cexp)) {
            swal("Warning!", " Amount cannot contain certain symbols ", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if(expense_id!="")
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-expense`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Expense details updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else
        {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-expense`, object, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Expense details added successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }

    const SplitExpenseDetails = (e) => {
        e.preventDefault();
        var object = {};
        object.id = expense_id;
        object.organization_id = organization_id;
        object.Merchant = Merchant;
        object.Report = Report;
        object.Currency = Currency;
        object.References = References;
        object.Currency_conversion_value = Currency_conversion_value;
        object.Account = Account;
        object.ExpenseList=ExpenseList;
        object.page_name = page_name;
        
        if (Expense_date == "") {
            swal("Warning!", "Expense date cannot be a empty", "warning");
            return false;
        }
		var cexp=/^[0-9]+$/
        if (Currency == "") {
            swal("Warning!", "Currency cannot be a empty", "warning");
            return false;
        }
        let t = expenseAccounts.find(o=>{
            if((o.account==null)||(o.amount==0)){
                return o
            }
        })
        if(t!=undefined)
        {
            swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
            return
        }
        /*else if (!Amount.match(cexp)) {
            swal("Warning!", " Amount cannot contain certain symbols ", "warning");
            return false;
        }
        */
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/split-expense`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Expense details splitted successfully!", "success").then((value) => {
                    window.location.href="/expense-list";
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

const check_expense_count = (count) => {
    setExpenseList([]);
    let list=[];
    let date=Expense_date;
    let amount1=parseInt((Amount/count)*100)/100;
    let percentage=parseFloat(100/count).toFixed(2);
    let category=expenseAccounts[0].account.label;
    let amnt=0;
    for(let i=0;i<parseInt(count);i++)
    {
        amnt=parseInt((amnt)*100)/100+parseInt((amount1)*100)/100;
        list.push({Claim_reimbursement:Claim_reimbursement,expense_date:date,category: category, amount: amount1, percentage: percentage})
    }
    //console.log(amnt)
    setExpense_amount(amnt)
    setExpenseList(list);
}

const check_expense = (type) => {
    if(type=="Split by days")
    {
        setExpense_Start_date(Expense_date)
        setExpense_End_date(moment.utc(Expense_date).add(1, 'days').format('YYYY-MM-DD'))
    }
    setExpenseList([]);
    let list=[];
    let date=Expense_date;
    let amount1=parseInt((Amount/expense_number)*100)/100;
    let percentage=parseFloat(100/expense_number).toFixed(2);
    //console.log(expenseAccounts[0].account.label)
    let category=expenseAccounts[0].account;
    let amnt=0;
    amnt=parseFloat(amnt)+parseFloat(amount1);
    amnt=parseFloat(amnt)+parseFloat(amount1);
    setExpense_amount(parseFloat(amnt).toFixed(2))
    list.push({Claim_reimbursement:Claim_reimbursement,expense_date:Expense_date,category: category, amount: amount1, percentage: percentage})
    list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(Expense_date).add(1, 'days').format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
    setExpenseList(list);
}

const check_expense_date = (start_date,end_date) => {
    //console.log(Expense_Start_date,Expense_End_date)
    var start_date1 = moment(start_date, 'YYYY-MM-DD'); 
    var end_date1 = moment(end_date, 'YYYY-MM-DD');
    let diff = end_date1.diff(start_date1, 'days');
    setExpenseList([]);
    let list=[];
    let date=Expense_date;
    let amount1=parseFloat(Amount/(diff+1)).toFixed(2);
    let percentage=parseFloat(100/(diff+1)).toFixed(2);
    let category=expenseAccounts[0].account.label;
    let amnt=0;      
    amnt=parseFloat(amnt)+parseFloat(amount1);
    list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(start_date).format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
    for(let i=0;i<parseInt(diff);i++)
    {
        amnt=parseFloat(amnt)+parseFloat(amount1);
        list.push({Claim_reimbursement:Claim_reimbursement,expense_date:moment.utc(start_date).add(1, 'days').format('YYYY-MM-DD'),category: category, amount: amount1, percentage: percentage})
        start_date=moment.utc(start_date).add(1, 'days').format('YYYY-MM-DD')
    }
    setExpense_amount(parseFloat(amnt).toFixed(2))
    setExpenseList(list);    
}

const add_expense = (type) => {
    const values1 = [...ExpenseList];
    let count=ExpenseList.length+1;
    let date=Expense_date;
    if(type=="Split by days")
    {
        date=moment.utc(values1[ExpenseList.length-1].expense_date).add(1, 'days').format('YYYY-MM-DD');
    }
    let category=expenseAccounts[0].account;
     let amount=0;
     let percentage=0;
    if(Amount-Expense_amount>0)
    {
        amount=parseFloat(Amount-Expense_amount).toFixed(2);
        percentage=parseFloat((amount)/Amount).toFixed(2);
        setExpense_amount(parseFloat(Amount).toFixed(2));
    }
    values1.push({Claim_reimbursement:Claim_reimbursement,expense_date:date,category: category, amount:amount, percentage: percentage})
    setExpenseList(values1);
};

const check_expense_amount = (temp) => {
    let amnt=0;      
    for(let i=0;i<parseInt(temp.length);i++)
    {
        amnt=parseFloat(amnt)+parseFloat(temp[i].amount);
    }
    setExpense_amount(parseFloat(amnt).toFixed(2))   
}

const register = (e) => {
    e.preventDefault();
    var object = {};
    object.organization_id = organization_id;
    object.Expense_date = Expense_date;
    object.Merchant = Merchant;
    object.Report = Report;
    object.Currency = Currency;
    object.Amount = expenseAccounts.reduce((p,o)=>{return p+o.amount},0);
    object.References = References;
    object.expenseAccounts = expenseAccounts;
    object.Claim_reimbursement = Claim_reimbursement;
    object.Account = Account;
    object.page_name = page_name;
    object.Currency_conversion_value = Currency_conversion_value;

    var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (object.Expense_date == "") {
        swal("Warning!", "Expense date cannot be a empty", "warning");
        return false;
    }
    
    
    var cexp=/^[0-9]+$/
    if (Currency == "") {
        swal("Warning!", "Currency cannot be a empty", "warning");
        return false;
    }
    let t = expenseAccounts.find(o=>{
        if((o.account==null)||(o.amount==0)){
            return o
        }
    })
    if(t!=undefined)
    {
        swal("Oops!", "Expense Account Details and/or amount cannot be empty", "warning");
        return
    }
    setMainSubmitButtonState(true)
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }

    axios.post(`${process.env.REACT_APP_SERVER_URL}/add-expense`, object, headers).then((res) => {
        setMainSubmitButtonState(false)
        if (res.data && res.data.operation == "success") {
            swal("Great!", "Expense Created successfully!", "success").then((value) => {
                window.location.reload();
            });
        }
        else
        {
            swal("Oops!", "Something went wrong!", "error");
        }
    })
        .catch(function (error) {
        });      
}

const expense_rule_check = (account,indx) => {
    if(account!=null)
    {
        setIndex(indx);
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
        {
            setExpense_Amount_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
        }
        else
        {
            setExpense_Amount_Limit(General_Limits[0].Expense_Amount_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
        {
            setReceipt_Required_Limit(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
        }
        else
        {
            setReceipt_Required_Limit(General_Limits[0].Receipt_Required_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
        {
            setMake_Description_Mandatory(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
        }
        else
        {
            setMake_Description_Mandatory(General_Limits[0].Make_Description_Mandatory);
        }     
    }
}

const violation_check = (index,account,notes,amount) => {
    if(account!=null)
    {       
        let Expense_Amount= "";
        let Receipt_Required= "";
        let Make_Description= false;
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
        {
            Expense_Amount= (Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
        }
        else
            {if(General_Limits!="")
            {
                Expense_Amount= (General_Limits[0].Expense_Amount_Limit);
            }
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
        {
            Receipt_Required= (Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
        }
        else
        {
            if(General_Limits!="")
            {
                Receipt_Required= (General_Limits[0].Receipt_Required_Limit);
            }
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
        {
            Make_Description=(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
        }
        else
        {
            if(General_Limits!="")
            {
                Make_Description=(General_Limits[0].Make_Description_Mandatory);
            }
        }
        console.log(Expense_Amount,Receipt_Required,amount)
        if(parseInt(Expense_Amount)< parseInt(amount) || parseInt(Receipt_Required)< parseInt(amount) || (notes=="" && Make_Description=="Yes"))
        {
            let temp = [...expenseAccounts];
            temp[index].Violation=true;
            temp[index].account=account;
            temp[index].amount=parseInt(amount);
            temp[index].notes=notes;
            setExpenseAccounts(temp);
        }
        else
        {
            let temp = [...expenseAccounts];
            temp[index].account=account;
            temp[index].Violation=false;
            temp[index].amount=parseInt(amount);
            temp[index].notes=notes;
            setExpenseAccounts(temp);
        }  
    }
}

const violation_status_check = (account,notes,amount) => {
    if(account!=null)
    {
        let Expense_Amount= 0;
        let Receipt_Required= 0;
        let Make_Description= false;
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit!="")
        {
            Expense_Amount= parseInt(Category_Limits.filter(option => option.category_id == account.value)[0].Expense_Amount_Limit);
        }
        else
        {
            Expense_Amount= parseInt(General_Limits[0].Expense_Amount_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit!="")
        {
            Receipt_Required= parseInt(Category_Limits.filter(option => option.category_id == account.value)[0].Receipt_Required_Limit);
        }
        else
        {
            Receipt_Required= parseInt(General_Limits[0].Receipt_Required_Limit);
        }
        if(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory!="")
        {
            Make_Description=(Category_Limits.filter(option => option.category_id == account.value)[0].Make_Description_Mandatory);
        }
        else
        {
            Make_Description=(General_Limits[0].Make_Description_Mandatory);
        }
        if(parseInt(Expense_Amount)< parseInt(amount) || parseInt(Receipt_Required)< parseInt(amount) || (notes!="" && Make_Description=="Yes"))
        {
            return true;
        }  
    }
}

const load_currency_conversion_value = (val) => {
    let target_currency_code=AllCurrencylist.filter(option =>
        option.currency_id == val)[0].currency_code;
        let conversion_value=0;
        if(target_currency_code!="USD")
        {
            conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode]/LiveCurrencyData[0]["USD"+target_currency_code])*100)/100;
        }
        else
        {
            conversion_value=Math.round((LiveCurrencyData[0]["USD"+BaseCurrencyCode])*100)/100;
        }
    setTargetCurrencyCode(target_currency_code)
    setCurrency_conversion_value(conversion_value);
}

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Expense Details
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-stretch">
                        {write==true &&
                            <div className="topbar-item px-3 px-lg-5" id="kt_activities_toggle1" title="Activity Logs">
                                <a id="button_create_alert" type="button" onClick={(e) => { addExpense(); setloading(true); }} data-bs-toggle="modal" data-bs-target="#AddExpense" className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Add Expense</a>
                            </div>
                        }
                        </div>   
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card  pt-0 pb-15 px-3 px-xl-10">
                            <br />
                            {ExpenseDetails.length>0 ?
                                <>
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card mt-5 mt-xxl-8">
                                            <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                                <ul className="nav flex-wrap border-transparent fw-bolder col-12" role="tablist">
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Basic Information</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_4">Documents</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">History</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-xxl-stretch mb-5 mb-xl-10">
                                        <div className="card-body py-3">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                        <div className="card-header cursor-pointer">
                                                            <div className="card-title m-0">
                                                                <h3 className="fw-bolder m-0">Expense Details</h3>
                                                            </div>
                                                            <span className="badge badge-light fw-bolder my-2">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                                                    <i className="fas fa-bars px-0"></i>
                                                                    </button>
                                                                    {
                                                                        write ?
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                <li><a href="#" onClick={(e) => { splitExpense(props.match.params.id) }} data-bs-toggle="modal" data-bs-target="#SplitExpense" className="dropdown-item">Split Expense</a></li>
                                                                                <li><a href="#" onClick={(e) => { cloneExpense(props.match.params.id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Clone</a></li>
                                                                                <li><a href="#" onClick={(e) => { updateExpense(props.match.params.id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                                                                {<li><a onClick={(e) => { updateStatus(props.match.params.id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="dropdown-item" href="#">Delete</a></li>}
    
                                                                            </ul>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div className="card-body p-9">
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Expense Date</label>
                                                                <div className="col-lg-8">
                                                                    <span className="fw-bolder fs-6 text-dark">{moment(ExpenseDetails[0].expense_date).format(DateFormat)}  </span>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Status</label>
                                                                <div className="col-lg-8 fv-row">
                                                                    <span className="fw-bold fs-6">
                                                                    {ExpenseDetails[0].status=="Approved" ?<span className="badge badge-success">Approved</span>
                                                                    :
                                                                    ExpenseDetails[0].status=="UNREPORTED" ?<span className="badge badge-danger">UNREPORTED</span>
                                                                    :
                                                                    ExpenseDetails[0].status=="UNSUBMITTED" ?<span className="badge badge-danger">UNSUBMITTED</span>
                                                                    :
                                                                    ExpenseDetails[0].status=="Submitted" &&<span className="badge badge-warning">Awaiting Approval</span>
                                                                    }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Category</label>
                                                                <div className="col-lg-8 fv-row">
                                                                    <span className="fw-bold fs-6">
                                                                    {ExpenseDetails[0].category=="Itemized" ?
                                                                    <a data-bs-toggle="modal" data-bs-target="#ItemizedList">Itemized</a>
                                                                    :
                                                                    ExpenseDetails[0].category
                                                                    }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/*<div className="row mb-7">
                                                            <label className="col-lg-4 fw-bold text-muted">Merchant
                                                                    </label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                {ExpenseDetails[0].Merchant}
                                                                </div>
                                                                </div>*/}
                                                            <div className="row mb-7">
                                                                <label className="col-lg-4 fw-bold text-muted">Amount</label>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    <span className="fw-bolder fs-6 me-2">{AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol}{ExpenseDetails[0].amount}</span>
                                                                    {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                    <>
                                                                    <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                    <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*ExpenseDetails[0].amount}</span>
                                                                    </>
                                                                    }
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_table_widget_5_tab_2">
                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view2">
                                                        <div className="card-header cursor-pointer">
                                                            <div className="card-title m-0">
                                                                <h3 className="fw-bolder m-0">History</h3>
                                                            </div>
                                                            
                                                        </div><br/>
                                                        <div className="row gy-5 g-xl-8">
                                                        <div class="m-0">							
                                                            <div class="timeline ms-n1">
                                                                {
                                                                alert_log.length > 0 ? alert_log.map((obj, index) => {
                                                                    if(index < Count1)
                                                                    {
                                                                        return(
                                                                            <>
                                                                            {index%2==0 ?
                                                                            <>
                                                                            <br/>                                    
                                                                            <div class="timeline-item align-items-center mb-4">
                                                                                <div class="timeline-line w-20px mt-9 mb-n14"></div>
                                                                                    <div class="timeline-icon pt-1" style={{"margin-left": "0.7px"}}>
                                                                                        <span class="svg-icon svg-icon-2 svg-icon-success">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z" fill="black"></path>
                                                                                                <path d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="black"></path>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </div>
                                                                                
                                                                                <div class="timeline-content m-0">
                                                                                    <span class="fs-8 fw-boldest text-success text-uppercase"></span>
                                                                                    <a href="#" class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{obj.operation == "insert" ? <>An Expense of {AllCurrencylist.filter(option =>
                                                                                    option.currency_id == Currency)[0].currency_symbol}{obj.amount} was recorded.</> :obj.operation == "update" ? <>Expense Updated.{obj.amount!=""&&<>Amount change to {AllCurrencylist.filter(option =>
                                                                                        option.currency_id == Currency)[0].currency_symbol}{obj.amount}</>}</>:obj.operation == "insertreport" && <>An Advance of {AllCurrencylist.filter(option =>
                                                                                            option.currency_id == Currency)[0].currency_symbol}{obj.amount} has been applied to the report {obj.report_name}</>}</a>
                                                                                    <span class="fw-bold text-gray-400">{obj.user_id},{moment(obj.log_date_time).zone(timezone_change).format('llll')}</span>
                                                                                </div> 
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            <br/>
                                                                            <div class="timeline-item align-items-center">
                                                                                <div class="timeline-line w-20px"></div>
                                                                                <div class="timeline-icon pt-1" style={{"margin-left": "0.5px"}}> 
                                                                                    <span class="svg-icon svg-icon-2 svg-icon-info">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                            <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path>
                                                                                            <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path>
                                                                                        </svg>
                                                                                    </span>                                                                                  
                                                                                </div>
                                                                                <div class="timeline-content m-0">
                                                                                    <span class="fs-8 fw-boldest text-info text-uppercase"></span>
                                                                                    <a href="#" class="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{obj.operation == "insert" ? <>An Expense of {AllCurrencylist.filter(option =>
                                                                                    option.currency_id == Currency)[0].currency_symbol}{obj.amount} was recorded.</> :obj.operation == "update" ? <>Expense Updated.{obj.amount!=""&&<>Amount change to {AllCurrencylist.filter(option =>
                                                                                        option.currency_id == Currency)[0].currency_symbol}{obj.amount}</>}</>:obj.operation == "insertreport" && <>An Advance of {AllCurrencylist.filter(option =>
                                                                                            option.currency_id == Currency)[0].currency_symbol}{obj.amount} has been applied to the report {obj.report_name}</>}</a>
                                                                                    <span class="fw-bold text-gray-400">{obj.user_id},{moment(obj.log_date_time).zone(timezone_change).format('llll')}</span> 
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                            }
                                                                            </>   
                                                                            );
                                                                        }
                                                                    })
                                                                    :
                                                                    <>
                                                                    { state == 2 ?
                                                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                                                        :
                                                                        null
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_table_widget_5_tab_4">
                                                    <div className="timeline-content mb-10 mt-n1 py-4">
                                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                            <div className="flex-grow-1 me-2">
                                                                <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">Expense Attachments</a>
                                                                <span className="text-muted fw-bold d-block fs-7"></span>
                                                            </div>
                                                            <span className="badge badge-light fw-bolder my-2">
                                                            {(document_list.length<document_count && write==true)&&<button type="button" className="btn font-weight-bold btn-primary"  data-bs-toggle="modal" data-bs-target="#EditAttachments"><i className="la la-plus"></i>Add Document</button>}
                                                            </span>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                                                {document_list.length>0 &&
                                                                    <div className="overflow-auto pb-5 ">
                                                                        
                                                                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-350px p-2">
                                                                            {
                                                                                document_list.map((obj1, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="overlay me-10">
                                                                                                <div className="overlay-wrapper">
                                                                                                    <img id='inside_map_img' alt='img' className="rounded w-200px" src={`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`} />
                                                                                                </div>
                                                                                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                                    <a onClick={() => showImage(`${process.env.REACT_APP_PRODUCT_DOCUMENT_BUCKET}/${folder_name}/${obj1.file_name}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                                    <a onClick={(e) => { removeattachments(obj1.file_name,obj1.original_file_name,obj1.unique_id) }} data-bs-toggle="modal" data-bs-target="#DeleteDocument" className="btn btn-sm btn-primary me-3 rounded">Delete</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    document_list.length === 0 &&
                                                                    <>
                                                                    <div className="flex-lg-row-fluid me-lg-15">
                                                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                                            <div className="card-body pt-0">
                                                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                                                <div className="d-flex align-items-center p-3 mb-2">
                                                                                </div>
                                                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                                    <div className="d-flex flex-stack flex-grow-1">
                                                                                        <div className="fw-bold">
                                                                                            <h4 className="text-gray-800 fw-bolder">No Documents Available</h4>
                                                                                            <div className="fs-6 text-gray-600">
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="ItemizedList" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Item List</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12"> 
                                                        <div class="flex-grow-1">
															<div class="table-responsive border-bottom mb-9">
																<table class="table mb-3">
																	<thead>
																		<tr class="border-bottom fs-6 fw-bolder text-muted">
																			<th class="min-w-175px pb-2">Category</th>
																			<th class="min-w-70px text-end pb-2"></th>
																			<th class="min-w-100px text-end pb-2">Amount</th>
																		</tr>
																	</thead>
																	<tbody>
                                                                        {item_category.length > 0 && item_category.map((obj1, index) => {
                                                                            return (
                                                                            <>  
                                                                            <tr class="fw-bolder text-gray-700 fs-5 text-end">
                                                                                <td class="d-flex align-items-center pt-6">
                                                                                <i class="fa fa-genderless text-danger fs-2 me-2"></i>{obj1.account.label}</td>
                                                                                <td class="pt-6"></td>
                                                                                <td class="pt-6 text-dark fw-boldest">{AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol}{obj1.amount}</td>
                                                                            </tr>
                                                                            </>
                                                                        )})}
																	</tbody>
																</table>
															</div>
															<div class="d-flex justify-content-end">
																<div class="mw-300px">
																	<div class="d-flex flex-stack mb-3">
																		<div class="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
																		<div class="text-end fw-bolder fs-6 text-gray-800">{AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol}{ExpenseDetails[0].amount}</div>
																		
																	</div>
																	<div class="d-flex flex-stack">
																		
																		<div class="fw-bold pe-10 text-gray-600 fs-7">Total</div>

																		<div class="text-end fw-bolder fs-6 text-gray-800">{AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol}{ExpenseDetails[0].amount}</div>
																		
																	</div>
																	
																</div>
																
															</div>
															
														</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {/*<form >
                                                        <button type="button" className="btn btn-primary" onClick={(e) => { Select_Expense(e); }} >Save</button>
                                                    </form>
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="duplicateExpense" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Possible Duplicates</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <label className="d-flex align-items-center form-label mb-5">Possible Duplicates
                                                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="" aria-label=""></i></label>
                                                            <div className="mb-0">	
                                                            {DuplicateExpense.length > 0 && DuplicateExpense.map((obj1, index) => {
                                                            return (
                                                            <>
                                                            <div class="d-flex align-items-center mb-8">
                                                    
                                                                <span class="bullet bullet-vertical h-40px bg-success"></span>
                                                                <div class="form-check form-check-custom form-check-solid mx-5"></div>
                                                                
                                                                <div class="flex-grow-1">
                                                                    <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{BaseCurrency}{obj1.amount}</a>
                                                                    
                                                                    {obj1.mark_as_not_duplicate!="true" &&<span class="badge badge-light-success me-auto rounded">Mark as not duplicate</span>}
                                                                    <span class="text-muted fw-bold d-block">{moment(obj1.date).format("ll")} , Category:{JSON.parse(obj1.accounts).length>1 ?<span>Itemize</span> : JSON.parse(obj1.accounts)[0].account.label}</span>
                                                                </div>
                                                                {obj1.mark_as_not_duplicate=="true" && <button class="btn btn-sm btn-primary text-white d-block mx-auto me-2" type="button" onClick={(e) => { updateStatus(obj1.expense_id) }} data-bs-toggle="modal" data-bs-target="#ConfirmDuplicates">
                                                                    Not a duplicate
                                                                </button>}
                                                                <button class="btn btn-sm btn-primary text-white d-block mx-auto" type="button" onClick={(e) => { updateStatus(obj1.expense_id) }} data-bs-toggle="modal" data-bs-target="#EditStatus">
                                                                Delete
                                                                </button>
                                                            </div><br/>  
                                                            </>
                                                            );
                                                        })}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    {/*<form >
                                                        <button type="button" className="btn btn-primary" onClick={(e) => { Select_Expense(e); }} >Save</button>
                                                    </form>
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="DeleteDocument" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Delete Documents</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12"> 
                                                            <h3>Are you sure you want to delete this Documents?</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-danger" onClick={(e) => { delete_document(e);}} >Delete</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="modal fade" id="EditAttachments" tabindex="-1" aria-hidden="true">

                                        <div className="modal-dialog mw-650px">

                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Attach receipts</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                    {document_list.length ?
                                                            <div className="overflow-auto pb-5 ">
                                                                <label className="required fs-5 fw-bold mb-2">Documents</label>
                                                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-350px p-2">
                                                                    {
                                                                        document_list.map((obj1, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="overlay me-10">
                                                                                        <div className="overlay-wrapper">
                                                                                            <img id='inside_map_img' alt='img' className="rounded w-200px" src={`https://shavastra-gn.s3.ap-south-1.amazonaws.com/expense_attachments/${obj1.file_name}`} />
                                                                                        </div>
                                                                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                            <a onClick={() => showImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/expense_attachments/${obj1.file_name}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                            <a onClick={() => removeTravelDocument(index)} className="btn btn-sm btn-primary me-3 rounded">Delete</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                            }
                                                            {(document_list.length<document_count)&&
                                                                <div>
                                                                    <label className="fs-5 fw-bold mb-2">Maximum {document_count-document_list.length} files</label>
                                                                    <Dropzone
                                                                        getUploadParams={getUploadParams}
                                                                        onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                                        onSubmit={handleSubmit}
                                                                        validate={handleValidation2}
                                                                        LayoutComponent={CustomLayout}
                                                                        styles={{
                                                                            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                            inputLabelWithFiles: { margin: '20px 3%' }
                                                                        }}
                                                                        maxFiles={document_count-document_list.length}
                                                                        // canRemove={false}
                                                                        PreviewComponent={Preview}
                                                                        accept="image/*,.pdf"
                                                                    />
                                                                </div>
                                                            }

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-primary" onClick={(e) => { submitattachments(e); }} >Save</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                        */}
                                    <div className="modal fade" id="ConfirmDuplicates" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Marked as not a duplicate</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">

                                                        <div className="col-lg-12">
                                                            
                                                            <h3>Are you sure you want to Marked as not a duplicate?</h3>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-success" onClick={(e) => { Mark_duplicate(e); }} >Confirm</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Delete Expense Record</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <h3>Are you sure you want to delete this Expense Record?</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-danger" data-kt-indicator={mainSubmitButtonState?"on":"off"} 
                                                        disabled={mainSubmitButtonState} onClick={(e) => { submitStatus(e); }} >Delete</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="SplitExpense" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered mw-850px">
                                            <div className="modal-content">
                                                <form className="form" action="#" id="kt_modal_new_address_form">
                                                    <div className="modal-header" id="kt_modal_new_address_header">
                                                        <h2>Split Expense({Currency!=""&&<>{AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol}</>} {Amount})</h2>
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body py-10 px-lg-17">
                                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                        <div className="row mb-5">
                                                            <div className="col-md-4">
                                                                <label className="required fs-5 fw-bold mb-2">Split By</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    name="Currency"
                                                                    id="Currency"
                                                                    className="form-control form-control-solid"
                                                                    options={SplitTypelist}
                                                                    onChange={(event) => { 
                                                                        setSplit_type(event.value)
                                                                        {check_expense(event.value)} 
                                                                    }}
                                                                    value={SplitTypelist.filter(option => option.value == Split_type).length != 0 && SplitTypelist.filter(option => option.value == Split_type)[0]}
                                                                    placeholder="--Choose a Type--"
                                                                />
                                                            </div>
                                                            {Split_type=="Split by amount" ?
                                                            <div className="col-md-5">
                                                                <label className="required fs-5 fw-bold mb-2">Number of expenses</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    name="Currency"
                                                                    id="Currency"
                                                                    className="form-control form-control-solid"
                                                                    options={ExpenseNumberlist}
                                                                    onChange={(event) => { 
                                                                        setexpense_number(event.value)
                                                                        check_expense_count(event.value); 
                                                                    }}
                                                                    value={ExpenseNumberlist.filter(option => option.value == expense_number).length != 0 && ExpenseNumberlist.filter(option => option.value == expense_number)[0]}
                                                                    placeholder="--Choose a Number--"
                                                                />
                                                            </div>
                                                            :
                                                            Split_type=="Split by days" &&
                                                            <>
                                                            <div className="col-md-6 mb-1">
                                                            <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_Start_date}
                                                                onChange={(e) => {
                                                                    setExpense_Start_date(e.target.value);
                                                                    check_expense_date(e.target.value,Expense_End_date);
                                                                }}
                                                            />-
                                                            <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_End_date}
                                                                onChange={(e) => {
                                                                    setExpense_End_date(e.target.value);
                                                                    check_expense_date(Expense_Start_date,e.target.value);
                                                                }}
                                                            />
                                                            </div>
                                                            
                                                            </>
                                                            }
                                                            <div className="col-md-2">
                                                            <label className=" fs-5 fw-bold mb-2"></label><br/><br/>
                                                                <button type="button" className="btn font-weight-bold btn-primary" onClick={(event) => { add_expense(Split_type);}}><i className="la la-plus"></i>Add</button>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className=" col-md-1"><p className="fs-5"></p></div>
                                                            <div className=" col-md-3"><p className=" required fs-5">Expense date</p></div>
                                                            <div className=" col-md-3"><p className=" required fs-5">Category</p></div>
                                                            <div className="col-md-2"><p className="required fs-5">Amount</p></div>
                                                            <div className="col-md-2"><p className="required fs-5">Percentage</p></div>
                                                        </div>
                                                        {
                                                            ExpenseList.map((obj,indx,arr)=>{
                                                                return(

                                                                    <div className="row mb-5">
                                                                        <div className="col-md-1 mb-1">
                                                                            <input id="isReimbursable0" class="form-check-input ember-checkbox ember-view" type="checkbox" 
                                                                            onChange={(e) => { let temp = ExpenseList.map(a=>{return{...a}})
                                                                            temp[indx].Claim_reimbursement = e.target.checked;
                                                                            setExpenseList(temp) }} checked={obj.Claim_reimbursement}/>
                                                                        </div>
                                                                        <div className="col-md-3 mb-1">
                                                                            <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={obj.expense_date}
                                                                                onChange={(e) => {
                                                                                    let temp = ExpenseList.map(a=>{return{...a}})
                                                                                    temp[indx].expense_date = e.value
                                                                                    setExpenseList(temp)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div className="col-md-3 mb-1">
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="category"
                                                                                id="category"
                                                                                className="form-control"
                                                                                options={CategoryList}
                                                                                
                                                                                onChange={(val) => {
                                                                                    let temp = ExpenseList.map(a=>{return{...a}})
                                                                                    temp[indx].category = val
                                                                                    setExpenseList(temp)
                                                                                }}        
                                                                                value={ExpenseList[indx].category}
                                                                                placeholder="Select"
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div className="col-md-2 mb-1">
                                                                            <div className="input-group input-group-sm">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">{Currency!=""&&<>{AllCurrencylist.filter(option =>
                                                                                option.currency_id == Currency)[0].currency_symbol}</>}</span>
                                                                            </div>
                                                                                <input type="number" className="form-control form-control-solid" value={ExpenseList[indx].amount} 
                                                                                    onChange={(e) => {
                                                                                        if(parseFloat(e.target.value)>=0){
                                                                                            let temp = ExpenseList.map(a=>{return{...a}})
                                                                                            temp[indx].amount = parseFloat(e.target.value)
                                                                                            temp[indx].percentage = parseFloat(100*e.target.value/Amount).toFixed(2);
                                                                                            let amount=parseFloat(Expense_amount)-parseFloat(temp[indx].amount);
                                                                                            setExpense_amount(parseFloat(amount).toFixed(2))
                                                                                            setExpenseList(temp)
                                                                                            check_expense_amount(temp);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-md-2 mb-1">
                                                                        <div className="input-group input-group-sm">
                                                                            <input type="text" className="form-control form-control-solid" rows="1" value={ExpenseList[indx].percentage} 
                                                                                onChange={(e) => {
                                                                                    let temp = ExpenseList.map(a=>{return{...a}})
                                                                                    temp[indx].percentage = e.target.value;
                                                                                    temp[indx].amount = parseFloat(Amount*e.target.value/100).toFixed(2);
                                                                                    let amount=parseFloat(Expense_amount)-parseFloat(temp[indx].amount);
                                                                                    setExpense_amount(parseFloat(amount).toFixed(2))
                                                                                    setExpenseList(temp)
                                                                                    check_expense_amount(temp);
                                                                                }}
                                                                            ></input>
                                                                            <div class="input-group-append">
                                                                                <span class="input-group-text"> % </span>
                                                                            </div>                                           
                                                                        </div>                             
                                                                        </div>
                                                                            <div className="col-md-1 mb-1">
                                                                                <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                    onClick={()=>{
                                                                                        let temp = ExpenseList.map(a=>{return{...a}})
                                                                                        let amount=parseFloat(Expense_amount)-parseFloat(temp[indx].amount);
                                                                                        setExpense_amount(parseFloat(amount).toFixed(2))
                                                                                        temp.splice(indx,1)
                                                                                        setExpenseList(temp)
                                                                                    }}
                                                                                >&#10006;</button>
                                                                            </div>
                                                                        
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {(Amount-Expense_amount)>0 ?
                                                        <div className="alert alert-warning amount-difference-warning">
                                                        <>The total amount  short by <strong> {BaseCurrency}{parseInt((Amount-Expense_amount)*100)/100} </strong></>
                                                        </div>
                                                        :
                                                        (Expense_amount-Amount)>0 &&
                                                        <div className="alert alert-danger amount-difference-danger">
                                                        <>total amount exceed by <strong> {BaseCurrency}{parseInt((Expense_amount-Amount)*100)/100} </strong></>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer flex-center">
                                                    {write==1 ?
                                                        <button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { SplitExpenseDetails(e) }} type="button" className="btn btn-primary rounded" id="kt_contact_submit_button">
                                                            <span className="indicator-label">{expense_id!=""?"Split":"Split"}</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                        :
                                                        <button onClick={(e) => { SplitExpenseDetails(e) }} style={{ marginTop: '10px' }} type="button" className="btn btn-primary rounded" id="kt_contact_submit_button" disabled>
                                                            <span className="indicator-label">{expense_id!=""?"Split":"Split"}</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    }   
                                                    </div>
                                                    <input type="hidden" id="id" name="activeId" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered mw-850px">
                                            <div className="modal-content">
                                                <form className="form" action="#" id="kt_modal_new_address_form">
                                                    <div className="modal-header" id="kt_modal_new_address_header">
                                                        <h2>{expense_id!=""?"Update":"Clone"} Expense Details</h2>
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body py-10 px-lg-17">
                                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                            <div className="row mb-5">
                                                                <div className="col-md-6 fv-row">
                                                                    <label className="required fs-5 fw-bold mb-2">Expense Date</label>
                                                                    <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                                        onChange={(e) => {
                                                                            setExpense_date(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Merchant</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="category"
                                                                        id="category"
                                                                        className="form-control form-control-solid"
                                                                        options={MerchantList}
                                                                        onChange={(event) => { setMerchant(event.value) }}
                                                                        value={MerchantList.filter(option => option.value == Merchant).length != 0 && MerchantList.filter(option => option.value == Merchant)[0]}
                                                                        placeholder="--Choose a Merchant--"
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            {
                                                                multipleMode?
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6">
                                                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="Currency"
                                                                                id="Currency"
                                                                                className="form-control form-control-solid"
                                                                                options={Currencylist}
                                                                                onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value); }}
                                                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                placeholder="--Choose a Currency--"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="fs-5 fw-bold mb-2"></label><br/>
                                                                            {(LiveCurrencyData.length>0 && Currency!=Currency1)&&

                                                                                <>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                                </>
                                                                                
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row mb-5">
                                                                        <div className=" col-md-3"><p className=" required fs-5">Expense Account</p></div>
                                                                        <div className="col-md-3"><p className="fs-5">Notes</p></div>
                                                                        <div className="col-md-2"><p className="required fs-5">Amount</p></div>
                                                                        <div className="col-md-3"><p className="fs-5">Violation</p></div> 
                                                                        
                                                                    </div>
                                                                    {
                                                                        expenseAccounts.map((obj,indx,arr)=>{
                                                                            return(
                                                                                <div className="row mb-5">
                                                                                    <div className="col-md-3 mb-1">
                                                                                    <Select
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control-solid"
                                                                                        options={CategoryList}
                                                                                        onChange={(val) => {
                                                                                            let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                            temp[indx].account = val
                                                                                            setExpenseAccounts(temp)
                                                                                            violation_check(indx,val,expenseAccounts[indx].notes,expenseAccounts[indx].amount)
                                                                                        }}        
                                                                                        value={expenseAccounts[indx].account}
                                                                                        placeholder="Select"
                                                                                    />
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-1">
                                                                                        <textarea className="form-control form-control-solid" rows="1" value={expenseAccounts[indx].notes} 
                                                                                            onChange={(e) => {
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[indx].notes = e.target.value
                                                                                                setExpenseAccounts(temp)
                                                                                                violation_check(indx,expenseAccounts[indx].account,e.target.value,expenseAccounts[indx].amount) 
                                                                                            }}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                    <div className="col-md-2 mb-1">
                                                                                        <input type="number" className="form-control form-control-solid" value={expenseAccounts[indx].amount} 
                                                                                            onChange={(e) => {
                                                                                                if(parseFloat(e.target.value)>=0){
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp[indx].amount = parseFloat(e.target.value)
                                                                                                    setExpenseAccounts(temp)
                                                                                                    violation_check(indx,expenseAccounts[indx].account,expenseAccounts[indx].notes,parseFloat(e.target.value))    
                                                                                                }
                                                                                            }}
                                                                                        />                                                                        
                                                                                    </div>
                                                                                    {(expenseAccounts[indx].Violation==true || violation_status_check(expenseAccounts[indx].account,expenseAccounts[indx].notes,parseFloat(expenseAccounts[indx].amount))==true) ?
                                                                                    <div className="col-md-3 mb-1">
                                                                                        <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(obj.account,indx) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                                    </div>
                                                                                    :
                                                                                    <div className="col-md-3 mt-9"></div>
                                                                                    }
                                                                                    {
                                                                                        arr.length>1 &&
                                                                                        <div className="col-md-1 mb-1">
                                                                                            <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                                onClick={()=>{
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp.splice(indx,1)
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >&#10006;</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="row mb-5">
                                                                        <div className="col-3">
                                                                            <button className='btn btn-primary rounded fs-7' type="button" onClick={()=>{setExpenseAccounts([...expenseAccounts, {account: null, amount: 0, notes: ""}])}}>&#10011; Add a row</button>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <button className='btn btn-primary rounded fs-7' 
                                                                                onClick={()=>{
                                                                                    setMultipleMode(false)
                                                                                    setExpenseAccounts([expenseAccounts[0]])
                                                                                }}
                                                                            >
                                                                                
                                                                            Back to single expense view
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <p className='fw-bolder fs-4 mt-2'>Total Amount{Currency!=""&&<>({AllCurrencylist.filter(option =>
                                                                                option.currency_id == Currency)[0].currency_symbol})</>}
                                                                                <span className='ps-15'>{expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                            </p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="required fs-5 fw-bold mb-2">Category Name</label>
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="category"
                                                                                id="category"
                                                                                className="form-control form-control-solid"
                                                                                options={CategoryList}
                                                                                onChange={(val) => {
                                                                                    //console.log(val)
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].account = val
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(0,val,temp[0].notes,temp[0].amount);
                                                                                }}        
                                                                                value={expenseAccounts[0].account}
                                                                                placeholder="--Choose a Category--"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6">
                                                                            <label className="required fs-5 fw-bold mb-2">Amount</label>
                                                                            <div className='row'>
                                                                                <div className='col-md-6'>
                                                                                <Select
                                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                    name="Currency"
                                                                                    id="Currency"
                                                                                    className="form-control form-control-solid"
                                                                                    options={Currencylist}
                                                                                    onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value); }}
                                                                                    value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                    placeholder="--Currency--"
                                                                                />
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <input type="number" className="form-control form-control-solid" value={expenseAccounts[0].amount} 
                                                                                        onChange={(e) => {
                                                                                            if(parseFloat(e.target.value)>=0){
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[0].amount = parseFloat(e.target.value)
                                                                                                setExpenseAccounts(temp);
                                                                                                violation_check(0,temp[0].account,temp[0].notes,parseFloat(e.target.value));
                                                                                            }
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                        <>
                                                                            <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                            <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                        </>
                                                                        }
                                                                        <div className="col-md-3 mt-9">
                                                                            <button className='btn btn-primary rounded' onClick={()=>{setMultipleMode(true)}}>Itemize</button>
                                                                        </div>
                                                                        {(expenseAccounts[0].Violation==true || violation_status_check(expenseAccounts[0].account,expenseAccounts[0].notes,parseFloat(expenseAccounts[0].amount))==true) ?
                                                                        <div className="col-md-3 mt-9">
                                                                            <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(expenseAccounts[0].account,0) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                        </div>
                                                                        :
                                                                        <div className="col-md-3 mt-9"></div>
                                                                        }
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="fs-6 fw-bold mb-2">Note</label>
                                                                            <textarea className="form-control form-control-solid" rows="3" value={expenseAccounts[0].notes} 
                                                                                onChange={(e) => {
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].notes = e.target.value
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(0,temp[0].account,e.target.value,parseFloat(temp[0].amount));
                                                                                }} 
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                                <input name="deactivate" className="form-check-input" type="checkbox" value="" id="deactivate" onChange={(event) => { setClaim_reimbursement(event.target.checked) }} checked={Claim_reimbursement}/>
                                                                <label className="form-check-label fw-bold ps-2 fs-6" for="deactivate">Claim reimbursement </label>
                                                            <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {Claim_reimbursement==false &&
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Account"
                                                                        id="Account"
                                                                        className="form-control form-control-solid"
                                                                        options={PaidThrouhAccountlist}
                                                                        onChange={(event) => { setAccount(event.value) }}
                                                                        value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                                        placeholder="--Choose a Account--"
                                                                    />
                                                                </div>
                                                            </div>
                                                            }<br/>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">References </label>
                                                                    <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                                                </div>

                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Add To Report</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Report"
                                                                        id="Report"
                                                                        className="form-control form-control-solid"
                                                                        options={Reportlist}
                                                                        onChange={(event) => { setReport(event.value) }}
                                                                        value={Reportlist.filter(option => option.value == Report).length != 0 && Reportlist.filter(option => option.value == Report)[0]}
                                                                        placeholder="--Choose a Report--"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">

                                                                <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Description</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer flex-center">
                                                    {write==1 ?
                                                        <button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitExpenseDetails(e) }} type="button" className="btn btn-primary rounded" id="kt_contact_submit_button">
                                                            <span className="indicator-label">{expense_id!=""?"Update":"Clone"}</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                        :
                                                        <button onClick={(e) => { submitExpenseDetails(e) }} style={{ marginTop: '10px' }} type="button" className="btn btn-primary rounded" id="kt_contact_submit_button" disabled>
                                                            <span className="indicator-label">{expense_id!=""?"Update":"Clone"}</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    }   
                                                    </div>
                                                    <input type="hidden" id="id" name="activeId" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="modal fade" id="AddExpense" tabindex="-1" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered mw-700px">
                                            <div class="modal-content">
                                                <form class="form" action="#" id="kt_modal_new_address_form">
                                                    <div class="modal-header" id="kt_modal_new_address_header">
                                                        <h2>{expense_id=="" ?"Add":"Update"} Expense</h2>
                                                        <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span class="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="modal-body py-10 px-lg-17">
                                                        <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                        {loading_modal ?
                                                        <>
                                                            <div className="row mb-5">
                                                                <div className="col-md-6 fv-row">
                                                                    <label className="required fs-5 fw-bold mb-2">Expense Date</label>
                                                                    <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                                        onChange={(e) => {
                                                                            setExpense_date(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Merchant</label>
                                                                    <Select
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="category"
                                                                        id="category"
                                                                        className="form-control form-control-solid"
                                                                        options={MerchantList}
                                                                        onChange={(event) => { setMerchant(event.value) }}
                                                                        value={MerchantList.filter(option => option.value == Merchant).length != 0 && MerchantList.filter(option => option.value == Merchant)[0]}
                                                                        placeholder="--Choose a Merchant--"
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            {
                                                                multipleMode?
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6">
                                                                            <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                            <Select
                                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="Currency"
                                                                                id="Currency"
                                                                                className="form-control form-control-solid"
                                                                                options={Currencylist}
                                                                                onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                                                value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                placeholder="--Choose a Currency--"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="fs-5 fw-bold mb-2"></label><br/>
                                                                            {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                                <>
                                                                                <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row mb-5">
                                                                        <div className=" col-md-3"><p className=" required fs-5">Expense Account</p></div>
                                                                        <div className="col-md-3"><p className="fs-5">Notes</p></div>
                                                                        <div className="col-md-2"><p className="required fs-5">Amount</p></div>
                                                                        <div className="col-md-3"><p className="fs-5">Violation</p></div> 
                                                                    </div>
                                                                    {
                                                                        expenseAccounts.map((obj,indx,arr)=>{
                                                                            return(
                                                                                <div className="row mb-5">
                                                                                    <div className="col-md-3 mb-1">
                                                                                    <Select
                                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                        name="category"
                                                                                        id="category"
                                                                                        className="form-control form-control-solid"
                                                                                        options={CategoryList}
                                                                                        onChange={(val) => {
                                                                                            let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                            temp[indx].account = val
                                                                                            setExpenseAccounts(temp)
                                                                                            violation_check(indx,val,expenseAccounts[indx].notes,expenseAccounts[indx].amount) 
                                                                                        }}        
                                                                                        value={expenseAccounts[indx].account}
                                                                                        placeholder="--Select--"
                                                                                    />
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-1">
                                                                                        <textarea className="form-control form-control-solid" rows="1" value={expenseAccounts[indx].notes} 
                                                                                            onChange={(e) => {
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[indx].notes = e.target.value
                                                                                                setExpenseAccounts(temp)
                                                                                                violation_check(indx,expenseAccounts[indx].account,e.target.value,expenseAccounts[indx].amount) 
                                                                                            }}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                    <div className="col-md-2 mb-1">
                                                                                        <input type="number" className="form-control form-control-solid" value={expenseAccounts[indx].amount} 
                                                                                            onChange={(e) => {
                                                                                                if(parseFloat(e.target.value)>=0){
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp[indx].amount = parseFloat(e.target.value)
                                                                                                    setExpenseAccounts(temp)
                                                                                                    violation_check(indx,expenseAccounts[indx].account,expenseAccounts[indx].notes,parseFloat(e.target.value))  
                                                                                                }
                                                                                            }}
                                                                                        />                                                                        
                                                                                    </div>
                                                                                    {expenseAccounts[indx].Violation==true ?
                                                                                        <div className="col-md-3 mb-1">
                                                                                            <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(obj.account,indx) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-md-3 mb-1"></div>
                                                                                    }
                                                                                    {
                                                                                        arr.length>1 &&
                                                                                        <div className="col-md-1 mb-1">
                                                                                            <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                                onClick={()=>{
                                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                    temp.splice(indx,1)
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >&#10006;</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="row mb-5">
                                                                        <div className="col-3">
                                                                            <button className='btn btn-primary rounded fs-7' type="button" onClick={()=>{setExpenseAccounts([...expenseAccounts, {account: null, amount: 0, notes: ""}])}}>&#10011; Add a row</button>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <button className='btn btn-primary rounded fs-7' 
                                                                                onClick={()=>{
                                                                                    setMultipleMode(false)
                                                                                    setExpenseAccounts([expenseAccounts[0]])
                                                                                }}
                                                                            >
                                                                                
                                                                            Back to single expense view
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <p className='fw-bolder fs-4 mt-2'>Total Amount{Currency!=""&&<>({AllCurrencylist.filter(option =>
                                                                    option.currency_id == Currency)[0].currency_symbol})</>}
                                                                                <span className='ps-15'>{expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                            </p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="required fs-5 fw-bold mb-2">Category Name</label>
                                                                            <Select
                                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                name="category"
                                                                                id="category"
                                                                                className="form-control form-control-solid"
                                                                                options={CategoryList}
                                                                                onChange={(val) => {
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].account = val
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(0,val,temp[0].notes,temp[0].amount);
                                                                                }}        
                                                                                value={expenseAccounts[0].account}
                                                                                placeholder="--Choose a Category--"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-6">
                                                                            <label className="required fs-5 fw-bold mb-2">Amount</label>
                                                                            <div className='row'>
                                                                                <div className='col-md-3'>
                                                                                <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                    name="Currency"
                                                                                    id="Currency"
                                                                                    className="form-control form-control-solid"
                                                                                    options={Currencylist}
                                                                                    onChange={(event) => { setCurrency(event.value);load_currency_conversion_value(event.value);}}
                                                                                    value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                                                    placeholder="--Currency--"
                                                                                />
                                                                                </div>
                                                                                <div className='col-md-9'>
                                                                                    <input type="number" className="form-control form-control-solid" value={expenseAccounts[0].amount} 
                                                                                        onChange={(e) => {
                                                                                            if(parseFloat(e.target.value)>=0){
                                                                                                let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                                temp[0].amount = parseFloat(e.target.value)
                                                                                                setExpenseAccounts(temp)
                                                                                                violation_check(0,temp[0].account,temp[0].notes,parseFloat(e.target.value));
                                                                                            }
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(LiveCurrencyData.length>0 && Currency!=Currency1)&&
                                                                            <>
                                                                            <span class="badge badge-light-primary fs-8 fw-bolder mb-1">1 {TargetCurrencyCode} ={Currency_conversion_value}{BaseCurrencyCode}</span><br/>
                                                                            <span class="badge badge-light-primary fs-8 fw-bolder">Amount in {BaseCurrencyCode}:{BaseCurrency}{Currency_conversion_value*expenseAccounts.reduce((p,o)=>{return p+o.amount},0)}</span>
                                                                            </>
                                                                        }
                                                                        <div className="col-md-3 mt-9">
                                                                            <button className='btn btn-primary rounded' onClick={()=>{setMultipleMode(true)}}>Itemize</button>
                                                                        </div>
                                                                        {expenseAccounts[0].Violation==true ?
                                                                            <div className="col-md-3  mt-9">
                                                                                <a href="#" class="btn btn-sm btn-warning" onClick={(e) => { expense_rule_check(expenseAccounts[0].account,0) }} data-bs-toggle="modal" data-bs-target="#ViolationModal">Violation</a> 
                                                                            </div>
                                                                            :
                                                                            <div className="col-md-3 mb-1"></div>
                                                                        }
                                                                    </div>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-8">
                                                                            <label className="fs-6 fw-bold mb-2">Note</label>
                                                                            <textarea className="form-control form-control-solid" rows="3" value={expenseAccounts[0].notes} 
                                                                                onChange={(e) => {
                                                                                    let temp = expenseAccounts.map(a=>{return{...a}})
                                                                                    temp[0].notes = e.target.value
                                                                                    setExpenseAccounts(temp)
                                                                                    violation_check(0,temp[0].account,e.target.value,parseFloat(temp[0].amount));
                                                                                }} 
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="form-check form-check-solid fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                                <input name="deactivate" className="form-check-input" type="checkbox" value="" id="deactivate" onChange={(event) => { setClaim_reimbursement(event.target.checked) }} checked={Claim_reimbursement}/>
                                                                <label className="form-check-label fw-bold ps-2 fs-6" for="deactivate">Claim reimbursement </label>
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            {Claim_reimbursement==false &&
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Paid Through</label>
                                                                    <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                        name="Account"
                                                                        id="Account"
                                                                        className="form-control form-control-solid"
                                                                        options={PaidThrouhAccountlist}
                                                                        onChange={(event) => { setAccount(event.value) }}
                                                                        value={PaidThrouhAccountlist.filter(option => option.value == Account).length != 0 && PaidThrouhAccountlist.filter(option => option.value == Account)[0]}
                                                                        placeholder="--Choose a Account--"
                                                                    />
                                                                </div>
                                                            </div>
                                                            }<br/>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">References </label>
                                                                    <input id="References" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" value={References} onChange={(e) => { setReferences(e.target.value) }} />
                                                                </div>

                                                            </div>
                                                            
                                                            <div className="row mb-5">

                                                                <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">description</label><textarea id="subcategory_discription" className="form-control form-control-solid" rows="3" name="discription" placeholder="Max 150 Charecters" value={description} autocomplete="off"onChange={(e) => { setdescription(e.target.value) }}></textarea></div>
                                                            </div>
                                                            </>
                                                            :
                                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                        }
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer flex-center">
                                                    {write == 1 ?
                                                        <button type="submit"
                                                            className="btn btn-primary rounded"
                                                            data-kt-indicator={mainSubmitButtonState?"on":"off"}
                                                            disabled={mainSubmitButtonState}
                                                            onClick={(e) => { register(e) }}
                                                        >
                                                            <span className="indicator-label">Submit</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
                                                        :
                                                        <button type="submit"
                                                            className="btn btn-primary rounded"
                                                            onClick={(e) => { register(e) }} disabled
                                                        >
                                                            <span className="indicator-label">Submit</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
                                                        }   
                                                    </div>
                                                    <input type="hidden" id="id" name="activeId" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="ViolationModal" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered mw-650px">
                                            <div className="modal-content">
                                                <form className="form" action="#" id="kt_modal_new_address_form">
                                                    <div className="modal-header" id="kt_modal_new_address_header">
                                                        <h2>Warning Violations</h2>
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="modal-body py-10 px-lg-17">
                                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                        {(Expense_Amount_Limit!="" || Receipt_Required_Limit!="" || Make_Description_Mandatory!="")&&
                                                            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                                                {parseInt(Expense_Amount_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Exceeds Expense Amount Limit {BaseCurrency}{parseInt(Expense_Amount_Limit)}</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }<br/>
                                                                {parseInt(Receipt_Required_Limit)<parseInt(expenseAccounts[Index].amount)&&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Exceeds Receipt Required Limit {BaseCurrency}{parseInt(Receipt_Required_Limit)}</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }<br/>
                                                                {Make_Description_Mandatory=="Yes" && expenseAccounts[Index].notes=="" &&
                                                                <>			
                                                                <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
                                                                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                                <div class="d-flex flex-stack flex-grow-1">
                                                                    <div class="fw-bold">
                                                                        {/*<h4 class="text-gray-900 fw-bolder">Quick file uploader</h4>*/}
                                                                        <div class="fs-6 text-gray-700">Description is mandatory.</div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }
                                                            </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="modal fade" id="RemoveExpense" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Remove Expense</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">
                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                    <p className="font-small">If you remove the expense, it will be removed from this report and will be listed as an unreported expense. Are you sure you want to remove it?</p>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        <button type="button" className="btn btn-primary" onClick={(e) => { Remove_Expense(e); }} >Remove</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                                </div>*/}
                                    

                                    <div className="modal fade" id="EditAttachments" tabindex="-1" aria-hidden="true">
                                        <div className="modal-dialog mw-650px">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Attach Documents</h5>
                                                    <div className="modal-header pb-0 border-0 justify-content-end">

                                                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                                            <span className="svg-icon svg-icon-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group row">
                                                        {(document_list.length<document_count)&&
                                                            <div>
                                                                <label className="fs-5 fw-bold mb-2">Maximum {document_count-document_list.length} files</label>
                                                                <Dropzone
                                                                    getUploadParams={getUploadParams}
                                                                    onChangeStatus={handleChangeStatus2}
                                                                    onSubmit={handleSubmit}
                                                                    validate={handleValidation2}
                                                                    LayoutComponent={CustomLayout}
                                                                    styles={{
                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                    }}
                                                                    maxFiles={document_count-document_list.length}
                                                                    // canRemove={false}
                                                                    PreviewComponent={Preview}
                                                                    accept="image/*,.pdf"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" id="kt_contact_submit_button" data-bs-dismiss="modal">Cancel</button>
                                                    <form >
                                                        {write==true &&<button type="button" className="btn btn-primary" id="kt_contact_submit_button" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { submitattachments(e); }} ><span className="indicator-label">Save</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span></button>
                                                        }
                                                    </form>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </>
                                :
                                (state == 1 ) ?
                                <>

                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2">


                                                </div>


                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                            <div className="fs-6 text-gray-600">
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </>
                                : <>
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />

                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                        </div>
                                    </div>
                                </>
                            }

                            <br />
                        </div>
                    </div>
                </div>
                </div>
                
            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ExpenseDetails;

import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from 'react-router-dom';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
function CreateAccount(props) {
	//console.log(props);
	let modal = 2;
	let [RedirectState, setRedirectState] = useState(2);
	let [captchaToken, setCaptchaToken] = useState("");
	const [value, setValue] = useState();
	const [name, setname] = useState("");
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [user_id, setuser_id] = useState();
	const [reference_id, setreference_id] = useState();
	function onChange(value) {
		setCaptchaToken(value)
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		async function getverificationmailstatus() {
            var object = {};
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/check-account-status-id`, { method: 'POST', body: JSON.stringify({"id":props.match.params.id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
			console.log(body)
			if(body.length>0)
			{
				setuser_id(body[0].user_id);
				setname(body[0].name);
				setreference_id(body[0].reference_id);
			}
        }
        getverificationmailstatus();        
	}, [])

	const register = (e) => {

		e.preventDefault();
		var object = {};
		object.name=name;
		object.reference_id=reference_id;
		object.id=props.match.params.id;
		object.service="expense";
		object.email=props.history.location.email;
		object.password = document.getElementById("password").value;
		object.cpassword = document.getElementById("cpassword").value;
        //object.changePasswordId=props.match.params.id;

		//console.log(object)
		//var exp2=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%^&*])[a-zA-Z\d@.#$%^&*]{8,}$/;
		var exp2=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-]).{8,}$/;
			if(object.password=="")
			{
				//console.log("9");
				swal("Warning!", "Password cannot be empty", "warning");
				return false;
			}
			else if(object.password.length < 8)
			{
				swal("Warning!", "Password must have 8 characters.", "warning");
				return false;
			}
		/*else if (!object.password.match(exp2)) {
			swal("Warning!", "Password is not valid, try with a different password.", "warning");
			return false;
		}*/

			if(object.cpassword=="")
			{
				//console.log("14");
				swal("Warning!", "Please confirm your password by re-typing.", "warning");
				return false;
			}
			else if (object.cpassword != object.password)
			{
				//console.log("15");
				swal("Warning!", "New Password and Confirm Password is not matching.", "warning");
				return false;
			}
			
			if(document.getElementById("agreement").checked == false)
			{
				//console.log("16");
				swal("Warning!", "Please check our terms and condition.", "warning");
				return false;
			}

		setMainSubmitButtonState(true)

		axios.post(`${process.env.REACT_APP_SERVER_URL}/create-account`, object).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data.operation == "success") {
				swal("Great!", "Your account is successfully created. ", "success", {
					buttons: {
						continue: {
							text: "OK",
							value: "continue",
						},
					}
				}).then((result) => {
					//console.log(result);
					switch (result) {
						case "continue":
							window.location = `/login`;
							break;
						default:
							window.location = `/login`;
							break;

					}
				});
			}
			else {
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "something went wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
		.catch(function (error) {
			swal("Oops!", "something went wrong", "error").then((res) => {
				window.location.reload();
			});
		});
	}

	useEffect(() => {
		function loadScripts() {

			var se = document.createElement('script');
			se.src = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js";
			se.type = "text/javascript";
			document.body.appendChild(se);
		};
		loadScripts();
	}, [])

	return (
		<>
			{
				(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
				)
					:
					null
			}
			<div className="bg-dark header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{ "--kt-toolbar-height": "55px", "--kt-toolbar-height-tablet-and-mobile": "55px" }} >
				<div className="d-flex flex-column flex-root">
					<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ "background-size1": "100% 50%", "background-image": "url(assets/media/misc/outdoor.png)" }}>
						<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
							<a href="/login" className="mb-12">
								<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-45px" />
							</a>
							<div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
								<form className="form w-100" novalidate="novalidate" id="kt_sign_up_form" onSubmit={(e) => { register(e) }}>
								<div className="mb-10 text-center">
								<h1 className="text-dark mb-3">Create an Account for</h1>
								<div className="text-gray-400 fw-bold fs-4">
								<Link to="/login" className="link-primary fw-bolder">{user_id}</Link></div>
							</div>
							
							<div className="d-flex align-items-center mb-10">
								<div className="border-bottom border-gray-300 mw-50 w-100"></div>
								<span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
								<div className="border-bottom border-gray-300 mw-50 w-100"></div>
							</div>
								<div className="row fv-row mb-7">
										<div className="col-xl-12">
											<label className="form-label fw-bolder text-dark fs-6">Name</label>
											<input className="form-control" type="text" value={name} onChange={(e) => { setname(e.target.value) }} />
										</div>
										<div className="text-muted">Please enter Name.</div>
									</div>
									<div className="mb-10 fv-row" data-kt-password-meter="true">
										<div className="mb-1">
											<label className="form-label fw-bolder text-dark fs-6">New Password</label>
											<div className="position-relative mb-3">
												<input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" id="password" autocomplete="off" />
												<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
													<i className="bi bi-eye-slash fs-2"></i>
													<i className="bi bi-eye fs-2 d-none"></i>
												</span>
											</div>
										</div>
										<div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
									</div>
									<div className="fv-row mb-5">
										<label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
										<input className="form-control form-control-lg form-control-solid" type="password" id="cpassword" placeholder="" name="confirm-password" autocomplete="off" />
									</div>
									<div className="fv-row mb-10">
										<label className="form-check form-check-custom form-check-solid">
											<input className="form-check-input" id="agreement" type="checkbox" name="toc" value="1" />
											<span className="form-check-label fw-bold text-gray-700 fs-6">I Agree &amp;
												<a className="ms-1 link-primary">Terms and conditions</a>.</span>
										</label>
									</div>
									<div className="text-center">
										
										<button data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary rounded" onClick={(e) => { register(e) }}>
											<span className="indicator-label">Submit</span>
											<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="d-flex flex-center flex-column-auto p-10">
							<div className="d-flex align-items-center fw-bold fs-6">

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default CreateAccount;
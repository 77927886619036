import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ShowProfiles = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [PurchaseDetails, setPurchaseDetails] = useState([]);
    //const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [Permission, setpermission] = useState([]);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [groupname, setGroupname] = useState("");
    const [account_head, setAccount_head] = useState("");
    const [accountdesc, setAccountdesc] = useState("");
    const [product_name, setProduct_name] = useState("");
    const [vendor_name, setVendor_name] = useState("");
    const [manufacturer_name, setManufacturer_name] = useState("");
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    const [shortdesc, setShortdesc] = useState("");
    const [longdesc, setLongdesc] = useState("");
    const [product_active, setproduct_active] = useState("Yes");
    // let [group_type, setGroup_type] = useState("");
    const [status, setstatus] = useState("");
    const [tags, setTags] = React.useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [category, setCategory] = useState([]);
    const [outofstock,setoutofstock] = useState('');
    const [product_name1, setproduct_name1] = useState("");

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    let [object, setobject] = useState({});
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [Id, setId] = useState("");
    const [userlist, setuserList] = useState([])
    const [editmodal, setEditModal] = useState([]);
    const [globalsubcategory, setGlobalSubcategory] = useState([]);

    
    var page_name = "profiles";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
        downloadOptions:{filename: 'tableDownload.csv', separator: ',',filterOptions:{useDisplayedColumnsOnly:true}}
    };

    const columns = [
        {
            name: "role_name",
            label: "Role Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "role_description",
            label: "Role Description",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) 
							{
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);


    useEffect(() => {
        async function getpolicydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write && organization_id!="") {
            user.email && visibility && getpolicydetails();
        }

    }, [user, visibility, read, write,organization_id])

    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.profile_name = obj.user_profile;
                    if(obj.profile_description!="")
                    {
                        temp_obj.profile_description = obj.profile_description;
                    }
                    else
                    {
                        temp_obj.profile_description ="---";
                    }
                    temp_obj.status = obj.add_status;
                    var id = obj.user_profile_id;
                    temp_obj.actions =
                        <div class="dropdown">
                            {
                                write ?
                            <>
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Edit
                            </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a href={`/update-profiles/${id}`} class="dropdown-item">Edit details</a></li>
                                    {/*<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>*/}
                                    <li><a onClick={(e) => { deleteStatus(id) }} data-bs-toggle="modal" data-bs-target="#deleteStatus" class="dropdown-item" href="#">Delete</a></li>
                                </ul>
                            </>        
                                :
                                null
                            }
                        </div>
                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();
    }, [editmodal])
 
    const deleteStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].user_profile_id) {
                setId(editmodal[i].user_profile_id)
                setstatus("Deleted");
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": Id,"page_name": page_name,"organization_id":organization_id};
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-role-information`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Role information deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });  
    }

    return (
        <>
        { 
        (state==4) ?
        <>
        <Redirect to={{
            pathname: `/update-user/${object.user_unique_id}`,
            state: object
        }}
        />
        </>
        :
        null
        }
        
    
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Show Profiles
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                
                <div id="kt_app_content" class="app-content flex-column-fluid">			
                    <div id="kt_app_content_container" class="app-container container-xxl">
                    {state==1 ?
                    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
                        {editmodal.length > 0 && editmodal.map((obj, index) => {
                            return (
                            <>
                            <div class="col-md-4"> 
                                <div class="card card-flush h-md-100"> 
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h2>{obj.user_profile}</h2>
                                        </div> 
                                    </div>
                                    
                                    <div class="card-body pt-1">
                                        <div class="fw-bold text-gray-600 mb-5">Total users with this Profile: {obj.user_count}</div>
                                        <div class="d-flex flex-column text-gray-600">
                                            {obj.profile_description!=""&&
                                            <div class='d-flex align-items-center py-2'>
                                                <span class='bullet bg-primary me-3'></span>
                                                <em>{obj.profile_description}</em>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    
                                    <div class="card-footer flex-wrap pt-0">
                                        <Link to={`view-profiles/${obj.user_profile_id}`} class="btn btn-light btn-active-primary my-1 me-2">View Profile</Link>
                                        {obj.default!="true" && <Link to={`update-profile/${obj.user_profile_id}`} class="btn btn-light btn-active-primary">Edit Profile</Link>}
                                    </div>
                                </div>
                            </div>
                            </>
                            )
                        })}
                        </div>
                        :
                        state == 2 ?
                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                            <>
                                <div className="card-body pt-0">
                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Profiles</div>
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                <div className="fs-6 text-gray-600"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                    </div>
                </div>
                {/*<div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Role Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Policies</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>*/}
            </div>
            
            <div className="modal fade" id="deleteStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Role</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    <h3>Are you sure about delete the Role?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {<button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"}  disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {(status == "Active"|| status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active" || status == "ACTIVE") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default ShowProfiles;

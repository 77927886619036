import React from 'react'

const SetColor = (props) => {
    const { value, index, change } = props;
    let color = 'green';
    if (value == 'UNREPORTED' || value == 'UNSUBMITTED' || value == 'Stop' || value == 'Pending' || value == 'Open' || value == 'Expired' || value == 'Deactivated' || value == 'Closed') {
        color = 'red';
    }
    else if(value == 'AWAITING APPROVAL'){
        color = 'orange';
    }
    else if(value == 'Draft' || value == 'Recalled')
    {
        color = 'skyblue';
    }

    return (
        <p
            value={value}
            onChange={event => change(event.target.value, index)}
            style={{ color: color }}>{value}
        </p>
    )
}

export default SetColor

import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import $, { data } from 'jquery';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'

import uuid from 'react-uuid';
import { Link, Redirect } from 'react-router-dom';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function AddUser(props) {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    let [user, setUser] = useState({});
    let [state, setstate] = useState(2);
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Employee_id, setEmployee_id] = useState("");
    const [Gender, setGender] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Skip_mail, setSkip_mail] = useState(false);
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    let [Department, setDepartment] = useState("");
    let [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    let [Role, setRole] = useState("");
    const [UserRoleList, setUserRoleList] = useState([]);
    let [Profile, setProfile] = useState("");
    const [UserProfileList, setUserProfileList] = useState([]);
    let [Policy, setPolicy] = useState("");
    const [PolicyList, setPolicyList] = useState([]);
    const [GenderList, setGenderList] = useState([{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }])
    const [editmodal, setEditModal] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "add-user";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count);
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id!="" && validateJWT();

    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

    useEffect(() => {
        async function getuserdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
            let temp=[];
            temp.push({value:"user_all" ,label:"All User"});
            for(let i=0;i<body.length;i++)
            {
                temp.push({value: body[i].user_id ,label:body[i].user_id});
            }
            setuserList1(temp);
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setuserList(temp_dropdown);

        }
        if(read || write)
        {
            (user.email && visibility) && getuserdetails();
        }

    }, [user, visibility, read, write])


    useEffect(() => {

        async function getdeparmentdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.department_name;
                    temp_obj.value = obj.department_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setDepartmentList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getdeparmentdetails();
        }
        async function getUserRoledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.user_role;
                    temp_obj.value = obj.user_role_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setUserRoleList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserRoledetails();
        }

        async function getUserProfiledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.user_profile;
                    temp_obj.value = obj.user_profile_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setUserProfileList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserProfiledetails();
        }

        async function getUserPolicydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-travel-policy`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.policy_name;
                    temp_obj.value = obj.unique_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            console.log(temp_dropdown)
            setPolicy(body.filter(option => option.default_policy =="Yes")[0].unique_id);
            setPolicyList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserPolicydetails();
        }

    }, [user, visibility, read, write])


    const submitUser = (e) => {
        e.preventDefault();
        var object = {};
        object.organization_id=organization_id;
        object.name = Name;
        object.email_id = Email;
        object.mobile = Mobile;
        object.Employee_id = Employee_id;
        object.Submit_to = Submit_to;
        object.role = Role;
        object.profile = Profile;
        object.service = "expense";
        object.Dob_date = Dob_date;
        object.department = Department;
        object.Joining_date = Joining_date;
        object.Gender = Gender;
        object.Policy = Policy;
        object.Skip_mail = Skip_mail;
        object.Designation= Designation;
        object.page_name = page_name;


        if (object.name == "") {
            swal("Warning!", "Name cannot be a empty", "warning");
            return false;
        }
        var exp=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if(object.email_id=="")
        {
            //console.log("7");
            swal("Warning!", "Please provide an email", "warning");
            return false;
        }
        else if (!object.email_id.match(exp))
        {
            //console.log("8");
            swal("Warning!", "Please provide a valid email", "warning");
            return false;
        }
        for (var i = 0; i < userlist1.length; ++i) {
            if (userlist1[i].label == object.email_id) {
                swal("Oops!", "This email id already connected to your organization, try different one!", "error");
                return false;
            }
        }
        if (object.role == "") {
            swal("Warning!", "Role field cannot be a empty", "warning");
            return false;
        }
        if (object.Policy == "") {
            swal("Warning!", "Policy field cannot be a empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-user`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "user added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_user_name") {
                swal("Oops!", "Use a different User name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
        
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            
                    <div className="d-flex flex-column flex-column-fluid">
                        <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">New User
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-xxl">
                            {state==1 ?
                                <>
                                <div className="card">
                                    <form className="form">
                                        <div className="card-body border-top p-9">
                                            <div className=" py-10 px-lg-17">
                                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Name</label>
                                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Name} onChange={(e) => { setName(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Employee ID</label>
                                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Employee_id} onChange={(e) => { setEmployee_id(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>

                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Email} onChange={(e) => { setEmail(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Role</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={UserRoleList}
                                                                onChange={(event) => { setRole(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Role).length != 0 && UserRoleList.filter(option => option.value == Role)[0]}
                                                                placeholder="--Choose a Role--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Profile</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={UserProfileList}
                                                                onChange={(event) => { setProfile(event.value) }}
                                                                value={UserProfileList.filter(option => option.value == Profile).length != 0 && UserProfileList.filter(option => option.value == Profile)[0]}
                                                                placeholder="--Choose a Profile--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={userlist}
                                                                onChange={(event) => { setSubmit_to(event.value) }}
                                                                value={userlist.filter(option => option.value == Submit_to).length != 0 && userlist.filter(option => option.value == Submit_to)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={DepartmentList}
                                                                onChange={(event) => { setDepartment(event.value) }}
                                                                value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                                                placeholder="--Choose a Department--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Policy</label>
                                                        <Select
                                                            name="category"
                                                            id="category"
                                                            className="form-control form-control-solid"
                                                            options={PolicyList}
                                                            onChange={(event) => { setPolicy(event.value) }}
                                                            value={PolicyList.filter(option => option.value == Policy).length != 0 && PolicyList.filter(option => option.value == Policy)[0]}
                                                            placeholder="--Select--"
                                                        />
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                                            <input type="date" name="Dob_date" className="form-control" id="Dob_date" placeholder="Sales Start Date" value={Dob_date}
                                                                onChange={(e) => {
                                                                    setDob_date(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Gender</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={GenderList}
                                                                onChange={(event) => { setGender(event.value) }}
                                                                value={GenderList.filter(option => option.value == Gender).length != 0 && GenderList.filter(option => option.value == Gender)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                                            <input type="date" name="Joining_date" className="form-control" id="Joining_date" placeholder="Sales Start Date" value={Joining_date}
                                                                onChange={(e) => {
                                                                    setJoining_date(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Designation</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={UserRoleList}
                                                                onChange={(event) => { setDesignation(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Designation).length != 0 && UserRoleList.filter(option => option.value == Designation)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <label class="form-check form-check-custom form-check-solid align-items-start">
                                                        <input class="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_mail} onChange={(e) => {
                                                                    setSkip_mail(e.target.checked);
                                                                }}/>
                                                        <span class="form-check-label d-flex flex-column align-items-start">
                                                            <span class="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                                            {Skip_mail &&<span class="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                {write==1 &&
                                                <button type="submit"
                                                    className="btn btn-primary rounded"
                                                    onClick={(e) => { submitUser(e) }}
                                                    data-kt-indicator={mainSubmitButtonState?"on":"off"}
                                                    disabled={mainSubmitButtonState}
                                                >
                                                    <span className="indicator-label">Submit</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                </>
                                :
                                <>
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-header"></div>
                                                <div className="card-body pt-0">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                </>
                            }
                            </div>
                        </div>
                    </div>
                    <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>
    );
}
export default AddUser;